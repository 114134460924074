import React from "react";
import { ConfigProvider, Empty, Menu, Dropdown, Table, Avatar } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { deleteProgramExercise } from "../../redux/reducers/programSlice";
import { getImageInitialsFullname } from "../../helpers/user";

const ExerciseTable: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { exerciseDiaryData } = useAppSelector((state) => state.program);

  const deleteHandler = (id: string) => {
    dispatch(
      deleteProgramExercise({
        id: id,
      })
    );
  };

  const menu = (data: any) => (
    <Menu>
      <Menu.Item key="0" onClick={() => deleteHandler(data.id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const actionIcon = (data: any) => {
    return (
      <span onClick={(e) => e.stopPropagation()}>
        <Dropdown
          overlay={menu(data)}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <i className="more-icon cp"></i>
        </Dropdown>
      </span>
    );
  };

  const columns = [
    {
      key: "name",
      title: "Name",
      width: "35%",
      render: (_1: string, record: any) => {
        return (
          <div className="d-flex align-items-center">
            {record.imageUrl ? (
              <Avatar shape="circle" size="large" src={record.imageUrl} />
            ) : (
              <Avatar shape="circle" size="large">
                {getImageInitialsFullname(record.title)}
              </Avatar>
            )}
            <div className="ms-3">
              <p className="m-0 fw-medium">{record.title}</p>
            </div>
          </div>
        );
      },
    },
    {
      key: "description",
      title: "Exercise Description",
      dataIndex: "description",
      width: "30%",
      render: (val: string) => {
        return <>{val}</>;
      },
    },
    {
      title: "",
      width: "10%",
      render: (_: any, data: any) => actionIcon(data),
    },
  ];

  return (
    <div className="event-wraper ">
      <div className="event-table-container singer-custom-table">
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No response found"
            />
          )}
        >
          <Table
            columns={columns}
            dataSource={exerciseDiaryData}
            className="cp"
            pagination={false}
            showSorterTooltip={false}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default ExerciseTable;
