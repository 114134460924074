import { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { chartClickHandler } from "../../../../helpers/metric";
import { useAppSelector } from "../../../../redux/hooks";

const sleepOptions = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Sleep(mins)",
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
        // callback: function (label: string) {
        //   return label + "%";
        // },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsbold",
            size: 11,
            lineHeight: "16px",
            weight: 500,
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const dietOptions = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Healthiness",
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsbold",
            size: 11,
            lineHeight: "16px",
            weight: 500,
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
  onClick: function (...args: any[]) {
    try {
      if (args[1].length > 0) {
        const e = args[1][0];
        //@ts-ignore
        var y_value = this.data.datasets[0].data[e.index];
        chartClickHandler("Diet", y_value.date, y_value.userId);
      }
    } catch (err) {
      console.log(err);
    }
  },
};

const exerciseOptions = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Minutes",
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
        // callback: function (label: string) {
        //   return label + "%";
        // },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsbold",
            size: 11,
            lineHeight: "16px",
            weight: 500,
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const smokeOptions = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "No. of times",
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsbold",
            size: 11,
            lineHeight: "16px",
            weight: 500,
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const alcoholOptions = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Drinks(ml)",
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsbold",
            size: 11,
            lineHeight: "16px",
            weight: 500,
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const medicineOptions = {
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 100,
      title: {
        display: true,
        text: "Medicine Adherence",
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsbold",
            size: 11,
            lineHeight: "16px",
            weight: 500,
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
  onClick: function (...args: any[]) {
    try {
      if (args[1].length > 0) {
        const e = args[1][0];
        //@ts-ignore
        var y_value = this.data.datasets[0].data[e.index];
        chartClickHandler("Medicine", y_value.date, y_value.userId);
      }
    } catch (err) {
      console.log(err);
    }
  },
};

const BarActionChart = () => {
  const { type, chartData } = useAppSelector((state) => state.console);

  const options = useMemo(() => {
    if (type === "Sleep") {
      return sleepOptions;
    } else if (type === "Diet") {
      return dietOptions;
    } else if (type === "Exercise") {
      return exerciseOptions;
    } else if (type === "Smoke") {
      return smokeOptions;
    } else if (type === "Alcohol") {
      return alcoholOptions;
    } else if (type === "Medicine") {
      return medicineOptions;
    } else {
      return sleepOptions;
    }
  }, [type]);

  return <Bar data={chartData} options={options} />;
};

export default BarActionChart;
