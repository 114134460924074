import React from "react";
import { Select } from "antd";
import InputField from "../Common/CustomInput";

type Props = {
  updateScore: Function;
  handleRemoveScore: Function;
  scoreEval: any[];
};

const ScoreValidation = (props: Props) => {
  const addScore = () => {
    props.updateScore(0, "ADD", "", "");
  };

  const handleChange = (val: any, key: string, idx: number) => {
    props.updateScore(idx, "CHANGE", key, val);
  };

  const removeScore = (index: any) => {
    props.handleRemoveScore(index);
  };

  return (
    <div className="score-validation">
      <div className="score-validation-header">
        <h5>Score</h5>
        <div className="add-score cp" onClick={() => addScore()}></div>
      </div>
      {props.scoreEval.map((_: any, index: any) => {
        console.log(props.scoreEval[index], "scoreEval");
        return (
          <div className="score-validation-body" key={`${index}scoreEval`}>
            <div className="score-conditions">
              <span>If X</span>

              <div
                className={
                  props.scoreEval[index].condition === "between"
                    ? "floating-between ms-3 me-3"
                    : "floating-select ms-3 me-3"
                }
              >
                <Select
                  showArrow
                  value={props.scoreEval[index].condition}
                  onChange={(val) => handleChange(val, "condition", index)}
                >
                  <Select.Option value="gt">Greater than</Select.Option>
                  <Select.Option value="gte">
                    Greater than or equal to
                  </Select.Option>
                  <Select.Option value="lt">Less than</Select.Option>
                  <Select.Option value="lte">
                    Less than or equal to
                  </Select.Option>
                  <Select.Option value="between">Between</Select.Option>
                  <Select.Option value="eq">Equal to</Select.Option>
                  {/* <Select.Option value="not_eq">Not equal to</Select.Option> */}
                </Select>
              </div>

              {props.scoreEval[index].condition !== "between" ? (
                <>
                  {(props.scoreEval[index].condition === "gt" ||
                    props.scoreEval[index].condition === "gte" ||
                    props.scoreEval[index].condition === "eq" ||
                    props.scoreEval[index].condition === "not_eq") && (
                    <div className="input-name">
                      <InputField
                        name="minValue"
                        type="number"
                        placeholder=""
                        changeEvent={(e) =>
                          handleChange(
                            Number(e.target.value),
                            "minValue",
                            index
                          )
                        }
                        className="floating-label"
                        value={
                          props.scoreEval[index].minValue === 0
                            ? ""
                            : props.scoreEval[index].minValue
                        }
                      />
                    </div>
                  )}

                  {/* max value */}
                  {(props.scoreEval[index].condition === "lt" ||
                    props.scoreEval[index].condition === "lte") && (
                    <div className="input-name">
                      <InputField
                        name="maxValue"
                        type="number"
                        placeholder=""
                        changeEvent={(e) =>
                          handleChange(
                            Number(e.target.value),
                            "maxValue",
                            index
                          )
                        }
                        className="floating-label"
                        value={
                          props.scoreEval[index].maxValue === 0
                            ? ""
                            : props.scoreEval[index].maxValue
                        }
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex align-items-center">
                  <div className="input-name">
                    <InputField
                      name="minValue"
                      type="number"
                      placeholder=""
                      changeEvent={(e) =>
                        handleChange(Number(e.target.value), "minValue", index)
                      }
                      className="floating-label"
                      value={
                        props.scoreEval[index].minValue === 0
                          ? ""
                          : props.scoreEval[index].minValue
                      }
                    />
                  </div>
                  <span className="between-inputs-text">and</span>
                  <div className="input-name">
                    <InputField
                      name="maxValue"
                      type="number"
                      placeholder=""
                      changeEvent={(e) =>
                        handleChange(Number(e.target.value), "maxValue", index)
                      }
                      className="floating-label"
                      value={
                        props.scoreEval[index].maxValue === 0
                          ? ""
                          : props.scoreEval[index].maxValue
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="score-is">
              <span>then score</span>
              <div className="input-name ms-4">
                <InputField
                  name="score"
                  type="number"
                  placeholder=""
                  changeEvent={(e) =>
                    handleChange(Number(e.target.value), "score", index)
                  }
                  className="floating-label"
                  value={
                    props.scoreEval[index].score === 0
                      ? ""
                      : props.scoreEval[index].score
                  }
                />
              </div>
            </div>

            <hr></hr>
            <div className="text-end">
              <i className="add-score cp mb-3" onClick={() => addScore()}></i>
              <i
                className="remove-score cp ms-2 mb-3"
                onClick={() => removeScore(index)}
              ></i>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ScoreValidation;
