import { useState } from "react";
import { Select } from "antd";

export const ParticipantStatusFilter = (props: any) => {
  const { index, addFilter, handleChange, removeFilter } = props;

  const [anotherFilter, setAnotherFilter] = useState(false);

  return (
    <div className="user-filter-row-fields">
      <div className="input-group module">
        <label>Status</label>
        <Select
          showArrow
          value={props.filters[index].status}
          onChange={(val) => {
            handleChange(val, "status", index, "participant_status");
          }}
        >
          <Select.Option value="active">Active</Select.Option>
          <Select.Option value="inactive">InActive</Select.Option>
        </Select>
      </div>
      <i
        className="add-filter add-blue-bg-icon cp mb-3"
        onClick={() => {
          setAnotherFilter(true);
        }}
      ></i>
      <i
        className=" add-filter error-small-icon cp ms-2 mb-3"
        onClick={() => {
          removeFilter(index, props.filters[index].filterType);
          setAnotherFilter(false);
        }}
      ></i>

      {anotherFilter && (
        <div className="input-group module">
          <label>Filter type</label>
          <Select
            showArrow
            placeholder="----Select filter type----"
            value={
              props.filters[index + 1]
                ? props.filters[index + 1].filterType
                : "----Select filter type----"
            }
            onChange={(val) => addFilter(val, index + 1)}
          >
            <Select.Option value="participant_status">
              User status
            </Select.Option>
            <Select.Option value="participant_gender">
              User Gender
            </Select.Option>
          </Select>
        </div>
      )}
    </div>
  );
};

export const ParticipantGenderFilter = (props: any) => {
  const { index, addFilter, handleChange, removeFilter } = props;

  const [anotherFilter, setAnotherFilter] = useState(false);

  return (
    <div className="user-filter-row-fields">
      <div className="input-group module">
        <label>Gender</label>
        <Select
          showArrow
          value={props.filters[index].gender}
          onChange={(val) => {
            handleChange(val, "gender", index, "participant_gender");
          }}
        >
          <Select.Option value="male">Male</Select.Option>
          <Select.Option value="female">Female</Select.Option>
        </Select>
      </div>
      <i
        className="add-filter add-blue-bg-icon cp mb-3"
        onClick={() => {
          setAnotherFilter(true);
        }}
      ></i>
      <i
        className=" add-filter error-small-icon cp ms-2 mb-3"
        onClick={() => {
          removeFilter(index, props.filters[index].filterType);
          setAnotherFilter(false);
        }}
      ></i>

      {anotherFilter && (
        <div className="input-group module">
          <label>Filter type</label>
          <Select
            showArrow
            placeholder="----Select filter type----"
            value={
              props.filters[index + 1]
                ? props.filters[index + 1].filterType
                : "----Select filter type----"
            }
            onChange={(val) => addFilter(val, index + 1)}
          >
            <Select.Option value="participant_status">
              User status
            </Select.Option>
            <Select.Option value="participant_gender">
              User Gender
            </Select.Option>
          </Select>
        </div>
      )}
    </div>
  );
};
