const Hba1cIcon = ({ width, height }: { width: string; height: string }) => (
  <svg
    width={width || "24"}
    height={height || "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.66 8.00008L12.71 3.06008C12.32 2.67008 11.69 2.67008 11.3 3.06008L6.34 8.00008C4.78 9.56008 4 11.6401 4 13.6401C4 15.6401 4.78 17.7501 6.34 19.3101C7.9 20.8701 9.95 21.6601 12 21.6601C14.05 21.6601 16.1 20.8701 17.66 19.3101C19.22 17.7501 20 15.6401 20 13.6401C20 11.6401 19.22 9.56008 17.66 8.00008Z"
      fill="#333333"
      className="fill-path"
    />
  </svg>
);
export default Hba1cIcon;
