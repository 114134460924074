import { useState } from "react";
import { Select, Switch } from "antd";
import {
  ParticipantStatusFilter,
  ParticipantGenderFilter,
} from "./FilterTypes";
import CustomButton from "../../Common/CustomButton";

type Props = {
  filters: any;
  updateFilters: Function;
  cancel: Function;
  handleSave: any;
  handleRemoveFilter: Function;
  handleGroupChecked: any;
};

const UserFilter = (props: Props) => {
  const [type, setType] = useState("");

  const addFilter = (val: any, index?: any) => {
    setType(val);
    if (typeof props.filters[index] === "undefined") {
      props.updateFilters(index, "ADD", "", val, val);
    } else {
      props.updateFilters(index, "REPLACE", "", val, "replace_filter");
    }
  };

  const removeFilter = (index: any, filterType?: any) => {
    props.handleRemoveFilter(index, filterType);
  };

  const handleChange = (val: any, key: string, idx: number, qType?: any) => {
    // props.getQuestionList(1, "active", false, 10);
    props.updateFilters(idx, "CHANGE", key, val, qType);
  };

  const renderFilters = () => {
    return (
      <div className="user-filter-row">
        <div className="input-pre-group module">
          <label>Filter type</label>
          <Select
            showArrow
            placeholder="----Select filter type----"
            value={
              props.filters.length > 0
                ? props.filters[0].filterType
                : "----Select filter type----"
            }
            onChange={(val: any) => {
              addFilter(val, 0);
            }}
          >
            <Select.Option value="participant_status">
              User status
            </Select.Option>
            <Select.Option value="participant_gender">
              User Gender
            </Select.Option>
          </Select>
        </div>
        {props.filters.map((filter: any, index: number) => (
          <div key={`${index}-filters`}>
            {filter.filterType === "participant_status" ? (
              <ParticipantStatusFilter
                filters={props.filters}
                filter={filter}
                index={index}
                addFilter={addFilter}
                handleChange={handleChange}
                removeFilter={removeFilter}
              />
            ) : filter.filterType === "participant_gender" ? (
              <ParticipantGenderFilter
                filters={props.filters}
                filter={filter}
                index={index}
                addFilter={addFilter}
                handleChange={handleChange}
                removeFilter={removeFilter}
              />
            ) : null}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="col-md-4 px-0 pb-5">
      <div className="user-filter">
        <h6>Filters</h6>
        {renderFilters()}
      </div>
      <div className="user-filter">
        <div className="control-intervention-group m-3">
          <div className="d-flex justify-content-between mb-3">
            <div>
              <div className="fw-semibold">Randomization algorithm</div>
              <div>Variable block randomization</div>
            </div>
            <Switch onChange={props.handleGroupChecked} />
          </div>
          <div className="input-field w-100 mb-4">
            <div className="field-name form-label">Block sizes</div>
            <div className="floating-label">
              <Select size="large" defaultValue="4,6,8">
                <Select.Option value="4,6,8">4,6,8</Select.Option>
                <Select.Option value="4,6">4,6</Select.Option>
                <Select.Option value="2,4,6">2,4,6</Select.Option>
                <Select.Option value="2,4">2,4</Select.Option>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="user-filter-button-container text-right my-3">
        <CustomButton
          buttonText="Cancel"
          primary={false}
          disabled={props.filters.length === 0}
          className="me-3 grey-btn"
          clickHandler={() => props.cancel()}
        />
        <CustomButton
          buttonText="Save"
          primary={true}
          className="btn-lg me-3"
          clickHandler={props.handleSave}
        />
      </div>
    </div>
  );
};

export default UserFilter;
