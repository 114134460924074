const MedicineIcon = ({ width, height }: { width: string; height: string }) => (
  <svg
    width={width || "24"}
    height={height || "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M19.4959 4.50444C18.5258 3.5343 17.2359 3 15.8639 3C14.4919 3 13.202 3.5343 12.2318 4.50444L8.74121 7.99507L16.0053 15.2592L19.4959 11.7685C21.4986 9.76582 21.4986 6.50719 19.4959 4.50444Z"
        fill="#333333"
        className="fill-path"
      />
      <path
        d="M7.99462 8.74072L4.50399 12.2314C2.50128 14.2341 2.50128 17.4927 4.50399 19.4954C5.47416 20.4656 6.76404 20.9999 8.13601 20.9999C9.50802 20.9999 10.7979 20.4656 11.7681 19.4954L15.2587 16.0048L7.99462 8.74072Z"
        fill="#333333"
        className="fill-path"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="18"
          height="18"
          fill="white"
          className="fill-path"
          transform="translate(3 3)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default MedicineIcon;
