import { useEffect, useState } from "react";
import Navigation from "../Navigation/Navigation";
import { getFolderDocumentsFile } from "../../redux/actions/documentAction";
import DocumentTable from "./DocumentTable";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import AppLayout from "../AppLayout/AppLayout";
import DocumentsHeader from "./DocumentsHeader";
import { Breadcrumb } from "antd";
import { useParams } from "react-router-dom";
import { setNestedDocumentSearch } from "../../redux/reducers/documentsSlice";

const FolderDocument = () => {
  const dispatch = useAppDispatch();
  const documentParentId = useParams<{ docId: string }>().docId;
  const { folderFiles, nestedSearchTerm, sortOrder } = useAppSelector(
    (state) => state.documents
  );

  const [breadcrumb, setBreadcrumb] = useState<any[]>([
    { name: "Documents", parentFolderId: "" },
  ]);

  useEffect(() => {
    dispatch(getFolderDocumentsFile(documentParentId));
  }, [dispatch, documentParentId, nestedSearchTerm, sortOrder]);

  useEffect(() => {
    return () => {
      dispatch(setNestedDocumentSearch(""));
    };
  }, [dispatch]);

  const setbreadcrumbs = (data: any) => {
    setBreadcrumb((prevState) => [
      ...prevState,
      {
        name: data.name,
        parentFolderId: data.parentFolderId,
      },
    ]);
    sessionStorage.setItem("documnet-breadcrumb", "1");
  };

  return (
    <AppLayout>
      <Navigation title="" />
      <DocumentsHeader
        documentParentId={documentParentId}
        type="Sub-Documents"
      />

      <div className="shadow-lg mb-4 p-3">
        <Breadcrumb separator=" > " className="navigation-crumb cp">
          {breadcrumb.map((item) => {
            return (
              <Breadcrumb.Item href={"/documents/" + item.parentFolderId}>
                {item.name}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </div>
      <DocumentTable documents={folderFiles} setbreadcrumbs={setbreadcrumbs} />
    </AppLayout>
  );
};

export default FolderDocument;
