const input_types = ["yes_no", "date"];
const text_types = ["short_text", "long_text"];
const number_types = ["number"];
const choice_types = [
  "multiple_choice",
  "dropdown",
  "likert_scale",
  "checkbox",
];

declare type textMapType = "multiple_choice" | "dropdown" | "picture_choice";

const textMap = {
  multiple_choice: "Choice",
  dropdown: "Option",
  picture_choice: "Label",
};

function compare(a: any, b: any) {
  const posA = a.position;
  const posB = b.position;

  let comparison = 0;
  if (posA > posB) {
    comparison = 1;
  } else if (posA < posB) {
    comparison = -1;
  }
  return comparison;
}

function emptyStringValidation(qnum: any, str: string) {
  if (str === "") {
    throw new Error(`Question ${qnum} Title cannot be empty!`);
  }

  return str;
}

function choiceProperties(qnum: any, question: any) {
  const { type, fields } = question;
  const choices: any[] = [];
  const responseCodes: any[] = [];
  fields.forEach(({ id, label, value, code }: any, index: any) => {
    if (label === "") {
      throw new Error(
        `Q${qnum} ${textMap[type as textMapType]} Position ${
          index + 1
        } cannot be Empty!`
      );
    }

    if (value === "") {
      throw new Error(
        `Q${qnum} ${textMap[type as textMapType]} Position ${
          index + 1
        } upload an image!`
      );
    }

    if (code === "") {
      throw new Error(
        `Q${qnum} Response Code at Position ${index + 1} cannot be Empty!`
      );
    }

    // const choiceId = uuid();
    let choice: any = {
      id: id,
      label,
      position: index + 1,
    };
    choice =
      type === "picture_choice"
        ? { ...choice, attachment: { type: "image", href: value } }
        : { ...choice };
    choices.push(choice);

    responseCodes.push({
      questionChoiceId: id,
      // code: code ? code : index
      code: code,
    });
  });

  return { choices, responseCodes };
}

function textProperties(qnum: any, question: any) {
  const { fields } = question;
  if (fields[0].code === "") {
    throw new Error(`Question ${qnum} Response code cannot be empty!`);
  }
  if (question.type === "yes_no") {
    return [{ code: fields[0].code }, { code: fields[1].code }];
  } else {
    return [{ code: fields[0].code }];
  }
}

function getLogicJump(qid: any, logicJumps: any) {
  let res = [];
  let newRes = [];
  if (logicJumps !== undefined && logicJumps.length) {
    res =
      logicJumps.find((logic: any) => logic.questionId === qid) !== undefined
        ? logicJumps.find((logic: any) => logic.questionId === qid).actions
        : [];
  }
  if (res.length > 0) {
    newRes = res.map((item: any, index: number) => {
      return { ...item, position: String(index + 1) };
    });
  }

  return newRes;
}

const handleNumberTypesResponseCode = (responseCodes: any[]) => {
  let result = [];

  for (let i = 0; i < responseCodes.length; i++) {
    if (
      responseCodes[i].condition === "lt" ||
      responseCodes[i].condition === "lte"
    ) {
      result.push({
        condition: responseCodes[i].condition,
        code: responseCodes[i].code,
        maxValue: responseCodes[i].maxValue,
      });
    } else if (
      responseCodes[i].condition === "gt" ||
      responseCodes[i].condition === "gte" ||
      responseCodes[i].condition === "eq"
    ) {
      result.push({
        condition: responseCodes[i].condition,
        code: responseCodes[i].code,
        minValue: responseCodes[i].minValue,
      });
    } else {
      result.push({
        condition: responseCodes[i].condition,
        code: responseCodes[i].code,
        minValue: responseCodes[i].minValue,
        maxValue: responseCodes[i].maxValue,
      });
    }
  }

  return result;
};

const handleChoiceTypesResponseCode = (
  choices: any[],
  responseCodes: any[],
  index: any
) => {
  let result = choices
    .sort(function (a, b) {
      return Number(a.position) - Number(b.position);
    })
    .map((choice) => {
      let codeArr =
        responseCodes.find(
          (element) => element.questionChoiceId === choice.id
        ) !== undefined
          ? responseCodes.find(
              (element) => element.questionChoiceId === choice.id
            ).code
          : 0;
      return codeArr;
    });

  return result[index];
};

const educationQuestionHandler = (QuestionArr: any[]) => {
  let result = QuestionArr.map((item, index) => {
    return item.branchType === "TEXT"
      ? { title: item.fields[0].value, type: "text", position: index + 1 }
      : item.branchType === "checkbox"
      ? {
          title: item.title,
          type: "multi_select",
          choices: item.fields.map((item: any, index: number) => {
            return { label: item.label, position: index + 1 };
          }),
          position: index + 1,
        }
      : {
          title: item.title,
          type: "single_select",
          choices: item.fields.map((item: any, index: number) => {
            return { label: item.label, position: index + 1 };
          }),
          position: index + 1,
        };
  });

  return result;
};

function numberProperties(qnum: any, question: any) {
  const { properties } = question;

  if (properties.hasOwnProperty("responseCodes")) {
    const res = properties.responseCodes.map((code: any, index: number) => {
      if (
        (code.condition === "lt" || code.condition === "lte") &&
        code.maxValue === ""
      ) {
        throw new Error(
          `Q${qnum} Response Value ${index + 1} cannot be Empty!`
        );
      }

      if (
        (code.condition === "gt" || code.condition === "gte") &&
        code.minValue === ""
      ) {
        throw new Error(
          `Q${qnum} Response Value ${index + 1} cannot be Empty!`
        );
      }

      if (code.condition === "between") {
        if (code.minValue === "") {
          throw new Error(
            `Q${qnum} Response Code ${index + 1} minimum value required!`
          );
        }

        if (code.maxValue === "") {
          throw new Error(
            `Q${qnum} Response Code ${index + 1} maximum value required!`
          );
        }
      }

      if (code.code === "") {
        throw new Error(`Q${qnum} Response Code ${index + 1} required!`);
      }
      return code;
    });

    return res;
  }

  // return [{ code: 0 }];
}

const handleResponseQuestions = (questions: any[]) => {
  let result = questions.map((item) => {
    return item.type === "text"
      ? {
          fileAttachment: {},
          fields: [{ type: "input", value: item.title }],
          type: "text",
          label: "Enter question text here",
          properties: {},
          options: {
            showMax: true,
            max: 40,
            attachment: false,
          },
          required: false,
          branches: [],
          branchType: "TEXT",
        }
      : item.type === "multi_select"
      ? {
          label: "Enter question text here",
          title: item.title,
          fileAttachment: {},
          fields: item.choices.map((item: any) => {
            return { type: "dropdown", label: item.label, code: "" };
          }),
          properties: {},
          options: {
            showMax: false,
            max: 1,
            attachment: false,
          },
          required: false,
          branches: [],
          branchType: "checkbox",
        }
      : {
          label: "Enter question text here",
          title: item.title,
          fileAttachment: {},
          fields: item.choices.map((item: any) => {
            return { type: "dropdown", label: item.label, code: "" };
          }),
          properties: {},
          options: {
            showMax: false,
            max: 1,
            attachment: false,
          },
          required: false,
          branches: [],
          branchType: "radio",
        };
  });

  return result;
};

export const questionResponseFormat = (questions: any[], logicJumps: any) => {
  const res: any[] = questions.map((question) => {
    if (question.type === "date") {
      return {
        id: question.id,
        fileAttachment: {},
        fields: question.properties.responseCodes
          .map(({ id, code }: any) => ({
            type: "input",
            value: question.title,
            id,
            code,
          }))
          .sort(compare),
        type: question.type,
        label: "Enter question text here",
        properties: {},
        options: {
          showMax: false,
          max: "40",
          attachment: false,
        },
        // required: question.required,
        position: question.position,
        branches: getLogicJump(question.id, logicJumps),
        branchType: question.type === "date" ? "DATE" : "TEXT",
        questionCode: question.questionCode,
      };
    } else if (question.type === "yes_no") {
      return {
        id: question.id,
        fileAttachment: {},
        fields: [
          {
            type: "input",
            value: question.title,
            id: question.properties.responseCodes.reverse()[0].id,
            label: "Yes",
            code: question.properties.responseCodes[0].code,
          },
          {
            id: question.properties.responseCodes[1].id,
            label: "No",
            code: question.properties.responseCodes[1].code,
          },
        ],
        type: question.type,
        label: "Enter question text here",
        properties: {},
        options: {
          showMax: false,
          max: "40",
          attachment: false,
        },
        // required: question.required,
        position: question.position,
        branches: getLogicJump(question.id, logicJumps),
        branchType: "BOOL",
        questionCode: question.questionCode,
      };
    } else if (text_types.includes(question.type)) {
      return {
        id: question.id,
        fileAttachment: {},
        fields: question.properties.responseCodes
          .map(({ id, code }: any) => ({
            type: "input",
            value: question.title,
            id,
            code,
          }))
          .sort(compare),
        type: question.type,
        label: "Enter question text here",
        properties: {},
        options: {
          showMax: true,
          max: question.properties.maxRange,
          attachment: false,
        },
        // required: question.required,
        position: question.position,
        branches: getLogicJump(question.id, logicJumps),
        branchType: "TEXT",
        questionCode: question.questionCode,
      };
    } else if (number_types.includes(question.type)) {
      return {
        id: question.id,
        fileAttachment: {},
        fields: [{ type: "input", value: question.title }],
        type: question.type,
        label: "Enter question text here",
        properties: {
          responseCodes: handleNumberTypesResponseCode(
            question.properties.responseCodes
          ),
        },
        options: {
          showMax: false,
          min: question.properties.minRange,
          max: question.properties.maxRange,
          attachment: false,
        },
        // required: question.required,
        position: question.position,
        branches: getLogicJump(question.id, logicJumps),
        branchType: "NUMBER",
        questionCode: question.questionCode,
      };
    } else if (choice_types.includes(question.type)) {
      return {
        id: question.id,
        type: question.type,
        title: question.title,
        label: "Enter question text here",
        fileAttachment: {},
        fields: question.properties.choices
          .sort(function (a: any, b: any) {
            return Number(a.position) - Number(b.position);
          })
          .map(({ id, label, position }: any, i: number) => ({
            type: "dropdown",
            label,
            id,
            position,
            code: handleChoiceTypesResponseCode(
              question.properties.choices,
              question.properties.responseCodes,
              i
            ),
          }))
          .sort(compare),
        properties: {},
        options: {
          showMax: false,
          max: 1,
          attachment: false,
        },
        // required: question.required,
        position: question.position,
        branches: getLogicJump(question.id, logicJumps),
        branchType: "CHOICE",
        questionCode: question.questionCode,
      };
    } else if (question.type === "picture_choice") {
      return {
        id: question.id,
        type: question.type,
        title: question.title,
        label: "Enter question text here",
        fileAttachment: {},
        fields: question.properties.choices
          .sort(function (a: any, b: any) {
            return Number(a.position) - Number(b.position);
          })
          .map(({ id, label, attachment, position }: any, i: number) => ({
            type: "picture",
            label,
            id,
            position,
            value: attachment.href,
            name: `Picture ${i + 1}`,
            code: handleChoiceTypesResponseCode(
              question.properties.choices,
              question.properties.responseCodes,
              i
            ),
          }))
          .sort(compare),
        properties: {},
        options: {
          showMax: false,
          max: 1,
          attachment: false,
        },
        // required: question.required,
        position: question.position,
        branches: getLogicJump(question.id, logicJumps),
        branchType: "CHOICE",
        questionCode: question.questionCode,
      };
    } else if (question.type === "opinion_scale") {
      return {
        id: question.id,
        type: question.type,
        title: question.title,
        label: "Enter question text here",
        fileAttachment: {},
        fields: [{ type: "slider", ...question.properties }],
        info: "Select scale",
        properties: {},
        options: {
          showMax: false,
          max: 1,
          attachment: false,
        },
        // required: question.required,
        position: question.position,
        branches: [],
        branchType: "CHOICE",
        questionCode: question.questionCode,
      };
    } else if (question.type === "group") {
      return {
        id: question.id,
        type: question.type,
        label: "Question Group",
        fileAttachment: {},
        fields: [{ type: "group", value: question.title }],
        questions: questionResponseFormat(
          question.properties.questions,
          logicJumps
        ),
        properties: {},
        options: {
          showMax: false,
          max: 1,
          attachment: false,
        },
        // required: question.required,
        position: question.position,
        branches: [],
        title: question.title,
        branchType: "",
        questionCode: question.questionCode,
      };
    }
  });

  return res.sort(compare);
};

export const questionPreviewFormat = (questions: any[]) => {
  function sortChoice(properties: any) {
    if (!properties.hasOwnProperty("choices")) return properties;
    return {
      choices: properties.choices.sort(compare),
      responseCodes: properties.responseCodes,
    };
  }

  const res = questions.map((q) => {
    let queGroup = [];
    if (q.type === "group") {
      queGroup = q.properties.questions.map((que: any) => ({
        ...que,
        position: Number(`${q.position}.${que.position}`),
        properties: sortChoice(que.properties),
      }));

      return {
        id: q.questionnaireId,
        type: q.type,
        title: q.title,
        position: q.position,
        properties: {
          questions: queGroup,
        },
      };
    } else {
      return { ...q, properties: sortChoice(q.properties) };
    }
  });

  return res.flat().sort(compare);
};

export const questionSubmitFormat = (questions: any[]) => {
  const res: any[] = questions.map((question: any, index: number) => {
    const itemPosition = index + 1;
    let obj: any = {
      id: question.id,
      type: question.type,
      title: "",
      properties: question.properties,
      position: itemPosition,
      // required: question.required,
    };

    if (question.questionCode) {
      obj["questionCode"] = question.questionCode;
    }

    if (number_types.includes(question.type)) {
      return {
        ...obj,
        title: emptyStringValidation(itemPosition, question.fields[0].value),
        properties: {
          responseCodes: numberProperties(itemPosition, question),
          maxRange: Number(question.options.max),
          minRange: Number(question.options.min),
        },
      };
    } else if (text_types.includes(question.type)) {
      return {
        ...obj,
        title: emptyStringValidation(itemPosition, question.fields[0].value),
        properties: {
          responseCodes: textProperties(itemPosition, question),
          maxRange: Number(question.options.max),
        },
      };
    } else if (input_types.includes(question.type)) {
      return {
        ...obj,
        title: emptyStringValidation(itemPosition, question.fields[0].value),
        properties: {
          responseCodes: textProperties(itemPosition, question),
          // maxRange: Number(question.options.max),
        },
      };
    } else if (question.type === "opinion_scale") {
      return {
        ...obj,
        title: emptyStringValidation(itemPosition, question.title),
        properties: {
          minRange: question.fields[0].minRange,
          maxRange: question.fields[0].maxRange,
          step: question.fields[0].step,
          responseCodes: numberProperties(itemPosition, question),
        },
      };
    } else if (choice_types.includes(question.type)) {
      return {
        ...obj,
        title: emptyStringValidation(itemPosition, question.title),
        properties: choiceProperties(itemPosition, question),
      };
    } else if (question.type === "picture_choice") {
      return {
        ...obj,
        title: emptyStringValidation(itemPosition, question.title),
        properties: choiceProperties(itemPosition, question),
      };
    } else if (question.type === "group") {
      return {
        ...obj,
        title: emptyStringValidation(itemPosition, question.title),
        properties: {
          questions: questionSubmitFormat(question.questions),
        },
      };
    }
  });
  return res;
};

export const logicJumpFormat = (questions: any) => {
  const res: any[] = [];
  questions.forEach((question: any) => {
    if (question.branches.length > 0) {
      res.push({
        questionId: question.id,
        actions: [...question.branches],
      });
    } else if (question.type === "group") {
      question.questions.forEach((ques: any) => {
        if (ques.branches.length > 0) {
          res.push({
            questionId: ques.id,
            actions: [...ques.branches],
          });
        }
      });
    }
  });
  return res;
};

export const educationResponse = (sections: any[]) => {
  const newSection: any[] = [];
  const resourcesArray: any[] = [];

  sections.forEach((section) => {
    if (section.type === "title") {
      const res = {
        // key: section.position.toString(),
        key: section.position - 1,
        classValue: "A-title",
        activeClass: "A-title",
        name: "TITLE",
        description: "Title block for headers and sub-headers.",
        title: section.value,
      };

      newSection.push(res);
      return;
    } else if (section.type === "subtitle") {
      const res = {
        // key: section.position.toString(),
        key: section.position - 1,
        classValue: "A-title",
        activeClass: "A-title",
        name: "SUBTITLE",
        description:
          "Subtitle block to introduce your page section (usually below the Title.)",
        subTitle: section.value,
      };

      newSection.push(res);
      return;
    } else if (section.type === "resource") {
      const res = {
        // key: section.position.toString(),
        key: section.position - 1,
        classValue: "A-resources",
        activeClass: "A-resources",
        name: "RESOURCES",
        description: "Share images, PDFs and links.",
        resources: [
          ...resourcesArray,
          { label: section.label, url: section.value },
        ],
      };

      newSection.push(res);
      return;
    } else if (section.type === "text") {
      const res = {
        // key: section.position.toString(),
        key: section.position - 1,
        classValue: "A-article",
        activeClass: "A-article",
        name: "ARTICLE/TEXT",
        description: "Text block for body text.",
        content: section.value,
      };

      newSection.push(res);
      return;
    } else if (section.type === "video") {
      const res = {
        // key: section.position.toString(),
        key: section.position - 1,
        classValue: "video-section",
        activeClass: "A-video",
        name: "VIDEO",
        description: "Video block with support for live comments.",
        video: section.value,
      };

      newSection.push(res);
      return;
    } else if (section.type === "picture") {
      const res = {
        // key: section.position.toString(),
        key: section.position - 1,
        classValue: "A-pictures",
        activeClass: "A-pictures",
        name: "PICTURES",
        files: [section.value],
        fileNames: "",
        description: "Image block for sharing pictures.",
      };

      newSection.push(res);
      return;
    } else if (section.type === "question") {
      const res = {
        // key: section.position.toString(),
        key: section.position - 1,
        activeClass: "A-question",
        classValue: "A-question",
        name: "QUESTION",
        description:
          "Ask participants a multiple-choice or open response question.",
        questions: handleResponseQuestions(section.questions),
      };

      newSection.push(res);
    }
  });

  return newSection;
};

export const educationRequest = (sections: any[]) => {
  const titleTypes = ["TITLE", "SUBTITLE"];
  const fileTypes = ["PICTURES"];
  let position = 1;
  let questionKey = 1;
  const newSection: any[] = [];
  sections.forEach((section) => {
    if (titleTypes.includes(section.name)) {
      const res = {
        value:
          section.name === "TITLE"
            ? section.title !== ""
              ? section.title
              : ""
            : section.subTitle,
        position: position,
        type: section.name.toLowerCase(),
      };
      position++;
      newSection.push(res);
      return;
    } else if (fileTypes.includes(section.name)) {
      const res = {
        label: section.fileNames !== "" ? section.fileNames : "A picture",
        value: section.files.length > 0 ? section.files[0] : "",
        position: position,
        type: section.name.slice(0, 7).toLowerCase(),
      };
      position++;
      newSection.push(res);
      return;
    } else if (section.name === "RESOURCES") {
      section.resources.forEach((resource: any) => {
        const res = {
          label: resource.label,
          value: resource.url,
          position: position,
          type: "resource",
        };
        position++;
        newSection.push(res);
        return;
      });
      return;
    } else if (section.name === "ARTICLE/TEXT") {
      const res = {
        value: section.content,
        position: position,
        type: "text",
      };
      position++;
      newSection.push(res);
      return;
    } else if (section.name === "VIDEO") {
      const res = {
        label: "A video",
        value: section.video,
        position: position,
        type: "video",
      };
      position++;
      newSection.push(res);
    } else if (section.name === "QUESTION") {
      const res = {
        value: `Questions ${questionKey}`,
        position: position,
        type: "question",
        questions: educationQuestionHandler(section.questions),
      };

      position++;
      questionKey++;
      newSection.push(res);
    }
  });

  return newSection;
};
