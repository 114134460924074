import React, { useState } from "react";
import ResponseLogic from "../../QuestionBuilder/ResponseLogic";
import BranchLogic from "../../QuestionBuilder/BranchLogic";
import { questionResponseFormat } from "../../../../helpers/question";

import { DatePicker, Tooltip } from "antd";

const placeholder = {
  short_text: "Short Text",
  long_text: "Long Text",
  number: "Number Type",
};

function range(start, end) {
  if (start === end) return [start];
  return [start, ...range(start + 1, end)];
}

export const InputType = ({ question, allQuestions, logicJumps }) => {
  const { type, properties } = question;
  const [showResponseModal, setResponseModal] = useState(false);
  const [showBranching, setShowBranching] = useState(false);
  let checkQ = questionResponseFormat([question], logicJumps);
  let allQ = questionResponseFormat(allQuestions, logicJumps);

  let logicExists = logicJumps.some((item) => item.questionId === checkQ[0].id);
  return (
    <div className="input-control">
      <input
        type="text"
        style={{ width: type === "number" ? "100%" : "100%" }}
        placeholder={placeholder[type]}
        autoComplete="off"
      />
      {type === "number" ? (
        <span>
          min - {properties.minRange}, max - {properties.maxRange}
        </span>
      ) : (
        ""
      )}
      <div className="d-flex justify-content-end mt-4">
        <Tooltip placement="bottom" title="Response Code">
          <i
            className="response-icon cp"
            onClick={() => setResponseModal(true)}
          ></i>
        </Tooltip>
      </div>

      {type !== "group" && logicExists && (
        <div className="d-flex justify-content-end mt-4 me-3">
          <Tooltip placement="bottom" title="Logic Jump">
            <i
              className="branch-icon cp"
              onClick={() => setShowBranching(true)}
            ></i>
          </Tooltip>
        </div>
      )}

      <ResponseLogic
        show={showResponseModal}
        hideModal={setResponseModal}
        question={checkQ[0]}
        previewDisabling={true}
      />

      {showBranching && (
        <BranchLogic
          choice={checkQ[0]}
          questions={allQ}
          show={showBranching}
          hideModal={setShowBranching}
          previewDisabling={true}
        />
      )}
    </div>
  );
};

export const BooleanType = ({ question, allQuestions, logicJumps }) => {
  const { position } = question;
  const [showResponseModal, setResponseModal] = useState(false);
  const [showBranching, setShowBranching] = useState(false);
  let checkQ = questionResponseFormat([question], logicJumps);
  let allQ = questionResponseFormat(allQuestions, logicJumps);

  let logicExists = logicJumps.some((item) => item.questionId === checkQ[0].id);

  return (
    <>
      <div className="input-control">
        <input
          id={`toggle-on-q${position}`}
          className="toggle toggle-left"
          name={`q${position}`}
          value="yes"
          type="radio"
        />
        <label htmlFor={`toggle-on-q${position}`} className="text-label">
          <span>A</span> Yes
        </label>
        <input
          id={`toggle-off-q${position}`}
          className="toggle toggle-right"
          name={`q${position}`}
          value="no"
          type="radio"
        />
        <label htmlFor={`toggle-off-q${position}`} className="text-label">
          <span>B</span> No
        </label>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Tooltip placement="bottom" title="Response Code">
          <i
            className="response-icon cp"
            onClick={() => setResponseModal(true)}
          ></i>
        </Tooltip>
      </div>

      {question.type !== "group" && logicExists && (
        <div className="float-end d-flex justify-content-end me-3 mt-2">
          <Tooltip placement="bottom" title="Logic Jump">
            <i
              className="branch-icon cp"
              onClick={() => setShowBranching(true)}
            ></i>
          </Tooltip>
        </div>
      )}

      <ResponseLogic
        show={showResponseModal}
        hideModal={setResponseModal}
        question={checkQ[0]}
        previewDisabling={true}
      />

      {showBranching && (
        <BranchLogic
          choice={checkQ[0]}
          questions={allQ}
          show={showBranching}
          hideModal={setShowBranching}
          previewDisabling={true}
        />
      )}
    </>
  );
};

export const DateType = ({ question, allQuestions, logicJumps }) => {
  const [showResponseModal, setResponseModal] = useState(false);
  const [showBranching, setShowBranching] = useState(false);
  let checkQ = questionResponseFormat([question], logicJumps);
  let allQ = questionResponseFormat(allQuestions, logicJumps);

  let logicExists = logicJumps.some((item) => item.questionId === checkQ[0].id);

  return (
    <div className="input-control">
      <DatePicker className="date-selector" format="DD/MM/YYYY" />
      <div className="float-end yhk">
        <Tooltip placement="bottom" title="Response Code">
          <i
            className="response-icon cp"
            onClick={() => setResponseModal(true)}
          ></i>
        </Tooltip>
      </div>

      {question.type !== "group" && logicExists && (
        <div className="d-flex mt-3  justify-content-end">
          <Tooltip placement="bottom" title="Logic Jump">
            <i
              className="branch-icon cp"
              onClick={() => setShowBranching(true)}
            ></i>
          </Tooltip>
        </div>
      )}

      <ResponseLogic
        show={showResponseModal}
        hideModal={setResponseModal}
        question={checkQ[0]}
        previewDisabling={true}
      />

      {showBranching && (
        <BranchLogic
          choice={checkQ[0]}
          questions={allQ}
          show={showBranching}
          hideModal={setShowBranching}
          previewDisabling={true}
        />
      )}
    </div>
  );
};

export const OpinionType = ({ question }) => {
  const { position, properties } = question;
  const numbers = range(properties.minRange, properties.maxRange);
  return (
    <div className="input-control">
      <div className="d-flex">
        {numbers.map((number) => (
          <React.Fragment key={number}>
            <input
              id={`opinion-${number}-q${position}`}
              className="toggle opinion"
              name={`q${position}`}
              value={number}
              type="radio"
            />
            <label
              htmlFor={`opinion-${number}-q${position}`}
              className="text-label"
            >
              {number}
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export const LikertType = ({ question, allQuestions, logicJumps }) => {
  const { position, properties } = question;
  console.log("kmdklsamlfks", question);
  const [showResponseModal, setResponseModal] = useState(false);
  const [showBranching, setShowBranching] = useState(false);
  let checkQ = questionResponseFormat([question], logicJumps);
  let allQ = questionResponseFormat(allQuestions, logicJumps);

  let logicExists = logicJumps.some((item) => item.questionId === checkQ[0].id);
  const [selected, setSelected] = useState(null);
  return (
    <>
      <div className="input-control-bar">
        <div className="container-bar">
          <ul className="progressbar">
            {properties.choices.map((choice) => (
              <li
                onClick={() => setSelected(choice.id)}
                className={selected === choice.id ? " activated " : "active"}
                key={choice.id}
              >
                <label> {choice.label}</label>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Tooltip placement="bottom" title="Response Code">
          <i
            className="response-icon cp"
            onClick={() => setResponseModal(true)}
          ></i>
        </Tooltip>
      </div>

      {question.type !== "group" && logicExists && (
        <div className="justify-content-end d-flex me-3 mt-3">
          <Tooltip placement="bottom" title="Logic Jump">
            <i
              className="branch-icon cp"
              onClick={() => setShowBranching(true)}
            ></i>
          </Tooltip>
        </div>
      )}

      <ResponseLogic
        show={showResponseModal}
        hideModal={setResponseModal}
        question={checkQ[0]}
        previewDisabling={true}
      />

      {showBranching && (
        <BranchLogic
          choice={checkQ[0]}
          questions={allQ}
          show={showBranching}
          hideModal={setShowBranching}
          previewDisabling={true}
        />
      )}
    </>
  );
};
