const MedicineTakenIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0002 2.8335C9.18016 2.8335 2.8335 9.18016 2.8335 17.0002C2.8335 24.8202 9.18016 31.1668 17.0002 31.1668C24.8202 31.1668 31.1668 24.8202 31.1668 17.0002C31.1668 9.18016 24.8202 2.8335 17.0002 2.8335ZM17.0002 28.3335C10.7527 28.3335 5.66683 23.2477 5.66683 17.0002C5.66683 10.7527 10.7527 5.66683 17.0002 5.66683C23.2477 5.66683 28.3335 10.7527 28.3335 17.0002C28.3335 23.2477 23.2477 28.3335 17.0002 28.3335ZM22.4968 11.7443L14.1668 20.0743L11.5035 17.411C10.951 16.8585 10.0585 16.8585 9.506 17.411C8.9535 17.9635 8.9535 18.856 9.506 19.4085L13.1752 23.0777C13.7277 23.6302 14.6202 23.6302 15.1727 23.0777L24.5085 13.7418C25.061 13.1893 25.061 12.2968 24.5085 11.7443C23.956 11.1918 23.0493 11.1918 22.4968 11.7443Z"
      fill="#30D073"
    />
  </svg>
);

export default MedicineTakenIcon;
