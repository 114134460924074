import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import {
  setExerciseDetails,
  setFoodDetails,
  toggleDiaryLoader,
} from "../reducers/diarySlice";
import { setAppLoader } from "../reducers/loaderSlice";
import { hideModal } from "../reducers/modalSlice";
import { AppThunk } from "../store";

export const getFoodDiary =
  (page: number, search?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      let url = `/diary/food?page=${page}&size=8`;
      if (search) {
        url += `&search=${search}`;
      }
      const res: AxiosResponse = await http.get(url);
      dispatch(
        setFoodDetails({
          data: res.data.data.foods,
          count: res.data.data.count,
        })
      );
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const addFoodDiary =
  (body: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      await http.post(`/diary/food`, body);
      toastMessage("success", "Food item created successfully");
      dispatch(hideModal());
      dispatch(toggleDiaryLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const UpdateFoodDiary =
  (body: any, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      await http.put(`/diary/food/${id}`, body);
      toastMessage("success", "Food item updated successfully");
      dispatch(hideModal());
      dispatch(toggleDiaryLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const getExerciseDiary =
  (page: number, search?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      let url = `/diary/exercise?page=${page}&size=8`;
      if (search) {
        url += `&search=${search}`;
      }
      const res: AxiosResponse = await http.get(url);
      dispatch(
        setExerciseDetails({
          data: res.data.data.exercises,
          count: res.data.data.count,
        })
      );
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const addExerciseDiary =
  (body: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      await http.post(`diary/exercise`, body);
      toastMessage("success", "Exercise item created successfully");
      dispatch(hideModal());
      dispatch(toggleDiaryLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const UpdateExerciseDiary =
  (body: any, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      await http.put(`/diary/exercise/${id}`, body);
      toastMessage("success", "Exercise item updated successfully");
      dispatch(hideModal());
      dispatch(toggleDiaryLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const deleteExerciseDiary =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      await http.delete(`/diary/exercise/${id}`);
      toastMessage("success", "Exercise item deleted successfully");
      dispatch(toggleDiaryLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const deleteFoodDiary =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      await http.delete(`/diary/food/${id}`);
      toastMessage("success", "Food item deleted successfully");
      dispatch(toggleDiaryLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };
