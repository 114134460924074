const AlcoholIcon = ({ width, height }: { width: string; height: string }) => (
  <svg
    width={width || "24"}
    height={height || "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9.00002H17.44C17.77 8.45002 17.97 7.82002 17.99 7.14002C18.03 6.11002 17.56 5.15002 16.83 4.43002C15.29 2.89002 14.09 2.87002 13.01 3.14002C12.2 2.45002 11.16 2.02002 10 2.02002C8.11 2.02002 6.49 3.13002 5.73 4.73002C4.15 5.26002 3 6.74002 3 8.50002C3 10.36 4.28 11.91 6 12.36V19C6 20.1 6.9 21 8 21H15C16.1 21 17 20.1 17 19H19C20.1 19 21 18.1 21 17V11C21 9.90002 20.1 9.00002 19 9.00002ZM7 10.5C5.9 10.5 5 9.60002 5 8.50002C5 7.65002 5.55 6.90002 6.37 6.62002L7.17 6.35002L7.53 5.59002C8 4.62002 8.94 4.02002 10 4.02002C10.79 4.02002 11.39 4.37002 11.74 4.67002L12.52 5.32002C12.52 5.32002 13.16 5.00002 13.99 5.00002C15.09 5.00002 15.99 5.90002 15.99 7.00002H12.99C9.67 7.00002 9.15 10.5 7 10.5ZM19 17H17V11H19V17Z"
      fill="#333333"
      className="fill-path"
    />
  </svg>
);
export default AlcoholIcon;
