import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminUserPayload, ParticipantGroupPayload } from "../../types";

export interface ParticipantState {
  searchTerm: string;
  activeParticipantUsers: any[];
  activeParticipantCount: number;
  inactiveParticipantUsers: any[];
  inactiveParticipantCount: number;
  groupCount: number;
  groups: any[];
  filterParticipants: any[];
  filterParticipantsCount: number;
  toggleLoader: boolean;
}

const initialState: ParticipantState = {
  searchTerm: "",
  activeParticipantUsers: [],
  activeParticipantCount: 0,
  inactiveParticipantUsers: [],
  inactiveParticipantCount: 0,
  toggleLoader: false,
  groups: [],
  groupCount: 0,
  filterParticipants: [],
  filterParticipantsCount: 0,
};

export const participantSlice = createSlice({
  name: "participant",
  initialState,
  reducers: {
    setParticipantSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setActiveParticipantUsers: (
      state,
      action: PayloadAction<AdminUserPayload>
    ) => {
      state.activeParticipantUsers = action.payload.users;
      state.activeParticipantCount = action.payload.count;
    },
    setParticipantGroups: (
      state,
      action: PayloadAction<ParticipantGroupPayload>
    ) => {
      state.groups = action.payload.groups;
      state.groupCount = action.payload.count;
    },
    setFilterParticipants: (state, action: PayloadAction<AdminUserPayload>) => {
      state.filterParticipants = action.payload.users;
      state.filterParticipantsCount = action.payload.count;
    },
    setInactiveParticipantUsers: (
      state,
      action: PayloadAction<AdminUserPayload>
    ) => {
      state.inactiveParticipantUsers = action.payload.users;
      state.inactiveParticipantCount = action.payload.count;
    },
    toggleParticipantLoader: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
  },
});

export const {
  setActiveParticipantUsers,
  setInactiveParticipantUsers,
  setParticipantSearchTerm,
  toggleParticipantLoader,
  setParticipantGroups,
  setFilterParticipants,
} = participantSlice.actions;

export default participantSlice.reducer;
