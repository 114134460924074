import React from "react";
import { Select } from "antd";

type Props = {
  value: string | number;
  onChange: Function;
  options: any;
};

const UserSort = (props: Props) => {
  const { value, onChange, options } = props;

  return (
    <Select
      value={value}
      onChange={(val) => onChange(val)}
      defaultValue="Filter"
    >
      {options.map((option: any) => (
        <Select.Option value={option.value} key={option.key}>
          {option.title}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UserSort;
