import * as types from "../actionTypes";
import { Dispatch } from "redux";
import { toastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import history from "../../history";

export const setScoresScroll =
  (scores: any, count: any) => (dispatch: Dispatch) => {
    dispatch({
      type: types.INFINITE_GET_SCORES,
      payload: {
        scores: scores,
        count: count,
      },
    });
  };

export const getScoresList =
  (
    pageNo: any,
    qid?: any,
    index?: any,
    isGroupFilter?: boolean,
    fromProjectView?: boolean
  ) =>
  (dispatch: Dispatch, getState: any) => {
    const pageSize = 8;
    http
      .get(
        `/score_expressions?page=${pageNo}&size=${pageSize}&questionnaireId=${qid}`
      )
      .then((res) => {
        let scoreExps: any[] = getState().scores.scoresList;
        if (isGroupFilter) {
          let found = scoreExps.some((item) => item.idx === index);
          let finalPayload = found
            ? scoreExps.map((item) => {
                return item.idx === index
                  ? { idx: index, scoreExp: res.data.data.scoreExpressions }
                  : item;
              })
            : [
                ...scoreExps,
                { idx: index, scoreExp: res.data.data.scoreExpressions },
              ];

          dispatch({
            type: types.SET_SCORES_LIST,
            payload: finalPayload,
            count: res.data.data.count,
          });
        } else {
          if (res.data.data.scoreExpressions.length === 0 && fromProjectView) {
            toastMessage(
              "warning",
              "No score expressions exists for this questionnaire"
            );
          }
          dispatch({
            type: types.SET_SCORES_LIST,
            payload: res.data.data.scoreExpressions,
            count: res.data.data.count,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
      });
  };

export const updateGetScores =
  (scoreExps: any) => (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: types.SET_SCORES_LIST,
      payload: scoreExps,
      count: getState().scores.count,
    });
  };

// Create a score expression with nodes and conditions
export const createScoreExp =
  (sbody: any, qid: any, qname?: any) => (dispatch: Dispatch<any>) => {
    http
      .post(`/score_expressions`, sbody)
      .then((res) => {
        console.log(res.data, qname);
        toastMessage("success", res.data.message);
        dispatch(getScoresList(1, qid));
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
      });
  };

//update a score expression with nodes and conditions

export const updateScoreExp = (scoreId: any, body: any) => () => {
  http
    .put(`/score_expressions/${scoreId}`, body)
    .then((res) => {
      toastMessage("success", res.data.message);
      history.goBack();
    })
    .catch((err) => {
      console.log(err);
      toastMessage(
        "error",
        err!.response !== undefined
          ? err!.response!.data!.message[0]
          : "Network error:  Failed to load response data"
      );
    });
};

export const DeleteScore =
  (sid: any, qid: any, current: any) => (dispatch: Dispatch<any>) => {
    http
      .delete(`/score_expressions/${sid}`)
      .then((res) => {
        console.log(res.data);
        dispatch(getScoresList(current, qid));
      })
      .catch((err) => {
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
      });
  };

export const searchScores =
  (searchItem: any, qid: any) => (dispatch: Dispatch) => {
    const pageNo = 1;
    const pageSize = 10;

    http
      .get(
        `/score_expressions?page=${pageNo}&size=${pageSize}&questionnaireId=${qid}&search=${searchItem}`
      )
      .then((res) => {
        dispatch({
          type: types.SEARCH_SCORES,
          payload: res.data.data.scoreExpressions,
          count: res.data.data.count,
        });
      })
      .catch((err) => {
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
      });
  };

// Get a Score Expression by Id with nodes and conditions

export const GetScoreInfo = (scoreId: any) => (dispatch: Dispatch<any>) => {
  http
    .get(`/score_expressions/${scoreId}`)
    .then((res) => {
      dispatch({
        type: types.GET_SCORE_INFO,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      toastMessage(
        "error",
        err!.response !== undefined
          ? err!.response!.data!.message[0]
          : "Network error:  Failed to load response data"
      );
    });
};
