import React from "react";
import moment from "moment";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { appointmentAnalyticsPeriodConstructor } from "../../helpers/Appointment";
import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { Select, Spin } from "antd";
import roles from "../../constants/roles";
import { debounce } from "lodash";

const chartOptions = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: false,
        //   text: "Sleep(mins)",
        //   font: function (context: any) {
        //     return {
        //       color: "#333333",
        //       family: "poppinsregular",
        //       size: 12,
        //       lineHeight: "18px",
        //     };
        //   },
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 12,
            lineHeight: "18px",
          };
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 12,
            lineHeight: "18px",
            weight: 500,
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const SchedulingCard = () => {
  const [period, setPeriod] = useState("week");
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState<any[]>();
  const [fetching, setFetching] = useState<boolean>(false);
  const [therapist, setTherapist] = useState<string | undefined>(undefined);

  const periodSelector = (value: string) => {
    setPeriod(value);
  };

  const chooseTherapist = (val: string) => {
    setTherapist(val);
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const { query, from_date, to_date } =
          appointmentAnalyticsPeriodConstructor(period);
        const therapistQuery = therapist ? `&therapistId=${therapist}` : "";
        const res = await http.get(
          "/ap/analytics/scheduling" + query + therapistQuery
        );
        const dataArr = res.data.data;
        const arr = [];
        while (from_date.isBefore(to_date)) {
          const formattedStr = from_date.local().format("MMM DD");
          const foundDate = dataArr.find((entry: any) => {
            return moment(entry.index).isSame(from_date, "day");
          });
          arr.push({
            label: formattedStr,
            value: foundDate ? +foundDate.count : 0,
          });
          from_date.add(1, "day");
        }
        const chartData = {
          labels: arr.map((entry) => {
            return entry.label;
          }),
          datasets: [
            {
              data: arr.map((entry) => entry.value),
              backgroundColor: "#00b9fc",
              borderColor: "#00b9fc",
              borderRadius: 20,
              barThickness: 20,
              minBarLength: 7,
            },
          ],
        };
        setData(chartData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err);
      }
    };
    fetchDetails();
  }, [period, setData, setLoading, therapist]);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value: string) => {
      try {
        setOptions([]);
        if (!value) {
          return;
        }
        setFetching(true);
        const res = await http.get(
          `/users?page=1&size=20&role=${roles.Therapist["be_value"]}&search=${value}`
        );
        const users = res.data.data.users.map((user: any) => {
          return {
            label: user.firstName + " " + user.lastName,
            value: user.id,
          };
        });
        setOptions(users);
        setFetching(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setFetching(false);
      }
    };
    return debounce(loadOptions, 500);
  }, [setFetching, setOptions]);

  return (
    <div className="dashboard-scheduling-wrapper">
      <div className="scheduling-header">
        <div className="dashboard-title">Scheduling Overview</div>
        <span className="scheduling-options">
          <div className="scheduling-period-select">
            <span
              className={period === "week" ? "active" : ""}
              onClick={() => periodSelector("week")}
            >
              Week
            </span>
            <span
              className={period === "month" ? "active" : ""}
              onClick={() => periodSelector("month")}
            >
              Month
            </span>
          </div>
          <span className="therapist-title">Therapist</span>
          <Select
            allowClear
            showSearch
            className="therapist-selector"
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            value={therapist}
            onChange={chooseTherapist}
            placeholder="Select a therapist"
          />
        </span>
      </div>
      <div className="graph-wrapper">
        {isLoading ? (
          <span className="graph-spinner">
            <Spin />
          </span>
        ) : null}
        <GraphComponent data={data} />
      </div>
    </div>
  );
};

const GraphComponent = React.memo(({ data }: any) => {
  return <Bar data={data} options={chartOptions} />;
});

export default SchedulingCard;
