import moment from "moment";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import history from "../../history";
import http from "../../http";
import { AppointmentSlot } from "../../types";
import { setSlots, setTimings } from "../reducers/appointmentSlice";
import { setAppLoader } from "../reducers/loaderSlice";
import { AppThunk } from "../store";

export const fetchTherapistSlots =
  (therapistId: string | undefined): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    let query = "";
    if (therapistId) {
      query = `?therapistId=${therapistId}`;
    }
    const res = await http.get("/ap/timings" + query);
    const timings = res.data.data;
    if (!timings) {
      dispatch(setSlots([]));
      return;
    }
    let timePeriod = timings.interval;
    let newSlots: AppointmentSlot[] = [];
    if (timings.onlineStartOffsets.length > 0) {
      let start = moment()
        .startOf("day")
        .add(timings.onlineStartOffsets[0], "seconds");
      const end = moment()
        .startOf("day")
        .add(
          timings.onlineEndOffsets[timings.onlineEndOffsets.length - 1],
          "seconds"
        );
      while (start.isBefore(end)) {
        newSlots.push({
          start: start.format("hh:mm A"),
          end: start.add(timePeriod, "seconds").format("hh:mm A"),
          className: "available",
        });
      }
    }
    newSlots = newSlots.map((slot) => {
      let isIn = false;
      timings.offlineStartOffsets.forEach((_1: any, index: number) => {
        if (
          moment(slot.start, "hh:mm A").isSameOrAfter(
            moment()
              .startOf("day")
              .add(timings.offlineStartOffsets[index], "seconds")
          ) &&
          moment(slot.end, "hh:mm A").isSameOrBefore(
            moment()
              .startOf("day")
              .add(timings.offlineEndOffsets[index], "seconds")
          )
        ) {
          isIn = true;
        }
      });
      if (isIn) {
        return {
          ...slot,
          className: "unavailable",
        };
      } else {
        return {
          ...slot,
        };
      }
    });
    dispatch(setSlots(newSlots));
    dispatch(setTimings({ interval: timePeriod }));
  };

export const rescheduleAppointment =
  (id: string, date: string, time: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const interval = getState().appointment.timings.interval;
      if (!interval) {
        toastMessage("error", "Therapist details unavailable");
        return;
      }
      const scheduledStartTime = moment(date + " " + time, "DD-MM-YYYY hh:mm A")
        .utc()
        .toISOString();
      const scheduledEndTime = moment(date + " " + time, "DD-MM-YYYY hh:mm A")
        .add(interval, "seconds")
        .utc()
        .toISOString();
      dispatch(setAppLoader(true));
      await http.patch(`/ap/${id}/reschedule`, {
        scheduledStartTime: scheduledStartTime,
        scheduledEndTime: scheduledEndTime,
      });
      toastMessage("success", "Appointment Rescheduled successfully");
      history.push("/dashboard");
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const setTherapistAvailablity =
  (
    status: string,
    date: string,
    time: string,
    appointmentId?: string
  ): AppThunk =>
  async (dispatch, getState) => {
    const interval = getState().appointment.timings.interval;
    if (!interval) {
      toastMessage("error", "Therapist details unavailable");
      return;
    }
    if (status === "Available") {
      await http.patch(`/ap/${appointmentId}/cancel`);
    } else {
      const therapistId = localStorage.getItem("userId");
      const scheduledStartTime = moment(
        date + " " + time,
        "DD, MMMM, YYYY hh:mm A"
      )
        .utc()
        .toISOString();
      const scheduledEndTime = moment(
        date + " " + time,
        "DD, MMMM, YYYY hh:mm A"
      )
        .add(interval, "seconds")
        .utc()
        .toISOString();
      const finalValues = {
        invitees: [therapistId],
        inviterId: therapistId,
        contactType: "mahalo_vc",
        type: "init",
        notes: "Dummy notes",
        repeating: false,
        scheduledStartTime,
        scheduledEndTime,
      };
      await http.post("/ap", finalValues);
    }
    // dispatch(setAppLoader(true));
    toastMessage("success", "Availability changed successfully");
  };
