import React, { useState } from "react";
import { Tabs } from "antd";
import DiaryHeader from "./DiaryHeader";
import AppLayout from "../../components/AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import FoodDiary from "./FoodDiaryList";
import ExerciseDiary from "./ExerciseDiaryList";

const { TabPane } = Tabs;

const Diary: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>(
    sessionStorage.getItem("diary-view-tab") || "Food"
  );

  const tabChangeHandler = (key: string) => {
    setSelectedTab(key);
    sessionStorage.setItem("diary-view-tab", key);
  };

  return (
    <AppLayout>
      <Navigation />
      <DiaryHeader type={selectedTab} />
      <Tabs
        activeKey={selectedTab}
        onChange={tabChangeHandler}
        destroyInactiveTabPane
        className="singer-cutsom-tab"
      >
        <TabPane tab="Food Diary" key="Food">
          <FoodDiary />
        </TabPane>
        <TabPane tab="Exercise Diary" key="Exercise">
          <ExerciseDiary />
        </TabPane>
      </Tabs>
    </AppLayout>
  );
};

export default Diary;
