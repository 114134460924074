import React, { useCallback, useRef, useState } from "react";
import firebase, { db } from "../../../firebase";
import { Input, Upload } from "antd";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import {
  ChatAttachmentIcon,
  ChatHyperLink,
  ChatImage,
  ChatVideo,
} from "../../Common/Icons";
import { uploadAsset } from "../../../helpers/imageUpload";
import ChatAttachment from "./ChatAttachment";
import ChatMedia from "./ChatMedia";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { setModalDetails } from "../../../redux/reducers/modalSlice";

const ChatInput = ({ roomName }: any) => {
  const dispatch = useAppDispatch();
  const [textValue, setTextValue] = useState("");
  const [attachment, setAttachment] = useState<any>(null);
  const chatInputRef = useRef<Input>(null);

  const textAreadHandler = (event: any) => {
    const val = event.target.value;
    setTextValue(val);
  };

  const sendData = () => {
    const finalText = textValue.trim();
    if (finalText || attachment) {
      dispatch(setAppLoader(true));
      const timestamp = firebase.firestore.FieldValue.serverTimestamp;
      const userId = localStorage.getItem("userId");
      const newMessage: any = {
        sentBy: userId,
        sentAt: timestamp(),
        text: textValue,
      };
      if (attachment) {
        newMessage.resourceType = attachment.type;
        newMessage.resourceUrl = attachment.resourceUrl;
        if (attachment.name) {
          newMessage.resourceName = attachment.name;
        }
      }
      const chat = db.collection("chat").doc(roomName).collection("messages");
      chat
        .add(newMessage)
        .then(() => {
          setTextValue("");
          setAttachment(null);
          dispatch(setAppLoader(false));
        })
        .catch((err) => {
          errorToastMessage(err);
          dispatch(setAppLoader(false));
        });
    }
  };

  const beforeUpload = (file: any) => {
    const isLt2M = file.size / 1024 / 1024 < 25;
    if (!isLt2M) {
      toastMessage("warning", "Image must smaller than 25MB!");
    }
    return isLt2M;
  };

  const attachmentHandler = async (file: any, type: any) => {
    const name = file.name;
    const fileUrl = await uploadAsset(file, "chat", "Upload Successful.")();
    setAttachment({
      type: type,
      resourceUrl: fileUrl,
      name: name,
    });
    if (chatInputRef.current) {
      chatInputRef.current.focus();
    }
  };

  const sendLink = () => {
    const send = (text: any, link: any) => {
      dispatch(setAppLoader(true));
      const timestamp = firebase.firestore.FieldValue.serverTimestamp;
      const userId = localStorage.getItem("userId");
      const newMessage: any = {
        sentBy: userId,
        sentAt: timestamp(),
        text: text,
        resourceType: "link",
        resourceUrl: link,
      };
      const chat = db.collection("chat").doc(roomName).collection("messages");
      chat
        .add(newMessage)
        .then(() => {
          dispatch(setAppLoader(false));
        })
        .catch((err) => {
          errorToastMessage(err);
          dispatch(setAppLoader(false));
        });
    };
    dispatch(
      setModalDetails({
        type: "SEND_CHAT_LINK",
        modalProps: {
          show: true,
          sendLinkHandler: send,
        },
      })
    );
  };

  const clearAttachment = useCallback(() => {
    setAttachment(null);
  }, [setAttachment]);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      sendData();
    }
  };

  return (
    <>
      {attachment && attachment.type === "attachment" && (
        <ChatAttachment
          attachment={attachment}
          clearAttachment={clearAttachment}
          sendAttachment={sendData}
        />
      )}
      {attachment &&
        (attachment.type === "image" || attachment.type === "video") && (
          <ChatMedia
            attachment={attachment}
            clearAttachment={clearAttachment}
          />
        )}
      <div className="chat-editor">
        <Input
          ref={chatInputRef}
          onKeyDown={handleKeyDown}
          className="border-0 chat-input"
          value={textValue}
          onChange={textAreadHandler}
        />
        <span className="chat-input-actions">
          <Upload
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={({ file }) => attachmentHandler(file, "attachment")}
            multiple={false}
          >
            <ChatAttachmentIcon />
          </Upload>
        </span>
        <span className="chat-input-actions">
          <span onClick={sendLink} className="link-wrapper">
            <ChatHyperLink />
          </span>
        </span>
        <span className="chat-input-actions">
          <Upload
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={({ file }) => attachmentHandler(file, "image")}
            multiple={false}
            accept="image/*"
          >
            <ChatImage />
          </Upload>
        </span>
        <span className="chat-input-actions">
          <Upload
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={({ file }) => attachmentHandler(file, "video")}
            multiple={false}
            accept="video/*"
          >
            <ChatVideo />
          </Upload>
        </span>
      </div>
    </>
  );
};

export default React.memo(ChatInput);
