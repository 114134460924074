import React, { useState, useEffect } from "react";
import { Upload } from "antd";
import { toastMessage } from "../../helpers/toastMessage";
import { fieldMap } from "./educationDataList";

import InputField from "../Common/CustomInput";
import { connect } from "react-redux";
import QuestionSection from "./questionSection";
import { UploadImage } from "../../helpers/imageUpload";
// import "../../../pages/css/education/editLessonPage.scss";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { quillFormats, quillModules } from "../../constants/reactQuillOption";

type Props = {
  item: {
    name: string;
    key: number;
    title: string;
    subTitle: string;
    resources: { label: ""; url: "" }[];
    files: any[];
    fileNames: string;
    questions: any[];
    content: string;
    video: string;
  };
  handleSection: Function;
  index: number;
  UploadImage: Function;
  editSections: any;
};

const EditSection = (props: Props) => {
  const { item } = props;

  const [postImageUrl, setPostImageUrl] = useState("");

  const [textAreaVal, setTextAreaVal] = useState("");

  useEffect(() => {
    if (item.content !== undefined) {
      setTextAreaVal(item.content);
    }
  }, [item.content, setTextAreaVal]);

  const handleTextChange = (e: any) => {
    const newItem = {
      ...item,
      [e.target.name]: e.target.value,
    };
    props.handleSection(newItem, props.index);
  };

  //RESOURCES-URL

  const handlelabelChange = (e: any, index: any, key: string) => {
    const newItem = {
      ...item,
      resources: item.resources.map((resource: any, i: any) =>
        i === index ? { ...resource, [key]: e.target.value } : resource
      ),
    };

    props.handleSection(newItem, props.index);
  };

  const addUrl = () => {
    const newItem = {
      ...item,
      resources: [...item.resources, { label: "", url: "" }],
    };
    props.handleSection(newItem, props.index);
  };

  //React quill description
  const handleQuill = (data: any) => {
    const newItem = {
      ...item,
      content: data,
    };

    setTextAreaVal(data);
    props.handleSection(newItem, props.index);
  };

  const handleUpload = (data: any) => {
    setPostImageUrl(data[0].postUploadImageUrl);
    const newSection = {
      ...item,
      files: [data[0].postUploadImageUrl],
      fileNames: data[0].fileName,
    };
    props.handleSection(newSection, props.index);
  };

  const handleAvater = (fileObj: any) => {
    props.UploadImage(fileObj, handleUpload, "education_lesson_image");
  };

  // QUESTIONS

  const handleDelete = (index: number) => {
    const newSection = {
      ...item,
      questions: item.questions.filter((_: any, i: number) => {
        return i !== index;
      }),
    };

    props.handleSection(newSection, props.index);
  };

  const handleInnerFields = (index: number, newChoice: any) => {
    const newSection = {
      ...item,
      questions: item.questions.map((question, i) => {
        return i === index ? newChoice : question;
      }),
    };

    props.handleSection(newSection, props.index);
  };

  const handleQuestionType = (type: any, index: number) => {
    const newSection = {
      ...item,
      questions: item.questions.map((question, i) => {
        return i === index ? { ...fieldMap[type], type } : question;
      }),
    };

    props.handleSection(newSection, props.index);
  };

  const addQuestionField = () => {
    const newSection = {
      ...item,
      questions: [...item.questions, { ...fieldMap.S }],
    };
    props.handleSection(newSection, props.index);
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      toastMessage("warning", "Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const switchForm = () => {
    switch (item.name) {
      case "TITLE":
        return (
          <>
            <div className="field-group">
              <div className="field-title">Section Title</div>
              <div className="floating-label">
                <InputField
                  name="title"
                  value={item.title}
                  changeEvent={handleTextChange}
                  className="floating-input px-2"
                />
              </div>
            </div>
            <div
              className="mt-4 mb-3"
              style={{ border: "1px solid #DBDBDB" }}
            ></div>
          </>
        );

      case "SUBTITLE":
        return (
          <>
            <div className="form-group">
              <label>Section Subtitle</label>
              <div className="floating-label">
                <InputField
                  name="subTitle"
                  value={item.subTitle}
                  changeEvent={handleTextChange}
                  className="floating-input px-2"
                />
              </div>
            </div>
            <div
              className="mt-4 mb-3"
              style={{ border: "1px solid #DBDBDB" }}
            ></div>
          </>
        );
      case "VIDEO":
        return (
          <>
            <div className="field-group">
              {/* <div className="font-l mb-4">Section Video</div> */}
              <div className="form-group">
                <label>Paste the video url:</label>
                <div className="floating-label">
                  <InputField
                    name="video"
                    value={item.video}
                    changeEvent={handleTextChange}
                    className="floating-input px-2"
                  />
                </div>
              </div>
            </div>
            <div
              className="mt-4 mb-4"
              style={{ borderBottom: "1px solid #DBDBDB" }}
            ></div>
          </>
        );
      case "PICTURES":
        return (
          <>
            <div className="pictures-section">
              <div className="field-title">Section Picture</div>
              <div className="pictures-wrapper">
                {postImageUrl !== "" || item.files[0] ? (
                  <>
                    <div className="image-url-wrapper">
                      <img src={postImageUrl || item.files[0]} alt="section" />
                    </div>
                  </>
                ) : (
                  <div className="image-container">
                    <div className="placeholder-icon"></div>
                  </div>
                )}
                <div className="ms-3">
                  <p>Upload your page photos</p>
                  <p>
                    750 x 422 pixels, .jpg, .jpeg, .gif or .png and no text on
                    the image.
                  </p>
                  <div className="upload-bar">
                    <InputField
                      placeholder="No file selected"
                      disabled={true}
                      value={props.item.fileNames}
                    />

                    <Upload
                      multiple
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      customRequest={handleAvater}
                      accept=".gif,.jpg,.jpeg,.png"
                    >
                      <button className="upload-button">Upload file</button>
                    </Upload>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mt-4 mb-3"
              style={{ border: "1px solid #DBDBDB" }}
            ></div>
          </>
        );
      case "QUESTION":
        return (
          <>
            <div className="questions-section">
              <div className="field-title">Questions</div>
              {item.questions.map((question: any, index: number) => {
                return (
                  <QuestionSection
                    key={index}
                    index={index}
                    choice={question}
                    delete={handleDelete}
                    setInnerFields={handleInnerFields}
                    handleQuestionType={handleQuestionType}
                  />
                );
              })}
              <div
                className="add-extra text-end mt-1 cp"
                onClick={() => addQuestionField()}
              >
                + Add Another Question
              </div>
            </div>
            <div
              className="mt-4 mb-3"
              style={{ border: "1px solid #DBDBDB" }}
            ></div>
          </>
        );
      case "ARTICLE/TEXT":
        return (
          <>
            <div className="text-editor-wrapper">
              <div className="text-editor">
                <div className="field-title">Section Text Area</div>
                <ReactQuill
                  modules={quillModules}
                  formats={quillFormats}
                  placeholder="Type something"
                  onChange={handleQuill}
                  value={textAreaVal}
                />
              </div>
            </div>
            <div
              className="mt-4 mb-3"
              style={{ border: "1px solid #DBDBDB" }}
            ></div>
          </>
        );
      case "RESOURCES":
        return (
          <>
            <div className="resources-section">
              <div className="field-title">Section Resources</div>
              <div className="field-group">
                <div className="floating-label mt-4">
                  {item.resources.map((resource, index) => {
                    return (
                      <div key={index + item.key + "resources"}>
                        <div>Resource Label</div>
                        <InputField
                          name={`item.resources[${index}].label`}
                          value={resource.label}
                          changeEvent={(e) =>
                            handlelabelChange(e, index, "label")
                          }
                          className="floating-input px-2"
                        />
                        <div>Resource Url</div>
                        <InputField
                          name={`item.resources[${index}].url`}
                          value={resource.url}
                          changeEvent={(e) =>
                            handlelabelChange(e, index, "url")
                          }
                          className="floating-input px-2"
                        />
                      </div>
                    );
                  })}
                  <div className="add-extra text-end mb-4 cp">
                    <span onClick={() => addUrl()}>+ Add new resource url</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="m4-4 mb-3"
              style={{ border: "1px solid #DBDBDB" }}
            ></div>
          </>
        );
      default:
        break;
    }
  };

  return <div className="edit-lesson-wrapper">{switchForm()}</div>;
};

export default connect(null, { UploadImage })(EditSection);
