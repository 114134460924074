import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { Avatar, Dropdown, Menu, Progress, Table } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { errorToastMessage } from "../../helpers/toastMessage";
import {
  fetchParticipantDetails,
  modifyUserDetails,
} from "../../redux/actions/adminAction";
import { SortOrder } from "antd/lib/table/interface";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { getImageInitials } from "../../helpers/user";
import { toggleParticipantLoader } from "../../redux/reducers/participantSlice";
import { SettingIcons } from "../Common/Icons";
import { useHistory, useParams } from "react-router-dom";
import { setModalDetails } from "../../redux/reducers/modalSlice";

type Props = {
  type: string;
};

const ParticipantListView: React.FC<Props> = ({ type }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { therapistId } = useParams<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState<any | null>(null);
  const {
    searchTerm,
    activeParticipantCount,
    activeParticipantUsers,
    inactiveParticipantCount,
    inactiveParticipantUsers,
    toggleLoader,
  } = useAppSelector((state) => state.participant);
  const toggleRef = useRef(toggleLoader);

  const fetchClientData = useCallback(
    async (searchTerm, type, therapistId) => {
      try {
        await dispatch(
          fetchParticipantDetails(type, searchTerm, 1, null, therapistId)
        );
        setCurrentPage(1);
        setSort(null);
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, setCurrentPage, setSort]
  );

  useEffect(() => {
    fetchClientData(searchTerm, type, therapistId);
  }, [searchTerm, type, fetchClientData, therapistId]);

  useEffect(() => {
    if (toggleLoader !== toggleRef.current) {
      fetchClientData(searchTerm, type, therapistId);
      toggleRef.current = toggleLoader;
    }
  }, [searchTerm, type, fetchClientData, toggleLoader, therapistId]);

  const onTableChange = async (pagination: any, filters: any, sorters: any) => {
    let page = 1;
    let sort = null;
    if (sorters.order) {
      sort = {
        orderBy: sorters["order"] === "ascend" ? "asc" : "desc",
        sortBy: "firstName",
        //hardcoded because BE accepts only firstName as param
      };
      setSort(sort);
    } else {
      setSort(null);
    }
    if (pagination.current) {
      setCurrentPage(pagination.current);
      page = pagination.current;
    }
    try {
      await dispatch(
        fetchParticipantDetails(type, searchTerm, page, sort, therapistId)
      );
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const modifyUser = useCallback(
    async (data: any) => {
      try {
        const obj = {
          status: data.status === "inactive" ? "active" : "inactive",
        };
        await dispatch(modifyUserDetails(obj, data.id));
        dispatch(toggleParticipantLoader());
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch]
  );

  const crfNavigate = useCallback(
    (name: string) => {
      history.push(`/crf?name=${name}`);
    },
    [history]
  );

  const showRights = useCallback(() => {
    dispatch(
      setModalDetails({
        type: "RIGHTS_MODAL",
        modalProps: {
          show: true,
        },
      })
    );
  }, [dispatch]);

  const columns: any = useMemo(() => {
    const menu = (data: any) => (
      <Menu>
        <Menu.Item key="0" onClick={() => modifyUser(data)}>
          {data.status === "inactive" ? "Restore" : "Archive"}
        </Menu.Item>
        <Menu.Item key="1">Lock</Menu.Item>
        <Menu.Item key="2">Unlock</Menu.Item>
        <Menu.Item key="3">Print selected</Menu.Item>
        <Menu.Item key="4">Print Empty CRF</Menu.Item>
        <Menu.Item key="5">Export</Menu.Item>
        <Menu.Item key="6">Import</Menu.Item>
        <Menu.Item key="7">Update status</Menu.Item>
        <Menu.Item key="8">Update institute</Menu.Item>
      </Menu>
    );
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
        sortOrder:
          sort?.sortBy === "firstName"
            ? sort.orderBy === "asc"
              ? ("ascend" as SortOrder)
              : ("descend" as SortOrder)
            : undefined,
        render: (_1: any, record: any) => {
          return (
            <div className="d-flex">
              {record.profileImage ? (
                <Avatar shape="circle" size="large" src={record.profileImage} />
              ) : (
                <Avatar shape="circle" size="large">
                  {getImageInitials(record.firstName, record.lastName)}
                </Avatar>
              )}
              <div className="ms-3">
                <p className="text-s m-0 fw-medium">
                  {record.firstName + " " + record.lastName}
                </p>
                <span className="text-muted">{record.email}</span>
              </div>
            </div>
          );
        },
      },
      {
        title: "User Id",
        dataIndex: "status",
        key: "userId",
        render: (_1: string, record: any, rowIndex: any) => {
          let userId = "";
          if ([1, 6, 7].includes(rowIndex)) {
            userId += "AXN";
          } else if ([0, 3].includes(rowIndex)) {
            userId += "BXN";
          } else {
            userId += "AXY";
          }
          userId += record.email.length * 2;
          if ([1, 6, 7].includes(rowIndex)) {
            userId += "FS";
          } else {
            userId += "MS";
          }
          return <span>{userId}</span>;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status: string) => {
          return <span>{status === "active" ? "Confirmed" : "Archived"}</span>;
        },
      },
      {
        title: "Manage Rights",
        key: "mrights",
        render: (status: string, record: any) => {
          return <span>{record?.firstName?.length || 4}</span>;
        },
      },
      {
        title: "Institute Rights",
        key: "irights",
        render: (status: string, record: any) => {
          return <span>{record?.lastName?.length || 2}</span>;
        },
      },
      {
        title: "CRF",
        key: "CRF",
        render: (status: string, record: any) => {
          const name = record.firstName + " " + record.lastName;
          const len = record.email.length;
          let color = "green";
          if (len > 20) {
            color = "#cf5744";
          } else if (len > 17) {
            color = "#FFBF00";
          }
          return (
            <div
              onClick={() => crfNavigate(name)}
              style={{
                backgroundColor: color,
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></div>
          );
        },
      },
      {
        title: "Completion Rate",
        key: "completion",
        render: (status: string, record: any) => {
          const percent = (record.email.length * 100) / 40;
          return <Progress percent={percent} size="small" />;
        },
      },
      {
        title: "Edit Rights",
        key: "editRights",
        align: "center",
        render: (status: string) => {
          return (
            <span onClick={showRights} className="cp">
              <SettingIcons />
            </span>
          );
        },
      },
      // {
      //   title: "Contact",
      //   dataIndex: "phoneNumber",
      //   key: "mobile",
      // },
      {
        title: "Action",
        render: (_1: any, record: any) => {
          return (
            <span onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={menu(record)}
                placement="bottomRight"
                trigger={["click"]}
              >
                <i className="more-icon cp"></i>
              </Dropdown>
            </span>
          );
        },
      },
    ];
  }, [sort, modifyUser, showRights, crfNavigate]);

  return (
    <>
      <Table
        dataSource={
          type === "active" ? activeParticipantUsers : inactiveParticipantUsers
        }
        columns={columns}
        showSorterTooltip={false}
        className="singer-custom-table"
        onChange={onTableChange}
        rowKey={(record) => record.id}
        pagination={{
          current: currentPage,
          total:
            type === "active"
              ? activeParticipantCount
              : inactiveParticipantCount,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
          },
          position: ["bottomLeft"],
        }}
      />
    </>
  );
};

export default ParticipantListView;
