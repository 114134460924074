import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProgramState {
  programId: string;
  editProgram: any;
  lessonData: any[];
  deleteLessons: any[];
  addLessons: any[];
  questionData: any[];
  deleteQuestion: any[];
  addQuestions: any[];
  foodDiaryData: any[];
  addFoodDiary: any[];
  deleteFoodDiary: any[];
  dummyFoodArray: any[];
  exerciseDiaryData: any[];
  addExerciseDiary: any[];
  deleteExerciseDiary: any[];
  dummyExerciseArray: any[];
}

const initialState: ProgramState = {
  programId: "",
  editProgram: {},
  lessonData: [],
  deleteLessons: [],
  addLessons: [],
  questionData: [],
  deleteQuestion: [],
  addQuestions: [],
  foodDiaryData: [],
  addFoodDiary: [],
  deleteFoodDiary: [],
  dummyFoodArray: [],
  exerciseDiaryData: [],
  addExerciseDiary: [],
  deleteExerciseDiary: [],
  dummyExerciseArray: [],
};

export const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    setProgramDetails: (state, action: PayloadAction<any>) => {
      state.programId = action.payload.id;
      state.editProgram = action.payload.basic;
      state.lessonData = action.payload.lessons;
      state.addLessons = [];
      state.deleteLessons = [];
      state.questionData = action.payload.questions;
      state.deleteQuestion = [];
      state.addQuestions = [];
      state.foodDiaryData = action.payload.foods;
      state.deleteFoodDiary = [];
      state.addFoodDiary = [];
      state.dummyFoodArray = [];
      state.exerciseDiaryData = action.payload.exercises;
      state.deleteExerciseDiary = [];
      state.addExerciseDiary = [];
      state.dummyExerciseArray = [];
    },
    deleteProgramLesson: (state, action: PayloadAction<{ id: string }>) => {
      state.lessonData = state.lessonData.filter((lesson) => {
        return lesson.id !== action.payload.id;
      });
      const inDb = state.addLessons.every((lesson) => {
        return lesson.id !== action.payload.id;
      });
      state.addLessons = state.addLessons.filter((lesson) => {
        return lesson.id !== action.payload.id;
      });
      if (inDb)
        state.deleteLessons = [...state.deleteLessons, action.payload.id];
    },
    addProgramLesson: (state, action: PayloadAction<any>) => {
      state.lessonData = [...state.lessonData, { ...action.payload }];
      state.addLessons = [...state.addLessons, { ...action.payload }];
    },
    deleteProgramQuestion: (state, action: PayloadAction<{ id: string }>) => {
      state.questionData = state.questionData.filter((question) => {
        return question.id !== action.payload.id;
      });
      const inDb = state.addQuestions.every((question) => {
        return question.id !== action.payload.id;
      });
      state.addQuestions = state.addQuestions.filter((question) => {
        return question.id !== action.payload.id;
      });
      if (inDb)
        state.deleteQuestion = [...state.deleteQuestion, action.payload.id];
    },
    addProgramQuestion: (state, action: PayloadAction<any>) => {
      state.questionData = [...state.questionData, { ...action.payload }];
      state.addQuestions = [...state.addQuestions, { ...action.payload }];
    },
    addProgramFood: (state, action: PayloadAction<any[]>) => {
      state.foodDiaryData = [...state.foodDiaryData, ...action.payload];
      state.addFoodDiary = [...state.addFoodDiary, ...action.payload];
      state.dummyFoodArray = [];
    },
    deleteProgramFood: (state, action: PayloadAction<{ id: string }>) => {
      state.foodDiaryData = state.foodDiaryData.filter((food) => {
        return food.id !== action.payload.id;
      });
      const inDb = state.addFoodDiary.every((food) => {
        return food.id !== action.payload.id;
      });
      state.addFoodDiary = state.addFoodDiary.filter((food) => {
        return food.id !== action.payload.id;
      });
      if (inDb)
        state.deleteFoodDiary = [...state.deleteFoodDiary, action.payload.id];
    },
    setDummyFoodArray: (state, action: PayloadAction<any[]>) => {
      state.dummyFoodArray = action.payload;
    },
    addProgramExercise: (state, action: PayloadAction<any[]>) => {
      state.exerciseDiaryData = [...state.exerciseDiaryData, ...action.payload];
      state.addExerciseDiary = [...state.addExerciseDiary, ...action.payload];
      state.dummyExerciseArray = [];
    },
    deleteProgramExercise: (state, action: PayloadAction<{ id: string }>) => {
      state.exerciseDiaryData = state.exerciseDiaryData.filter((food) => {
        return food.id !== action.payload.id;
      });
      const inDb = state.addExerciseDiary.every((food) => {
        return food.id !== action.payload.id;
      });
      state.addExerciseDiary = state.addExerciseDiary.filter((food) => {
        return food.id !== action.payload.id;
      });
      if (inDb)
        state.deleteExerciseDiary = [
          ...state.deleteExerciseDiary,
          action.payload.id,
        ];
    },
    setDummyExerciseArray: (state, action: PayloadAction<any[]>) => {
      state.dummyExerciseArray = action.payload;
    },
  },
});

export const {
  setProgramDetails,
  deleteProgramLesson,
  addProgramLesson,
  addProgramQuestion,
  deleteProgramQuestion,
  addProgramFood,
  deleteProgramFood,
  setDummyFoodArray,
  addProgramExercise,
  deleteProgramExercise,
  setDummyExerciseArray,
} = programSlice.actions;

export default programSlice.reducer;
