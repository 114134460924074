import React, { useState } from "react";
import { Select, Tooltip } from "antd";
import {
  InputType,
  BooleanType,
  DateType,
  LikertType,
} from "../preview/SingleType";

import ResponseLogic from "../../QuestionBuilder/ResponseLogic";
import BranchLogic from "../../QuestionBuilder/BranchLogic";
import { questionResponseFormat } from "../../../../helpers/question";
// import PlaceHolder from "../../../../public/images/image-placeholder.png";

function checkObj(obj) {
  if (Object.keys(obj).length !== 0) {
    if (obj.hasOwnProperty("choices")) {
      return obj.choices;
    }
    return obj;
  }

  return null;
}

function indxToAlpha(indx) {
  return String.fromCharCode(65 + indx);
}

export const ChoiceType = ({ question, allQuestions, logicJumps }) => {
  const { position, properties } = question;
  const [showResponseModal, setResponseModal] = useState(false);
  const [showBranching, setShowBranching] = useState(false);
  let checkQ = questionResponseFormat([question], logicJumps);
  let allQ = questionResponseFormat(allQuestions, logicJumps);

  let logicExists = logicJumps.some((item) => item.questionId === checkQ[0].id);

  return (
    <>
      <div className="input-control choice-items row">
        {checkObj(properties) ? (
          checkObj(properties).map((choice, index) => (
            <div key={choice.id} className="col-md-6 pl-0 mb-2">
              <input
                id={`choice-${index}-q${position}`}
                className="toggle choice"
                name={`q${position}`}
                value={choice.label}
                type="radio"
              />
              <label
                htmlFor={`choice-${index}-q${position}`}
                className="text-label"
              >
                <span>{indxToAlpha(index)}</span> {choice.label}
              </label>
            </div>
          ))
        ) : (
          <div className="no-items">No Choice Items</div>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <div className="float-end mt-3">
          <Tooltip placement="bottom" title="Response Code">
            <i
              className="response-icon cp"
              onClick={() => setResponseModal(true)}
            ></i>
          </Tooltip>
        </div>

        {question.type !== "group" && logicExists && (
          <div className="float-end mt-3 me-3">
            <Tooltip placement="bottom" title="Logic Jump">
              <i
                className="branch-icon cp"
                onClick={() => setShowBranching(true)}
              ></i>
            </Tooltip>
          </div>
        )}
      </div>
      <ResponseLogic
        show={showResponseModal}
        hideModal={setResponseModal}
        question={checkQ[0]}
        previewDisabling={true}
      />

      {showBranching && (
        <BranchLogic
          choice={checkQ[0]}
          questions={allQ}
          show={showBranching}
          hideModal={setShowBranching}
          previewDisabling={true}
        />
      )}
    </>
  );
};

export const CheckBoxType = ({ question, allQuestions, logicJumps }) => {
  const { position, properties } = question;
  const [showResponseModal, setResponseModal] = useState(false);
  const [showBranching, setShowBranching] = useState(false);
  let checkQ = questionResponseFormat([question], logicJumps);
  let allQ = questionResponseFormat(allQuestions, logicJumps);

  let logicExists = logicJumps.some((item) => item.questionId === checkQ[0].id);

  return (
    <>
      <div className="input-control choice-items row">
        {checkObj(properties) ? (
          checkObj(properties).map((choice, index) => (
            <div key={choice.id} className="col-md-6 pl-0 mb-2">
              <input
                id={`choice-${index}-q${position}`}
                className="toggle choice"
                name={`q${position}`}
                value={choice.label}
                type="checkbox"
              />
              <label
                htmlFor={`choice-${index}-q${position}`}
                className="text-label"
              >
                <span>{indxToAlpha(index)}</span> {choice.label}
              </label>
            </div>
          ))
        ) : (
          <div className="no-items">No Choice Items</div>
        )}
      </div>

      <div className="d-flex justify-content-end">
        <div className="float-end mt-3">
          <Tooltip placement="bottom" title="Response Code">
            <i
              className="response-icon cp"
              onClick={() => setResponseModal(true)}
            ></i>
          </Tooltip>
        </div>
        {question.type !== "group" && logicExists && (
          <div className="float-end mt-3 me-3">
            <Tooltip placement="bottom" title="Logic Jump">
              <i
                className="branch-icon cp"
                onClick={() => setShowBranching(true)}
              ></i>
            </Tooltip>
          </div>
        )}
      </div>

      <ResponseLogic
        show={showResponseModal}
        hideModal={setResponseModal}
        question={checkQ[0]}
        previewDisabling={true}
      />

      {showBranching && (
        <BranchLogic
          choice={checkQ[0]}
          questions={allQ}
          show={showBranching}
          hideModal={setShowBranching}
          previewDisabling={true}
        />
      )}
    </>
  );
};

export const DropdownType = ({ question, allQuestions, logicJumps }) => {
  console.log(question, logicJumps, allQuestions);
  const { properties } = question;
  const [showResponseModal, setResponseModal] = useState(false);
  const [showBranching, setShowBranching] = useState(false);
  let checkQ = questionResponseFormat([question], logicJumps);
  let allQ = questionResponseFormat(allQuestions, logicJumps);
  console.log(checkQ);
  let logicExists = logicJumps.some((item) => item.questionId === checkQ[0].id);

  return (
    <>
      <div className="input-control d-flex flex-column">
        <Select defaultValue="Select an Option" className="systm-select">
          {checkObj(properties) ? (
            checkObj(properties).map((choice) => (
              <Select.Option value={choice.labe} key={choice.id}>
                {choice.label}
              </Select.Option>
            ))
          ) : (
            <div className="no-items">No Choice Items</div>
          )}
        </Select>
      </div>
      <div className="d-flex justify-content-end">
        <div className="float-end mt-2">
          <Tooltip placement="bottom" title="Response Code">
            <i
              className="response-icon cp"
              onClick={() => setResponseModal(true)}
            ></i>
          </Tooltip>
        </div>
        {question.type !== "group" && logicExists && (
          <div className="float-end mt-2 me-3">
            <Tooltip placement="bottom" title="Logic Jump">
              <i
                className="branch-icon cp"
                onClick={() => setShowBranching(true)}
              ></i>
            </Tooltip>
          </div>
        )}
      </div>
      <ResponseLogic
        show={showResponseModal}
        hideModal={setResponseModal}
        question={checkQ[0]}
        previewDisabling={true}
      />

      {showBranching && (
        <BranchLogic
          choice={checkQ[0]}
          questions={allQ}
          show={showBranching}
          hideModal={setShowBranching}
          previewDisabling={true}
        />
      )}
    </>
  );
};

export const PictureType = ({ question, allQuestions, logicJumps }) => {
  const { position, properties, type } = question;
  console.log("kmdklsamlfks", question);

  const [showResponseModal, setResponseModal] = useState(false);
  const [showBranching, setShowBranching] = useState(false);
  let checkQ = questionResponseFormat([question], logicJumps);
  let allQ = questionResponseFormat(allQuestions, logicJumps);

  let logicExists = logicJumps.some((item) => item.questionId === checkQ[0].id);

  return (
    <>
      <div className="input-control row">
        {checkObj(properties) ? (
          checkObj(properties).map((choice, index) => (
            <div className="col-6" key={choice.id}>
              <input
                id={`choice-${index}-q${position}`}
                className="toggle choice"
                name={`q${position}`}
                value={choice.id}
                type="radio"
              />
              <label
                htmlFor={`choice-${index}-q${position}`}
                className="text-label mb-3"
              >
                <div className="img-wrapper">
                  <img
                    src={choice.attachment.href}
                    onError={(e) => {
                      e.target.onerror = null;
                      // e.target.src = PlaceHolder;
                    }}
                    alt={choice.label}
                  />
                </div>
                <span>{indxToAlpha(index)}</span> {choice.label}
              </label>
            </div>
          ))
        ) : (
          <div className="no-items">No Choice Items</div>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <div className="float-end mt-3">
          <Tooltip placement="bottom" title="Response Code">
            <i
              className="response-icon cp"
              onClick={() => setResponseModal(true)}
            ></i>
          </Tooltip>
        </div>

        {type !== "group" && logicExists && (
          <div className="float-end mt-3 me-3">
            <Tooltip placement="bottom" title="Logic Jump">
              <i
                className="branch-icon cp"
                onClick={() => setShowBranching(true)}
              ></i>
            </Tooltip>
          </div>
        )}
      </div>
      <ResponseLogic
        show={showResponseModal}
        hideModal={setResponseModal}
        question={checkQ[0]}
        previewDisabling={true}
      />

      {showBranching && (
        <BranchLogic
          choice={checkQ[0]}
          questions={allQ}
          show={showBranching}
          hideModal={setShowBranching}
          previewDisabling={true}
        />
      )}
    </>
  );
};

const questionTypes = {
  short_text: InputType,
  long_text: InputType,
  number: InputType,
  multiple_choice: ChoiceType,
  likert_scale: LikertType,
  checkbox: ChoiceType,
  picture_choice: PictureType,
  yes_no: BooleanType,
  date: DateType,
  dropdown: DropdownType,
};

export const GroupType = ({ question, logicJumps }) => {
  return (
    <>
      {Array.isArray(question.properties.questions) &&
        question.properties.questions.length > 0 &&
        question.properties.questions
          .sort(function (a, b) {
            return Number(a.position) - Number(b.position);
          })
          .map((q) => {
            if (questionTypes[q.type] === undefined) return;
            const TypeComponent = questionTypes[q.type];
            return (
              <div className="input-block" key={q.id}>
                <div className="label">
                  <span>
                    Q{q.position}. {q.title}
                  </span>
                  {q.required && (
                    <span className="input-block-required">*</span>
                  )}
                </div>
                <TypeComponent
                  question={q}
                  allQuestions={question.properties.questions}
                  logicJumps={logicJumps}
                />
              </div>
            );
          })}
    </>
  );
};
