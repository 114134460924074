import { Formik } from "formik";
import React, { useMemo, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import * as yup from "yup";
import {
  DatePicker,
  Radio,
  RadioChangeEvent,
  Select,
  Spin,
  Checkbox,
  Input,
} from "antd";
import moment from "moment";
import { timepickerOptions } from "../../../helpers/Appointment";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import http from "../../../http";
import roles from "../../../constants/roles";
import { debounce } from "lodash";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { hideModal } from "../../../redux/reducers/modalSlice";

const { TextArea } = Input;
const schema = yup.object().shape({
  invitees: yup
    .array()
    .of(yup.string())
    .min(1, "Please select atleast one user"),
  inviterId: yup.string().required("Please select atleast one user"),
  contactType: yup.string().required("Please select the contact type"),
  type: yup.string().required("Please select the appointment type"),
  notes: yup.string().required("Please enter a note"),
  repeating: yup.boolean(),
  interval: yup.string().when("repeating", {
    is: (repeating: boolean) => repeating,
    then: yup.string().required("Please select the repeat interval"),
  }),
  count: yup.number().when("repeating", {
    is: (repeating: boolean) => repeating,
    then: yup.number().required("Please select a repeat count"),
  }),
  date: yup.string().required("Please select a date"),
  startTime: yup.number().required("Please select a start time"),
});

declare type appointmentType = "individual" | "group";

const AppointmentCreation: React.FC<any> = ({ therapistId }) => {
  const [type, setType] = useState<appointmentType>("individual");
  const timings = useAppSelector((state) => state.administrator.timings);
  const options = useMemo(() => {
    const opt = timepickerOptions();
    return opt;
  }, []);
  const dispatch = useAppDispatch();

  const [inviteeOptions, setInviteeOptions] = useState<any[]>();
  const [fetching, setFetching] = useState<boolean>(false);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value: string) => {
      try {
        setInviteeOptions([]);
        if (!value) {
          return;
        }
        setFetching(true);
        const res = await http.get(
          `/users?page=1&size=20&role=${roles.Participant["be_value"]}&search=${value}`
        );
        const users = res.data.data.users.map((user: any) => {
          return {
            label: user.firstName + " " + user.lastName,
            value: user.id,
          };
        });
        setInviteeOptions(users);
        setFetching(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setFetching(false);
      }
    };
    return debounce(loadOptions, 500);
  }, [setFetching, setInviteeOptions]);

  const modeProps: any = {};
  if (type === "group") {
    modeProps.mode = "multiple";
  }

  const handleRadioChange: (e: RadioChangeEvent) => void = (
    val: RadioChangeEvent
  ) => {
    const value = val.target.value;
    setType(value);
  };

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      const finalValues = { ...values };
      if (type === "individual") {
        finalValues.invitees = [finalValues.invitees[0]];
      }
      if (!finalValues.repeating) {
        delete finalValues["interval"];
        delete finalValues["count"];
      }
      finalValues.scheduledStartTime = moment(finalValues.date, "DD/MM/YYYY")
        .startOf("day")
        .add(finalValues.startTime, "seconds")
        .utc()
        .toISOString();
      finalValues.scheduledEndTime = moment(finalValues.date, "DD/MM/YYYY")
        .startOf("day")
        .add(finalValues.startTime + timings.interval, "seconds")
        .utc()
        .toISOString();
      delete finalValues.date;
      delete finalValues.startTime;
      await http.post("/ap", finalValues);
      dispatch(setAppLoader(false));
      toastMessage("success", "Appointment Created Successfully");
      dispatch(hideModal());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <>
      <Radio.Group
        onChange={handleRadioChange}
        value={type}
        className="d-flex  mb-4 justify-content-center"
      >
        <Radio value="individual">Individual</Radio>
        <Radio value="group">Group</Radio>
      </Radio.Group>
      <Formik
        validationSchema={schema}
        initialValues={{
          invitees: [],
          inviterId: therapistId,
          contactType: "",
          type: "",
          notes: "",
          repeating: false,
          interval: "",
          count: 1,
          date: "",
          startTime: undefined,
        }}
        onSubmit={(values) => {
          submitHandler(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit} className="m-0">
            <div className="input-field w-100 mb-4">
              <div className="field-name form-label">Invitee</div>
              <div className="floating-label">
                <Select
                  showSearch
                  {...modeProps}
                  placeholder="Select user..."
                  className="therapist-selector"
                  filterOption={false}
                  onSearch={debounceFetcher}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  options={inviteeOptions}
                  value={
                    type === "group" ? values.invitees : values.invitees[0]
                  }
                  onChange={(val) => {
                    if (type === "group") {
                      setFieldValue("invitees", val);
                    } else {
                      setFieldValue("invitees", [val]);
                    }
                  }}
                />
                <div className="input-error text-left mt-1">
                  {errors.invitees && touched.invitees && errors.invitees}
                </div>
              </div>
            </div>
            <div className="input-field w-100 mb-4">
              <div className="field-name form-label">Appointment Type</div>
              <div className="floating-label">
                <Select
                  value={values.type}
                  onChange={(val) => setFieldValue("type", val)}
                >
                  <Select.Option value="init">
                    Initial Consultation
                  </Select.Option>
                  <Select.Option value="follow_up">
                    Follow up Consultation
                  </Select.Option>
                </Select>
                <div className="input-error text-left mt-1">
                  {errors.type && touched.type && errors.type}
                </div>
              </div>
            </div>
            <div className="input-field w-100 mb-4">
              <div className="field-name form-label">Contact Type</div>
              <div className="floating-label">
                <Select
                  value={values.contactType}
                  onChange={(val) => setFieldValue("contactType", val)}
                >
                  <Select.Option value="mahalo_vc">
                    Mahalo Video call
                  </Select.Option>
                </Select>
                <div className="input-error text-left mt-1">
                  {errors.contactType &&
                    touched.contactType &&
                    errors.contactType}
                </div>
              </div>
            </div>
            <div className="input-field w-100 mb-4">
              <div className="field-name form-label">When</div>
              <div className="floating-label">
                <DatePicker
                  format="MMMM D, YYYY"
                  value={values.date ? moment(values.date, "DD/MM/YYYY") : null}
                  onChange={(date) => {
                    if (date) {
                      setFieldValue("date", date.format("DD/MM/YYYY"));
                    }
                  }}
                />
                <div className="input-error text-left mt-1">
                  {errors.date && touched.date && errors.date}
                </div>
              </div>
            </div>
            <div className="input-field w-100 mb-4">
              <div className="field-name form-label">Start Time</div>
              <div className="floating-label">
                <Select
                  value={values.startTime}
                  onChange={(val) => setFieldValue("startTime", val)}
                >
                  {options.map((mins) => {
                    if (
                      !timings.startTime ||
                      !timings.endTime ||
                      timings.startTime > mins ||
                      timings.endTime <= mins ||
                      (timings.offlineStart &&
                        timings.offlineEnd &&
                        timings.offlineStart <= mins &&
                        timings.offlineEnd > mins)
                    ) {
                      return null;
                    }
                    return (
                      <Select.Option value={mins} key={mins}>
                        {moment()
                          .startOf("day")
                          .add(mins, "seconds")
                          .format("hh:mm A")}
                      </Select.Option>
                    );
                  })}
                </Select>
                <div className="input-error text-left mt-1">
                  {errors.startTime && touched.startTime && errors.startTime}
                </div>
              </div>
            </div>
            <div className="input-field w-100 mb-4">
              <div className="field-name form-label">Notes</div>
              <div className="floating-label">
                <TextArea
                  name="notes"
                  rows={3}
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="input-error text-left mt-1">
                  {errors.notes && touched.notes && errors.notes}
                </div>
              </div>
            </div>
            <div className="input-field w-100 mb-4">
              <Checkbox
                value={values.repeating}
                onChange={(e) => setFieldValue("repeating", e.target.checked)}
              >
                Repeating Appointment?
              </Checkbox>
            </div>
            {values.repeating && (
              <>
                <div className="input-field w-100 mb-4">
                  <div className="field-name form-label">Repeat Interval</div>
                  <div className="floating-label">
                    <Select
                      value={values.interval}
                      onChange={(val) => setFieldValue("interval", val)}
                    >
                      <Select.Option value="weekly">Weekly</Select.Option>
                      <Select.Option value="biweekly">Biweekly</Select.Option>
                      <Select.Option value="monthly">Monthly</Select.Option>
                    </Select>
                    <div className="input-error text-left mt-1">
                      {errors.interval && touched.interval && errors.interval}
                    </div>
                  </div>
                </div>
                <div className="input-field w-100 mb-4">
                  <div className="field-name form-label">Repeat Times</div>
                  <div className="floating-label">
                    <Select
                      value={values.count}
                      onChange={(val) => setFieldValue("count", val)}
                    >
                      <Select.Option value={1}>1</Select.Option>
                      <Select.Option value={2}>2</Select.Option>
                      <Select.Option value={3}>3</Select.Option>
                      <Select.Option value={4}>4</Select.Option>
                      <Select.Option value={5}>5</Select.Option>
                    </Select>
                    <div className="input-error text-left mt-1">
                      {errors.count && touched.count && errors.count}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="text-end">
              <CustomButton
                buttonType="submit"
                buttonText="Create"
                primary={true}
                className=""
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AppointmentCreation;
