import { useAppSelector } from "../../../redux/hooks";
import BarActionChart from "./BarActionChart/BarActionChart";
import LineActionChart from "./LineActionChart/LineActionChart";

const CHART_COMPONENTS = {
  Steps: LineActionChart,
  Diet: BarActionChart,
  Sleep: BarActionChart,
  Exercise: BarActionChart,
  Smoke: BarActionChart,
  Alcohol: BarActionChart,
  Weight: LineActionChart,
  "Blood Pressure": LineActionChart,
  HbA1C: LineActionChart,
  Glucose: LineActionChart,
  Medicine: BarActionChart,
};
const ChartSelector = () => {
  const type = useAppSelector((state) => state.console.type);
  if (!type) return null;
  const SpecificChart = CHART_COMPONENTS[type];
  return <SpecificChart />;
};

export default ChartSelector;
