//education

export const SET_EDUCATION_LESSONS = "SET_EDUCATION_LESSONS";
export const SCROLL_EDUCATION_LESSONS = "SCROLL_EDUCATION_LESSONS";
export const GET_EDUCATION_LESSONS = "GET_EDUCATION_LESSONS";
export const SEARCH_EDUCATION_LESSONS = "SEARCH_EDUCATION_LESSONS";
export const EDUCATION_EDIT_VIEW = "EDUCATION_EDIT_VIEW";
export const EDUCATION_PAGES_LIST = "EDUCATION_PAGES_LIST";
export const SET_EDIT_PAGE_SECTIONS = "SET_EDIT_PAGE_SECTIONS";
export const EDUCATION_SEARCH_PAGES_LIST = "EDUCATION_SEARCH_PAGES_LIST";
export const SET_BOOKMARKS = "SET_BOOKMARKS";

export const SET_RECOMMENDED_DATA = "SET_RECOMMENDED_DATA";
export const SET_DAILY_TIPS = "SET_DAILY_TIPS";
export const SET_CONTENT_SEARCH = "SET_CONTENT_SEARCH";
export const SET_CONTENT_TOGGLE_LOADER = "SET_CONTENT_TOGGLE_LOADER";
//  Questions

export const ITEM_TYPE = "ITEM_TYPE";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_BUILDER_QUESTIONS = "GET_BUILDER_QUESTIONS";
export const SET_PREVIEW_QUESTIONS = "SET_PREVIEW_QUESTIONS";
export const INFINITE_GET_QUESTIONS = "INFINITE_GET_QUESTIONS";
export const GET_QUESTIONS_FILTER = "GET_QUESTIONS_FILTER";
export const RESET_QUESTIONS_FILTER = "RESET_QUESTIONS_FILTER";
export const GET_QUESTIONS_LIST_PROJECTS = "GET_QUESTIONS_LIST_PROJECTS";
export const GET_QUESTIONNAIRE_INFO = "GET_QUESTIONNAIRE_INFO";
export const SEARCH_PROJECTS = "SEARCH_PROJECTS";

//scores
export const SET_SCORES_LIST = "SET_SCORES_LIST";
export const SEARCH_SCORES_LIST = "SEARCH_SCORES_LIST";
export const SEARCH_SCORES = "SEARCH_SCORES";
export const GET_SCORE_INFO = "GET_SCORE_INFO";
export const INFINITE_GET_SCORES = "INFINITE_GET_SCORES";

// documents
export const SET_DOCUMENT_FILE = "SET_DOCUMENT_FILE";
export const GET_DOCUMENTS_FILE = "GET_DOCUMENTS_FILE";
export const GET_FOLDER_DOCUMENTS_FILE = "GET_FOLDER_DOCUMENTS_FILE";
export const GET_FOLDER_DOCUMENTS_NAME = "GET_FOLDER_DOCUMENTS_NAME";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const SET_BREADCRUMBS = "SET_BREADCRUMBS";
