import { useEffect } from "react";
import { fetchDashboardDetails } from "../../redux/actions/dashboardAction";
import { useAppDispatch } from "../../redux/hooks";
import MainCards from "./MainCards";
import OtherAnalytics from "./OtherAnalytics";
import QuestionnaireCards from "./QuestionnaireCards";
import SchedulingCard from "./SchedulingCard";

const Dashboard = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDashboardDetails());
  }, [dispatch]);

  return (
    <>
      <MainCards />
      <QuestionnaireCards />
      <SchedulingCard />
      <OtherAnalytics />
    </>
  );
};

export default Dashboard;
