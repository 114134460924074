import React, { useState } from "react";
import AppLayout from "../AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import { Tabs } from "antd";
import ContentHeader from "./ContentHeader";
import EducationModule from "../Education/EducationModule/EducationModule";
import RecommendedResource from "./RecommendedReading/RecommendedReading";
import DailyTips from "./DailyTips/DailyTips";

const { TabPane } = Tabs;

const Content = () => {
  const [selectedTab, setSelectedTab] = useState<string>(
    sessionStorage.getItem("singer-content-tab") || "Lesson"
  );

  const tabChangeHandler = (key: string) => {
    setSelectedTab(key);
    sessionStorage.setItem("singer-content-tab", key);
  };

  return (
    <AppLayout themelight={true}>
      <Navigation />
      <ContentHeader type={selectedTab} />
      <Tabs
        destroyInactiveTabPane
        className="singer-cutsom-tab"
        onChange={tabChangeHandler}
        activeKey={selectedTab}
      >
        <TabPane tab="Lessons" key="Lesson">
          <EducationModule />
        </TabPane>
        <TabPane tab="Recommended Reading" key="Recommended">
          <RecommendedResource />
        </TabPane>
        <TabPane tab="Tip of the day" key="Tip of the day">
          <DailyTips />
        </TabPane>
      </Tabs>
    </AppLayout>
  );
};

export default Content;
