import React, { useState } from "react";
import { Modal, Select, Input } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import CustomButton from "../../Common/CustomButton";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { debounce } from "lodash";
import http from "../../../http";
import { Spin } from "antd";
import roles from "../../../constants/roles";
import { addForumParticipants } from "../../../redux/actions/forumAction";

const schema = yup.object().shape({
  participantIds: yup.array().of(yup.string()).min(1, "Please choose users"),
});

const AddPatientForumModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const [patientOptions, setPatientOptions] = useState<any[]>([]);
  const [patientFetching, setPatientFetching] = useState(false);
  const { show, id } = useAppSelector((state) => state.modal.modalProps);

  const initialValues = {
    participantIds: [],
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async (values: any) => {
    dispatch(addForumParticipants(id, values.participantIds));
  };

  const debouncePatientFetcher = React.useMemo(() => {
    const loadOptions = async (value: string) => {
      try {
        setPatientOptions([]);
        if (!value) {
          return;
        }
        setPatientFetching(true);
        const res = await http.get(
          `/users?page=1&size=20&role=${roles.Participant["be_value"]}&search=${value}`
        );
        const users = res.data.data.users.map((user: any) => {
          return {
            label: user.firstName + " " + user.lastName,
            value: user.id,
          };
        });
        setPatientOptions(users);
        setPatientFetching(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setPatientFetching(false);
      }
    };
    return debounce(loadOptions, 500);
  }, [setPatientOptions, setPatientOptions]);

  return (
    <Modal
      title={`Add Forum`}
      visible={show}
      onCancel={closeHandler}
      closable={true}
      width={"38rem"}
      footer={null}
    >
      <div className="add-project-owner">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit} className="m-0">
              <div className="input-field w-100 mb-4">
                <div className="field-name form-label">Users</div>
                <div className="floating-label">
                  <Select
                    mode="multiple"
                    showSearch
                    size="large"
                    filterOption={false}
                    onSearch={debouncePatientFetcher}
                    notFoundContent={
                      patientFetching ? <Spin size="small" /> : null
                    }
                    options={patientOptions}
                    value={values.participantIds}
                    onChange={(val) => {
                      setFieldValue("participantIds", val);
                    }}
                  />
                  <div className="input-error text-left mt-1">
                    {errors.participantIds &&
                      touched.participantIds &&
                      errors.participantIds}
                  </div>
                </div>
              </div>
              <div className="text-end">
                <span
                  role="button"
                  className="button-cancel"
                  onClick={closeHandler}
                  tabIndex={0}
                >
                  Cancel
                </span>
                <CustomButton
                  buttonType="submit"
                  buttonText="Save"
                  primary={true}
                  className="btn-success"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddPatientForumModal;
