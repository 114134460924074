import { errorToastMessage } from "../../helpers/toastMessage";
import history from "../../history";
import http from "../../http";
import {
  setForumComments,
  setForumDetails,
  setForumParticipants,
  setForumToggleLoader,
} from "../reducers/forumSlice";
import { setAppLoader } from "../reducers/loaderSlice";
import { hideModal } from "../reducers/modalSlice";
import { AppThunk } from "../store";

export const fetchForumDetails =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.get(`/forums/${id}`);
      const data = res.data.data;
      dispatch(setForumDetails(data));
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      history.push("/forums");
      errorToastMessage(err);
    }
  };

export const fetchForumParticipants =
  (id: string, searchTerm: string, page: number): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    let url = `/forums/${id}/participants?page=${page}&size=8`;
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }
    const res = await http.get(url);
    const data = res.data.data;
    dispatch(setForumParticipants(data));
    dispatch(setAppLoader(false));
  };

export const addForumParticipants =
  (id: string, patientIds: string[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      let url = `/forums/${id}/participants`;
      await http.post(url, {
        addParticipantIds: patientIds,
      });
      dispatch(hideModal());
      dispatch(setForumToggleLoader());
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };

export const deleteForumParticipants =
  (id: string, patientId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      let url = `/forums/${id}/participants`;
      await http.delete(url, {
        data: {
          removeParticipantIds: [patientId],
        },
      });
      dispatch(setForumToggleLoader());
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };

export const fetchForumComments =
  (id: string, searchTerm: string, page: number): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    let url = `/forums/${id}/comments?page=${page}&size=8`;
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }
    const res = await http.get(url);
    const data = res.data.data;
    dispatch(setForumComments(data));
    dispatch(setAppLoader(false));
  };

export const addForumComment =
  (id: string, comment: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const userId = localStorage.getItem("userId");
      let url = `/forums/${id}/comments`;
      await http.post(url, {
        comment: comment,
        commenterId: userId,
      });
      dispatch(hideModal());
      dispatch(setForumToggleLoader());
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };

export const deleteForumComment =
  (id: string, commentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      let url = `/forums/${id}/comments/${commentId}`;
      await http.delete(url);
      dispatch(setForumToggleLoader());
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };
