import { Modal, Input } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
let yup = require("yup");

let schema = yup.object().shape({
  text: yup.string().trim().required("Text is Required"),
  link: yup.string().url().required("Link is Required"),
});

const ReportModal = () => {
  const dispatch = useAppDispatch();
  const { show, sendLinkHandler } = useAppSelector(
    (state) => state.modal.modalProps
  );

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = (values: any) => {
    const formData = { ...values };
    dispatch(hideModal());
    sendLinkHandler(formData.text, formData.link);
  };

  return (
    <Modal
      title={"Add Report"}
      visible={show}
      onCancel={closeHandler}
      footer={null}
    >
      <div className="add-education-wrapper"></div>
    </Modal>
  );
};

export default ReportModal;
