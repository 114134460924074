import { Table, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import AppLayout from "../AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import { useHistory } from "react-router-dom";
import { ColumnBlock, renderCell } from "./PatientListColumns";

import AlcoholIcon from "./Patienticons/AlcoholIcon";
import BpIcon from "./Patienticons/BpIcon";
import CalenderIcon from "./Patienticons/CalenderIcon";
import DietIcon from "./Patienticons/DietIcon";
import ExerciseIcon from "./Patienticons/ExerciseIcon";
import GlucoseIcon from "./Patienticons/GlucoseIcon";
import Hba1cIcon from "./Patienticons/Hba1cIcon";
import MedicineIcon from "./Patienticons/MedicineIcon";
import SleepIcon from "./Patienticons/SleepIcon";
import SmokeIcon from "./Patienticons/SmokeIcon";
import StepsIcon from "./Patienticons/StepsIcon";
import WeightIcon from "./Patienticons/WeightIcon";
import { CellProperties } from "../../types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchPatientMetricsList } from "../../redux/actions/consoleAction";
import { getImageInitials } from "../../helpers/user";
import roles from "../../constants/roles";

export const columns = [
  {
    title: function titleRender(): JSX.Element {
      return <h6 className="text-center font-m m-0">All Users</h6>;
    },
    dataIndex: "user",
    key: "user",
    render: function render(record: any): JSX.Element {
      return (
        <div className="p-2 d-flex cp align-items-center">
          {record.profileImage ? (
            <Avatar shape="circle" size="large" src={record.profileImage} />
          ) : (
            <Avatar shape="circle" size="large">
              {getImageInitials(record.firstName, record.lastName)}
            </Avatar>
          )}
          <div className="ms-3">
            <p className="text-s m-0 fw-medium">
              {record.firstName + " " + record.lastName}
            </p>
            <span className="text-muted">{record.email}</span>
          </div>
        </div>
      );
    },
  },
  {
    title: () => {
      return (
        <ColumnBlock
          Icon={<CalenderIcon width="24" height="24" />}
          columnHeader={
            <div className="dashboard-title">
              New <br /> Intervention
            </div>
          }
          bold={false}
        />
      );
    },
    dataIndex: "newIntervention",
    key: "newIntervention",
    render: function render(value: string): JSX.Element {
      return <div className="text-center">1</div>;
    },
  },
  {
    title: () => {
      return (
        <ColumnBlock
          Icon={<CalenderIcon width="24" height="24" />}
          columnHeader={
            <div className="dashboard-title">
              Last <br /> Intervention
            </div>
          }
          bold={false}
        />
      );
    },
    dataIndex: "lastIntervention",
    key: "lastIntervention",
    render: function render(value: string): JSX.Element {
      return <div className="text-center">27/03/2021</div>;
    },
  },
  {
    title: () => {
      return (
        <ColumnBlock
          Icon={<CalenderIcon width="24" height="24" />}
          columnHeader={
            <div className="dashboard-title">
              New <br /> Questions
            </div>
          }
          bold={false}
        />
      );
    },
    dataIndex: "newQuestions",
    key: "newQuestions",
    render: function render(value: string): JSX.Element {
      return <div className="text-center">0</div>;
    },
  },
  {
    title: () => {
      return (
        <ColumnBlock
          Icon={<CalenderIcon width="24" height="24" />}
          columnHeader={
            <div className="dashboard-title">
              Last <br /> Active
            </div>
          }
          bold={false}
        />
      );
    },
    dataIndex: "lastActive",
    key: "lastActive",
    render: function render(value: string): JSX.Element {
      return <div className="text-center p-2">29/03/2021</div>;
    },
  },
  {
    title: function render(): JSX.Element {
      return (
        <ColumnBlock
          Icon={<StepsIcon width="24" height="24" />}
          columnHeader="Steps"
        />
      );
    },
    dataIndex: "steps",
    key: "steps",
    render: (value: CellProperties): JSX.Element =>
      renderCell(value, null, "Steps"),
  },
  {
    title: function render(): JSX.Element {
      return (
        <ColumnBlock
          Icon={<SleepIcon width="24" height="24" />}
          columnHeader="Sleep"
        />
      );
    },
    dataIndex: "sleep",
    key: "sleep",
    render: (value: CellProperties): JSX.Element =>
      renderCell(value, null, "Sleep"),
  },
  {
    title: function render(): JSX.Element {
      return (
        <ColumnBlock
          Icon={<DietIcon width="24" height="24" />}
          columnHeader="Diet"
        />
      );
    },
    dataIndex: "diet",
    key: "diet",
    render: (value: CellProperties): JSX.Element =>
      renderCell(value, null, "Diet"),
  },
  {
    title: function render(): JSX.Element {
      return (
        <ColumnBlock
          Icon={<ExerciseIcon width="24" height="24" />}
          columnHeader="Exercise"
        />
      );
    },
    dataIndex: "exercise",
    key: "exercise",
    render: (value: CellProperties): JSX.Element =>
      renderCell(value, null, "Exercise"),
  },
  {
    title: function render(): JSX.Element {
      return (
        <ColumnBlock
          Icon={<SmokeIcon width="24" height="24" />}
          columnHeader="Smoke"
        />
      );
    },
    dataIndex: "smoke",
    render: (value: CellProperties): JSX.Element =>
      renderCell(value, null, "Smoke"),
    key: "smoke",
  },
  {
    title: function render(): JSX.Element {
      return (
        <ColumnBlock
          Icon={<AlcoholIcon width="24" height="24" />}
          columnHeader="Alcohol"
        />
      );
    },
    dataIndex: "alcohol",
    render: (value: CellProperties): JSX.Element =>
      renderCell(value, null, "Alcohol"),
    key: "alcohol",
  },
  {
    title: function render(): JSX.Element {
      return (
        <ColumnBlock
          Icon={<WeightIcon width="24" height="24" />}
          columnHeader="Weight"
        />
      );
    },
    dataIndex: "weight",
    render: (value: CellProperties): JSX.Element =>
      renderCell(value, null, "Weight"),
    key: "weight",
  },
  {
    title: function render(): JSX.Element {
      return (
        <ColumnBlock
          Icon={<BpIcon width="24" height="24" />}
          columnHeader="Bp"
        />
      );
    },
    dataIndex: "bloodPressure",
    render: (value: CellProperties): JSX.Element =>
      renderCell(value, null, "Blood Pressure"),
    key: "bp",
  },
  {
    title: function render(): JSX.Element {
      return (
        <ColumnBlock
          Icon={<Hba1cIcon width="24" height="24" />}
          columnHeader="HbA1C"
        />
      );
    },
    dataIndex: "hb",
    render: (value: CellProperties): JSX.Element =>
      renderCell(value, null, "HbA1C"),
    key: "hba1c",
  },
  {
    title: function render(): JSX.Element {
      return (
        <ColumnBlock
          Icon={<GlucoseIcon width="24" height="24" />}
          columnHeader="Glucose"
        />
      );
    },
    dataIndex: "glucose",
    render: (value: CellProperties): JSX.Element =>
      renderCell(value, null, "Glucose"),
    key: "glucose",
  },
  {
    title: function render(): JSX.Element {
      return (
        <ColumnBlock
          Icon={<MedicineIcon width="24" height="24" />}
          columnHeader="Medicine"
        />
      );
    },
    dataIndex: "medicine",
    render: (value: CellProperties): JSX.Element =>
      renderCell(value, null, "Medicine"),
    key: "medicine",
  },
];

const MainList: React.FC = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const { patientList, patientListCount } = useAppSelector(
    (state) => state.console
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPatientMetricsList(1));
  }, [dispatch]);

  const onTableChange = async (pagination: any) => {
    let page = 1;
    if (pagination.current) {
      setCurrentPage(pagination.current);
      page = pagination.current;
    }
    dispatch(fetchPatientMetricsList(page));
  };

  return (
    <AppLayout>
      <Navigation title="" />
      <div className="mb-4">
        <div className="button-group d-inline-flex font-m align-items-center btn-lg">
          <div className="me-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 9C5.33 9 0 10.34 0 13V16H16V13C16 10.34 10.67 9 8 9Z"
                fill="#828282"
              ></path>
            </svg>
          </div>
          All Users{" "}
          <span className="border-start ps-4 ms-4">{patientListCount}</span>
        </div>
      </div>
      <div className="h-100 custom-ant-table overflow-auto">
        <Table
          columns={columns}
          dataSource={patientList}
          onChange={onTableChange}
          className="clientlist-table"
          bordered
          pagination={{
            current: currentPage,
            showSizeChanger: false,
            total: patientListCount,
            pageSize: 8,
            hideOnSinglePage: true,
            showTotal: (total, range) => {
              return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
            },
            position: ["bottomLeft"],
          }}
          onRow={(record: any) => ({
            onClick: () => {
              if (localStorage.getItem("role") === roles.Admin["be_value"]) {
                history.push(`/patientview/${record.userId}`);
              } else {
                history.push(`/dashboard/${record.userId}`);
              }
            },
          })}
        />
      </div>
    </AppLayout>
  );
};

export default MainList;
