import React, { useEffect, useState } from "react";
import Button from "../../Common/CustomButton";
import SearchInput from "../../Common/SearchInput";
import AppLayout from "../../AppLayout/AppLayout";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Navigation from "../../Navigation/Navigation";
import { toastMessage } from "../../../helpers/toastMessage";
import { Modal } from "antd";
import {
  getLessonDetails,
  UpdateEducationModule,
  deletePageFromLesson,
  setLessonDetails,
  setEditPageSections,
  setLessonsAction,
} from "../../../redux/actions/educationAction";
// import "../../../../../../pages/css/education/listPages.scss";

const confirm = Modal.confirm;

const EducationListPages = (props: any) => {
  const history = useHistory();
  const assetId = useParams<{ assetId: string }>().assetId;
  const [localPages, setLocalPages] = useState<any[]>([]);
  const { getLessonDetails, setEditPageSections, setLessonsAction } = props;
  useEffect(() => {
    getLessonDetails(assetId);
  }, [assetId, getLessonDetails]);

  useEffect(() => {
    setEditPageSections([]);
    setLessonsAction([], true);
  }, [setLessonsAction, setEditPageSections]);

  const { lessonDetails } = props;

  useEffect(() => {
    if (lessonDetails.hasOwnProperty("pages")) {
      setLocalPages(lessonDetails.pages);
    }
  }, [lessonDetails, setLocalPages]);

  const onChange = (value: any) => {
    if (value === "") {
      if (lessonDetails.hasOwnProperty("pages")) {
        setLocalPages(lessonDetails.pages);
      }
    } else {
      const searchTerm = value.toLowerCase();
      setLocalPages((prevPages: any[]) => {
        return prevPages.filter((item: any) => {
          return item.title.toLowerCase().includes(searchTerm);
        });
      });
    }
  };

  const handlePublish = () => {
    let body;

    if (lessonDetails.description === null) {
      body = {
        name: lessonDetails.name,
        imageUrl: lessonDetails.imageUrl,
        status: "active",
      };
    } else {
      body = {
        name: lessonDetails.name,
        description: lessonDetails.description,
        imageUrl: lessonDetails.imageUrl,
        status: "active",
      };
    }

    if (body.imageUrl === null) {
      delete body.imageUrl;
    }

    if (lessonDetails.pageCount > 0) {
      props.UpdateEducationModule(body, lessonDetails.id);
    } else {
      toastMessage("error", "Lesson should include at least One Page");
    }
  };

  const handleDeletePage = (pageId: any, lessonId: any) => {
    props.deletePageFromLesson(lessonId, pageId);
  };

  const sortModules = () => {
    const result =
      lessonDetails.hasOwnProperty("id") &&
      localPages.map((item: any, index: number) => {
        return item.hasOwnProperty("id")
          ? {
              key: index + 1,
              title: item.title,
              pagePosition: item.position,
              image: item.imageUrl,
              pageId: item.id,
              lessonId: item.educationLessonId,
            }
          : {};
      });

    if (typeof result !== "boolean") {
      result.sort(function (a: any, b: any) {
        return a.pagePosition - b.pagePosition;
      });
    }
    return result;
  };

  const modules = sortModules();

  const showConfirmMessage = () => {
    confirm({
      title: "Education",
      content:
        "Please confirm the changes done before you navigate back to the Education dashboard",
      onOk() {
        history.push("/education");
      },
      onCancel() {},
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };

  return (
    <AppLayout themelight={true}>
      <div className="list-pages-wrapper">
        <Navigation />
        <div className="content-height ">
          <div className="mb-4">
            <h3 className="font-l color-header fw-bold mb-4">
              <span
                className="arrow-left-icon me-2 cp"
                onClick={showConfirmMessage}
              ></span>
              {lessonDetails.name}
              {lessonDetails.status === "draft" && (
                <span className="draft-mode ms-2">DRAFT</span>
              )}
            </h3>
            <h6 className="m-0 font-sm text-muted">
              Add and edit the pages in your Education Module here.
            </h6>
          </div>

          <div className="d-md-flex align-items-center justify-content-between">
            <div className="search-block my-4">
              <h3 className="font-m me-3 mb-0">
                All <span>{localPages.length}</span> Pages
              </h3>
              <div className="search searchInput-mini me-3">
                <SearchInput onChange={onChange} placeholder="Search..." />
              </div>
            </div>
            <div className="d-flex align-items-center ms-2 my-4">
              {lessonDetails.status !== "active" && (
                <>
                  <Button
                    primary
                    buttonText="Publish"
                    addButton={false}
                    className="btn-lg me-3 cp"
                    clickHandler={handlePublish}
                  />
                  <Button
                    primary
                    buttonText="Add Page"
                    addButton={true}
                    className="btn-lg cp"
                    clickHandler={() =>
                      history.push(`/education/builder/${assetId}`)
                    }
                  />
                </>
              )}
            </div>
          </div>

          <div className="card-section">
            {modules.length > 0 ? (
              modules.map((module: any) => {
                return (
                  <div
                    className="card-content cp mb-4"
                    key={module.key}
                    onClick={() => {
                      history.push(
                        `/education/preview?pageId=${module.pageId}`
                      );
                    }}
                  >
                    <div className="card-details">
                      <div className="img-wrapper">
                        {module.image.includes("picsum") ? (
                          <img
                            alt="cover"
                            src={
                              "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E"
                            }
                            onError={(ev: any) => {
                              ev.target.src =
                                "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                              ev.target.onerror = null;
                            }}
                          />
                        ) : (
                          <img alt="cover" src={module.image} />
                        )}
                      </div>
                      <div className="card-details-content ms-4">
                        <h3 className="font-ml fw-600">{module.title}</h3>
                        <div className="text-muted">{`Page ${module.pagePosition}`}</div>
                      </div>
                    </div>
                    <span onClick={(e) => e.stopPropagation()}>
                      {lessonDetails.status !== "active" && (
                        <div className="d-flex align-items-center">
                          <div
                            className="edit-pages"
                            onClick={
                              () =>
                                history.push(
                                  `/education/builder/${assetId}?pageId=${module.pageId}`
                                )
                              // handleDeletePage(module.pageId, module.lessonId)
                            }
                          >
                            EDIT
                          </div>
                          <div
                            className="edit-pages"
                            onClick={() =>
                              handleDeletePage(module.pageId, module.lessonId)
                            }
                          >
                            DELETE
                          </div>
                        </div>
                      )}
                    </span>
                  </div>
                );
              })
            ) : (
              <>
                {localPages.length === 0 && (
                  <div className="no-page-content">
                    <h1 className="font-l fw-bold">
                      NO PAGES FOUND FOR THIS MODULE
                    </h1>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

const mapStateToProps = (state: any) => ({
  lessonDetails: state.lessons.lessonDetails,
});

export default connect(mapStateToProps, {
  getLessonDetails,
  UpdateEducationModule,
  deletePageFromLesson,
  setLessonDetails,
  setEditPageSections,
  setLessonsAction,
})(EducationListPages);
