import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { fetchPatientMetrics } from "../../redux/actions/consoleAction";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setChartEntries,
  setConsoleChart,
  setConsoleChartData,
  setConsoleTime,
  setPatientMetrics,
} from "../../redux/reducers/consoleSlice";
import Navigation from "../Navigation/Navigation";
import PatientChartWrapper from "./ChartWrapper/PatientChartWrapper";
import PatientChat from "./PatientChat/PatientChat";
import PatientDetails from "./PatientDetails";
import PatientMetricTable from "./PatientMetricTable";
import PatientNotes from "./PatientNotes";

const Patientview = () => {
  const { patientId } = useParams<any>();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.console.userId);
  const history = useHistory();
  const backHandler = () => {
    history.push("/patientslist");
  };

  useEffect(() => {
    dispatch(fetchPatientMetrics(patientId));
    return () => {
      dispatch(setConsoleChart(null));
      dispatch(setConsoleTime("week"));
      dispatch(setConsoleChartData({}));
      dispatch(setPatientMetrics({}));
      dispatch(setChartEntries([]));
    };
  }, [dispatch, patientId]);

  return (
    <div className="content right-panel client-view-wrapper">
      <Navigation />
      {userId && (
        <div>
          <div id="belowNavigation" style={{ width: "95%", margin: "auto" }}>
            <div className="row align-items-center">
              <div className="col-lg-4 mb-4">
                <h6
                  className="font-m cp fw-bold m-0 d-flex align-items-center"
                  onClick={backHandler}
                >
                  <i className="icon-back arrow-left-icon me-2"></i>
                  <span>Back to Dashboard</span>
                </h6>
              </div>
              {/* <div
              id="ListMenu"
              className="col-lg-8 mb-4 justify-content-end d-lg-flex"
            >
              <ul className="filter-data">
                <li id="listItem">Since Last Intervention</li>
                <li id="listItem" className="active">
                  Week
                </li>
                <li id="listItem">Month</li>
                <li id="listItem">Year</li>
                <li id="listItem">Always</li>
              </ul>
            </div> */}
            </div>
            <PatientDetails />
            <PatientMetricTable />
            <div className="">
              <div className="row">
                <PatientNotes />
                <PatientChat />
                <PatientChartWrapper />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Patientview;
