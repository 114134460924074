import { AxiosResponse } from "axios";
import moment from "moment";
import roles from "../../constants/roles";
import {
  fetchTimeQuery,
  transformAlcoholData,
  transformBPdata,
  transformDietData,
  transformExerciseData,
  transformGlucoseData,
  transformHbData,
  transformMedicineData,
  transformSleepData,
  transformSmokeData,
  transformStepsData,
  transformWeightData,
} from "../../helpers/metric";
import { errorToastMessage } from "../../helpers/toastMessage";
import history from "../../history";
import http from "../../http";
import { TimeType } from "../../types";
import {
  setChartEntries,
  setConsoleChartData,
  setPatientList,
  setPatientMetrics,
} from "../reducers/consoleSlice";
import { setAppLoader } from "../reducers/loaderSlice";
import { AppThunk } from "../store";

export const fetchPatientMetricsList =
  (page: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.get(`mh/metrics?size=8&page=${page}`);
      dispatch(
        setPatientList({
          data: res.data.data.users,
          count: res.data.data.count,
        })
      );
      dispatch(setAppLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

export const fetchPatientMetrics =
  (patientId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.get(`mh/metrics/${patientId}`);
      dispatch(setPatientMetrics(res.data.data));
      dispatch(setAppLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
      const role = localStorage.getItem("role");
      if (role === roles.Admin["be_value"]) {
        history.push("/patientslist");
      } else {
        history.push("/dashboard");
      }
    }
  };

export const fetchPatientChartData =
  (): AppThunk => async (dispatch, getState) => {
    try {
      const { time, type, userId } = getState().console;
      if (!userId) {
        return;
      }
      dispatch(setConsoleChartData({}));
      dispatch(setChartEntries([]));
      dispatch(setAppLoader(true));
      if (type === "Alcohol") {
        await dispatch(fetchAlcoholChart(time, userId));
      } else if (type === "Blood Pressure") {
        await dispatch(fetchBPChart(time, userId));
      } else if (type === "Diet") {
        await dispatch(fetchDietChart(time, userId));
      } else if (type === "Exercise") {
        await dispatch(fetchExerciseChart(time, userId));
      } else if (type === "Glucose") {
        await dispatch(fetchGlucoseChart(time, userId));
      } else if (type === "HbA1C") {
        await dispatch(fetchHbChart(time, userId));
      } else if (type === "Medicine") {
        await dispatch(fetchMedicineChart(time, userId));
      } else if (type === "Sleep") {
        await dispatch(fetchSleepChart(time, userId));
      } else if (type === "Smoke") {
        await dispatch(fetchSmokeChart(time, userId));
      } else if (type === "Steps") {
        await dispatch(fetchStepsChart(time, userId));
      } else if (type === "Weight") {
        await dispatch(fetchWeightChart(time, userId));
      }
      dispatch(setAppLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

export const fetchAlcoholChart =
  (time: TimeType, userId: string): AppThunk =>
  async (dispatch) => {
    const query = fetchTimeQuery(time);
    const res: AxiosResponse = await http.get(
      `/mh/graph/${userId}?vital=Alcohol${query}`
    );
    const graphData = res.data.data;
    const entries = graphData.map((entry: any) => {
      return {
        label: moment(entry.index).format("ddd, Do MMM, YYYY"),
        value: entry.count,
      };
    });
    const data = transformAlcoholData(graphData, time);
    dispatch(setConsoleChartData(data));
    dispatch(setChartEntries(entries));
  };

export const fetchBPChart =
  (time: TimeType, userId: string): AppThunk =>
  async (dispatch) => {
    const query = fetchTimeQuery(time);
    const res: AxiosResponse = await http.get(
      `/mh/graph/${userId}?vital=Blood Pressure${query}`
    );
    const graphData = res.data.data;
    const entries = graphData.map((entry: any) => {
      return {
        label: moment(entry.index).format("ddd, Do MMM, YYYY"),
        value:
          (+entry.systolic).toFixed(0) + "/" + (+entry.diastolic).toFixed(0),
      };
    });
    const data = transformBPdata(graphData, time);
    dispatch(setConsoleChartData(data));
    dispatch(setChartEntries(entries));
  };

export const fetchDietChart =
  (time: TimeType, userId: string): AppThunk =>
  async (dispatch, getState) => {
    const patientMetric = getState().console.patientMetric;
    const query = fetchTimeQuery(time);
    const res: AxiosResponse = await http.get(
      `/mh/graph/${userId}?vital=Diet${query}`
    );
    const graphData = res.data.data;
    const entries = graphData.map((entry: any) => {
      return {
        label: moment(entry.index).format("ddd, Do MMM, YYYY"),
        value: (+entry.count).toFixed(2),
      };
    });
    const data = transformDietData(graphData, time, patientMetric.userId);
    dispatch(setConsoleChartData(data));
    dispatch(setChartEntries(entries));
  };

export const fetchExerciseChart =
  (time: TimeType, userId: string): AppThunk =>
  async (dispatch) => {
    const query = fetchTimeQuery(time);
    const res: AxiosResponse = await http.get(
      `/mh/graph/${userId}?vital=Exercise${query}`
    );
    const graphData = res.data.data;
    const entries = graphData.map((entry: any) => {
      return {
        label: moment(entry.index).format("ddd, Do MMM, YYYY"),
        value: entry.count + " mins",
      };
    });
    const data = transformExerciseData(graphData, time);
    dispatch(setConsoleChartData(data));
    dispatch(setChartEntries(entries));
  };

export const fetchMedicineChart =
  (time: TimeType, userId: string): AppThunk =>
  async (dispatch, getState) => {
    const patientMetric = getState().console.patientMetric;
    const query = fetchTimeQuery(time);
    const res: AxiosResponse = await http.get(
      `/mh/graph/${userId}?vital=Medicine${query}`
    );
    const graphData = res.data.data;
    const entries = graphData.map((entry: any) => {
      return {
        label: moment(entry.index).format("ddd, Do MMM, YYYY"),
        value: (+entry.count).toFixed(2),
      };
    });
    const data = transformMedicineData(graphData, time, patientMetric.userId);
    dispatch(setConsoleChartData(data));
    dispatch(setChartEntries(entries));
  };

export const fetchGlucoseChart =
  (time: TimeType, userId: string): AppThunk =>
  async (dispatch) => {
    const query = fetchTimeQuery(time);
    const res: AxiosResponse = await http.get(
      `/mh/graph/${userId}?vital=Glucose${query}`
    );
    const graphData = res.data.data;
    const entries = graphData.map((entry: any) => {
      return {
        label: moment(entry.index).format("ddd, Do MMM, YYYY"),
        value: (+entry.count).toFixed(2),
      };
    });
    const data = transformGlucoseData(graphData, time);
    dispatch(setConsoleChartData(data));
    dispatch(setChartEntries(entries));
  };

export const fetchHbChart =
  (time: TimeType, userId: string): AppThunk =>
  async (dispatch) => {
    const query = fetchTimeQuery(time);
    const res: AxiosResponse = await http.get(
      `/mh/graph/${userId}?vital=HbA1C${query}`
    );
    const graphData = res.data.data;
    const entries = graphData.map((entry: any) => {
      return {
        label: moment(entry.index).format("ddd, Do MMM, YYYY"),
        value: (+entry.count).toFixed(2),
      };
    });
    const data = transformHbData(graphData, time);
    dispatch(setChartEntries(entries));
    dispatch(setConsoleChartData(data));
  };

export const fetchSleepChart =
  (time: TimeType, userId: string): AppThunk =>
  async (dispatch) => {
    const query = fetchTimeQuery(time);
    const res: AxiosResponse = await http.get(
      `/mh/graph/${userId}?vital=Sleep${query}`
    );
    const graphData = res.data.data;
    const entries = graphData.map((entry: any) => {
      return {
        label: moment(entry.index).format("ddd, Do MMM, YYYY"),
        value: entry.count + " mins",
      };
    });
    const data = transformSleepData(graphData, time);
    dispatch(setChartEntries(entries));
    dispatch(setConsoleChartData(data));
  };

export const fetchSmokeChart =
  (time: TimeType, userId: string): AppThunk =>
  async (dispatch) => {
    const query = fetchTimeQuery(time);
    const res: AxiosResponse = await http.get(
      `/mh/graph/${userId}?vital=Smoke${query}`
    );
    const graphData = res.data.data;
    const entries = graphData.map((entry: any) => {
      return {
        label: moment(entry.index).format("ddd, Do MMM, YYYY"),
        value: entry.count,
      };
    });
    const data = transformSmokeData(graphData, time);
    dispatch(setConsoleChartData(data));
    dispatch(setChartEntries(entries));
  };

export const fetchStepsChart =
  (time: TimeType, userId: string): AppThunk =>
  async (dispatch) => {
    const query = fetchTimeQuery(time);
    const res: AxiosResponse = await http.get(
      `/mh/graph/${userId}?vital=Steps${query}`
    );
    const graphData = res.data.data;
    const data = transformStepsData(graphData, time);
    const entries = graphData.map((entry: any) => {
      return {
        label: moment(entry.index).format("ddd, Do MMM, YYYY"),
        value: entry.count,
      };
    });
    dispatch(setChartEntries(entries));
    dispatch(setConsoleChartData(data));
  };

export const fetchWeightChart =
  (time: TimeType, userId: string): AppThunk =>
  async (dispatch) => {
    const query = fetchTimeQuery(time);
    const res: AxiosResponse = await http.get(
      `/mh/graph/${userId}?vital=Weight${query}`
    );
    const graphData = res.data.data;
    const data = transformWeightData(graphData, time);
    const entries = graphData.map((entry: any) => {
      return {
        label: moment(entry.index).format("ddd, Do MMM, YYYY"),
        value: entry.count,
      };
    });
    dispatch(setConsoleChartData(data));
    dispatch(setChartEntries(entries));
  };
