import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import Calendar, {
  FormatterCallback,
  OnChangeDateCallback,
  ViewCallback,
} from "react-calendar";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { AxiosResponse } from "axios";
import { Appointment, AppointmentSlot } from "../../types";
import roles from "../../constants/roles";
import { appointmentPerTimePeriodConstructor } from "../../helpers/Appointment";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { useHistory } from "react-router-dom";
import { rescheduleAppointment } from "../../redux/actions/appointmentAction";
const { confirm } = Modal;

const TherapistCalendar: React.FC<any> = ({ therapistId, reschedule }) => {
  const history = useHistory();
  const [dayWiseSlot, setDayWiseSlot] = useState<AppointmentSlot[]>([]);
  const [previousDate] = useState(new Date());
  const [activeDate, setActiveDate] = useState<Date>(new Date());
  const [activeDateString, setActiveDateString] = useState<string>(
    moment().format("MM-YYYY")
  );
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const { slots } = useAppSelector((state) => state.appointment);
  const dispatch = useAppDispatch();

  const formatWeekDay: FormatterCallback = (_1: string, date: Date) => {
    return moment(date).format("dd");
  };

  const dateChangeHandler: OnChangeDateCallback = (value: Date) => {
    setSelectedDate(value);
  };

  const activeChangeHandler: ViewCallback = (values) => {
    setActiveDate(values.activeStartDate);
    if (values.view === "month") {
      setActiveDateString(moment(values.activeStartDate).format("MM-YYYY"));
    }
  };

  const fetchAppointmentDetails = useCallback(
    async (dateString, selectedDate?: any) => {
      try {
        dispatch(setAppLoader(true));
        console.log(dateString);
        let query = appointmentPerTimePeriodConstructor("month");
        if (therapistId) {
          query += `&inviterId=${therapistId}`;
        }
        const response: AxiosResponse = await http.get(
          "/ap/generic" +
            query +
            "&unconfirmed=true&status=confirmed,pending,busy,inprogress"
        );
        const res = response.data.data;
        console.log(res);
        setAppointments(res);
        if (dateString === moment().format("MM-YYYY")) {
          console.log("Same");
          console.log(new Date());
          if (selectedDate) {
            setSelectedDate(selectedDate);
          } else {
            setSelectedDate(new Date());
          }
        } else {
          const monthStart = moment(dateString, "MM-YYYY")
            .startOf("month")
            .toDate();
          console.log("diff");
          console.log(monthStart);
          if (selectedDate) {
            setSelectedDate(selectedDate);
          } else {
            setSelectedDate(monthStart);
          }
        }
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [setSelectedDate, setAppointments, therapistId, dispatch]
  );

  useEffect(() => {
    if (activeDateString) {
      fetchAppointmentDetails(activeDateString);
    }
  }, [fetchAppointmentDetails, activeDateString]);

  useEffect(() => {
    if (selectedDate) {
      let newSlots: AppointmentSlot[] = slots.map((slot: AppointmentSlot) => {
        return { ...slot };
      });
      appointments.forEach((appointment: Appointment) => {
        const appStart = moment(appointment.scheduledStartTime);
        if (appStart.isSame(moment(selectedDate), "day")) {
          console.log("Inside same day");
          const updateSlot: AppointmentSlot | undefined = newSlots.find(
            (slot) => slot.start === appStart.format("hh:mm A")
          );
          if (updateSlot) {
            if (appointment.invitees[0].inviteeId === appointment.inviterId) {
              updateSlot.className = "busy";
              updateSlot.appointmentId = appointment.id;
            } else {
              updateSlot.className = "scheduled";
            }
          }
        }
      });
      setDayWiseSlot(newSlots);
    }
  }, [slots, appointments, selectedDate, setDayWiseSlot]);

  const slotHandler = (
    date: Date | null,
    time: string,
    type: string,
    appointmentId?: string
  ) => {
    if (localStorage.getItem("role") === roles.Therapist["be_value"]) {
      if (reschedule && type === "available") {
        const dateStr = moment(date).format("DD-MM-YYYY");
        confirm({
          title: "Reschedule",
          content: `Do you confirm to reschedule the appointment to ${dateStr} ${time}`,
          onOk() {
            dispatch(rescheduleAppointment(reschedule, dateStr, time));
          },
          onCancel() {},
          okText: "Yes",
          cancelText: "No",
          type: "confirm",
        });
      } else if (!reschedule) {
        const allowedTypes = ["busy", "available"];
        if (date && time && type && allowedTypes.includes(type)) {
          const dataModified = async (changed: boolean) => {
            if (changed) {
              await fetchAppointmentDetails(activeDateString, selectedDate);
            }
          };
          let status = "";
          if (type === "busy") {
            status = "Available";
          } else if (type === "available") {
            status = "Not Available";
          }
          dispatch(
            setModalDetails({
              type: "CALENDAR_AVAILABILITY",
              modalProps: {
                time: time,
                date: moment(date).format("DD, MMMM, YYYY"),
                status: status,
                show: true,
                appointmentId: appointmentId,
                dataChanged: dataModified,
              },
            })
          );
        }
      }
    }
  };

  const backHandler = () => {
    if (therapistId) {
      history.push("/administrator");
    } else {
      history.push("/appointments");
    }
  };

  return (
    <div className="calendar-view-wrapper">
      <h2 className="font-ml fw-semibold mb-l">
        <span
          className="arrow-left-icon me-2 mb-2 cp"
          onClick={backHandler}
        ></span>
        Calendar
      </h2>
      <div className="container-fluid p-0">
        <div className="calendar-cards row mx-md-n3">
          <div className="col-md-6 col-12 px-md-3">
            <div className="calendar-card">
              <div className="header">Date</div>
              <Calendar
                value={selectedDate}
                onChange={dateChangeHandler}
                activeStartDate={activeDate}
                className="singer-calendar ps-lg-4"
                showNeighboringMonth={false}
                formatShortWeekday={formatWeekDay}
                calendarType="US"
                minDate={previousDate}
                onActiveStartDateChange={activeChangeHandler}
                maxDetail="month"
                minDetail="month"
                defaultView="month"
                // showNavigation={false}
              />
            </div>
          </div>
          <div className="col-md-6 col-12 px-md-3">
            <div className="calendar-card ">
              <div className="header">Time</div>
              <div className="slot-container ps-lg-4">
                {dayWiseSlot.map((slot) => {
                  return (
                    <div
                      onClick={() =>
                        slotHandler(
                          selectedDate,
                          slot.start,
                          slot.className,
                          slot.appointmentId
                        )
                      }
                      className={"slot-item " + slot.className}
                      key={slot.start}
                    >
                      {slot.start}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TherapistCalendar;
