import { Avatar, Modal, Table } from "antd";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { getImageInitials } from "../../../helpers/user";
import http from "../../../http";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { hideModal, setModalDetails } from "../../../redux/reducers/modalSlice";
import { QuestionIcon } from "../../Common/Icons";

const QuestionnaireResponse = () => {
  const dispatch = useAppDispatch();
  const { responses, show } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      title={"Questionnaire Response"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
      className="participant-view-modal"
    >
      <div className="question-response-container modal-question-view styled-scrollbar">
        {responses.length > 0 ? (
          responses.map((item: any, _: any) => {
            const patientChoice = item?.patientResponses?.[0];
            return (
              <div className="question-response-card">
                <div className="question-icon">
                  <QuestionIcon />
                </div>
                <div>
                  <div className="question">{`${item.title}?`}</div>
                  {item.choices.length > 0 ? (
                    <div className="option-wrapper">
                      {item.choices.map((choiceItem: any) => {
                        const attachment = choiceItem?.attachment?.href;
                        return (
                          <div
                            className={
                              patientChoice?.questionChoiceId === choiceItem.id
                                ? "options selected-option bordered-option"
                                : "options bordered-option"
                            }
                          >
                            {attachment && (
                              <div className="questions-image">
                                <img src={attachment} />
                              </div>
                            )}
                            <div className="questions-option">
                              {choiceItem.label}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="option-wrapper">
                      <div className="options">
                        {patientChoice.textValue ||
                          patientChoice.numberValue ||
                          patientChoice.dateValue}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="empty-questions">No Questions Answered</div>
        )}
      </div>
    </Modal>
  );
};

export default QuestionnaireResponse;
