import React, { useState, useEffect, useRef } from "react";
import {
  lessonCompleted,
  getBookmarks,
  addBookmark,
  removeBookmark,
} from "../../redux/actions/webAction";
import { connect } from "react-redux";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import RenderMobQuestions from "../Education/renderMobQuestions";
import { Pagination } from "antd";

import "react-quill/dist/quill.snow.css";
import { toastMessage } from "../../helpers/toastMessage";
import { useLocation, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import ReactPlayer from "react-player";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LessonReadView = (props: any) => {
  const { assetId } = useParams<{ assetId: string }>();
  const projectId = useQuery().get("programId");
  const pageId = useQuery().get("pageId");
  const [checkBookmarked, setCheckBookmarked] = useState(false);
  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState<any[]>([]);
  const [arrlength, setArrLength] = useState(0);
  const { lessonDetails, getBookmarks, bookmarks } = props;
  const eleRefs = useRef<HTMLElement[]>([]);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    getBookmarks(userId, assetId, projectId);
  }, [getBookmarks, assetId, projectId]);

  useEffect(() => {
    let newPages = lessonDetails.hasOwnProperty("pages")
      ? [...lessonDetails.pages]
      : [];
    setArrLength(
      newPages[current - 1] ? newPages[current - 1].sections.length : 0
    );
    setCount(newPages.length);

    newPages.sort(function (a, b) {
      return a.position - b.position;
    });

    newPages = newPages.map(function (item: any) {
      const newSections = [...item.sections].sort(function (a: any, b: any) {
        return a.position - b.position;
      });
      return {
        ...item,
        sections: newSections,
      };
    });
    setPages(newPages);
    // if there is only one page in a lesson
    // if (
    //   props.lessonDetails.hasOwnProperty("pages") &&
    //   props.lessonDetails.pages.length === 1 &&
    //   props.projectId.includes("completed") !== true
    // ) {
    //   // props.lessonCompleted(props.lessonDetails.id, projectId);
    // }
  }, [lessonDetails, setPages, setArrLength, setCount, current]);

  useEffect(() => {
    if (pages.length > 0 && pageId) {
      let result = pages.findIndex((page) => {
        return page.id === pageId;
      });
      if (result !== -1) {
        setCurrent(result + 1);
      } else {
        toastMessage("error", "There is no such page in this lesson");
      }
    }
  }, [pages, pageId]);

  useEffect(() => {
    if (pages.length > 0 && bookmarks.length > 0) {
      let booleanValue = false;
      bookmarks.forEach((item: any) => {
        if (item.educationLessonPageId === pages[current - 1].id) {
          booleanValue = true;
          return;
        }
      });
      setCheckBookmarked(booleanValue);
    } else {
      setCheckBookmarked(false);
    }
  }, [bookmarks, current, pages, setCheckBookmarked]);

  useEffect(() => {
    eleRefs.current = eleRefs.current.slice(0, arrlength);
  }, [arrlength]);

  const handleSkip = (idx: any) => {
    // {
    //   lessonDetails.pages[current - 1].sections.forEach(
    //     (section: any) => {
    //       if (section.type !== "question") {
    //         count = count + 1;
    //         return;
    //       }
    //     }
    //   );
    // }
    if (arrlength === idx) {
      if (pages.length > current) {
        handleChangePagination(current + 1);
      } else {
        toastMessage("info", "This is the END of lesson");
      }
    } else {
      eleRefs.current[idx + 1].scrollIntoView();
    }
    // if (count > 0) {
    //   if (typeof window !== "undefined") {
    //     window.scrollTo(0, count*100);
    // console.log(current,count)
    //   }
    // } else {
    //   setValues((prevState) => ({
    //     ...prevState,
    //     current: prevState.current - 1,
    //   }));
    // }
  };

  function videoControl() {
    var myVideo = document.getElementById("myVideo");

    // myVideo.addEventListener(
    //   "webkitbeginfullscreen",
    //   enteringFullscreen,
    //   false
    // );
    // myVideo.addEventListener("webkitendfullscreen", exitingFullscreen, false);

    if (myVideo && myVideo.requestFullscreen) {
      myVideo.requestFullscreen();
      //@ts-ignore
    } else if (myVideo && myVideo["msRequestFullscreen"]) {
      //@ts-ignore
      myVideo["msRequestFullscreen"]();
      //@ts-ignore
    } else if (myVideo && myVideo["mozRequestFullScreen"]) {
      //@ts-ignore
      myVideo["mozRequestFullScreen"]();
      //@ts-ignore
    } else if (myVideo && myVideo["webkitRequestFullscreen"]) {
      //@ts-ignore
      myVideo["webkitRequestFullscreen"]();
    }
  }

  const switchForm = (item: any, idx: number) => {
    switch (item.type) {
      case "title":
        return (
          <div
            key={item.id}
            className="section-header"
            ref={(el) => (eleRefs.current[idx] = el as HTMLElement)}
          >
            <h3>{item.value}</h3>
          </div>
        );
      case "subtitle":
        return (
          <div
            key={item.id}
            className="section-header"
            ref={(el) => (eleRefs.current[idx] = el as HTMLElement)}
          >
            <div className="section-header-subtitle">{item.value}</div>
          </div>
        );
      case "picture":
        const imgSrc = item.value
          ? item.value.replace(
              "https://storage.cloud.google.com/",
              "https://storage.googleapis.com/"
            )
          : item.value;
        return (
          <div
            key={item.id}
            className="section-photo"
            ref={(el) => (eleRefs.current[idx] = el as HTMLElement)}
          >
            <img src={imgSrc} alt="section-photo" />
          </div>
        );
      case "video":
        return (
          <div
            key={item.id}
            className="section-video"
            ref={(el) => (eleRefs.current[idx] = el as HTMLElement)}
          >
            <ReactPlayer
              className="education-video"
              controls
              loop
              url={item.value}
            />
          </div>
        );
      case "text":
        return (
          <div
            key={item.id}
            className="section-description"
            ref={(el) => (eleRefs.current[idx] = el as HTMLElement)}
          >
            <div className="description-body">
              <ReactQuill value={item.value} readOnly={true} theme={"bubble"} />
            </div>
          </div>
        );
      case "resource":
        return (
          <div
            key={item.id}
            className="section-resources"
            ref={(el) => (eleRefs.current[idx] = el as HTMLElement)}
          >
            <div>
              <a href={item.value} target="_blank">
                {item.label}
              </a>
              <br />
            </div>
          </div>
        );
      case "question":
        return (
          <div
            key={item.id}
            className="section-question"
            ref={(el) => (eleRefs.current[idx] = el as HTMLElement)}
          >
            <RenderMobQuestions
              idx={idx}
              questions={item.questions}
              lessonId={assetId}
              handleSkip={handleSkip}
              projectId={projectId}
              status={
                item.participantResponses &&
                item.participantResponses.length > 0
                  ? item.participantResponses[0].status
                  : "Not Completed"
              }
            />
          </div>
        );
      default:
        break;
    }
  };

  const finishHandler = () => {
    props.setModalDetails({
      type: "LESSON_COMPLETION",
      modalProps: { show: true },
    });
    props.lessonCompleted(props.lessonDetails.id, projectId);
  };

  const itemRender = (_: any, type: any, originalElement: any) => {
    if (type === "prev") {
      return <a className="add-prev-line">PREV</a>;
    }
    if (type === "next") {
      if (current === count) {
        return (
          <span className="add-finish-line" onClick={finishHandler}>
            FINISH
          </span>
        );
      } else {
        return <a className="add-next-line">NEXT</a>;
      }
    }
    return originalElement;
  };

  const handleChangePagination = (pageNo: any) => {
    setCurrent(pageNo);
    setArrLength(pages[current - 1].sections.length);
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  const handleBookmark = (type: string, page: any) => {
    if (type === "add") {
      let body = {
        pageId: page.id,
        projectId: projectId,
      };
      props.addBookmark(body, assetId);
    } else if (type === "remove") {
      let body = {
        pageId: page.id,
        projectId: projectId,
      };
      props.removeBookmark(body, assetId);
    }
  };

  return (
    <>
      {/* main-container */}
      {pages.length > 0 ? (
        <>
          <div className="preview-section-mobile-wrapper">
            <div className="preview-header">
              <div className="preview-header-lesson">
                {`${lessonDetails.name}`.toUpperCase()}
              </div>
              <div>
                {checkBookmarked ? (
                  <div
                    className="bookmark-indicator"
                    onClick={() => {
                      handleBookmark("remove", pages[current - 1]);
                    }}
                  >
                    <span className="post-bookmarked-icon cp"></span>
                  </div>
                ) : (
                  <div
                    className="bookmark-indicator"
                    onClick={() => {
                      handleBookmark("add", pages[current - 1]);
                    }}
                  >
                    <span className="pre-bookmarked-icon cp"></span>
                  </div>
                )}
              </div>
            </div>

            <div className="preview-header-pages text-center">{`PAGE ${current} OF ${count}`}</div>

            <div className="preview-section-container">
              {pages[current - 1].sections.map(
                (section: any, index: number) => {
                  return switchForm(section, index);
                }
              )}
            </div>

            {/* pagination */}
            <div className="preview-section-pagination">
              <Pagination
                total={count}
                pageSize={1}
                current={current}
                onChange={(pageNo) => {
                  handleChangePagination(pageNo);
                }}
                itemRender={itemRender}
                showSizeChanger={false}
              />
            </div>
            <div className="page-number">{`Page ${current} of ${count}`}</div>
          </div>
        </>
      ) : (
        <>
          {pages.length === 0 && (
            <div className="preview-no-page-content">
              <p>No Pages Found For This Lesson</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  lessonDetails: state.lessons.lessonDetails,
  bookmarks: state.lessons.bookmarks,
});

export default connect(mapStateToProps, {
  setModalDetails,
  lessonCompleted,
  removeBookmark,
  addBookmark,
  getBookmarks,
})(LessonReadView);
