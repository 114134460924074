import React from "react";
import { ConfigProvider, Empty, Menu, Dropdown, Table } from "antd";
// import { deleteLesssonData } from "../../../redux/actions/programs/programAction";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { deleteProgramQuestion } from "../../redux/reducers/programSlice";

const QuestionTable: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { questionData } = useAppSelector((state) => state.program);

  const deleteHandler = (id: string) => {
    dispatch(
      deleteProgramQuestion({
        id: id,
      })
    );
  };

  const menu = (data: any) => (
    <Menu>
      <Menu.Item key="0" onClick={() => deleteHandler(data.id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const actionIcon = (data: any) => {
    return (
      <span onClick={(e) => e.stopPropagation()}>
        <Dropdown
          overlay={menu(data)}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <i className="more-icon cp"></i>
        </Dropdown>
      </span>
    );
  };

  const columns = [
    {
      key: "title",
      title: "#",
      width: "15%",
      render: (_1: any, _2: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      width: "35%",
      render: (val: string) => {
        return <>{val}</>;
      },
    },
    {
      key: "data",
      title: "Duration",
      dataIndex: "duration",
      width: "30%",
      render: (val: string) => {
        return <>{val}</>;
      },
    },
    {
      title: "",
      width: "10%",
      render: (_: any, data: any) => actionIcon(data),
    },
  ];

  return (
    <div className="event-wraper ">
      <div className="event-table-container singer-custom-table">
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No response found"
            />
          )}
        >
          <Table
            columns={columns}
            dataSource={questionData}
            className="cp"
            pagination={false}
            showSorterTooltip={false}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default QuestionTable;
