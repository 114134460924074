import React from "react";
import { Modal } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import moment from "moment";

const DietDetailModal = (props: any) => {
  const dispatch = useAppDispatch();
  const { show, dietDetails } = useAppSelector(
    (state) => state.modal.modalProps
  );

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      title={"Diet Details"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="mb-2">
        <span className="fw-bold me-2">Date: </span>
        <span>
          {moment(dietDetails.trackedDate, "YYYY-MM-DD").format("MMMM DD YYYY")}
        </span>
      </div>
      <div className="mb-2">
        <span className="fw-bold me-2">Meal Type: </span>
        <span>{dietDetails.mealType}</span>
      </div>
      <div className="mb-2">
        <span className="fw-bold me-2">Meal Size: </span>
        <span>{dietDetails.mealSize}</span>
      </div>
      <div className="mb-2">
        <span className="fw-bold me-2">Healthiness: </span>
        <span>{dietDetails.healthiness}</span>
      </div>
      <div className="fw-bold mb-2">Food Items Taken</div>
      {dietDetails.foodItems.map((item: any, index: number) => {
        return (
          <div>
            <span>{"Food Item " + (index + 1)}</span>
            <div>{item.food}</div>
            <div>
              <img
                style={{ width: "200px", height: "200px" }}
                src={"https://picsum.photos/200"}
                alt="food"
              />
            </div>
          </div>
        );
      })}
    </Modal>
  );
};

export default DietDetailModal;
