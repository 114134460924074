import { Dispatch } from "redux";
import axios from "axios";
import * as types from "../actionTypes";
import { toastMessage, errorToastMessage } from "../../helpers/toastMessage";
import { AppThunk } from "../store";
import { setAppLoader } from "../reducers/loaderSlice";

const API = process.env.REACT_APP_BASE_URL;

const getConfig = () => {
  return {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
};

// 1st action
export const getLessonDetails =
  (id: string, projectID: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res = await axios.get(
        `${API}/education_lessons/${id}?projectId=${projectID}`,
        getConfig()
      );
      dispatch({
        type: types.EDUCATION_PAGES_LIST,
        payload: res.data.data,
      });
      await lessonStarted(id, projectID);
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

// 2nd action
export const lessonStarted = async (lessonId: any, projectId: any) => {
  try {
    let body = {
      tasks: [
        {
          programId: projectId,
          educationLessonId: lessonId,
          status: "started",
        },
      ],
    };
    const id = localStorage.getItem("userId");
    await axios.put(`${API}/participants/${id}/tasks`, body, getConfig());
  } catch (err) {
    errorToastMessage(err);
  }
};

// 3rd action
export const lessonCompleted =
  (lessonId: any, projectId: any): AppThunk =>
  (dispatch) => {
    dispatch(setAppLoader(true));
    let body = {
      tasks: [
        {
          programId: projectId,
          educationLessonId: lessonId,
          status: "completed",
        },
      ],
    };

    const id = localStorage.getItem("userId");
    axios
      .put(`${API}/participants/${id}/tasks`, body, getConfig())
      .then((res) => {
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err);
      });
  };

// Add , remove and get bookmarks in a lesson

export const addBookmark =
  (body: any, lessonId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      await axios.post(
        `${API}/education_lessons/${lessonId}/add_bookmark`,
        body,
        getConfig()
      );
      const userId = localStorage.getItem("userId") || "";
      dispatch(getBookmarks(userId, lessonId, body.projectId));
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };

export const removeBookmark =
  (body: any, lessonId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      await axios.post(
        `${API}/education_lessons/${lessonId}/remove_bookmark`,
        body,
        getConfig()
      );
      const userId = localStorage.getItem("userId") || "";
      dispatch(getBookmarks(userId, lessonId, body.projectId));
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };

export const getBookmarks =
  (id: string, lessonId: string, projectId: string): AppThunk =>
  async (dispatch) => {
    try {
      await dispatch(getLessonDetails(lessonId, projectId));
      dispatch(setAppLoader(true));
      const res = await axios.get(
        `${API}/education_lessons/bookmarks?participantId=${id}&educationLessonId=${lessonId}`,
        getConfig()
      );
      dispatch({
        type: types.SET_BOOKMARKS,
        payload: res.data.data.bookmarks,
      });
      dispatch(setAppLoader(false));
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };

export const submitQuestionResponse =
  (lessonId: any, body: any, projectId: any): AppThunk =>
  (dispatch: Dispatch<any>) => {
    dispatch(setAppLoader(true));
    axios
      .post(
        `${API}/education_lessons/${lessonId}/submit_question_response`,
        body,
        getConfig()
      )
      .then((res) => {
        toastMessage("success", "Answers to the questions are submitted");
        dispatch(getLessonDetails(lessonId, projectId));
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err);
      });
  };
