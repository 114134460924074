const DietIcon = ({ width, height }: { width: string; height: string }) => (
  <svg
    width={width || "24"}
    height={height || "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.1 13.34L10.93 10.51L4.74 4.32996C4.26 3.84996 3.43 3.97996 3.13 4.59996C2.42 6.08996 2.68 7.91996 3.91 9.15996L8.1 13.34ZM14.88 11.53C16.41 12.24 18.56 11.74 20.15 10.15C22.06 8.23996 22.43 5.49996 20.96 4.02996C19.5 2.56996 16.76 2.92996 14.84 4.83996C13.25 6.42996 12.75 8.57996 13.46 10.11L4.4 19.17C4.01 19.56 4.01 20.19 4.4 20.58C4.79 20.97 5.42 20.97 5.81 20.58L12 14.41L18.18 20.59C18.57 20.98 19.2 20.98 19.59 20.59C19.98 20.2 19.98 19.57 19.59 19.18L13.41 13L14.88 11.53Z"
      fill="#333333"
      className="fill-path"
    />
  </svg>
);
export default DietIcon;
