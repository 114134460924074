import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getBuilderQuestions } from "../../redux/actions/questionAction";

type Props = {
  qid?: any;
  name?: any;
  handleExp: Function;
  getBuilderQuestions: Function;
  questions: any[];
};

const ListScoreQuestions = (props: Props) => {
  useEffect(() => {
    props.getBuilderQuestions(props.qid);
  }, []);

  const handleTitle = (item: any) => {
    if (item.hasOwnProperty("title")) {
      return item.title;
    } else if (item.hasOwnProperty("fields")) {
      return item.fields[0].value;
    }
  };

  let justQuestions =
    props.questions.length > 0
      ? props.questions.map((item) => {
          return (
            item.type !== "group" && {
              questionId: item.id,
              questionNo: `Q${item.position}`,
              questionTitle: handleTitle(item),
            }
          );
        })
      : [];

  justQuestions.length > 0 &&
    justQuestions.map((item, index) => {
      if (typeof item === "boolean") {
        let item = props.questions[index];
        let result =
          item.questions.length > 0 &&
          item.questions.map((ques: any) => {
            return {
              questionId: ques.id,
              questionNo: `Q${item.position}.${ques.position}`,
              questionTitle: handleTitle(ques),
            };
          });

        justQuestions.splice(index, 1, ...result);
      }
    });

  return (
    <div className="score-questions-list">
      <div className="score-questions-header">{`${props.name} questions`}</div>
      <div className="score-questions-item">
        {justQuestions.length > 0 &&
          justQuestions.map((item: any, index: any) => {
            return (
              <div
                className="score-question-content row cp"
                key={index}
                onClick={() => props.handleExp(item)}
              >
                <div className="question-number col-md-2">
                  {item.questionNo}
                </div>
                <div className="question-title col-md-10">
                  {item.questionTitle}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  questions: state.question.builderQuestions,
});

export default connect(mapStateToProps, { getBuilderQuestions })(
  ListScoreQuestions
);
