import React, { useEffect, useRef, useState } from "react";
import { Menu, Dropdown, Avatar, Table } from "antd";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  deleteExerciseDiary,
  getExerciseDiary,
} from "../../redux/actions/diaryAction";
import { getImageInitialsFullname } from "../../helpers/user";
import { setDummyExerciseArray } from "../../redux/reducers/programSlice";

type Props = {
  select?: boolean;
};

const ExerciseDiary: React.FC<Props> = ({ select }) => {
  const [current, setCurrent] = useState(1);
  const dispatch = useAppDispatch();
  const [rowKeys, setRowKeys] = useState<any[]>([]);
  const { exerciseCount, exerciseList, toggleLoader, searchTerm } =
    useAppSelector((state) => state.diary);
  const toggleRef = useRef<boolean>(toggleLoader);

  useEffect(() => {
    dispatch(getExerciseDiary(1, searchTerm));
    setCurrent(1);
  }, [dispatch, searchTerm, setCurrent]);

  useEffect(() => {
    if (toggleLoader !== toggleRef.current) {
      dispatch(getExerciseDiary(1, searchTerm));
      setCurrent(1);
      toggleRef.current = toggleLoader;
    }
  }, [dispatch, searchTerm, setCurrent, toggleLoader]);

  const menu = (data: any) => (
    <Menu style={{ width: "120px" }}>
      <Menu.Item
        key="1"
        onClick={() => {
          dispatch(deleteExerciseDiary(data.id));
        }}
      >
        Delete
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() =>
          dispatch(
            setModalDetails({
              type: "ADD_EXERCISE_MODAL",
              modalProps: {
                show: true,
                exerciseData: data,
              },
            })
          )
        }
      >
        Edit
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Exercise Name",
      key: "title",
      width: "30%",
      render: (_1: any, record: any) => {
        return (
          <div className="d-flex align-items-center">
            {record.imageUrl ? (
              <Avatar shape="circle" size="large" src={record.imageUrl} />
            ) : (
              <Avatar shape="circle" size="large">
                {getImageInitialsFullname(record.title)}
              </Avatar>
            )}
            <div className="ms-3" style={{ flex: 1, minWidth: "0px" }}>
              <p className="m-0 fw-medium">{record.title}</p>
            </div>
          </div>
        );
      },
    },
    {
      title: "Excercise Description",
      dataIndex: "description",
      key: "description",
      width: "60%",
    },
    {
      title: "",
      width: "10%",
      className: "text-end",
      render: (_1: any, record: any) => {
        return (
          <span onClick={(e) => e.stopPropagation()} className="d-inline-block">
            <Dropdown
              overlay={menu(record)}
              placement="bottomRight"
              trigger={["click"]}
            >
              <i className="more-icon cp"></i>
            </Dropdown>
          </span>
        );
      },
    },
  ];

  const onTableChange = async (pagination: any) => {
    let page = 1;
    if (pagination.current) {
      setCurrent(pagination.current);
      page = pagination.current;
    }
    await dispatch(getExerciseDiary(page, searchTerm));
  };

  const rowSelectionHandler = (selectedRowKeys: any[], selectedRows: any) => {
    setRowKeys(selectedRowKeys);
    dispatch(setDummyExerciseArray(selectedRows));
  };

  return (
    <>
      <Table
        dataSource={exerciseList}
        columns={columns}
        className="singer-custom-table"
        onChange={onTableChange}
        rowSelection={
          select
            ? {
                onChange: rowSelectionHandler,
                selectedRowKeys: rowKeys,
                preserveSelectedRowKeys: true,
              }
            : undefined
        }
        rowKey={(record) => record.id}
        pagination={{
          current: current,
          total: exerciseCount,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
          },
          position: ["bottomLeft"],
        }}
      />
    </>
  );
};

export default ExerciseDiary;
