import { Dropdown, Menu, Table } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { useAppDispatch } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import AppLayout from "../AppLayout/AppLayout";
import CustomButton from "../Common/CustomButton";
import Navigation from "../Navigation/Navigation";

const Forums = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState<any | null>(null);
  const [filter, setFilter] = useState("");
  const [toggleLoader, setToggleLoader] = useState(false);
  const toggleRef = useRef(false);

  const deleteHandler = useCallback(
    async (id) => {
      try {
        dispatch(setAppLoader(true));
        await http.delete(`/forums/${id}`);
        setToggleLoader((prev) => !prev);
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err);
      }
    },
    [setToggleLoader, dispatch]
  );

  const loadAllModerators = useCallback(
    (moderators: any) => {
      dispatch(
        setModalDetails({
          type: "ALL_MODERATOR_DETAILS",
          modalProps: {
            show: true,
            moderators: moderators,
          },
        })
      );
    },
    [dispatch]
  );

  const columns = useMemo(() => {
    const menu = (data: any) => (
      <Menu className="action-dropdown">
        <Menu.Item key="0" onClick={() => deleteHandler(data.id)}>
          Delete Forum
        </Menu.Item>
      </Menu>
    );
    return [
      {
        title: "Forum Title",
        dataIndex: "title",
        key: "title",
        sorter: true,
        sortOrder:
          sort?.sortBy === "title"
            ? sort.orderBy === "asc"
              ? ("ascend" as SortOrder)
              : ("descend" as SortOrder)
            : undefined,
        render: (title: string, record: any) => {
          return <NavLink to={"/forums/" + record.id}>{title}</NavLink>;
        },
      },
      {
        title: "Creation Date",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        sortOrder:
          sort?.sortBy === "createdAt"
            ? sort.orderBy === "asc"
              ? ("ascend" as SortOrder)
              : ("descend" as SortOrder)
            : undefined,
        render: (text: string) => (
          <span>{moment(text).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        title: "Moderator",
        dataIndex: "moderators",
        key: "moderators",
        render: (moderators: any) => {
          const moderator = moderators[0];
          return (
            <>
              <span>{moderator.firstName + " " + moderator.lastName}</span>
              {moderators.length > 1 && (
                <span>
                  <span className="ms-1 me-1 more-users">{"&"}</span>
                  <span
                    className="more-users-count"
                    onClick={() => loadAllModerators(moderators)}
                  >
                    {moderators.length > 2
                      ? `${moderators.length} others`
                      : "1 other"}
                  </span>
                </span>
              )}
            </>
          );
        },
      },
      {
        title: "Users",
        dataIndex: "participantsCount",
        key: "participantsCount",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        filters: [
          {
            text: "Open",
            value: "open",
          },
          {
            text: "Closed",
            value: "closed",
          },
        ],
        filterMultiple: false,
        filteredValue: filter ? [filter] : null,
        render: (text: string) => (
          <span
            className={
              text === "open" ? "active-tag badge" : "inactive-tag badge"
            }
          >
            {text}
          </span>
        ),
      },
      {
        title: "",
        width: "10%",
        className: "text-end",
        render: (_1: any, record: any) => {
          return (
            <span
              onClick={(e) => e.stopPropagation()}
              className="d-inline-block"
            >
              <Dropdown
                overlay={menu(record)}
                placement="bottomRight"
                trigger={["click"]}
              >
                <i className="more-icon cp"></i>
              </Dropdown>
            </span>
          );
        },
      },
    ];
  }, [sort, filter, deleteHandler, loadAllModerators]);

  const fetchForumData = useCallback(
    async (page: number, sort?: any, filter?: any) => {
      try {
        dispatch(setAppLoader(true));
        let query = `?page=${page}&size=8`;
        if (sort) {
          query += `&orderBy=${sort.orderBy}&sortBy=${sort.sortBy}`;
        }
        if (filter) {
          query += `&type=${filter}`;
        }
        const res = await http.get(`/forums${query}`);
        const data = res.data.data;
        setData(data.forums);
        setTotal(+data.count);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, setCurrentPage, setData, setTotal]
  );

  useEffect(() => {
    fetchForumData(1);
  }, [fetchForumData]);

  useEffect(() => {
    if (toggleLoader !== toggleRef.current) {
      fetchForumData(currentPage, sort, filter);
      toggleRef.current = toggleLoader;
    }
  }, [fetchForumData, toggleLoader, currentPage, filter, sort]);

  const addForumHandler = () => {
    const successCallback = () => {
      fetchForumData(currentPage, sort, filter);
    };
    dispatch(
      setModalDetails({
        type: "ADD_FORUM_MODALS",
        modalProps: {
          show: true,
          successCallback,
        },
      })
    );
  };

  const onTableChange = async (pagination: any, filters: any, sorters: any) => {
    let page = 1;
    let sort = null;
    let filter = "";
    if (sorters.order) {
      sort = {
        orderBy: sorters["order"] === "ascend" ? "asc" : "desc",
        sortBy: sorters.field,
      };
      setSort(sort);
    } else {
      setSort(null);
    }
    if (filters.type) {
      filter = filters.type[0];
      setFilter(filter);
    } else {
      filter = "";
      setFilter(filter);
    }
    if (pagination.current) {
      setCurrentPage(pagination.current);
      page = pagination.current;
    }
    fetchForumData(page, sort, filter);
  };

  return (
    <AppLayout>
      <Navigation />
      <span className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="font-l fw-bold">Forums</h2>
        <CustomButton
          primary={true}
          clickHandler={addForumHandler}
          buttonText={`Add Forum`}
          addButton={true}
          buttonType={"button"}
          className="btn-lg"
        />
      </span>
      <Table
        dataSource={data}
        columns={columns}
        showSorterTooltip={false}
        className="singer-custom-table"
        onChange={onTableChange}
        rowKey={(record) => record.id}
        pagination={{
          current: currentPage,
          total: total,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
          },
          position: ["bottomLeft"],
        }}
      />
    </AppLayout>
  );
};

export default Forums;
