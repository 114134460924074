import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchForumDetails } from "../../redux/actions/forumAction";
import { useAppDispatch } from "../../redux/hooks";
import { setForumSearchTerm } from "../../redux/reducers/forumSlice";
import AppLayout from "../AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import ForumComments from "./ForumComments";
import ForumHeader from "./ForumHeader";
import ForumParticipants from "./ForumParticipants";

const { TabPane } = Tabs;

const ForumDetails = () => {
  const { id } = useParams<any>();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<string>("Users");

  const tabChangeHandler = (key: string) => {
    setSelectedTab(key);
    dispatch(setForumSearchTerm(""));
  };

  useEffect(() => {
    dispatch(fetchForumDetails(id));
  }, [id, dispatch]);

  return (
    <AppLayout>
      <Navigation />
      <ForumHeader type={selectedTab} id={id} />
      <Tabs
        activeKey={selectedTab}
        onChange={tabChangeHandler}
        destroyInactiveTabPane
        className="singer-cutsom-tab"
      >
        <TabPane tab="Users" key="Users">
          <ForumParticipants id={id} />
        </TabPane>
        <TabPane tab="Comments" key="Comments">
          <ForumComments id={id} />
        </TabPane>
      </Tabs>
    </AppLayout>
  );
};

export default ForumDetails;
