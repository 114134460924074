import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Input, Card, Divider, Upload } from "antd";
import InputField from "../Common/CustomInput";
import Navigation from "../Navigation/Navigation";
import CustomButton from "../Common/CustomButton";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import LessonTable from "./LessonTable";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import AppLayout from "../AppLayout/AppLayout";
import {
  editProgram,
  fetchProgramDetails,
} from "../../redux/actions/programAction";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import QuestionTable from "./questionnaireTable";
import { useHistory } from "react-router-dom";
import FoodTable from "./FoodTable";
import ExerciseTable from "./ExerciseTable";
import { UploadImage, UploadPdf } from "../../helpers/imageUpload";

const { TextArea } = Input;

const beforeUpload = (file: any) => {
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    toastMessage("warning", "File must smaller than 5MB!");
  }
  return isLt2M;
};

const schema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  notes: Yup.string().required("Notes is required"),
  duration: Yup.number().required("Duration of the program is required"),
});

const EditProgram = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const basicDetails = useAppSelector((state) => state.program.editProgram);
  const [initData, setInitData] = React.useState({
    title: "",
    description: "",
    notes: "",
    duration: 0,
    consentUrl: "",
  });

  useEffect(() => {
    if (basicDetails) {
      setInitData(basicDetails);
    }
  }, [setInitData, basicDetails]);

  useEffect(() => {
    const noLoad = sessionStorage.getItem("singer-program-no-load");
    if (!noLoad) {
      dispatch(fetchProgramDetails());
    } else {
      sessionStorage.removeItem("singer-program-no-load");
    }
  }, [dispatch]);

  const addLessonHandler = () => {
    dispatch(
      setModalDetails({
        type: "ADD_PROGRAM_LESSON",
        modalProps: {
          show: true,
          title: "Add Lesson",
        },
      })
    );
  };

  const addQuestionHandler = () => {
    dispatch(
      setModalDetails({
        type: "ADD_PROGRAM_QUESTION",
        modalProps: {
          show: true,
          title: "Add Questionnaire",
        },
      })
    );
  };

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      const formData: any = {
        title: values.title,
        description: values.description,
        notes: values.notes ? values.notes : null,
        duration: values.duration,
      };
      if (values.consentUrl) {
        formData.consentUrl = values.consentUrl;
      }
      await dispatch(editProgram(formData));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const addFoodHandler = () => {
    history.push("/programs/select/Food");
  };

  const addExerciseHandler = () => {
    history.push("/programs/select/Exercise");
  };

  const handleConsent = (file: any, setFieldValue: Function) => {
    const handleUpload = (data: any) => {
      setFieldValue("consentUrl", data[0].postUploadImageUrl);
    };
    UploadPdf(file, handleUpload, "education_lesson_image")();
  };

  return (
    <AppLayout>
      <Navigation />
      <Formik
        initialValues={{ ...initData }}
        validationSchema={schema}
        enableReinitialize
        onSubmit={(values) => {
          submitHandler(values);
        }}
      >
        {({
          values,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="content-height">
            <h2
              className="mb-4 font-l cp"
              style={{ fontSize: "24px", fontWeight: "bold" }}
            >
              Singer Program
            </h2>
            <div>
              <div className="create-action">
                <div className="row mx-n3">
                  <div className="col-lg-8 px-3">
                    <Card
                      title="Program Information"
                      className="border-0 mb-5 shadow-lg"
                      headStyle={{
                        fontSize: "22px",
                        fontWeight: "bold",
                        border: "none",
                      }}
                    >
                      <div className="form-group mb-4">
                        <div className="field-name form-label">
                          Program Title
                        </div>
                        <div className="floating-label">
                          <InputField
                            name="title"
                            value={values.title}
                            changeEvent={handleChange}
                            blurEvent={handleBlur}
                          />
                          <div className="input-error text-left">
                            {errors.title && touched.title && errors.title}
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="field-name form-label">
                          Program Description
                        </div>
                        <TextArea
                          rows={1}
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="program-name"
                        />
                        <div className="input-error text-left">
                          {errors.description &&
                            touched.description &&
                            errors.description}
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="field-name form-label">
                          Duration in days
                        </div>
                        <div className="floating-label">
                          <InputField
                            name="duration"
                            value={values.duration}
                            changeEvent={handleChange}
                            blurEvent={handleBlur}
                          />
                          <div className="input-error text-left">
                            {errors.duration &&
                              touched.duration &&
                              errors.duration}
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="field-name form-label">Notes</div>
                        <TextArea
                          rows={1}
                          name="notes"
                          value={values.notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="program-note"
                        />
                        <div className="input-error text-left">
                          {errors.notes && touched.notes && errors.notes}
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="field-name form-label">
                          Consent form
                        </div>
                        <div className="floating-label">
                          <Upload
                            showUploadList={false}
                            accept="application/pdf"
                            beforeUpload={beforeUpload}
                            customRequest={(file) =>
                              handleConsent(file, setFieldValue)
                            }
                          >
                            <CustomButton
                              primary
                              buttonText="Upload Consent Form"
                              className="me-2"
                            />
                          </Upload>
                          <span>
                            {values?.consentUrl
                              ? values?.consentUrl.split?.("/").pop?.()
                              : null}
                          </span>
                        </div>
                      </div>
                    </Card>

                    <Card
                      title="Lessons"
                      className="border-0 mb-5 shadow-lg"
                      headStyle={{
                        fontSize: "22px",
                        fontWeight: "bold",
                        border: "none",
                      }}
                      extra={
                        <div
                          className="cp color-primary d-inline-flex align-items-center"
                          onClick={addLessonHandler}
                        >
                          <span className="add-blue-icon icon-sm me-2 float-left"></span>
                          Add Lesson
                        </div>
                      }
                    >
                      <LessonTable />
                    </Card>

                    <Card
                      title="Questionnaires"
                      className="border-0 mb-5 shadow-lg"
                      headStyle={{
                        fontSize: "22px",
                        fontWeight: "bold",
                        border: "none",
                      }}
                      extra={
                        <div
                          className="cp color-primary d-inline-flex align-items-center"
                          onClick={addQuestionHandler}
                        >
                          <span className="add-blue-icon icon-sm me-2 float-left"></span>
                          Add Questionnaire
                        </div>
                      }
                    >
                      <QuestionTable />
                    </Card>

                    <Card
                      title="Food Diary"
                      className="border-0 mb-5 shadow-lg"
                      headStyle={{
                        fontSize: "22px",
                        fontWeight: "bold",
                        border: "none",
                      }}
                      extra={
                        <div
                          className="cp color-primary d-inline-flex align-items-center"
                          onClick={addFoodHandler}
                        >
                          <span className="add-blue-icon icon-sm me-2 float-left"></span>
                          <span>Add Food Item</span>
                        </div>
                      }
                    >
                      <FoodTable />
                    </Card>

                    <Card
                      title="Exercise Diary"
                      className="border-0 mb-2 shadow-lg"
                      headStyle={{
                        fontSize: "22px",
                        fontWeight: "bold",
                        border: "none",
                      }}
                      extra={
                        <div
                          className="cp color-primary d-inline-flex align-items-center"
                          onClick={addExerciseHandler}
                        >
                          <span className="add-blue-icon icon-sm me-2 float-left"></span>
                          Add Exercise
                        </div>
                      }
                    >
                      <ExerciseTable />
                    </Card>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <Divider />
                <div className=" justify-content-end d-flex">
                  <CustomButton
                    buttonText="Cancel"
                    primary={false}
                    className="cancel-button btn-lg"
                  />
                  <CustomButton
                    primary={true}
                    buttonType="submit"
                    buttonText="Save"
                    className="ms-3 btn-lg btn-success"
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </AppLayout>
  );
};

export default EditProgram;
