// import {fieldMap} from "../questions/builder/dataList";

export const choices = [
  {
    key: '1',
    type: 'text',
    title: 'Text',
  },
  {
    key: '2',
    type: 'checkbox',
    title: 'Checkbox ',
  },
  {
    key: '3',
    type: 'radio',
    title: 'Radio Button',
  }
]



const textField = {
  fileAttachment: {},
  fields: [{ type: 'input', value: '' }],
  type: 'text',
  label: 'Enter question text here',
  properties: {},
  options: {
    showMax: true,
    max: 40,
    attachment: false
  },
  required: false,
  branches: [],
  branchType: 'TEXT'
};


const checkField = {
  label: 'Enter question text here',
  title: '',
  fileAttachment: {},
  fields: [{ type: 'dropdown', label: '', code: '' }],
  properties: {},
  options: {
    showMax: false,
    max: 1,
    attachment: false
  },
  required: false,
  branches: [],
  branchType: 'checkbox'
};

const radioField = {
  label: 'Enter question text here',
  title: '',
  fileAttachment: {},
  fields: [{ type: 'dropdown', label: '', code: '' }],
  properties: {},
  options: {
    showMax: false,
    max: 1,
    attachment: false
  },
  required: false,
  branches: [],
  branchType: 'radio'
};

export const fieldMap: any = {
  S: textField,
  text: textField,
  checkbox: checkField,
  radio: radioField
};


export const cards = [
  {
    key: "1",
    classValue: "A-title",
    activeClass: "A-title",
    name: "TITLE",
    description: "Title block for headers and sub-headers.",
    title: '',
  },
  {
    key: "2",
    classValue: "A-title",
    activeClass: "A-title",
    name: "SUBTITLE",
    description: "Subtitle block to introduce your page section (usually below the Title.)",
    subTitle: ''
  },
  {
    key: "3",
    classValue: "video-section",
    activeClass: "A-video",
    name: "VIDEO",
    description: "Video block with support for live comments.",
    video: ""
  },
  {
    key: "4",
    classValue: "A-question",
    activeClass: "A-question",
    name: "QUESTION",
    description: "Ask participants a multiple-choice or open response question.",
    questions: [{...fieldMap.S }]
  },
  {
    key: "5",
    classValue: "A-article",
    activeClass: "A-article",
    name: "ARTICLE/TEXT",
    description:
      "Text block for body text.",
    content: ''
  },
  {
    key: "6",
    classValue: "A-resources",
    activeClass: "A-resources",
    name: "RESOURCES",
    resources: [{label: "", url: ""}],
    description: "Share images, PDFs and links.",
  },
  {
    key: "7",
    classValue: "A-pictures",
    activeClass: "A-pictures",
    name: "PICTURES",
    files: [],
    fileNames:"",
    description: "Image block for sharing pictures",
  }
];

