import React, { useState } from "react";
import { Modal, Input } from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { addForumComment } from "../../../redux/actions/forumAction";
import CustomButton from "../../Common/CustomButton";
import { toastMessage } from "../../../helpers/toastMessage";

const AddForumCommentModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const [comment, setComment] = useState("");
  const { show, id } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = () => {
    const finalComment = comment.trim();
    if (finalComment) {
      dispatch(addForumComment(id, comment));
    } else {
      toastMessage("error", "Comment cannot be empty");
    }
  };

  const handleChange = (event: any) => {
    setComment(event.target.value);
  };

  return (
    <Modal
      title={`Add Comment`}
      visible={show}
      onCancel={closeHandler}
      closable={true}
      width={"38rem"}
      footer={null}
    >
      <div>
        <div className="input-field w-100 mb-4">
          <div className="field-name form-label">Comment</div>
          <div className="floating-label">
            <Input.TextArea value={comment} onChange={handleChange} />
          </div>
        </div>
        <div className="text-end">
          <span
            role="button"
            className="button-cancel"
            onClick={closeHandler}
            tabIndex={0}
          >
            Cancel
          </span>
          <CustomButton
            buttonType="button"
            buttonText="Save"
            clickHandler={submitHandler}
            primary={true}
            className="btn-success"
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddForumCommentModal;
