import { Table } from "antd";
import React, { useMemo } from "react";
import { colorCodingDecoder, currentMetricDecoder } from "../../helpers/metric";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setConsoleChart } from "../../redux/reducers/consoleSlice";
import { ChartTypes } from "../../types";
import AlcoholIcon from "./Patienticons/AlcoholIcon";
import BpIcon from "./Patienticons/BpIcon";
import CalenderIcon from "./Patienticons/CalenderIcon";
import DietIcon from "./Patienticons/DietIcon";
import ExerciseIcon from "./Patienticons/ExerciseIcon";
import GlucoseIcon from "./Patienticons/GlucoseIcon";
import Hba1cIcon from "./Patienticons/Hba1cIcon";
import MedicineIcon from "./Patienticons/MedicineIcon";
import SleepIcon from "./Patienticons/SleepIcon";
import SmokeIcon from "./Patienticons/SmokeIcon";
import StepsIcon from "./Patienticons/StepsIcon";
import WeightIcon from "./Patienticons/WeightIcon";
interface CellProperties {
  total: any;
  current: any;
  imprints: any;
  currentIntensity: any;
  imprintIntensity: any;
}

const ColumnBlock: (props: {
  Icon: JSX.Element;
  columnHeader: React.ReactNode | string;
  bold?: boolean;
  clickHandler?: Function;
  type?: ChartTypes | null;
  activeType?: ChartTypes | null;
}) => JSX.Element = ({
  Icon,
  columnHeader,
  bold = true,
  clickHandler,
  type,
  activeType,
}) => {
  const classname = type
    ? type === activeType
      ? "graph-item active-graph"
      : "graph-item inactive-graph"
    : "";
  return (
    <div
      className={
        "client-table-header d-flex flex-column justify-content-evenly align-items-center " +
        classname
      }
      onClick={clickHandler && type ? () => clickHandler(type) : () => {}}
    >
      {Icon}
      <div className="text-center graph-text">
        {bold ? <p>{columnHeader}</p> : columnHeader}
      </div>
    </div>
  );
};

const renderCell: (
  cellProperties: CellProperties,
  clickHandler: Function,
  type: ChartTypes
) => JSX.Element = (cellProperties, clickHandler, type) => {
  const currentClassName = colorCodingDecoder(cellProperties.currentIntensity);
  const imprintClassName = colorCodingDecoder(cellProperties.imprintIntensity);
  const currentValue = currentMetricDecoder(cellProperties.current, type);
  return (
    <div
      className="text-center td-wrapper"
      onClick={clickHandler && type ? () => clickHandler(type) : () => {}}
    >
      <div className={`mahalo-dashboard-cell-section ${currentClassName}`}>
        {currentValue}
      </div>
      <div className={`${imprintClassName} mahalo-dashboard-cell-section`}>
        {cellProperties.imprints + "/" + cellProperties.total}
      </div>
    </div>
  );
};

const PatientMetricTable = () => {
  const dispatch = useAppDispatch();
  const activeType = useAppSelector((state) => state.console.type);
  const metricData = useAppSelector((state) => state.console.patientMetric);

  const typeHandler = (type: ChartTypes) => {
    dispatch(setConsoleChart(type));
  };

  const finalMetricData = useMemo(() => {
    if (Object.keys(metricData).length > 0) {
      return [metricData];
    }
    return [];
  }, [metricData]);

  const columnsView = [
    {
      title: () => {
        return (
          <ColumnBlock
            Icon={<CalenderIcon width="24" height="24" />}
            columnHeader={
              <div className="dashboard-title">
                New <br /> Intervention
              </div>
            }
            bold={false}
          />
        );
      },
      dataIndex: "newIntervention",
      key: "newIntervention",
      render: function render(value: string): JSX.Element {
        return <div className="text-center">1</div>;
      },
    },
    {
      title: () => {
        return (
          <ColumnBlock
            Icon={<CalenderIcon width="24" height="24" />}
            columnHeader={
              <div className="dashboard-title">
                Last <br /> Intervention
              </div>
            }
            bold={false}
          />
        );
      },
      dataIndex: "lastIntervention",
      key: "lastIntervention",
      render: function render(value: string): JSX.Element {
        return <div className="text-center">27/03/2021</div>;
      },
    },
    {
      title: () => {
        return (
          <ColumnBlock
            Icon={<CalenderIcon width="24" height="24" />}
            columnHeader={
              <div className="dashboard-title">
                New <br /> Questions
              </div>
            }
            bold={false}
          />
        );
      },
      dataIndex: "newQuestions",
      key: "newQuestions",
      render: function render(value: string): JSX.Element {
        return <div className="text-center">0</div>;
      },
    },
    {
      title: () => {
        return (
          <ColumnBlock
            Icon={<CalenderIcon width="24" height="24" />}
            columnHeader={
              <div className="dashboard-title">
                Last <br /> Active
              </div>
            }
            bold={false}
          />
        );
      },
      dataIndex: "lastActive",
      key: "lastActive",
      render: function render(value: string): JSX.Element {
        return <div className="text-center p-2">29/03/2021</div>;
      },
    },
    {
      title: () => {
        return (
          <ColumnBlock
            Icon={<StepsIcon width="24" height="24" />}
            columnHeader="Steps"
            clickHandler={typeHandler}
            type="Steps"
            activeType={activeType}
          />
        );
      },
      dataIndex: "steps",
      key: "steps",
      render: (value: CellProperties) =>
        renderCell(value, typeHandler, "Steps"),
    },
    {
      title: function render(): JSX.Element {
        return (
          <ColumnBlock
            Icon={<SleepIcon width="24" height="24" />}
            columnHeader="Sleep"
            clickHandler={typeHandler}
            type="Sleep"
            activeType={activeType}
          />
        );
      },
      dataIndex: "sleep",
      key: "sleep",
      render: (value: CellProperties): JSX.Element =>
        renderCell(value, typeHandler, "Sleep"),
    },
    {
      title: function render(): JSX.Element {
        return (
          <ColumnBlock
            Icon={<DietIcon width="24" height="24" />}
            columnHeader="Diet"
            clickHandler={typeHandler}
            type="Diet"
            activeType={activeType}
          />
        );
      },
      dataIndex: "diet",
      key: "diet",
      render: (value: CellProperties): JSX.Element =>
        renderCell(value, typeHandler, "Diet"),
    },
    {
      title: function render(): JSX.Element {
        return (
          <ColumnBlock
            Icon={<ExerciseIcon width="24" height="24" />}
            columnHeader="Exercise"
            clickHandler={typeHandler}
            type="Exercise"
            activeType={activeType}
          />
        );
      },
      dataIndex: "exercise",
      key: "exercise",
      render: (value: CellProperties): JSX.Element =>
        renderCell(value, typeHandler, "Exercise"),
    },
    {
      title: function render(): JSX.Element {
        return (
          <ColumnBlock
            Icon={<SmokeIcon width="24" height="24" />}
            columnHeader="Smoke"
            clickHandler={typeHandler}
            type="Smoke"
            activeType={activeType}
          />
        );
      },
      dataIndex: "smoke",
      render: (value: CellProperties): JSX.Element =>
        renderCell(value, typeHandler, "Smoke"),
      key: "smoke",
    },
    {
      title: function render(): JSX.Element {
        return (
          <ColumnBlock
            Icon={<AlcoholIcon width="24" height="24" />}
            columnHeader="Alcohol"
            clickHandler={typeHandler}
            type="Alcohol"
            activeType={activeType}
          />
        );
      },
      dataIndex: "alcohol",
      render: (value: CellProperties): JSX.Element =>
        renderCell(value, typeHandler, "Alcohol"),
      key: "alcohol",
    },
    {
      title: function render(): JSX.Element {
        return (
          <ColumnBlock
            Icon={<WeightIcon width="24" height="24" />}
            columnHeader="Weight"
            clickHandler={typeHandler}
            type="Weight"
            activeType={activeType}
          />
        );
      },
      dataIndex: "weight",
      render: (value: CellProperties): JSX.Element =>
        renderCell(value, typeHandler, "Weight"),
      key: "weight",
    },
    {
      title: function render(): JSX.Element {
        return (
          <ColumnBlock
            Icon={<BpIcon width="24" height="24" />}
            columnHeader="Bp"
            clickHandler={typeHandler}
            type="Blood Pressure"
            activeType={activeType}
          />
        );
      },
      dataIndex: "bloodPressure",
      render: (value: CellProperties): JSX.Element =>
        renderCell(value, typeHandler, "Blood Pressure"),
      key: "bloodPressure",
    },
    {
      title: function render(): JSX.Element {
        return (
          <ColumnBlock
            Icon={<Hba1cIcon width="24" height="24" />}
            columnHeader="HbA1C"
            clickHandler={typeHandler}
            type="HbA1C"
            activeType={activeType}
          />
        );
      },
      dataIndex: "hb",
      render: (value: CellProperties): JSX.Element =>
        renderCell(value, typeHandler, "HbA1C"),
      key: "hb",
    },
    {
      title: function render(): JSX.Element {
        return (
          <ColumnBlock
            Icon={<GlucoseIcon width="24" height="24" />}
            columnHeader="Glucose"
            clickHandler={typeHandler}
            type="Glucose"
            activeType={activeType}
          />
        );
      },
      dataIndex: "glucose",
      render: (value: CellProperties): JSX.Element =>
        renderCell(value, typeHandler, "Glucose"),
      key: "glucose",
    },
    {
      title: function render(): JSX.Element {
        return (
          <ColumnBlock
            Icon={<MedicineIcon width="24" height="24" />}
            columnHeader="Medicine"
            clickHandler={typeHandler}
            type="Medicine"
            activeType={activeType}
          />
        );
      },
      dataIndex: "medicine",
      render: (value: CellProperties): JSX.Element =>
        renderCell(value, typeHandler, "Medicine"),
      key: "medicine",
    },
  ];
  return (
    <div
      style={{ overflow: "auto" }}
      className="shadow-s mb-5 bg-white rounded custom-ant-table"
    >
      <Table
        scroll={{ x: "max-content" }}
        columns={columnsView}
        dataSource={finalMetricData}
        pagination={false}
        className="clientlist-table individual-table"
      />
    </div>
  );
};

export default PatientMetricTable;
