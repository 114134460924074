import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { useAppSelector } from "../../../../redux/hooks";

const stepOptions = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Steps",
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
        callback: function (label: number) {
          if (label < 1000000) {
            return (label / 1000).toFixed(2).replace(/\.00$/, "") + "K";
          } else if (label >= 1000000) {
            return (label / 1000000).toFixed(0).replace(/\.00$/, "") + "M";
          }
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsbold",
            size: 11,
            lineHeight: "16px",
            weight: 500,
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const weightOptions = {
  scales: {
    y: {
      suggestedMin: 40,
      suggestedMax: 120,
      title: {
        display: true,
        text: "Weight(Kg)",
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsbold",
            size: 11,
            lineHeight: "16px",
            weight: 500,
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const hbOptions = {
  scales: {
    y: {
      // suggestedMin: 4,
      // suggestedMax: 8,
      title: {
        stepSize: 0.5,
        display: true,
        text: "HbA1C(DDCT%)",
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsbold",
            size: 11,
            lineHeight: "16px",
            weight: 500,
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const bpOptions = {
  scales: {
    y: {
      suggestedMax: 150,
      title: {
        display: true,
        text: "Blood Pressure (mmHg)",
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsbold",
            size: 11,
            lineHeight: "16px",
            weight: 500,
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
      labels: {
        usePointStyle: true,
      },
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const glucoseOptions = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Glucose level",
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 11,
            lineHeight: "16px",
          };
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsbold",
            size: 11,
            lineHeight: "16px",
            weight: 500,
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const LineActionChart = () => {
  const { type, chartData } = useAppSelector((state) => state.console);

  const options = useMemo(() => {
    if (type === "Steps") {
      return stepOptions;
    } else if (type === "Weight") {
      return weightOptions;
    } else if (type === "HbA1C") {
      return hbOptions;
    } else if (type === "Blood Pressure") {
      return bpOptions;
    } else if (type === "Glucose") {
      return glucoseOptions;
    } else {
      return stepOptions;
    }
  }, [type]);

  return <Line data={chartData} options={options} />;
};

export default LineActionChart;
