import React, { useCallback, useEffect, useState } from "react";
import { appointmentQueryConstructor } from "../../helpers/Appointment";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { useAppDispatch } from "../../redux/hooks";
import { setAppointmentCount } from "../../redux/reducers/appointmentSlice";
import EmptyAppointment from "./EmptyAppointment";
import moment from "moment";
import AppointmentItem from "./AppointmentItem";
import AppointmentGroup from "./AppointmentGroup";
import { setAppLoader } from "../../redux/reducers/loaderSlice";

type Props = {
  tabType: string;
};

const Appointments: React.FC<Props> = ({ tabType }) => {
  const [data, setData] = useState<any[]>([]);
  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    async (tabType) => {
      try {
        dispatch(setAppLoader(true));
        const query = appointmentQueryConstructor(tabType);
        const res = await http.get("/ap" + query);
        const resData = res.data.data;
        const countData = {
          todayCount: resData?.counts?.today || 0,
          weekCount: resData?.counts?.week || 0,
          unconfirmedCount: resData?.counts?.pending || 0,
        };
        dispatch(setAppointmentCount(countData));
        let data = resData.appointments;
        if (tabType !== "today" && data.length > 0) {
          const tempData: any = {};
          data.forEach((ap: any) => {
            const startDate = moment(ap.scheduledStartTime)
              .format("dddd, MMM D, YYYY")
              .toUpperCase();
            if (tempData[startDate]) {
              tempData[startDate].push(ap);
            } else {
              tempData[startDate] = [ap];
            }
          });
          data = [];
          Object.entries(tempData).forEach(([key, value]: [any, any]) => {
            data.push({
              date: key,
              appointments: value,
            });
          });
        }
        setData(data);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, setData]
  );

  useEffect(() => {
    fetchData(tabType);
  }, [fetchData, tabType]);

  const cancelHandler = useCallback(
    async (id: string) => {
      try {
        dispatch(setAppLoader(true));
        await http.patch(`/ap/${id}/cancel`);
        toastMessage("success", "Appointment cancelled successfully");
        await fetchData(tabType);
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [fetchData, tabType, dispatch]
  );

  const acceptHandler = useCallback(
    async (id: string) => {
      try {
        dispatch(setAppLoader(true));
        await http.patch(`/ap/${id}/confirm`);
        toastMessage("success", "Appointment confirmed successfully");
        await fetchData(tabType);
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [tabType, fetchData, dispatch]
  );

  return (
    <>
      {data.length > 0 ? (
        tabType === "today" ? (
          data.map((appointment: any) => {
            return (
              <AppointmentItem
                type={tabType}
                key={appointment.id}
                appointment={appointment}
                cancelHandler={cancelHandler}
                acceptHandler={acceptHandler}
              />
            );
          })
        ) : (
          <AppointmentGroup
            data={data}
            cancelHandler={cancelHandler}
            acceptHandler={acceptHandler}
            type={tabType}
          />
        )
      ) : (
        <EmptyAppointment tabType={tabType} />
      )}
    </>
  );
};

export default Appointments;
