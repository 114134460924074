import { Modal, Input } from "antd";
import { Formik } from "formik";
import InputField from "../../Common/CustomInput";
import { toastMessage } from "../../../helpers/toastMessage";
import { hideModal } from "../../../redux/reducers/modalSlice";
import Button from "../../Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
let yup = require("yup");

let schema = yup.object().shape({
  text: yup.string().trim().required("Text is Required"),
  link: yup.string().url().required("Link is Required"),
});

const ChatHyperLinkModal = () => {
  const dispatch = useAppDispatch();
  const { show, sendLinkHandler } = useAppSelector(
    (state) => state.modal.modalProps
  );

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = (values: any) => {
    const formData = { ...values };
    dispatch(hideModal());
    sendLinkHandler(formData.text, formData.link);
  };

  return (
    <Modal
      title={"Send Link"}
      visible={show}
      onCancel={closeHandler}
      width={"30rem"}
      footer={null}
    >
      <div className="add-education-wrapper">
        <Formik
          initialValues={{
            text: "",
            link: "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="input-container mt-0">
                    <div className="form-label">Text</div>
                    <div className="floating-label">
                      <InputField
                        name="text"
                        placeholder="Text to send"
                        changeEvent={handleChange}
                        className="floating-input"
                        value={values.text}
                        blurEvent={handleBlur}
                      />
                      <div className="input-error spaced-error">
                        {errors.text && touched.text && errors.text}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-container mt-0">
                    <label className="form-label">Link</label>
                    <div className="floating-label">
                      <InputField
                        name="link"
                        placeholder="link"
                        changeEvent={handleChange}
                        className="floating-input"
                        value={values.link}
                        blurEvent={handleBlur}
                      />
                    </div>
                    <div className="input-error spaced-error">
                      {errors.link && touched.link && errors.link}
                    </div>
                  </div>
                </div>
                <div className="button-container text-end">
                  <span
                    className="button-cancel ms-2 cp"
                    onClick={closeHandler}
                  >
                    Cancel
                  </span>
                  <Button
                    primary={false}
                    buttonType="submit"
                    buttonText="Send"
                    className="m-2 button-save cp"
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ChatHyperLinkModal;
