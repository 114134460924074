import { AppointmentCallIcon } from "../../Common/Icons";
import ChatInput from "./ChatInput";
import ChatContent from "./ChatContent";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { computeRoomName } from "../../../helpers/chat";
import roles from "../../../constants/roles";
import zoomIcon from "../../../assets/img/zoom.svg";

const PatientChat = () => {
  const { userId } = useAppSelector((state) => state.console);
  const [roomName, setRoomName] = useState("");
  const [hideInput, setHideInput] = useState(true);

  useEffect(() => {
    if (userId) {
      const selfId = localStorage.getItem("userId") || "";
      const room = computeRoomName(userId, selfId);
      // const room = computeRoomName(
      //   "e986fe7d-c83c-402f-bde7-0213045eb75e",
      //   "8dbcab8f-16f1-4e62-ae9f-ae7ff1f9a3f5"
      // );
      setRoomName(room);
    }
  }, [userId, setRoomName]);

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === roles.Admin["be_value"]) {
      setHideInput(true);
    } else {
      setHideInput(false);
    }
  }, [setHideInput]);

  return userId && roomName ? (
    <div className="col-lg-5 col-12">
      <div className="shadow-s mb-5 bg-white rounded patient-chat-holder">
        <div className="d-flex justify-content-between align-items-center p-3">
          <h6 className="font-m fw-bold mb-0">Conversation</h6>
          <span>
            <img
              src={zoomIcon}
              style={{ width: "25px", height: "25px" }}
              className="cp"
            />
          </span>
        </div>
        <hr className="m-0" />
        <ChatContent roomName={roomName} />
        {!hideInput && <ChatInput roomName={roomName} />}
      </div>
    </div>
  ) : null;
};

export default React.memo(PatientChat);
