import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  name: string;
  profile: any;
  userInfo: any;
}

const initialState: UserState = {
  name: "User",
  profile: null,
  userInfo: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setProfileDetails: (state, action: PayloadAction<any>) => {
      state.profile = action.payload;
    },
    setUserInfo :  (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload;
    },
  },
});

export const { setUserName, setProfileDetails,setUserInfo } = userSlice.actions;

export default userSlice.reducer;
