import { useEffect } from "react";
import { fetchPatientChartData } from "../../../redux/actions/consoleAction";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setConsoleTime } from "../../../redux/reducers/consoleSlice";
import { TimeType } from "../../../types";

const ChartHeader = () => {
  const { type, time, userId } = useAppSelector((state) => state.console);
  const dispatch = useAppDispatch();
  const timeHandler = (value: TimeType) => {
    dispatch(setConsoleTime(value));
  };

  useEffect(() => {
    if (userId) dispatch(fetchPatientChartData());
  }, [dispatch, time, type, userId]);

  return (
    <div className="d-flex justify-content-between align-items-center p-3">
      <h6 className="m-0 font-m fw-bold">{type}</h6>
      {type !== "HbA1C" && (
        <ul className="filter-data d-flex m-0 p-0">
          <li
            onClick={() => timeHandler("week")}
            className={time === "week" ? "active" : ""}
          >
            Week
          </li>
          <li
            onClick={() => timeHandler("month")}
            className={time === "month" ? "active" : ""}
          >
            Month
          </li>
        </ul>
      )}
    </div>
  );
};

export default ChartHeader;
