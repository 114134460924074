import React from "react";
import { cards } from "./educationDataList";
import { useDrag, useDrop } from "react-dnd";

type Props = {
  section: any;
  index: number;
  handleDrop: Function;
  handleFilter: Function;
};

const SectionItem = (props: Props) => {
  const icon = cards.find((card) => props.section.name === card.name);

  const [, dragRef] = useDrag({
    item: {
      type: props.section.name,
      sort: true,
      origin: props.index,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });

  const [, drop] = useDrop({
    accept: cards.map((card) => card.name),
    drop: (dropItem: any) => {
      if (dropItem.sort) {
        props.handleDrop(dropItem, props.index);
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  return (
    <div ref={drop}>
      <div className="section-content m-2" ref={dragRef}>
        <div className="options-section ms-2 me-3"></div>
        <div
          className={
            icon!.classValue === "A-video"
              ? `video-section me-4`
              : `${icon!.classValue} me-4`
          }
        ></div>
        <div className="section-content-details">
          <div className="section-content-details-title ms-2">
            {`SECTION ${props.section.name}`}
          </div>
          {/* <div className="section-content-details-preview">
            {props.section.previewItem}
          </div> */}
        </div>
        <div
          className="cross-icon cp"
          onClick={() => props.handleFilter(props.section)}
        ></div>
        {/* <div
          className="edit-section cp ms-3"
          onClick={() => Router.push("/home/education/builder/edit")}
        ></div> */}
      </div>
    </div>
  );
};

export default SectionItem;
