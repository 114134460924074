import { useAppSelector } from "../../redux/hooks";
import DrawSVG from "react-svg-drawing";
// const DrawSVG = require("react-svg-drawing");

const Loader = () => {
  const { loader } = useAppSelector((state) => state.loader);

  if (loader)
    return (
      <div className="app-loader">
        {/* <span className="loading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span> */}
        <DrawSVG
          loop={true}
          delay="100"
          strokeWidth="0.5"
          easing="easeOutQuart"
          targets=".path"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="73"
            height="63"
            viewBox="0 0 73 63"
            fill="none"
            className="loader-animate"
          >
            <path
              d="M23.3896 49.112C28.2758 47.4496 40.6681 40.3217 46.1574 32.5912C53.0189 22.9281 59.626 18.3593 64.2468 17.6289C70.7775 16.5967 71.7321 28.8507 69.5489 33.8381C67.3657 38.8255 64.8706 41.6309 61.4399 43.1895"
              stroke="#FBA306"
              strokeWidth="3"
              className="path"
            />
            <path
              d="M22.1422 49.4244C15.9045 50.0479 4.05252 54.1 2.18131 43.1901C0.310111 32.2802 13.0975 0.663365 32.4344 2.04382C35.3454 2.25163 39.9198 2.97902 43.0386 7.96639C46.0186 12.7318 44.2863 19.4999 43.9743 23.8638C43.5731 29.4747 45.8457 31.0333 46.7814 32.2802"
              stroke="#1DD0F8"
              strokeWidth="3"
              className="path"
            />
            <path
              d="M20.7264 49.5033L21.7423 50.6069L23.9495 48.5751L22.9336 47.4715L20.7264 49.5033ZM27.7558 14.5105L27.8556 13.0139L27.8058 13.0105H27.7558V14.5105ZM35.2412 27.9142L36.7405 27.8687L36.7405 27.8687L35.2412 27.9142ZM45.8454 45.9937L46.227 44.543L46.167 44.5272L46.106 44.5165L45.8454 45.9937ZM22.9336 47.4715C21.4101 45.8165 20.2378 43.0324 19.5798 39.6078C18.9286 36.2189 18.8145 32.3736 19.2639 28.7618C19.7153 25.1334 20.7206 21.8577 22.2144 19.5349C23.6918 17.2375 25.5371 16.0105 27.7558 16.0105V13.0105C24.2046 13.0105 21.5275 15.0566 19.6911 17.9122C17.871 20.7424 16.771 24.4998 16.2868 28.3914C15.8005 32.2998 15.9203 36.4616 16.6337 40.1739C17.3402 43.8506 18.6631 47.2619 20.7264 49.5033L22.9336 47.4715ZM27.656 16.0072C29.1355 16.1058 30.3187 16.253 31.3302 17.4623C32.4402 18.7894 33.5505 21.6552 33.7419 27.9598L36.7405 27.8687C36.5444 21.4064 35.4154 17.6705 33.6313 15.5376C31.7488 13.2869 29.383 13.1157 27.8556 13.0139L27.656 16.0072ZM33.7419 27.9598C33.862 31.9143 33.8496 36.295 35.2652 39.8662C35.995 41.7073 37.1135 43.3772 38.8287 44.7115C40.5358 46.0394 42.752 46.9712 45.5849 47.4709L46.106 44.5165C43.6367 44.081 41.9073 43.3055 40.6707 42.3436C39.4423 41.388 38.6189 40.1854 38.0541 38.7607C36.8806 35.8004 36.8683 32.0765 36.7405 27.8687L33.7419 27.9598ZM45.4639 47.4443C46.7021 47.77 49.2469 47.91 52.0888 47.6018C54.9641 47.2901 58.3648 46.4977 61.2643 44.7931L59.7438 42.2069C57.3412 43.6194 54.4001 44.3336 51.7654 44.6193C49.0974 44.9086 46.964 44.7369 46.227 44.543L45.4639 47.4443Z"
              fill="#013765"
              strokeWidth="0"
              className="path"
            />
            <path
              d="M22.7659 49.4264C28.6918 57.5309 50.5239 65.6355 59.8805 57.8427C64.8707 52.8552 63.3113 44.7507 59.2567 41.0101C55.2022 37.2695 49.2763 35.711 46.7812 32.2821"
              stroke="#FF577A"
              strokeWidth="3"
              className="path"
            />
          </svg>
        </DrawSVG>
      </div>
    );
  else {
    return null;
  }
};

export default Loader;
