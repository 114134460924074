import { colorCodingDecoder, currentMetricDecoder } from "../../helpers/metric";
import { CellProperties, ChartTypes } from "../../types";

export const ColumnBlock: (props: {
  Icon: JSX.Element;
  columnHeader: React.ReactNode | string;
  bold?: boolean;
  clickHandler?: Function;
  type?: ChartTypes | null;
  activeType?: ChartTypes | null;
}) => JSX.Element = ({
  Icon,
  columnHeader,
  bold = true,
  clickHandler,
  type,
  activeType,
}) => {
  const classname = type
    ? type === activeType
      ? "graph-item active-graph"
      : "graph-item inactive-graph"
    : "";
  return (
    <div
      className={
        "client-table-header d-flex flex-column justify-content-evenly align-items-center " +
        classname
      }
      onClick={clickHandler && type ? () => clickHandler(type) : () => {}}
    >
      {Icon}
      <div className="text-center graph-text">
        {bold ? <p>{columnHeader}</p> : columnHeader}
      </div>
    </div>
  );
};

export const renderCell: (
  cellProperties: CellProperties,
  clickHandler: Function | null,
  type: ChartTypes
) => JSX.Element = (cellProperties, clickHandler, type) => {
  const currentClassName = colorCodingDecoder(cellProperties.currentIntensity);
  const imprintClassName = colorCodingDecoder(cellProperties.imprintIntensity);
  const currentValue = currentMetricDecoder(cellProperties.current, type);
  return (
    <div
      className="text-center td-wrapper"
      onClick={clickHandler && type ? () => clickHandler(type) : () => {}}
    >
      <div className={`mahalo-dashboard-cell-section ${currentClassName}`}>
        {currentValue}
      </div>
      <div className={`${imprintClassName} mahalo-dashboard-cell-section`}>
        {cellProperties.imprints + "/" + cellProperties.total}
      </div>
    </div>
  );
};
