import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import Navigation from "../../Navigation/Navigation";
import { useLocation } from "react-router-dom";
import RenderQuestions from "../RenderQuestions";
// import "../../../../pages/css/education/previewSection.scss";
import "react-quill/dist/quill.snow.css";
import ReactPlayer from "react-player";

import ReactQuill from "react-quill";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PreviewSection = (props: any) => {
  const queryParams = useQuery();
  const pageID = queryParams.get("pageId") || "12345";

  const { lessonDetails } = props;
  const [pages, setPages] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(
    lessonDetails.hasOwnProperty("pages") ? lessonDetails.pages.length : 0
  );
  useEffect(() => {
    let newPages = lessonDetails.hasOwnProperty("pages")
      ? [...lessonDetails.pages]
      : [];
    newPages.sort(function (a: any, b: any) {
      return a.position - b.position;
    });
    newPages = newPages.map(function (item: any) {
      if (item.sections && item.sections.length > 0) {
        const newSections = [...item.sections];
        newSections.sort(function (a: any, b: any) {
          return a.position - b.position;
        });
        return {
          ...item,
          sections: newSections,
        };
      } else {
        return item;
      }
    });
    if (pageID !== "12345") {
      const desiredPage = newPages.find((page: any) => page.id === pageID);
      if (desiredPage && desiredPage.position) {
        setCurrentPage(desiredPage.position);
      }
      if (props.sectionsArray.length > 0) {
        newPages = newPages.map((page: any) =>
          page.id === pageID ? props.sectionsArray[0] : page
        );
      }
    } else if (props.sectionsArray) {
      newPages.push(...props.sectionsArray);
      setTotal((prevTotal: number) => prevTotal + props.sectionsArray.length);
      setCurrentPage(newPages.length);
    }
    setPages(newPages);
  }, [
    pageID,
    setCurrentPage,
    setPages,
    props.sectionsArray,
    setTotal,
    lessonDetails,
  ]);

  const switchForm = (item: any) => {
    switch (item.type) {
      case "title":
        return (
          <div className="section-header px-3" key={item.id}>
            <h2>{item.value}</h2>
          </div>
        );
      case "subtitle":
        return (
          <div className="section-header" key={item.id}>
            <div className="section-header-subtitle">{item.value}</div>
          </div>
        );
      case "picture":
        const imgSrc = item.value
          ? item.value.replace(
              "https://storage.cloud.google.com/",
              "https://storage.googleapis.com/"
            )
          : item.value;
        return (
          <div className="section-photo" key={item.id}>
            <img src={imgSrc} alt="section" />
          </div>
        );
      case "video":
        return (
          <div className="section-video-new" key={item.id}>
            <ReactPlayer
              className="education-video"
              controls
              loop
              height="40vh"
              width="100%"
              url={item.value}
            />
          </div>
        );
      case "question":
        return (
          <div className="section-question" key={item.id}>
            <RenderQuestions questions={item.questions} />
          </div>
        );
      case "text":
        return (
          <div className="section-description" key={item.id}>
            <div className="description-body">
              <ReactQuill value={item.value} readOnly={true} theme={"bubble"} />
            </div>
          </div>
        );
      case "resource":
        return (
          <div className="section-resources" key={item.id}>
            <div>
              {item.value.match(/([0|\+[0-9]{1,5})?([7-9][0-9]{9})/) ? (
                <a href={"tel:" + item.value}>{item.label}</a>
              ) : (
                <a href={item.value} target="_blank">
                  {item.label}
                </a>
              )}
              <br />
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const itemRender = (_: any, type: any, originalElement: any) => {
    if (type === "prev") {
      return <a>PREV</a>;
    }
    if (type === "next") {
      if (currentPage === total) {
        return <a className="add-web-finish-line">FINISH</a>;
      } else {
        return <a>NEXT</a>;
      }
    }
    return originalElement;
  };

  return (
    <>
      {/* <h1 className="fw-bold font-l mb-5">
        <span
          className="arrow-left-icon me-2 cp"
          onClick={() => history.goBack()}
        ></span>
        {`${lessonDetails.name} lesson preview`}
      </h1> */}

      <div className="preview-section-wrapper">
        {/* <Navigation /> */}
        <Navigation
          title={`${lessonDetails.name} lesson preview`}
          back={true}
        />
        <div className="content-height ">
          {/* main-container */}
          <div className="preview-section-container">
            <div className="preview-header">
              <div className="preview-header-lesson">
                {`${lessonDetails.name}`.toUpperCase()}
              </div>
              <div className="preview-header-pages text-center">{`PAGE ${currentPage} OF ${total}`}</div>
            </div>
            {pages.length > 0 &&
              pages[currentPage - 1].sections &&
              pages[currentPage - 1].sections.map((section: any) => {
                return switchForm(section);
              })}
          </div>

          {/* pagination */}
          <div className="preview-section-pagination">
            <Pagination
              total={total}
              pageSize={1}
              current={currentPage}
              onChange={(pageNo) => {
                setCurrentPage(pageNo);
                if (typeof window !== "undefined") {
                  window.scrollTo(0, 0);
                }
              }}
              itemRender={itemRender}
              showSizeChanger={false}
            />
            <span>{`Page ${currentPage} of ${total}`}</span>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  lessonDetails: state.lessons.lessonDetails,
  sectionsArray: state.lessons.lessons,
  editPageSections: state.lessons.editPageSections,
});

export default connect(mapStateToProps)(PreviewSection);
