import { Input } from "antd";

type Props = {
  placeholder?: string;
  value?: string | number;
  onChange: (value: string) => void;
  name?: string;
};

const SearchInput = (props: Props) => {
  const { placeholder, name, onChange } = props;

  return (
    <Input.Search
      className="search-input"
      placeholder={placeholder}
      name={name}
      onSearch={(value: string) => onChange(value)}
      enterButton={false}
    />
  );
};

export default SearchInput;
