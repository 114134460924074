import { Dispatch } from "redux";
import { toastMessage, errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import history from "../../history";
import {
  questionResponseFormat,
  questionPreviewFormat,
} from "../../helpers/question";
import * as types from "../actionTypes";
import { setAppLoader } from "../reducers/loaderSlice";

export const setQuestionnaireScroll =
  (questions: any, count: any) => (dispatch: Dispatch) => {
    dispatch({
      type: types.INFINITE_GET_QUESTIONS,
      payload: {
        questions: questions,
        count: count,
      },
    });
  };

export const getQuestionList =
  (
    pageNo: number,
    status?: string,
    scroll?: string | boolean,
    pageSize?: number
  ) =>
  (dispatch: Dispatch) => {
    dispatch(setAppLoader(true));
    pageSize = pageSize !== undefined ? pageSize : 12;
    const url = `/questionnaires?page=${pageNo}&size=${pageSize}`;
    const extraParam = status === undefined ? url : `${url}&status=${status}`;

    http
      .get(extraParam)
      .then((res) => {
        if (scroll) {
          dispatch({
            type: types.INFINITE_GET_QUESTIONS,
            payload: {
              questions: res.data.data.questionnaires,
              count: res.data.data.count,
            },
          });
        } else {
          dispatch({
            type: types.GET_QUESTIONS,
            payload: {
              questions: res.data.data.questionnaires,
              count: res.data.data.count,
            },
          });
        }
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const getProjectQuestionsList =
  (pageNo?: number, projectId?: any, index?: any) =>
  (dispatch: Dispatch, getState: any) => {
    const pageSize = 12;
    dispatch(setAppLoader(true));
    http
      .get(
        `/questionnaires?page=${pageNo}&size=${pageSize}&status=active&projectId=${projectId}`
      )
      .then((res) => {
        let projectQues = getState().question.questionsProjects;
        let found = projectQues.some((item: any) => item.idx === index);

        let finalPayload = found
          ? projectQues.map((item: any) => {
              return item.idx === index
                ? { idx: index, ques: res.data.data.questionnaires }
                : item;
            })
          : [
              ...projectQues,
              { idx: index, ques: res.data.data.questionnaires },
            ];

        dispatch({
          type: types.GET_QUESTIONS_LIST_PROJECTS,
          payload: {
            questions: finalPayload,
            count: res.data.data.count,
          },
        });
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const updateProjectsQuestions =
  (projQues: any) => (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: types.GET_QUESTIONS_LIST_PROJECTS,
      payload: {
        questions: projQues,
        count: getState().question.qpCount,
      },
    });
  };

export const getTotalQuestionsList = () => (dispatch: Dispatch) => {
  dispatch(setAppLoader(true));
  http
    .get(`/questionnaires`)
    .then((res) => {
      dispatch({
        type: types.GET_QUESTIONS,
        payload: {
          questions: res.data.data.questionnaires,
          count: res.data.data.count,
        },
      });
      dispatch(setAppLoader(false));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setAppLoader(false));
    });
};

export const searchQuestionModules =
  (searchItem: string, status?: string) => (dispatch: Dispatch) => {
    const pageNo = 1;
    const pageSize = 10;
    dispatch(setAppLoader(true));
    const url = `/questionnaires?page=${pageNo}&size=${pageSize}&search=${searchItem}`;
    const extraParam = status === undefined ? url : `${url}&status=${status}`;
    http
      .get(extraParam)
      .then((res) => {
        dispatch({
          type: types.GET_QUESTIONS,
          payload: {
            questions: res.data.data.questionnaires,
            count: res.data.data.count,
          },
        });
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const addQuestionModule =
  (body: any, hide: Function) => (dispatch: Dispatch) => {
    dispatch(setAppLoader(true));
    for (let key in body) {
      if (body[key] === "" || body[key] === undefined || body[key] === null)
        delete body[key];
    }
    if (body.imageUrl === "") delete body.imageUrl;
    http
      .post("/questionnaires", body)
      .then((res) => {
        const { id, name } = res.data.data;
        toastMessage("success", res.data.message);
        hide();
        dispatch(setBuilderQuestions([], ""));
        dispatch(setAppLoader(false));
        history.push(`/question/builder?id=${id}&name=${name}&view=canCreate`);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const updateQuestionModule =
  (body: any, id: string, hide: Function) => (dispatch: Dispatch) => {
    dispatch(setAppLoader(true));
    for (let key in body) {
      if (body[key] === "" || body[key] === undefined || body[key] === null)
        delete body[key];
    }
    if (body.imageUrl === "") delete body.imageUrl;
    console.log(body);

    http
      .put(`/questionnaires/${id}`, body)
      .then((res) => {
        console.log(res);
        toastMessage("success", res.data.message);
        hide();
        dispatch(setAppLoader(false));
        getQuestionList(1)(dispatch);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };
export const updateQuestion =
  (body: any, id: string) => (dispatch: Dispatch) => {
    dispatch(setAppLoader(true));
    for (let key in body) {
      if (body[key] === "" || body[key] === undefined || body[key] === null)
        delete body[key];
    }
    if (body.imageUrl === "") delete body.imageUrl;
    console.log(body);

    http
      .put(`/questionnaires/${id}`, body)
      .then((res) => {
        console.log(res);
        toastMessage("success", res.data.message);
        getQuestionList(1)(dispatch);
        history.push("/question");
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const deleteQuestionModule = (id: string) => (dispatch: Dispatch) => {
  dispatch(setAppLoader(true));
  http
    .delete(`/questionnaires/${id}`)
    .then((res) => {
      toastMessage("success", res.data.message);
      getQuestionList(1)(dispatch);
    })
    .catch((err) => {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    });
};

export const createQuestionBuilder =
  (formObject: any, setter?: Function) => () => {
    http
      .post(`/questionnaires/${formObject.id}/build`, formObject)
      .then(({ data }) => {
        if (setter) {
          setter(true);
        }
        toastMessage("success", data.message);
        if (formObject.status === "active") {
          history.push("/question");
        }
      })
      .catch((err) => {
        errorToastMessage(err as Error);
      });
  };

/** Get all questions for a questionnaire */

export const getBuilderQuestions = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      let { data } = await http.get(`/questionnaires/${id}/questions`);
      let questions = await questionResponseFormat(
        data.data.questions,
        data.data.logicJumps
      );
      console.log("getBuilderQuestionsEx", questions);
      dispatch(setBuilderQuestions(questions, data.data.status));
    } catch (err) {
      console.log(err);
      dispatch(setBuilderQuestions());
    }
  };
};

export const getPreviewQuestions = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setAppLoader(true));
      let { data } = await http.get(`/questionnaires/${id}/questions`);
      let questions = await questionPreviewFormat(data.data.questions);
      dispatch(setPreviewQuestions(questions, data.data.logicJumps));
      dispatch(setAppLoader(false));
    } catch (err) {
      console.log(err);
      dispatch(setPreviewQuestions());
      dispatch(setAppLoader(false));
    }
  };
};

export const getQuestionsFilter =
  (id: string, index?: any) => (dispatch: Dispatch, getState: any) => {
    dispatch(setAppLoader(true));
    http
      .get(`/questionnaires/${id}/questions`)
      .then((res) => {
        let qFilter = getState().question.questionsFilter;
        let found = qFilter.some((item: any) => item.idx === index);

        if (res.data.data.questions[0].type === "group") {
          if (
            res.data.data.questions[0].properties.questions[0].properties.hasOwnProperty(
              "responseCodes"
            )
          ) {
            let finalPayload = found
              ? qFilter.map((item: any) => {
                  return item.idx === index
                    ? { idx: index, ...res.data.data.questions[0].properties }
                    : item;
                })
              : [
                  ...qFilter,
                  { idx: index, ...res.data.data.questions[0].properties },
                ];

            dispatch({
              type: types.GET_QUESTIONS_FILTER,
              payload: finalPayload,
            });
          } else {
            toastMessage(
              "warning",
              "No response codes available for this question group"
            );
          }
        } else {
          let finalPayload = found
            ? qFilter.map((item: any) => {
                return item.idx === index
                  ? { idx: index, ...res.data.data }
                  : item;
              })
            : [...qFilter, { idx: index, ...res.data.data }];

          dispatch({
            type: types.GET_QUESTIONS_FILTER,
            payload: finalPayload,
          });
        }
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAppLoader(false));
      });
  };

export const resetQuestionsFilter = () => (dispatch: Dispatch) => {
  dispatch({
    type: types.RESET_QUESTIONS_FILTER,
    payload: [],
  });
};

export const updateQuestionsFilter =
  (questionsFilter: any) => (dispatch: Dispatch) => {
    dispatch({
      type: types.GET_QUESTIONS_FILTER,
      payload: questionsFilter,
    });
  };

export const questionnaireDuplicate =
  (id: string) => (dispatch: Dispatch<any>) => {
    dispatch(setAppLoader(true));
    http
      .post(`/questionnaires/${id}/duplicate`)
      .then((res) => {
        toastMessage("success", res.data.message);
        getQuestionList(1)(dispatch);
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        console.log(err);
      });
  };

export const setBuilderQuestions = (questions: any[] = [], status?: any) => {
  return {
    type: types.GET_BUILDER_QUESTIONS,
    payload: { questions },
    status: status,
  };
};

export const setPreviewQuestions = (questions: any[] = [], logicJumps = []) => {
  return {
    type: types.SET_PREVIEW_QUESTIONS,
    payload: { questions, logicJumps },
  };
};

export const setQuestionnaire = (questions: any[] = [], count = 0) => ({
  type: "SET_QUESTIONNAIRE",
  payload: { questions, count },
});

export const activateDeactivateQuestionnaire =
  (questionId: any, body: any) => (dispatch: Dispatch) => {
    dispatch(setAppLoader(true));
    http
      .put(`/questionnaires/${questionId}`, body)
      .then((res) => {
        console.log(res.data);
        getQuestionList(1)(dispatch);
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err);
      });
  };

export const beforeLockBuilder =
  (formObject: any, qid: any, body: any) => (dispatch: Dispatch<any>) => {
    dispatch(setAppLoader(true));
    http
      .post(`/questionnaires/${formObject.id}/build`, formObject)
      .then(({ data }) => {
        console.log(data);
        dispatch(activateLockedState(qid, body));
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const activateLockedState =
  (qid: any, body: any) => (dispatch: Dispatch<any>) => {
    dispatch(setAppLoader(true));
    http
      .put(`/questionnaires/${qid}`, body)
      .then((res) => {
        toastMessage("success", res.data.message);
        console.log(res.data, "activateLockedState");
        dispatch(getBuilderQuestions(qid));
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const getQuestionnaireInfo = (qId: any) => (dispatch: Dispatch<any>) => {
  dispatch(setAppLoader(true));
  http
    .get(`/questionnaires/${qId}`)
    .then((res) => {
      dispatch({
        type: types.GET_QUESTIONNAIRE_INFO,
        payload: res.data.data,
      });
      dispatch(setAppLoader(false));
    })
    .catch((err) => {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    });
};
