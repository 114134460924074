import { Avatar, Modal, Table } from "antd";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { getImageInitials } from "../../../helpers/user";
import http from "../../../http";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { hideModal, setModalDetails } from "../../../redux/reducers/modalSlice";

const QuestionnaireResponseList = () => {
  const dispatch = useAppDispatch();
  const { questionnaire, show } = useAppSelector(
    (state) => state.modal.modalProps
  );

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const loadResponse = useCallback(
    async (id: any) => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.get(`questionnaires/response/${id}`);
        const responses = res.data.data;
        dispatch(
          setModalDetails({
            type: "PATIENT_QUESTIONNAIRE_RESPONSE",
            modalProps: {
              responses: responses,
              show: true,
            },
          })
        );
        dispatch(setAppLoader(false));
      } catch (error) {
        dispatch(setAppLoader(false));
        errorToastMessage(error);
      }
    },
    [dispatch]
  );

  const columns = useMemo(() => {
    return [
      {
        title: "Questionnaire Name",
        render: (_1: any, record: any) => {
          const name = record.questionnaire.name;
          return <span>{name}</span>;
        },
      },
      {
        title: "Completed Date",
        dataIndex: "completedAt",
        key: "completedAt",
        render: (date: any) => {
          return <span>{moment(date).format("DD MMMM, YYYY")}</span>;
        },
      },
      {
        title: "View Response",
        render: (_1: any, record: any) => {
          return (
            <div
              onClick={() => loadResponse(record.id)}
              className="color-primary cp"
            >
              View
            </div>
          );
        },
      },
    ];
  }, [loadResponse]);

  return (
    <Modal
      title={"Completed Questionnaires"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
      className="participant-view-modal"
    >
      <Table
        dataSource={questionnaire}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
};

export default QuestionnaireResponseList;
