import React, { useState } from "react";
import { Modal, Upload, Tooltip, Input } from "antd";
import { Formik } from "formik";
import InputField from "../../Common/CustomInput";
import { toastMessage } from "../../../helpers/toastMessage";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { UploadImage } from "../../../helpers/imageUpload";
import Button from "../../Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  addExerciseDiary,
  UpdateExerciseDiary,
} from "../../../redux/actions/diaryAction";
import ReactQuill from "react-quill";
import {
  quillFormats,
  quillModules,
} from "../../../constants/reactQuillOption";

let yup = require("yup");

let schema = yup.object().shape({
  title: yup.string().trim().required("Name is Required"),
  description: yup.string().trim().required("Description is Required"),
  details: yup.string().trim().required("Details is Required"),
  imageUrl: yup.string().nullable().optional(),
});

const AddExerciseDiaryModal = (props: any) => {
  const dispatch = useAppDispatch();
  const { exerciseData, show } = useAppSelector(
    (state) => state.modal.modalProps
  );
  const [imgData, setImgData] = useState({
    expiresAt: "",
    fileName: "",
    postUploadImageUrl: "",
    presignedUploadUrl: "",
    type: "",
  });

  const initialValues = props.exerciseData
    ? {
        title: props.exerciseData.title,
        description: props.exerciseData.description,
        imageUrl: props.exerciseData.imageUrl,
        details: props.exerciseData.details || "",
      }
    : {
        title: "",
        description: "",
        imageUrl: "",
        details: "",
      };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      toastMessage("warning", "Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleUpload = (data: any) => {
    setImgData(data[0]);
  };

  const handleAvater = (fileObj: any) => {
    UploadImage(fileObj, handleUpload, "education_lesson_image")();
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = (values: any) => {
    const formData = { ...values };
    formData.imageUrl =
      imgData.postUploadImageUrl !== ""
        ? imgData.postUploadImageUrl
        : formData.imageUrl;
    if (formData.imageUrl === "" || !formData.imageUrl) {
      delete formData.imageUrl;
    }
    if (exerciseData) {
      dispatch(UpdateExerciseDiary(formData, exerciseData.id));
    } else {
      dispatch(addExerciseDiary(formData));
    }
  };

  return (
    <Modal
      title={exerciseData ? `Edit ${exerciseData.title}` : "Add new Exercise"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="add-education-wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              <div className="row">
                <div className="col-md-7">
                  <div className="input-container mt-0">
                    <div className="form-label">Title</div>
                    <div className="floating-label">
                      <InputField
                        name="title"
                        placeholder=""
                        changeEvent={handleChange}
                        className="floating-input"
                        value={values.title}
                        blurEvent={handleBlur}
                      />
                      <div className="input-error spaced-error">
                        {errors.title && touched.title && errors.title}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="input-container mt-0">
                    <label className="form-label">Description</label>
                    <Tooltip placement="right" title={values.description}>
                      <div className="floating-label">
                        <InputField
                          name="description"
                          placeholder=""
                          changeEvent={handleChange}
                          className="floating-input"
                          value={values.description}
                          blurEvent={handleBlur}
                        />
                      </div>
                    </Tooltip>
                    <div className="input-error spaced-error">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-container mt-0">
                    <label className="form-label">Details</label>
                    <ReactQuill
                      modules={quillModules}
                      formats={quillFormats}
                      placeholder="Type something"
                      value={values.details}
                      onChange={(event) => setFieldValue("details", event)}
                    />
                    <div className="input-error spaced-error">
                      {errors.details && touched.details && errors.details}
                    </div>
                  </div>
                </div>
                <div className="col-md-8 image-row form-group">
                  <label htmlFor="lastname">Image</label>
                  <div className="row">
                    <div className="col-md-5">
                      {imgData.postUploadImageUrl !== "" ? (
                        <div className="image-container">
                          <img src={imgData.postUploadImageUrl} alt="module" />
                        </div>
                      ) : values.imageUrl !== null && values.imageUrl !== "" ? (
                        <>
                          <div className="image-container">
                            <img src={values.imageUrl} alt="module" />
                          </div>
                        </>
                      ) : (
                        <div className="image-wrapper">
                          <div className="placeholder-icon"></div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-7 ps-0 upload-wrapper">
                      <Input
                        disabled
                        placeholder={
                          imgData.fileName === ""
                            ? "No file selected"
                            : imgData.fileName
                        }
                      />
                      <Upload
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        customRequest={handleAvater}
                        accept=".gif,.jpg,.jpeg,.png"
                      >
                        <button type="button" className="upload-button">
                          Upload File
                        </button>
                      </Upload>
                    </div>
                  </div>
                  <div className="mt-3">
                    Upload an image of max 5 mb, accepted formats are
                    .jpg,.jpeg,.png{" "}
                  </div>
                </div>

                <div className="button-container text-end">
                  <span
                    className="button-cancel ms-2 cp"
                    onClick={closeHandler}
                  >
                    Cancel
                  </span>
                  {/* <button className="draft m-2">Save Draft</button> */}
                  <Button
                    primary={false}
                    buttonType="submit"
                    buttonText="Save"
                    className="m-2 button-save cp"
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddExerciseDiaryModal;
