import React from "react";
import { useDrag } from "react-dnd";

type Props = {
  choice: {
    key: string;
    icon: string;
    type: string;
    title: string;
  };
};

const ChoiceItem = (props: Props) => {
  const { choice } = props;

  const [, dragRef] = useDrag({
    item: {
      type: props.choice.type,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });

  return (
    <div className="col-12  grid-choice">
      <div ref={dragRef} className="choice-item">
        <i className={choice.icon}></i>
        <span>{choice.title}</span>
      </div>
    </div>
  );
};

export default ChoiceItem;
