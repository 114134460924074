import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { useAppDispatch } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";

const PatientNotes = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { patientId } = useParams<any>();
  const viewDocumentsHandler = async () => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.get(`/documents/ref/patient/${patientId}`);
      const folder = res.data.data.folderId;
      history.push(`/documents/${folder}`);
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

  return (
    <div className="col-lg-3">
      <div id="belowTable1" className="">
        <div className="shadow-s p-3 mb-4 bg-white rounded">
          <h6 className="mb-3 font-m fw-bold ">Data Assistant</h6>
          <div className="card p-3 mb-3">
            <div className="">
              <p style={{ color: "#EB5757" }} className="fw-600">
                <i className="run-red-icon"></i>User not active
              </p>
              <p className="m-0">
                Please note that the user has not been active for more than two
                weeks
              </p>
            </div>
          </div>
          <div className="card p-3 mb-1">
            <div className="">
              <p style={{ color: "#00B9FC" }} className="fw-600">
                <i className="pill-icon ms-2"></i>Medication taken
              </p>
              <p className="m-0">
                User has taken the morning dosages of medication
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="belowTable1" className="">
        <div className="shadow-s p-3 mb-4 bg-white rounded">
          <h6 className="mb-3 fw-bold font-m">Patient Notes</h6>
          <div className="card p-3">
            <div className="">
              <p>
                <h6 className="fw-600 font-sm">medication notes</h6>
                26 July 2021
              </p>
              <p>User has taken the morning dosages of medication</p>
              <button
                style={{
                  float: "right",
                  background: "none",
                  color: "#00B9FC",
                  border: "none",
                }}
              >
                + Add New Note
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="belowTable1" className="">
        <div className="shadow-s p-3 mb-5 bg-white rounded">
          <h6 className="fw-bold font-m">Documents</h6>
          <hr />
          <div className="">
            <div className="d-flex align-items-center">
              <div className="d-flex">
                <img
                  src="https://picsum.photos/50/40"
                  className="rounded-corner profile-m"
                />
                <img
                  src="https://picsum.photos/50/40"
                  className="rounded-corner profile-m"
                  style={{
                    top: "20px",
                    left: "-27px",
                    position: "relative",
                  }}
                />
              </div>
              <div>
                <p className="fw-bold font-m">Upload or view consent forms</p>
                <button
                  className="btn-outline-primary"
                  onClick={viewDocumentsHandler}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientNotes;
