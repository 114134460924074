import React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Radio } from "antd";
import PasswordInput from "../Common/PasswordInput";
import CustomButton from "../Common/CustomButton";
import AuthLayout from "../AuthLayout/AuthLayout";
import roles from "../../constants/roles";
import CustomInput from "../Common/CustomInput";
import { useAppDispatch } from "../../redux/hooks";
import { loginForm } from "../../types";
import { loginAction } from "../../redux/actions/userAction";
import { errorToastMessage } from "../../helpers/toastMessage";
import { Row, Col } from "antd";
import logo from "../../assets/img/logo-singer.svg";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password is Required"),
});

const Login: React.FC = () => {
  const dispatch = useAppDispatch();

  const loginHandler = async (values: loginForm, setSubmitting: Function) => {
    try {
      setSubmitting(true);
      await dispatch(loginAction(values));
    } catch (err) {
      setSubmitting(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <div className="d-block auth-layout">
      <Row className="h-100">
        <Col sm={24} md={12}>
          <div className="auth-left-container d-flex align-items-center justify-content-center h-100">
            <div className="flex-grow-1">
              <img
                src={logo}
                alt="SINGER-logo"
                width="200px"
                className="mb-5"
              />
              <div className="px-md-4">
                <div className="font-l">Welcome back!</div>
                <div className="font-l fw-bold mb-5">Please Login...</div>
                <Formik
                  initialValues={{
                    role: roles.Therapist["be_value"],
                    email: "",
                    password: "",
                  }}
                  validationSchema={schema}
                  onSubmit={(values: loginForm, { setSubmitting }) => {
                    loginHandler(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="input-container mb-4">
                          <div className="floating-label">
                            <CustomInput
                              name="email"
                              placeholder="Your Email Address"
                              value={values.email}
                              changeEvent={handleChange}
                              blurEvent={handleBlur}
                            />
                            <div className="input-error text-left">
                              {touched.email && errors.email}
                            </div>
                          </div>
                        </div>
                        <div className="input-container">
                          <div className="floating-label input-password">
                            <PasswordInput
                              name="password"
                              placeHolder="Enter Your Password"
                              value={values.password}
                              changeEvent={handleChange}
                              blurEvent={handleBlur}
                            />
                            <div className="input-error text-left">
                              {touched.password && errors.password}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mt-1 mb-3">
                          <a
                            href="/auth/forgot-password"
                            className="link-primary font-m"
                          >
                            Forgot Password?
                          </a>
                        </div>
                        <div className="role-selector role-selector-custom">
                          <Radio.Group
                            onChange={handleChange}
                            value={values.role}
                            name="role"
                            className="d-flex justify-content-between"
                          >
                            {Object.entries(roles).map(([key, value]) => {
                              if (value.be_value === "patient") {
                                return null;
                              }
                              return (
                                <Radio.Button
                                  value={value.be_value}
                                  className="role-selector-btn"
                                  key={value.be_value}
                                >
                                  {value.fe_value}
                                </Radio.Button>
                              );
                            })}
                          </Radio.Group>
                        </div>
                        <div className="bottom-pos-btn-container mt-4">
                          <CustomButton
                            buttonType="submit"
                            buttonText="Login"
                            className="login-button btn-block"
                            loading={isSubmitting}
                            primary={true}
                          />
                        </div>
                        {/* <h6 className="text-center mt-4">
                          Don’t have an account?
                          <a
                            href="/auth/register"
                            className="fw-semibold link-primary ps-1 "
                          >
                            Signup
                          </a>
                        </h6> */}
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={12} className="bg-primary-blue p-3 p-lg-4">
          <AuthLayout
            headerText="Know your health better!"
            paragraphText="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
          />
        </Col>
      </Row>
    </div>
  );
};

export default Login;
