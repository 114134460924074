const WeightIcon = ({ width, height }: { width: string; height: string }) => (
  <svg
    width={width || "24"}
    height={height || "24"}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21 6H3C1.9 6 1 6.9 1 8V16C1 17.1 1.9 18 3 18H21C22.1 18 23 17.1 23 16V8C23 6.9 22.1 6 21 6ZM20 16H4C3.45 16 3 15.55 3 15V9C3 8.45 3.45 8 4 8H5V11C5 11.55 5.45 12 6 12C6.55 12 7 11.55 7 11V8H9V11C9 11.55 9.45 12 10 12C10.55 12 11 11.55 11 11V8H13V11C13 11.55 13.45 12 14 12C14.55 12 15 11.55 15 11V8H17V11C17 11.55 17.45 12 18 12C18.55 12 19 11.55 19 11V8H20C20.55 8 21 8.45 21 9V15C21 15.55 20.55 16 20 16Z"
      fill="#333333"
      className="fill-path"
    />
  </svg>
);
export default WeightIcon;
