export const LogoSidebar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="30"
    viewBox="0 0 39 30"
    fill="none"
  >
    <path
      d="M12.3369 22.8608C14.9267 22.0894 21.4947 18.782 24.4041 15.1949C28.0408 10.711 31.5426 8.59104 33.9917 8.25213C37.453 7.77314 37.959 13.4592 36.8019 15.7735C35.6448 18.0877 34.3223 19.3894 32.504 20.1127"
      stroke="#FBA306"
      strokeWidth="2"
    />
    <path
      d="M11.6756 23.0058C8.36953 23.2951 2.08786 25.1753 1.0961 20.1129C0.10434 15.0505 6.88179 0.379778 17.1306 1.02033C18.6734 1.11676 21.0979 1.45428 22.7509 3.76851C24.3303 5.97972 23.4122 9.12025 23.2468 11.1452C23.0342 13.7488 24.2387 14.472 24.7346 15.0505"
      stroke="#1DD0F8"
      strokeWidth="2"
    />
    <path
      d="M10.8212 23.2961L11.5458 23.9853L12.9241 22.5361L12.1995 21.8469L10.8212 23.2961ZM14.6511 6.80566L14.7094 5.80736L14.6803 5.80566H14.6511V6.80566ZM18.6185 13.0252L17.6191 13.0599V13.0599L18.6185 13.0252ZM24.2388 21.4144L24.4632 20.4399L24.4276 20.4317L24.3914 20.4261L24.2388 21.4144ZM12.1995 21.8469C11.4493 21.1334 10.8527 19.9125 10.5138 18.3682C10.1789 16.8426 10.1195 15.1053 10.3514 13.4733C10.5846 11.8322 11.1012 10.3739 11.8514 9.35258C12.5833 8.3561 13.5054 7.80566 14.6511 7.80566V5.80566C12.7387 5.80566 11.2639 6.77396 10.2395 8.16858C9.23329 9.53838 8.634 11.3435 8.37131 13.1919C8.10733 15.0494 8.17189 17.0275 8.56025 18.797C8.94452 20.5478 9.67037 22.2016 10.8212 23.2961L12.1995 21.8469ZM14.5929 7.80397C15.3947 7.85076 15.9607 7.92595 16.4323 8.4196C16.94 8.95093 17.5189 10.171 17.6191 13.0599L19.6179 12.9906C19.5126 9.95536 18.9047 8.11217 17.8784 7.038C16.8162 5.92613 15.5012 5.85357 14.7094 5.80736L14.5929 7.80397ZM17.6191 13.0599C17.6815 14.8602 17.6714 16.9871 18.4593 18.7271C19.3154 20.618 21.0231 21.9297 24.0862 22.4027L24.3914 20.4261C21.8342 20.0312 20.7974 19.0421 20.2812 17.9022C19.6968 16.6115 19.6868 14.9777 19.6179 12.9906L17.6191 13.0599ZM24.0144 22.3889C24.686 22.5435 26.046 22.6076 27.5567 22.4642C29.0849 22.3191 30.9047 21.9498 32.4657 21.1464L31.5504 19.3681C30.3011 20.0111 28.7598 20.341 27.3677 20.4731C25.958 20.6069 24.8385 20.5263 24.4632 20.4399L24.0144 22.3889Z"
      fill="#013765"
    />
    <path
      d="M12.0059 23.006C15.1466 26.7667 26.7179 30.5274 31.677 26.9113C34.3219 24.5971 33.4954 20.8364 31.3464 19.1007C29.1975 17.365 26.0567 16.6418 24.7343 15.0508"
      stroke="#FF577A"
      strokeWidth="2"
    />
  </svg>
);

export const Dashboard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.99961 18.9997V13.9997H13.9996V18.9997C13.9996 19.5497 14.4496 19.9997 14.9996 19.9997H17.9996C18.5496 19.9997 18.9996 19.5497 18.9996 18.9997V11.9997H20.6996C21.1596 11.9997 21.3796 11.4297 21.0296 11.1297L12.6696 3.59973C12.2896 3.25973 11.7096 3.25973 11.3296 3.59973L2.96961 11.1297C2.62961 11.4297 2.83961 11.9997 3.29961 11.9997H4.99961V18.9997C4.99961 19.5497 5.44961 19.9997 5.99961 19.9997H8.99961C9.54961 19.9997 9.99961 19.5497 9.99961 18.9997Z"
      fill="#BDBDBD"
    />
  </svg>
);
export const DashboardActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.99961 18.9997V13.9997H13.9996V18.9997C13.9996 19.5497 14.4496 19.9997 14.9996 19.9997H17.9996C18.5496 19.9997 18.9996 19.5497 18.9996 18.9997V11.9997H20.6996C21.1596 11.9997 21.3796 11.4297 21.0296 11.1297L12.6696 3.59973C12.2896 3.25973 11.7096 3.25973 11.3296 3.59973L2.96961 11.1297C2.62961 11.4297 2.83961 11.9997 3.29961 11.9997H4.99961V18.9997C4.99961 19.5497 5.44961 19.9997 5.99961 19.9997H8.99961C9.54961 19.9997 9.99961 19.5497 9.99961 18.9997Z"
      fill="white"
    />
  </svg>
);

export const Participants = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6699 9.12988C17.0399 10.0599 17.9999 11.3199 17.9999 12.9999V15.9999H20.9999C21.5499 15.9999 21.9999 15.5499 21.9999 14.9999V12.9999C21.9999 10.8199 18.4299 9.52988 15.6699 9.12988Z"
      fill="#BDBDBD"
    />
    <path
      d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z"
      fill="#BDBDBD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9999 8C16.2099 8 17.9999 6.21 17.9999 4C17.9999 1.79 16.2099 0 13.9999 0C13.5299 0 13.0899 0.0999998 12.6699 0.24C13.4999 1.27 13.9999 2.58 13.9999 4C13.9999 5.42 13.4999 6.73 12.6699 7.76C13.0899 7.9 13.5299 8 13.9999 8Z"
      fill="#BDBDBD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9C5.33 9 0 10.34 0 13V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V13C16 10.34 10.67 9 8 9Z"
      fill="#BDBDBD"
    />
  </svg>
);
export const ParticipantsActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6699 9.12988C17.0399 10.0599 17.9999 11.3199 17.9999 12.9999V15.9999H20.9999C21.5499 15.9999 21.9999 15.5499 21.9999 14.9999V12.9999C21.9999 10.8199 18.4299 9.52988 15.6699 9.12988Z"
      fill="#FFF"
    />
    <path
      d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z"
      fill="#FFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9999 8C16.2099 8 17.9999 6.21 17.9999 4C17.9999 1.79 16.2099 0 13.9999 0C13.5299 0 13.0899 0.0999998 12.6699 0.24C13.4999 1.27 13.9999 2.58 13.9999 4C13.9999 5.42 13.4999 6.73 12.6699 7.76C13.0899 7.9 13.5299 8 13.9999 8Z"
      fill="#FFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9C5.33 9 0 10.34 0 13V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V13C16 10.34 10.67 9 8 9Z"
      fill="#FFF"
    />
  </svg>
);

export const ActionsActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.41 7.41L14.58 2.58C14.21 2.21 13.7 2 13.17 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.83C20 8.3 19.79 7.79 19.41 7.41ZM10.23 17.29L8.11 15.17C7.72 14.78 7.72 14.15 8.11 13.76C8.5 13.37 9.13 13.37 9.52 13.76L10.93 15.17L14.47 11.63C14.86 11.24 15.49 11.24 15.88 11.63C16.27 12.02 16.27 12.65 15.88 13.04L11.64 17.28C11.26 17.68 10.62 17.68 10.23 17.29ZM14 9C13.45 9 13 8.55 13 8V3.5L18.5 9H14Z"
      fill="white"
    />
  </svg>
);
export const Actions = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.41 7.41L14.58 2.58C14.21 2.21 13.7 2 13.17 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.83C20 8.3 19.79 7.79 19.41 7.41ZM10.23 17.29L8.11 15.17C7.72 14.78 7.72 14.15 8.11 13.76C8.5 13.37 9.13 13.37 9.52 13.76L10.93 15.17L14.47 11.63C14.86 11.24 15.49 11.24 15.88 11.63C16.27 12.02 16.27 12.65 15.88 13.04L11.64 17.28C11.26 17.68 10.62 17.68 10.23 17.29ZM14 9C13.45 9 13 8.55 13 8V3.5L18.5 9H14Z"
      fill="#BDBDBD"
    />
  </svg>
);

export const Programs = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM13 17H8C7.45 17 7 16.55 7 16C7 15.45 7.45 15 8 15H13C13.55 15 14 15.45 14 16C14 16.55 13.55 17 13 17ZM16 13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13ZM16 9H8C7.45 9 7 8.55 7 8C7 7.45 7.45 7 8 7H16C16.55 7 17 7.45 17 8C17 8.55 16.55 9 16 9Z"
      fill="#BDBDBD"
    />
  </svg>
);
export const ProgramsActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM13 17H8C7.45 17 7 16.55 7 16C7 15.45 7.45 15 8 15H13C13.55 15 14 15.45 14 16C14 16.55 13.55 17 13 17ZM16 13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13ZM16 9H8C7.45 9 7 8.55 7 8C7 7.45 7.45 7 8 7H16C16.55 7 17 7.45 17 8C17 8.55 16.55 9 16 9Z"
      fill="white"
    />
  </svg>
);

export const Administrators = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M14 7.99957C14.34 7.99957 14.67 8.03957 15 8.08957V4.57957C15 3.77957 14.53 3.05957 13.8 2.74957L8.3 0.34957C7.79 0.12957 7.21 0.12957 6.7 0.34957L1.2 2.74957C0.47 3.06957 0 3.78957 0 4.57957V8.17957C0 12.7196 3.2 16.9696 7.5 17.9996C8.05 17.8696 8.58 17.6796 9.1 17.4496C8.41 16.4696 8 15.2796 8 13.9996C8 10.6896 10.69 7.99957 14 7.99957Z"
      fill="#BDBDBD"
    />
    <path
      d="M14 10C11.79 10 10 11.79 10 14C10 16.21 11.79 18 14 18C16.21 18 18 16.21 18 14C18 11.79 16.21 10 14 10ZM14 11.38C14.62 11.38 15.12 11.89 15.12 12.5C15.12 13.11 14.61 13.62 14 13.62C13.39 13.62 12.88 13.11 12.88 12.5C12.88 11.89 13.38 11.38 14 11.38ZM14 16.75C13.07 16.75 12.26 16.29 11.76 15.58C11.81 14.86 13.27 14.5 14 14.5C14.73 14.5 16.19 14.86 16.24 15.58C15.74 16.29 14.93 16.75 14 16.75Z"
      fill="#BDBDBD"
    />
  </svg>
);

export const AdministratorsActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M14 7.99957C14.34 7.99957 14.67 8.03957 15 8.08957V4.57957C15 3.77957 14.53 3.05957 13.8 2.74957L8.3 0.34957C7.79 0.12957 7.21 0.12957 6.7 0.34957L1.2 2.74957C0.47 3.06957 0 3.78957 0 4.57957V8.17957C0 12.7196 3.2 16.9696 7.5 17.9996C8.05 17.8696 8.58 17.6796 9.1 17.4496C8.41 16.4696 8 15.2796 8 13.9996C8 10.6896 10.69 7.99957 14 7.99957Z"
      fill="#fff"
    />
    <path
      d="M14 10C11.79 10 10 11.79 10 14C10 16.21 11.79 18 14 18C16.21 18 18 16.21 18 14C18 11.79 16.21 10 14 10ZM14 11.38C14.62 11.38 15.12 11.89 15.12 12.5C15.12 13.11 14.61 13.62 14 13.62C13.39 13.62 12.88 13.11 12.88 12.5C12.88 11.89 13.38 11.38 14 11.38ZM14 16.75C13.07 16.75 12.26 16.29 11.76 15.58C11.81 14.86 13.27 14.5 14 14.5C14.73 14.5 16.19 14.86 16.24 15.58C15.74 16.29 14.93 16.75 14 16.75Z"
      fill="#fff"
    />
  </svg>
);

export const Documents = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.59 2.59C14.21 2.21 13.7 2 13.17 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.83C20 8.3 19.79 7.79 19.41 7.42L14.59 2.59ZM15 18H9C8.45 18 8 17.55 8 17C8 16.45 8.45 16 9 16H15C15.55 16 16 16.45 16 17C16 17.55 15.55 18 15 18ZM15 14H9C8.45 14 8 13.55 8 13C8 12.45 8.45 12 9 12H15C15.55 12 16 12.45 16 13C16 13.55 15.55 14 15 14ZM13 8V3.5L18.5 9H14C13.45 9 13 8.55 13 8Z"
      fill="#BDBDBD"
    />
  </svg>
);

export const DocumentsActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.59 2.59C14.21 2.21 13.7 2 13.17 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.83C20 8.3 19.79 7.79 19.41 7.42L14.59 2.59ZM15 18H9C8.45 18 8 17.55 8 17C8 16.45 8.45 16 9 16H15C15.55 16 16 16.45 16 17C16 17.55 15.55 18 15 18ZM15 14H9C8.45 14 8 13.55 8 13C8 12.45 8.45 12 9 12H15C15.55 12 16 12.45 16 13C16 13.55 15.55 14 15 14ZM13 8V3.5L18.5 9H14C13.45 9 13 8.55 13 8Z"
      fill="#FFF"
    />
  </svg>
);

export const Questionnaires = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20 6H19V14C19 14.55 18.55 15 18 15H6V16C6 17.1 6.9 18 8 18H18L22 22V8C22 6.9 21.1 6 20 6ZM17 11V4C17 2.9 16.1 2 15 2H4C2.9 2 2 2.9 2 4V17L6 13H15C16.1 13 17 12.1 17 11Z"
      fill="#BDBDBD"
    />
  </svg>
);

export const QuestionnairesActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20 6H19V14C19 14.55 18.55 15 18 15H6V16C6 17.1 6.9 18 8 18H18L22 22V8C22 6.9 21.1 6 20 6ZM17 11V4C17 2.9 16.1 2 15 2H4C2.9 2 2 2.9 2 4V17L6 13H15C16.1 13 17 12.1 17 11Z"
      fill="#fff"
    />
  </svg>
);

export const Content = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5.00027 13.1803V15.9903C5.00027 16.7203 5.40027 17.4003 6.04027 17.7503L11.0403 20.4803C11.6403 20.8103 12.3603 20.8103 12.9603 20.4803L17.9603 17.7503C18.6003 17.4003 19.0003 16.7203 19.0003 15.9903V13.1803L12.9603 16.4803C12.3603 16.8103 11.6403 16.8103 11.0403 16.4803L5.00027 13.1803ZM11.0403 3.52027L2.61027 8.12027C1.92027 8.50027 1.92027 9.50027 2.61027 9.88027L11.0403 14.4803C11.6403 14.8103 12.3603 14.8103 12.9603 14.4803L21.0003 10.0903V16.0003C21.0003 16.5503 21.4503 17.0003 22.0003 17.0003C22.5503 17.0003 23.0003 16.5503 23.0003 16.0003V9.59027C23.0003 9.22027 22.8003 8.89027 22.4803 8.71027L12.9603 3.52027C12.3603 3.20027 11.6403 3.20027 11.0403 3.52027Z"
      fill="#BDBDBD"
    />
  </svg>
);

export const ContentActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5.00027 13.1803V15.9903C5.00027 16.7203 5.40027 17.4003 6.04027 17.7503L11.0403 20.4803C11.6403 20.8103 12.3603 20.8103 12.9603 20.4803L17.9603 17.7503C18.6003 17.4003 19.0003 16.7203 19.0003 15.9903V13.1803L12.9603 16.4803C12.3603 16.8103 11.6403 16.8103 11.0403 16.4803L5.00027 13.1803ZM11.0403 3.52027L2.61027 8.12027C1.92027 8.50027 1.92027 9.50027 2.61027 9.88027L11.0403 14.4803C11.6403 14.8103 12.3603 14.8103 12.9603 14.4803L21.0003 10.0903V16.0003C21.0003 16.5503 21.4503 17.0003 22.0003 17.0003C22.5503 17.0003 23.0003 16.5503 23.0003 16.0003V9.59027C23.0003 9.22027 22.8003 8.89027 22.4803 8.71027L12.9603 3.52027C12.3603 3.20027 11.6403 3.20027 11.0403 3.52027Z"
      fill="#fff"
    />
  </svg>
);

export const Dairy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM6 4H11V12L8.5 10.5L6 12V4Z"
      fill="#BDBDBD"
    />
  </svg>
);

export const DairyActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM6 4H11V12L8.5 10.5L6 12V4Z"
      fill="#FFF"
    />
  </svg>
);

export const InforCards = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="19"
    viewBox="0 0 15 19"
    fill="none"
  >
    <path
      d="M8.00032 0C7.54032 0 7.07032 0.0400001 6.60032 0.14C3.84032 0.67 1.64032 2.9 1.12032 5.66C0.640316 8.27 1.60032 10.67 3.34032 12.22C3.77032 12.6 4.00032 13.13 4.00032 13.69V16C4.00032 17.1 4.90032 18 6.00032 18H6.28032C6.63032 18.6 7.26032 19 8.00032 19C8.74032 19 9.38032 18.6 9.72032 18H10.0003C11.1003 18 12.0003 17.1 12.0003 16V13.69C12.0003 13.14 12.2203 12.6 12.6403 12.23C14.0903 10.95 15.0003 9.08 15.0003 7C15.0003 3.13 11.8703 0 8.00032 0ZM8.50032 11H7.50032V8.41L5.67032 6.59L6.38032 5.88L8.00032 7.5L9.62032 5.88L10.3303 6.59L8.50032 8.42V11ZM9.50032 16C9.49032 16 9.48032 15.99 9.47032 15.99V16H6.53032V15.99C6.52032 15.99 6.51032 16 6.50032 16C6.22032 16 6.00032 15.78 6.00032 15.5C6.00032 15.22 6.22032 15 6.50032 15C6.51032 15 6.52032 15.01 6.53032 15.01V15H9.47032V15.01C9.48032 15.01 9.49032 15 9.50032 15C9.78032 15 10.0003 15.22 10.0003 15.5C10.0003 15.78 9.78032 16 9.50032 16ZM9.50032 14H6.50032C6.22032 14 6.00032 13.78 6.00032 13.5C6.00032 13.22 6.22032 13 6.50032 13H9.50032C9.78032 13 10.0003 13.22 10.0003 13.5C10.0003 13.78 9.78032 14 9.50032 14Z"
      fill="#BDBDBD"
    />
  </svg>
);
export const InforCardsActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="19"
    viewBox="0 0 15 19"
    fill="none"
  >
    <path
      d="M8.00032 0C7.54032 0 7.07032 0.0400001 6.60032 0.14C3.84032 0.67 1.64032 2.9 1.12032 5.66C0.640316 8.27 1.60032 10.67 3.34032 12.22C3.77032 12.6 4.00032 13.13 4.00032 13.69V16C4.00032 17.1 4.90032 18 6.00032 18H6.28032C6.63032 18.6 7.26032 19 8.00032 19C8.74032 19 9.38032 18.6 9.72032 18H10.0003C11.1003 18 12.0003 17.1 12.0003 16V13.69C12.0003 13.14 12.2203 12.6 12.6403 12.23C14.0903 10.95 15.0003 9.08 15.0003 7C15.0003 3.13 11.8703 0 8.00032 0ZM8.50032 11H7.50032V8.41L5.67032 6.59L6.38032 5.88L8.00032 7.5L9.62032 5.88L10.3303 6.59L8.50032 8.42V11ZM9.50032 16C9.49032 16 9.48032 15.99 9.47032 15.99V16H6.53032V15.99C6.52032 15.99 6.51032 16 6.50032 16C6.22032 16 6.00032 15.78 6.00032 15.5C6.00032 15.22 6.22032 15 6.50032 15C6.51032 15 6.52032 15.01 6.53032 15.01V15H9.47032V15.01C9.48032 15.01 9.49032 15 9.50032 15C9.78032 15 10.0003 15.22 10.0003 15.5C10.0003 15.78 9.78032 16 9.50032 16ZM9.50032 14H6.50032C6.22032 14 6.00032 13.78 6.00032 13.5C6.00032 13.22 6.22032 13 6.50032 13H9.50032C9.78032 13 10.0003 13.22 10.0003 13.5C10.0003 13.78 9.78032 14 9.50032 14Z"
      fill="#FFF"
    />
  </svg>
);

export const AppointmentActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20ZM7 11H12V16H7V11Z"
      fill="#FFF"
    />
  </svg>
);

export const AppointmentInActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20ZM7 11H12V16H7V11Z"
      fill="#BDBDBD"
    />
  </svg>
);

export const ForumActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1818 3C11.1612 3 8.59353 4.97466 7.70067 7.70067C4.97466 8.59353 3 11.1612 3 14.1818C3 17.9414 6.05863 21 9.81817 21C12.8388 21 15.4065 19.0253 16.2993 16.2993C19.0253 15.4065 21 12.8388 21 9.81817C21 6.05863 17.9414 3 14.1818 3ZM9.81817 19.3637C6.96091 19.3637 4.63635 17.0391 4.63635 14.1818C4.63635 12.2108 5.74264 10.4934 7.36679 9.61757C7.36489 9.68422 7.36363 9.75109 7.36363 9.81817C7.36363 13.5777 10.4223 16.6363 14.1818 16.6363C14.2489 16.6363 14.3157 16.6351 14.3824 16.6332C13.5066 18.2574 11.7892 19.3637 9.81817 19.3637ZM16.6332 14.3824C16.6351 14.3158 16.6364 14.2489 16.6364 14.1818C16.6364 10.4223 13.5777 7.36366 9.8182 7.36366C9.75113 7.36366 9.68426 7.36493 9.6176 7.36683C10.4934 5.74268 12.2108 4.63638 14.1819 4.63638C17.0391 4.63638 19.3637 6.96095 19.3637 9.8182C19.3637 11.7892 18.2574 13.5066 16.6332 14.3824Z"
      fill="white"
    />
  </svg>
);

export const ForumInActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1818 3C11.1612 3 8.59353 4.97466 7.70067 7.70067C4.97466 8.59353 3 11.1612 3 14.1818C3 17.9414 6.05863 21 9.81817 21C12.8388 21 15.4065 19.0253 16.2993 16.2993C19.0253 15.4065 21 12.8388 21 9.81817C21 6.05863 17.9414 3 14.1818 3ZM9.81817 19.3637C6.96091 19.3637 4.63635 17.0391 4.63635 14.1818C4.63635 12.2108 5.74264 10.4934 7.36679 9.61757C7.36489 9.68422 7.36363 9.75109 7.36363 9.81817C7.36363 13.5777 10.4223 16.6363 14.1818 16.6363C14.2489 16.6363 14.3157 16.6351 14.3824 16.6332C13.5066 18.2574 11.7892 19.3637 9.81817 19.3637ZM16.6332 14.3824C16.6351 14.3158 16.6364 14.2489 16.6364 14.1818C16.6364 10.4223 13.5777 7.36366 9.8182 7.36366C9.75113 7.36366 9.68426 7.36493 9.6176 7.36683C10.4934 5.74268 12.2108 4.63638 14.1819 4.63638C17.0391 4.63638 19.3637 6.96095 19.3637 9.8182C19.3637 11.7892 18.2574 13.5066 16.6332 14.3824Z"
      fill="#BDBDBD"
    />
  </svg>
);

export const Reports = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM8 17C7.45 17 7 16.55 7 16V11C7 10.45 7.45 10 8 10C8.55 10 9 10.45 9 11V16C9 16.55 8.55 17 8 17ZM12 17C11.45 17 11 16.55 11 16V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V16C13 16.55 12.55 17 12 17ZM16 17C15.45 17 15 16.55 15 16V14C15 13.45 15.45 13 16 13C16.55 13 17 13.45 17 14V16C17 16.55 16.55 17 16 17Z"
      fill="#BDBDBD"
    />
  </svg>
);
export const ReportsActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM8 17C7.45 17 7 16.55 7 16V11C7 10.45 7.45 10 8 10C8.55 10 9 10.45 9 11V16C9 16.55 8.55 17 8 17ZM12 17C11.45 17 11 16.55 11 16V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V16C13 16.55 12.55 17 12 17ZM16 17C15.45 17 15 16.55 15 16V14C15 13.45 15.45 13 16 13C16.55 13 17 13.45 17 14V16C17 16.55 16.55 17 16 17Z"
      fill="white"
    />
  </svg>
);
export const MetricsInactive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 3.18V20.82C11 21.46 10.41 21.94 9.79 21.8C5.32 20.8 2 16.79 2 12C2 7.21 5.32 3.2 9.79 2.2C10.41 2.06 11 2.54 11 3.18ZM13.03 3.18V9.99C13.03 10.54 13.48 10.99 14.03 10.99H20.82C21.46 10.99 21.94 10.4 21.8 9.77C20.95 6.01 18 3.05 14.25 2.2C13.62 2.06 13.03 2.54 13.03 3.18ZM13.03 14.01V20.82C13.03 21.46 13.62 21.94 14.25 21.8C18.01 20.95 20.96 17.98 21.81 14.22C21.95 13.6 21.46 13 20.83 13H14.04C13.48 13.01 13.03 13.46 13.03 14.01Z"
      fill="#BDBDBD"
    />
  </svg>
);

export const MetricsActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 3.18V20.82C11 21.46 10.41 21.94 9.79 21.8C5.32 20.8 2 16.79 2 12C2 7.21 5.32 3.2 9.79 2.2C10.41 2.06 11 2.54 11 3.18ZM13.03 3.18V9.99C13.03 10.54 13.48 10.99 14.03 10.99H20.82C21.46 10.99 21.94 10.4 21.8 9.77C20.95 6.01 18 3.05 14.25 2.2C13.62 2.06 13.03 2.54 13.03 3.18ZM13.03 14.01V20.82C13.03 21.46 13.62 21.94 14.25 21.8C18.01 20.95 20.96 17.98 21.81 14.22C21.95 13.6 21.46 13 20.83 13H14.04C13.48 13.01 13.03 13.46 13.03 14.01Z"
      fill="white"
    />
  </svg>
);

export const AuditInactive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.54 11L13 7.45999L14.41 6.04999L16.53 8.16999L20.77 3.92999L22.18 5.33999L16.54 11ZM11 6.99999H2V8.99999H11V6.99999ZM21 13.41L19.59 12L17 14.59L14.41 12L13 13.41L15.59 16L13 18.59L14.41 20L17 17.41L19.59 20L21 18.59L18.41 16L21 13.41ZM11 15H2V17H11V15Z"
      fill="#BDBDBD"
    />
  </svg>
);

export const AuditActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.54 11L13 7.45999L14.41 6.04999L16.53 8.16999L20.77 3.92999L22.18 5.33999L16.54 11ZM11 6.99999H2V8.99999H11V6.99999ZM21 13.41L19.59 12L17 14.59L14.41 12L13 13.41L15.59 16L13 18.59L14.41 20L17 17.41L19.59 20L21 18.59L18.41 16L21 13.41ZM11 15H2V17H11V15Z"
      fill="white"
    />
  </svg>
);
