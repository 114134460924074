import React from "react";
import AppLayout from "../../components/AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import FoodDiary from "./FoodDiaryList";
import ExerciseDiary from "./ExerciseDiaryList";
import DiarySelectionHeader from "./DiarySelectionHeader";
import { useParams } from "react-router";

const DiarySelectWrapper: React.FC = () => {
  const { type } = useParams<any>();
  return (
    <AppLayout>
      <Navigation />
      <DiarySelectionHeader type={type} />
      {type === "Food" && <FoodDiary select />}
      {type === "Exercise" && <ExerciseDiary select />}
    </AppLayout>
  );
};

export default DiarySelectWrapper;
