import { Dropdown, Input, Menu, Select, Table } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import AppLayout from "../AppLayout/AppLayout";
import CustomButton from "../Common/CustomButton";
import Navigation from "../Navigation/Navigation";

const data: any = [
  {
    id: 1,
    tracking: "Sleep",
    title: "Sleep tracker",
    description: "This tracker monitors user sleep",
    date: "2021-11-01T09:52:24.307Z",
    status: "active",
  },
  {
    id: 2,
    tracking: "Steps",
    title: "Steps tracker",
    description: "This tracker monitors user steps",
    date: "2021-11-01T09:52:24.307Z",
    status: "active",
  },
  {
    id: 3,
    tracking: "Glucose",
    title: "Glucose tracker",
    description: "This tracker monitors user glucose level",
    date: "2021-11-01T09:52:24.307Z",
    status: "active",
  },
  {
    id: 4,
    tracking: "Weight",
    title: "Weight tracker",
    description: "This tracker monitors user weight",
    date: "2021-11-01T09:52:24.307Z",
    status: "active",
  },
  {
    id: 5,
    tracking: "Medication",
    title: "Medication tracker",
    description: "This tracker monitors user medication",
    date: "2021-11-01T09:52:24.307Z",
    status: "active",
  },
  {
    id: 6,
    tracking: "Food",
    title: "Food tracker",
    description: "This tracker monitors user diet",
    date: "2021-11-01T09:52:24.307Z",
    status: "active",
  },
];

const Actions = () => {
  const [search, setSearch] = useState("");
  const history = useHistory();

  const changeHandler = (event: any) => {
    setSearch(event.target.value);
  };

  const handleButtonClick = () => {
    history.push("/actions/new");
  };

  const columns = useMemo(() => {
    const menu = (data: any) => (
      <Menu className="action-dropdown">
        <Menu.Item key="0">Delete</Menu.Item>
      </Menu>
    );
    return [
      {
        title: "Action title",
        key: "title",
        dataIndex: "title",
      },
      {
        title: "Tracking",
        dataIndex: "tracking",
        key: "tracking",
      },
      {
        title: "Task Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Created",
        dataIndex: "date",
        key: "date",
        render: (text: string) => (
          <span>{moment(text).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        width: "20%",
        render: (text: string) => (
          <span
            className={
              text === "active" ? "active-tag badge" : "inactive-tag badge"
            }
          >
            {text}
          </span>
        ),
      },
      {
        title: "",
        width: "10%",
        className: "text-end",
        render: (_1: any, record: any) => {
          return (
            <span
              onClick={(e) => e.stopPropagation()}
              className="d-inline-block"
            >
              <Dropdown
                overlay={menu(record)}
                placement="bottomRight"
                trigger={["click"]}
              >
                <i className="more-icon cp"></i>
              </Dropdown>
            </span>
          );
        },
      },
    ];
  }, []);

  return (
    <AppLayout>
      <Navigation />
      <span className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          <h2 className="font-l fw-bold me-3">Actions</h2>
          <Input
            value={search}
            onChange={changeHandler}
            style={{ width: "300px" }}
            placeholder="Search..."
            className="search-input"
          />
        </div>
        <CustomButton
          primary={true}
          clickHandler={handleButtonClick}
          buttonText={`Add Actions`}
          addButton={true}
          buttonType={"button"}
          className="btn-lg"
        />
      </span>
      <Table
        dataSource={data}
        columns={columns}
        showSorterTooltip={false}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={{
          total: data.length,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
          },
          position: ["bottomLeft"],
        }}
      />
    </AppLayout>
  );
};

export default Actions;
