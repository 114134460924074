import React from "react";
import roles from "./constants/roles";
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";
import Login from "./components/Login/Login";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import SmsSecurity from "./components/SmsSecurity/SmsSecurity";
import VerifyEmail from "./components/VerifyEmail/VerifyEmail";
import Administrator from "./components/Administrators/Administrator";
import SetPassword from "./components/SetPassword/SetPassword";
import Participants from "./components/Participants/Participants";
import Appointments from "./components/Appointments/Appointments";
import { useAppSelector } from "./redux/hooks";
import CalendarWrapper from "./components/Appointments/CalendarWrapper";
import AllAppointments from "./components/Appointments/AppointmentOverview/AllAppointments";
import Documents from "./components/Documents/Documents";
import FolderDocument from "./components/Documents/FolderDocument";
import EducationListPages from "./components/Education/EducationListPages/EducationListPages";
import EducationBuilder from "./components/Education/EducationBuilder/EducationBuilder";
import EducationPreview from "./components/Education/EducationPreview/EducationPreview";
import QuestionList from "./components/Questions/QuestionList/QuestionList";
import QuestionPreview from "./components/Questions/QuestionPreview/QuestionPreview";
import QuestionBuilder from "./components/Questions/QuestionBuilder/QuestionBuilder";
import Scores from "./components/Scores/Scores";
import CreateScore from "./components/Scores/CreateScore";
import Content from "./components/Content/Content";
import PatientAppointments from "./components/Appointments/AppointmentOverview/PatientAppointment";
import Diary from "./components/Diary/Diary";
import PatientsList from "./components/PatientsList/PatientsList";
import EditProgram from "./components/Programs/EditProgram";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import EditProfile from "./components/Profile/EditProfile";
import patientview from "./components/PatientsList/PatientView";
import DiarySelectWrapper from "./components/Diary/DiarySelectWrapper";
import LessonReadView from "./components/LessonReadView/LessonReadView";
import AlertAnalytics from "./components/Dashboard/AlertAnalytics/AlertAnalytics";
import ProgramOverview from "./components/Dashboard/ProgramOverview/ProgramOverview";
import Forums from "./components/Forums/Forums";
import ForumDetails from "./components/Forums/ForumDetails";
import TherapistProfile from "./components/Profile/TherapistProfile";
import GroupList from "./components/Participants/Group/GroupList";
import GroupCreate from "./components/Participants/Group/GroupCreate";
import Reports from "./components/Reports/Reports";
import AuditLog from "./components/AuditLogs/AuditLog";
import AssociatedUsers from "./components/AssociatedUsers/AssociatedUsers";
import CRF from "./components/CRF/CRF";
import Actions from "./components/Actions/Actions";
import CreateAction from "./components/Actions/CreateAction";

const isAuthenticated = () => {
  if (localStorage.getItem("singer-accessToken") !== null) {
    return true;
  } else {
    localStorage.clear();
    return false;
  }
};

const isLoggedOut = () => {
  if (localStorage.getItem("singer-accessToken") === null) {
    return true;
  } else {
    return false;
  }
};

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  if (!Component) {
    return null;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const LoggedOutRoute = ({ component: Component, ...rest }: RouteProps) => {
  if (!Component) {
    return null;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedOut() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const adminRoutes = [
  <PrivateRoute path="/dashboard" exact component={ProgramOverview} />,
  <PrivateRoute path="/actions" exact component={Actions} />,
  <PrivateRoute path="/actions/new" exact component={CreateAction} />,
  <PrivateRoute path="/dashboard/alert" exact component={AlertAnalytics} />,
  <PrivateRoute path="/appointments" exact component={AllAppointments} />,
  <PrivateRoute
    path="/appointments/therapist/:therapistId"
    exact
    component={AllAppointments}
  />,
  <PrivateRoute path="/programs" exact component={EditProgram} />,
  <PrivateRoute
    path="/programs/select/:type"
    exact
    component={DiarySelectWrapper}
  />,
  <PrivateRoute
    path="/appointments/patient/:patientId"
    component={PatientAppointments}
  />,
  <PrivateRoute path="/audit-logs" exact component={AuditLog} />,
  <PrivateRoute path="/administrator" exact component={Administrator} />,
  <PrivateRoute
    path="/associated-users/:therapistId"
    component={AssociatedUsers}
  />,
  <PrivateRoute path="/participants" exact component={Participants} />,
  <PrivateRoute path="/participants/group-new" component={GroupCreate} />,
  <PrivateRoute path="/participants/group/:id" component={GroupList} />,
  <PrivateRoute path="/education" exact component={Content} />,
  <PrivateRoute path="/education/preview" component={EducationPreview} />,
  <PrivateRoute path="/documents" exact component={Documents} />,
  <PrivateRoute path="/documents/:docId" component={FolderDocument} />,
  <PrivateRoute
    path="/education/pages/:assetId"
    component={EducationListPages}
  />,
  <PrivateRoute
    path="/education/builder/:assetId"
    component={EducationBuilder}
  />,
  <PrivateRoute
    path="/administrator/calendar/:therapistId"
    component={CalendarWrapper}
  />,
  <PrivateRoute path="/diary" exact component={Diary} />,
  <PrivateRoute path="/question" exact component={QuestionList} />,
  <PrivateRoute path="/question/preview" component={QuestionPreview} />,
  <PrivateRoute path="/question/builder" component={QuestionBuilder} />,
  <PrivateRoute path="/question/score" exact component={Scores} />,
  <PrivateRoute path="/question/score/create" exact component={CreateScore} />,
  <PrivateRoute path="/patientsList" exact component={PatientsList} />,
  <PrivateRoute path="/patientview/:patientId" component={patientview} />,
  <PrivateRoute path="/auth/change-password" component={ChangePassword} />,
  <PrivateRoute path="/profile/edit" component={EditProfile} />,
  <PrivateRoute
    path="/therapist-profile/edit/:id"
    component={TherapistProfile}
  />,
  <PrivateRoute path="/forums" exact component={Forums} />,
  <PrivateRoute path="/forums/:id" component={ForumDetails} />,
  <PrivateRoute path="/reports" component={Reports} />,
  <PrivateRoute path="/crf" exact component={CRF} />,
];

const therapistRoutes = [
  <PrivateRoute path="/dashboard" exact component={PatientsList} />,
  <PrivateRoute path="/dashboard/:patientId" component={patientview} />,
  <PrivateRoute path="/appointments" exact component={Appointments} />,
  <PrivateRoute path="/appointments/all" exact component={AllAppointments} />,
  <PrivateRoute
    path="/appointments/patient/:patientId"
    component={PatientAppointments}
  />,
  <PrivateRoute path="/appointments/calendar" component={CalendarWrapper} />,
  <PrivateRoute path="/programs" exact component={EditProgram} />,
  <PrivateRoute path="/actions" exact component={Actions} />,
  <PrivateRoute path="/actions/new" exact component={CreateAction} />,
  <PrivateRoute path="/participants" component={Participants} />,
  <PrivateRoute path="/documents" component={Documents} />,
  <PrivateRoute path="/question" exact component={QuestionList} />,
  <PrivateRoute path="/question/preview" component={QuestionPreview} />,
  <PrivateRoute path="/question/builder" component={QuestionBuilder} />,
  <PrivateRoute path="/question/score" exact component={Scores} />,
  <PrivateRoute path="/question/score/create" exact component={CreateScore} />,
  <PrivateRoute path="/education" exact component={Content} />,
  <PrivateRoute path="/documents/:docId" component={FolderDocument} />,
  <PrivateRoute path="/education/preview" component={EducationPreview} />,
  <PrivateRoute
    path="/education/pages/:assetId"
    component={EducationListPages}
  />,
  <PrivateRoute
    path="/education/builder/:assetId"
    component={EducationBuilder}
  />,
  <PrivateRoute path="/diary" exact component={Diary} />,
  <PrivateRoute path="/forums" exact component={Forums} />,
  <PrivateRoute path="/forums/:id" component={ForumDetails} />,
  <PrivateRoute path="/profile/edit" component={TherapistProfile} />,
  <PrivateRoute path="/crf" exact component={CRF} />,
  <PrivateRoute path="/reports" component={Reports} />,
];

function Routes() {
  const profile = useAppSelector((state) => state.user.profile);
  return (
    <Switch>
      <LoggedOutRoute path="/auth/login" component={Login} />
      <LoggedOutRoute path="/auth/forgot-password" component={ForgotPassword} />
      <LoggedOutRoute path="/auth/sms-security" component={SmsSecurity} />
      <LoggedOutRoute path="/auth/verify-email" component={VerifyEmail} />
      <LoggedOutRoute
        path="/auth/set-password/:token"
        component={SetPassword}
      />
      <LoggedOutRoute
        path="/auth/reset-password/:token"
        component={ResetPassword}
      />
      <Route path="/lesson/read/:assetId" component={LessonReadView} />
      {localStorage.getItem("role") === roles.Admin["be_value"] &&
        adminRoutes.map((route) => route)}
      {localStorage.getItem("role") === roles.Therapist["be_value"] &&
        therapistRoutes.map((route) => route)}
      <Route exact path="/">
        <Redirect to="/auth/login" />
      </Route>
      <Redirect path="*" to="/" />
    </Switch>
  );
}

export default Routes;
