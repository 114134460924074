import React, { useState } from 'react';
import { Input } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

type Props = {
    changeEvent: (event: React.ChangeEvent<HTMLInputElement>) => void;
    blurEvent: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    placeHolder?: string;
    name: string;
};

const PasswordInput = (props: Props) => {
  const [showPwd, setShowPwd] = useState(false);

  const handleShow: () => void = () => {
    setShowPwd(prev => !prev);
  };

  return (
    <React.Fragment>
      <Input
        type={showPwd ? "text" : "password"}
        name={props.name}
        placeholder={props.placeHolder ? props.placeHolder : "Your Password"}
        onChange={props.changeEvent}
        onBlur={props.blurEvent}
        value={props.value}
        suffix={showPwd ? <EyeOutlined onClick={handleShow}/> : <EyeInvisibleOutlined onClick={handleShow}/>}
      />
    </React.Fragment>
  );
}

export default PasswordInput;