import { Input, Table } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { getImageInitials } from "../../../helpers/user";
import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import AppLayout from "../../AppLayout/AppLayout";
import Navigation from "../../Navigation/Navigation";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const GroupList = () => {
  const { id } = useParams<any>();
  const name = useQuery().get("name");
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.get(
          `/participant_groups/${id}/participants?page=${currentPage}&size=8`
        );
        const participants = res.data.data.participants.map((user: any) => {
          return user.participant;
        });
        setData(participants);
        setTotal(res.data.data.count);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    fetchClientData();
  }, [currentPage, id, dispatch]);

  const columns = useMemo(() => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (_1: any, record: any) => {
          return (
            <div className="d-flex">
              {record.profileImage ? (
                <Avatar shape="circle" size="large" src={record.profileImage} />
              ) : (
                <Avatar shape="circle" size="large">
                  {getImageInitials(record.firstName, record.lastName)}
                </Avatar>
              )}
              <div className="ms-3">
                <p className="text-s m-0 fw-medium">
                  {record.firstName + " " + record.lastName}
                </p>
                <span className="text-muted">{record.email}</span>
              </div>
            </div>
          );
        },
      },
      {
        title: "Contact",
        dataIndex: "phoneNumber",
        key: "mobile",
      },
    ];
  }, []);

  const onTableChange = async (pagination: any) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }
  };

  return (
    <AppLayout>
      <Navigation />
      <div className="d-flex align-items-center mb-4">
        <h2 className="font-l mb-0 fw-bold">{name} Users</h2>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        showSorterTooltip={false}
        className="singer-custom-table"
        onChange={onTableChange}
        rowKey={(record) => record.id}
        pagination={{
          current: currentPage,
          total: total,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
          },
          position: ["bottomLeft"],
        }}
      />
    </AppLayout>
  );
};

export default GroupList;
