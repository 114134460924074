import React from "react";
import { Menu, Dropdown } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { MoreOutlined } from "@ant-design/icons";
import { errorToastMessage } from "../../helpers/toastMessage";
import { useAppDispatch } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import http from "../../http";
import { AppointmentCallIcon } from "../Common/Icons";
import { setModalDetails } from "../../redux/reducers/modalSlice";

type Props = {
  appointment: any;
  acceptHandler: Function;
  cancelHandler: Function;
  type: string;
};
const AppointmentItem: React.FC<Props> = ({
  appointment,
  acceptHandler,
  cancelHandler,
  type,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const rescheduleHandler = (id: string) => {
    console.log(id);
    history.push("/appointments/calendar?reschedule=" + id);
  };

  const loadAllParticipants = () => {
    dispatch(
      setModalDetails({
        type: "ALL_PARTICIPANTS_DETAILS",
        modalProps: {
          show: true,
          participants: appointment.invitees,
        },
      })
    );
  };

  const startCallHandler = async () => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.post(`/ap/${appointment.id}/start`);
      window.open(res.data.data.link, "_blank");
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };
  const inviteeLength = appointment.invitees.length - 1;
  const invitee = appointment.invitees[0].invitee;
  const inviteeShortText = (
    invitee.firstName[0] + invitee.lastName[0]
  ).toUpperCase();
  const startTime = moment(appointment.scheduledStartTime);
  const endTime = moment(appointment.scheduledEndTime);
  const onGoingAppointment =
    type !== "pending" &&
    startTime.isBefore(moment()) &&
    endTime.isAfter(moment());
  const menu = (
    <Menu>
      {type === "pending" && (
        <Menu.Item onClick={() => acceptHandler(appointment.id)}>
          <span>Accept</span>
        </Menu.Item>
      )}
      {type !== "pending" && (
        <Menu.Item onClick={() => rescheduleHandler(appointment.id)}>
          <span>Reschedule</span>
        </Menu.Item>
      )}
      <Menu.Item danger onClick={() => cancelHandler(appointment.id)}>
        <span>Cancel Appointment</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="appointment-item" key={appointment.id}>
      <div className="time-detail">
        <span className="time">{startTime.format("hh:mm")}</span>
        <span className="meridien">{startTime.format("A")}</span>
        <svg
          width="2"
          height="10"
          viewBox="0 0 2 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="1" cy="1" r="1" fill="#BDBDBD" />
          <circle cx="1" cy="9" r="1" fill="#BDBDBD" />
          <circle cx="1" cy="5" r="1" fill="#BDBDBD" />
        </svg>
        <span className="time">{endTime.format("hh:mm")}</span>
        <span className="meridien">{endTime.format("A")}</span>
      </div>
      <div className="patient-details">
        <div>
          <div className="appointment-type">Mahalo Video Call</div>
          <div className="d-flex align-items-center">
            <div className="patient-name">
              {invitee.firstName + " " + invitee.lastName}
            </div>
            {inviteeLength > 0 && (
              <div>
                <span className="ms-1 me-1 more-users">{"&"}</span>
                <span
                  className="more-users-count"
                  onClick={loadAllParticipants}
                >
                  {inviteeLength > 1 ? `${inviteeLength} others` : "1 other"}
                </span>
              </div>
            )}
          </div>
        </div>
        <span className="d-flex align-items-center">
          {onGoingAppointment && (
            <span className="me-2 appointment-call" onClick={startCallHandler}>
              <AppointmentCallIcon />
            </span>
          )}
          <Dropdown overlay={menu}>
            <MoreOutlined
              color="#828282"
              rotate={90}
              style={{ fontSize: "24px" }}
            />
          </Dropdown>
        </span>
      </div>
      <div className="patient-avatar">{inviteeShortText}</div>
    </div>
  );
};

export default AppointmentItem;
