import React from "react";
import { Modal } from "antd";
import { Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import "../../../pages/css/administrators/addProjectOwner.scss";
import * as yup from "yup";
import roles, { ROLE } from "../../../constants/roles";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { addAdministrator } from "../../../redux/actions/adminAction";
import CustomInput from "../../Common/CustomInput";
import CustomButton from "../../Common/CustomButton";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
  mobileNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    // .test("len", "Phone Number must be more than digits", (val) => val.length >= 8)
    .required("Phone Number is Required"),
  meetingLink: yup.string().optional(),
});

const AddTherapistModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { type, show, header } = useAppSelector(
    (state) => state.modal.modalProps
  );

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    role: roles[type as ROLE]["be_value"],
    meetingLink: "",
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async (values: any) => {
    try {
      let obj = {
        ...values,
        phoneNumber: `+${values.mobileNumber}`,
      };
      delete obj["mobileNumber"];
      if (type !== roles.Therapist["fe_value"]) {
        delete obj["meetingLink"];
      }
      await dispatch(addAdministrator(obj));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  return (
    <Modal
      title={`Add ${header}`}
      visible={show}
      onCancel={closeHandler}
      closable={true}
      width={"38rem"}
      footer={null}
    >
      <div className="add-project-owner">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              {/* email address */}
              <div className="input-field w-100 mb-4">
                <div className="field-name form-label">Email Address*</div>
                <div className="floating-label">
                  <CustomInput
                    name="email"
                    value={values.email}
                    changeEvent={handleChange}
                    blurEvent={handleBlur}
                    className="floating-input form-control"
                  />
                  <div className="input-error text-left mt-1">
                    {errors.email && touched.email && errors.email}
                  </div>
                </div>
              </div>
              {/* first name and last name */}
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="field-name form-label">First Name*</div>
                  <div className="floating-label">
                    <CustomInput
                      name="firstName"
                      value={values.firstName}
                      changeEvent={handleChange}
                      blurEvent={handleBlur}
                      className="floating-input form-control"
                    />
                    <div className="input-error text-left mt-1">
                      {errors.firstName &&
                        touched.firstName &&
                        errors.firstName}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="field-name form-label">Last Name*</div>
                  <div className="floating-label">
                    <CustomInput
                      name="lastName"
                      value={values.lastName}
                      changeEvent={handleChange}
                      blurEvent={handleBlur}
                      className="floating-input form-control"
                    />
                    <div className="input-error text-left mt-1">
                      {errors.lastName && touched.lastName && errors.lastName}
                    </div>
                  </div>
                </div>
              </div>
              {/* mobile and phone number */}
              <div className="row mb-4">
                <div className="input-field">
                  <div className="field-name form-label">Mobile*</div>
                  <div className="floating-label tel-input-sm ">
                    <PhoneInput
                      country={"sg"}
                      // disableDropdown
                      countryCodeEditable={false}
                      value={values.mobileNumber}
                      onChange={(phone) => setFieldValue("mobileNumber", phone)}
                    />
                    <div className="input-error text-left">
                      {errors.mobileNumber &&
                        touched.mobileNumber &&
                        errors.mobileNumber}
                    </div>
                  </div>
                </div>
              </div>
              {type === roles.Therapist["fe_value"] && (
                <div className="row mb-4">
                  <div className="input-field">
                    <div className="field-name form-label">Meeting Link*</div>
                    <div className="floating-label tel-input-sm ">
                      <CustomInput
                        name="meetingLink"
                        value={values.meetingLink}
                        changeEvent={handleChange}
                        blurEvent={handleBlur}
                        className="floating-input form-control"
                      />
                      <div className="input-error text-left mt-1">
                        {errors.meetingLink &&
                          touched.meetingLink &&
                          errors.meetingLink}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="text-end">
                <span
                  role="button"
                  className="button-cancel"
                  onClick={closeHandler}
                  tabIndex={0}
                >
                  Cancel
                </span>
                <CustomButton
                  buttonType="submit"
                  buttonText="Save"
                  primary={true}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddTherapistModal;
