import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import {
  setBasicDetails,
  setQuestionniareDetails,
  setQuestionniareResponse,
} from "../reducers/dashboardSlice";
import { setAppLoader } from "../reducers/loaderSlice";
import { AppThunk } from "../store";

export const fetchDashboardDetails = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setAppLoader(true));
    const res = await http.get(`/dashboard`);
    dispatch(setBasicDetails(res.data.data));
    const res1 = await http.get(`/dashboard/qnr`);
    const questionnaires = Object.entries(res1.data.data).map(
      ([key, value]: any) => {
        const percentage =
          value.total && value.completed
            ? ((value.completed * 100) / value.total).toFixed(2)
            : 0;
        return {
          id: key,
          name: value.name,
          percentage,
        };
      }
    );
    dispatch(setQuestionniareDetails(questionnaires));
    dispatch(setAppLoader(false));
  } catch (err) {
    errorToastMessage(err as Error);
    dispatch(setAppLoader(false));
  }
};

export const fetchProgramOverview = (): AppThunk => async (dispatch) => {
  try {
    // dispatch(setAppLoader(true));
    // const res = await http.get(
    //   // `/programs/ade58392-631c-4435-908d-d4a0ebbfea75/overview`
    //   `/actions/notifications`
    // );
    // dispatch(setAppLoader(false));
  } catch (err) {
    errorToastMessage(err as Error);
    dispatch(setAppLoader(false));
  }
};

export const fetchQuestionnaireDetails =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const query = `?questionnaireId=${id}`;
      const res = await http.get(
        `/programs/ade58392-631c-4435-908d-d4a0ebbfea75/overview/questions${query}`
      );
      dispatch(
        setQuestionniareResponse({
          id: id,
          data: res.data.data,
        })
      );
      dispatch(setAppLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };
