import { Radio, RadioChangeEvent, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import CustomButton from "../../Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { modifyTherapistSlot } from "../../../redux/actions/adminAction";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { timepickerOptions } from "../../../helpers/Appointment";

declare type AvailabilityType = "block" | "slot";

const AvailabilityForm = (props: any) => {
  const dispatch = useAppDispatch();
  const timings = useAppSelector((state) => state.administrator.timings);
  const [type, setType] = useState<AvailabilityType>("slot");
  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();
  const [offlineStart, setOfflineStart] = useState<number>();
  const [offlineEnd, setOfflineEnd] = useState<number>();

  useEffect(() => {
    setStartTime(timings.startTime);
  }, [setStartTime, timings.startTime]);

  useEffect(() => {
    setEndTime(timings.endTime);
  }, [setEndTime, timings.endTime]);

  useEffect(() => {
    setOfflineStart(timings.offlineStart);
  }, [setOfflineStart, timings.offlineStart]);

  useEffect(() => {
    setOfflineEnd(timings.offlineEnd);
  }, [setOfflineEnd, timings.offlineEnd]);

  const handleRadioChange: (e: RadioChangeEvent) => void = (
    val: RadioChangeEvent
  ) => {
    const value = val.target.value;
    setType(value);
  };

  const handleStartTime = (val: number) => {
    setStartTime(val);
    setEndTime(undefined);
  };

  const handleEndTime = (val: number) => {
    setEndTime(val);
  };

  const handleOfflineStart = (val: number) => {
    setOfflineStart(val);
    setOfflineEnd(undefined);
  };

  const handleOfflineEnd = (val: number) => {
    setOfflineEnd(val);
  };

  const submitHandler = () => {
    try {
      if (type === "slot" && startTime && endTime && startTime < endTime) {
        dispatch(
          modifyTherapistSlot("slot", startTime, endTime, props.therapistId)
        );
      } else if (
        type === "block" &&
        offlineStart &&
        offlineEnd &&
        offlineStart < offlineEnd
      ) {
        dispatch(
          modifyTherapistSlot(
            "block",
            offlineStart,
            offlineEnd,
            props.therapistId
          )
        );
      }
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const options = useMemo(() => {
    const opt = timepickerOptions();
    return opt;
  }, []);

  return (
    <>
      <Radio.Group
        onChange={handleRadioChange}
        value={type}
        className="d-flex mb-4 justify-content-center"
      >
        <Radio value="slot">Add Availability</Radio>
        <Radio value="block">Block Availability</Radio>
      </Radio.Group>
      <div className="font-m fw-bold mb-3 ">
        {type === "slot"
          ? "Edit Therapist Availability"
          : "Block off Availability"}
      </div>
      <div className="mb-3 floating-label">
        <div className="form-label">Start Time</div>

        {type === "slot" ? (
          <Select value={startTime} onChange={handleStartTime}>
            {options.map((mins) => {
              return (
                <Select.Option value={mins} key={mins}>
                  {moment()
                    .startOf("day")
                    .add(mins, "seconds")
                    .format("hh:mm A")}
                </Select.Option>
              );
            })}
          </Select>
        ) : (
          <Select value={offlineStart} onChange={handleOfflineStart}>
            {options.map((mins) => {
              if (
                !startTime ||
                !endTime ||
                startTime > mins ||
                endTime < mins
              ) {
                return null;
              }
              return (
                <Select.Option value={mins} key={mins}>
                  {moment()
                    .startOf("day")
                    .add(mins, "seconds")
                    .format("hh:mm A")}
                </Select.Option>
              );
            })}
          </Select>
        )}
      </div>
      <div className="floating-label mb-3">
        <div className="form-label">End Time</div>

        {type === "slot" ? (
          <Select value={endTime} onChange={handleEndTime}>
            {options.map((mins) => {
              if (!startTime || mins <= startTime) {
                return null;
              }
              return (
                <Select.Option value={mins} key={mins}>
                  {moment()
                    .startOf("day")
                    .add(mins, "seconds")
                    .format("hh:mm A")}
                </Select.Option>
              );
            })}
          </Select>
        ) : (
          <Select value={offlineEnd} onChange={handleOfflineEnd}>
            {options.map((mins) => {
              if (
                !startTime ||
                !endTime ||
                !offlineStart ||
                startTime > mins ||
                endTime < mins ||
                offlineStart >= mins
              ) {
                return null;
              }
              return (
                <Select.Option value={mins} key={mins}>
                  {moment()
                    .startOf("day")
                    .add(mins, "seconds")
                    .format("hh:mm A")}
                </Select.Option>
              );
            })}
          </Select>
        )}
      </div>
      <div className="text-end mt-4">
        <CustomButton
          primary={true}
          buttonText="Update"
          clickHandler={submitHandler}
        />
      </div>
    </>
  );
};

export default AvailabilityForm;
