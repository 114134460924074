import { useState } from "react";
import AppLayout from "../../components/AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import DocumentsHeader from "./DocumentsHeader";
import { Tabs } from "antd";
import { useAppDispatch } from "../../redux/hooks";
import GeneralDocument from "./GeneralDocuments";
import ArchivedDocumnet from "./ArchivedDocument";

const { TabPane } = Tabs;

type Props = {
  type: string;
};

const DocumentList: React.FC<Props> = ({ type }) => {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<string>(
    sessionStorage.getItem("documnet-tab") || "Documents"
  );

  const tabChangeHandler = (key: string) => {
    setSelectedTab(key);
    sessionStorage.setItem("document-tab", key);
  };
  return (
    <AppLayout>
      <Navigation />
      <DocumentsHeader type={selectedTab} />
      <Tabs
        defaultActiveKey="selectedTab"
        className="singer-cutsom-tab"
        onChange={tabChangeHandler}
        destroyInactiveTabPane
      >
        <TabPane tab="General Documents" key="Documents">
          <>
            <GeneralDocument />
          </>
        </TabPane>
        <TabPane tab="Archive Document" key="Archive">
          <>
            <ArchivedDocumnet />
          </>
        </TabPane>
      </Tabs>
    </AppLayout>
  );
};

export default DocumentList;
