import { NavLink } from "react-router-dom";
import {
  LogoSidebar,
  Dashboard,
  DashboardActive,
  Participants,
  ParticipantsActive,
  ActionsActive,
  Actions,
  Programs,
  ProgramsActive,
  AdministratorsActive,
  Administrators,
  DocumentsActive,
  Documents,
  ForumActive,
  ForumInActive,
  DairyActive,
  Dairy,
  Content,
  ContentActive,
  Questionnaires,
  QuestionnairesActive,
  AppointmentActive,
  AppointmentInActive,
  ReportsActive,
  Reports,
  MetricsActive,
  MetricsInactive,
  AuditActive,
  AuditInactive,
} from "./SidebarIcons";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import roles from "../../constants/roles";
// interface SidebarItem {
//   name: string;
//   children?: Array<SidebarItem>;
//   path: string;
//   icon: string;
//   activeIcon: string;
//   key: string;
// }

const Sidebar: React.FC = () => {
  const [role] = useState(localStorage.getItem("role") || "");

  //   const bar =
  //     checkStorage() === "project_manager"
  //       ? sidebarCoach
  //       : checkStorage() === "system_admin"
  //       ? sidebarAdmin
  //       : sidebarClient;

  const history = useHistory();
  return (
    <div className="sidebar nova-white-sidebar">
      <div className="logo-container mb-5 cp" onClick={() => history.push("/")}>
        <LogoSidebar />
      </div>
      <div className="main-sidebar">
        <NavLink
          className="item"
          activeClassName="active"
          to="/dashboard"
          exact
        >
          {window.location.href.includes("dashboard") ? (
            <DashboardActive />
          ) : (
            <Dashboard />
          )}
          <p className="nav-elements">Dashboard</p>
        </NavLink>
        <NavLink
          className="item"
          activeClassName="active"
          to="/appointments"
          exact
        >
          {window.location.href.includes("appointments") ? (
            <AppointmentActive />
          ) : (
            <AppointmentInActive />
          )}
          <p className="nav-elements">Appointments</p>
        </NavLink>
        <NavLink
          className="item"
          activeClassName="active"
          to="/participants"
          exact
        >
          {window.location.href.includes("participants") ? (
            <ParticipantsActive />
          ) : (
            <Participants />
          )}
          <p className="nav-elements">Users</p>
        </NavLink>
        {role === roles.Admin["be_value"] && (
          <NavLink className="item" activeClassName="active" to="/actions">
            {window.location.href.includes("actions") ? (
              <ActionsActive />
            ) : (
              <Actions />
            )}
            <p className="nav-elements">Actions</p>
          </NavLink>
        )}
        {role === roles.Admin["be_value"] && (
          <NavLink className="item" activeClassName="active" to="/programs">
            {window.location.href.includes("programs") ? (
              <ProgramsActive />
            ) : (
              <Programs />
            )}
            <p className="nav-elements">Programs</p>
          </NavLink>
        )}
        {role === roles.Admin["be_value"] && (
          <NavLink
            className="item"
            activeClassName="active"
            to="/administrator"
          >
            {window.location.href.includes("administrator") ? (
              <AdministratorsActive />
            ) : (
              <Administrators />
            )}
            <p className="nav-elements">Administrators</p>
          </NavLink>
        )}
        {role === roles.Admin["be_value"] && (
          <NavLink className="item" activeClassName="active" to="/patientslist">
            {window.location.href.includes("patientslist") ? (
              <MetricsActive />
            ) : (
              <MetricsInactive />
            )}
            <p className="nav-elements">Vitals</p>
          </NavLink>
        )}
        <NavLink className="item" activeClassName="active" to="/documents">
          {window.location.href.includes("documents") ? (
            <DocumentsActive />
          ) : (
            <Documents />
          )}
          <p className="nav-elements">Documents</p>
        </NavLink>
        <NavLink className="item" activeClassName="active" to="/question">
          {window.location.href.includes("question") ? (
            <QuestionnairesActive />
          ) : (
            <Questionnaires />
          )}
          <p className="nav-elements">Questionnaires</p>
        </NavLink>
        <NavLink className="item" activeClassName="active" to="/education">
          {window.location.href.includes("education") ? (
            <ContentActive />
          ) : (
            <Content />
          )}
          <p className="nav-elements">Content</p>
        </NavLink>
        <NavLink className="item" activeClassName="active" to="/forums">
          {window.location.href.includes("/forums") ? (
            <ForumActive />
          ) : (
            <ForumInActive />
          )}
          <p className="nav-elements">Forum</p>
        </NavLink>

        <NavLink className="item" activeClassName="active" to="/reports">
          {window.location.href.includes("/reports") ? (
            <ReportsActive />
          ) : (
            <Reports />
          )}
          <p className="nav-elements">Reports</p>
        </NavLink>

        <NavLink className="item" activeClassName="active" to="/diary">
          {window.location.href.includes("/diary") ? (
            <DairyActive />
          ) : (
            <Dairy />
          )}
          <p className="nav-elements">Diary</p>
        </NavLink>

        {role === roles.Admin["be_value"] && (
          <NavLink className="item" activeClassName="active" to="/audit-logs">
            {window.location.href.includes("audit-logs") ? (
              <AuditActive />
            ) : (
              <AuditInactive />
            )}
            <p className="nav-elements">Audit Trail</p>
          </NavLink>
        )}

        {/* <NavLink className="item" activeClassName="active" to="/inforcards">
          {window.location.href.includes("inforcards") ? (
            <InforCardsActive />
          ) : (
            <InforCards />
          )}
          <p className="nav-elements">Inforcards</p>
        </NavLink> */}
      </div>
    </div>
  );
};

export default Sidebar;
