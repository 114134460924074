import { Table, Avatar, Dropdown, Menu } from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { errorToastMessage } from "../../helpers/toastMessage";
import { getImageInitials } from "../../helpers/user";
import {
  deleteForumComment,
  fetchForumComments,
} from "../../redux/actions/forumAction";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";

const ForumComments = ({ id }: any) => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { searchTerm, toggleLoader, comments, commentsCount, forumDetails } =
    useAppSelector((state) => state.forum);
  const toggleRef = useRef(toggleLoader);

  const fetchCommentData = useCallback(
    async (id, searchTerm, page) => {
      try {
        await dispatch(fetchForumComments(id, searchTerm, page));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    fetchCommentData(id, searchTerm, 1);
  }, [searchTerm, id, fetchCommentData]);

  useEffect(() => {
    if (toggleLoader !== toggleRef.current) {
      fetchCommentData(id, searchTerm, currentPage);
      toggleRef.current = toggleLoader;
    }
  }, [searchTerm, id, fetchCommentData, toggleLoader, currentPage]);

  const onTableChange = async (pagination: any, filters: any, sorters: any) => {
    let page = 1;
    if (pagination.current) {
      setCurrentPage(pagination.current);
      page = pagination.current;
    }
    try {
      await dispatch(fetchForumComments(id, searchTerm, page));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const deleteHandler = useCallback(
    (commentId) => {
      dispatch(deleteForumComment(id, commentId));
    },
    [id, dispatch]
  );

  const menu = (data: any) => (
    <Menu className="action-dropdown">
      <Menu.Item key="0" onClick={() => deleteHandler(data.id)}>
        Remove Comment
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "User",
      dataIndex: "commenter",
      key: "commenter",
      render: (commenter: any) => {
        let isModerator = false;
        if (forumDetails.moderators) {
          isModerator = forumDetails.moderators.some((user: any) => {
            return user.id === commenter.id;
          });
        }
        return (
          <div className="d-flex">
            {commenter.profileImage ? (
              <Avatar
                shape="circle"
                size="large"
                src={commenter.profileImage}
              />
            ) : (
              <Avatar shape="circle" size="large">
                {getImageInitials(commenter.firstName, commenter.lastName)}
              </Avatar>
            )}
            <div className="ms-3 commenter-details">
              <p className="text-s m-0 fw-medium">
                {commenter.firstName + " " + commenter.lastName}
              </p>
              {isModerator && (
                <span className="moderator-label">Moderator</span>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => (
        <span>{moment(text).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "Comment",
      key: "comment",
      dataIndex: "comment",
      width: "30%",
    },
    {
      title: "Likes",
      key: "likeCount",
      dataIndex: "likeCount",
      width: "15%",
    },
    {
      title: "",
      width: "10%",
      className: "text-end",
      render: (_1: any, record: any) => {
        return (
          <span onClick={(e) => e.stopPropagation()} className="d-inline-block">
            <Dropdown
              overlay={menu(record)}
              placement="bottomRight"
              trigger={["click"]}
            >
              <i className="more-icon cp"></i>
            </Dropdown>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Table
        dataSource={comments}
        columns={columns}
        className="singer-custom-table"
        onChange={onTableChange}
        rowKey={(record) => record.id}
        pagination={{
          current: currentPage,
          total: commentsCount,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
          },
          position: ["bottomLeft"],
        }}
      />
    </>
  );
};

export default ForumComments;
