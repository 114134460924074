const BpIcon = ({ width, height }: { width: string; height: string }) => (
  <svg
    width={width || "24"}
    height={height || "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.588 8.3169C18.5419 8.1576 18.3756 8.06549 18.2163 8.11229C18.057 8.1582 17.9655 8.3247 18.0117 8.48368C18.186 9.08397 18.2154 9.57327 18.1068 10.0692C18.0717 10.2312 18.174 10.3911 18.336 10.4262C18.3573 10.4307 18.3789 10.4331 18.4002 10.4331C18.5382 10.4331 18.6624 10.3374 18.693 10.1979C18.8224 9.6066 18.7888 9.00901 18.588 8.3169Z"
      fill="#333333"
      className="fill-path"
    />
    <path
      d="M17.9682 7.06556C17.574 6.27746 16.18 5.97477 15.4656 6.33146C15.3175 6.40557 15.2575 6.58585 15.3316 6.73407C15.4057 6.88225 15.5854 6.94199 15.7342 6.86816C16.2163 6.62786 17.2156 6.90205 17.4316 7.33405C17.4844 7.43934 17.5903 7.49995 17.7004 7.49995C17.7454 7.49995 17.7913 7.48975 17.8342 7.46813C17.9823 7.39406 18.0423 7.21374 17.9682 7.06556Z"
      fill="#333333"
      className="fill-path"
    />
    <path
      d="M18.9908 6.2403C17.9024 5.08532 16.7108 4.5 15.4487 4.5C14.0378 4.5 12.7613 5.34959 11.9999 6.75689C11.2394 5.34959 9.96652 4.5 8.56101 4.5C7.30551 4.5 6.1475 5.06851 5.02102 6.23791C3.72622 7.58162 3.17602 10.6746 5.51033 12.9867C6.61132 14.0766 11.7329 19.3557 11.7845 19.4088C11.8412 19.4673 11.9186 19.5 11.9999 19.5C12.0809 19.5 12.1586 19.4673 12.2153 19.4091C12.2669 19.3557 17.3984 14.0769 18.4994 12.9867C20.8112 10.6968 20.2703 7.59723 18.9908 6.2403ZM18.0773 12.5604C17.1023 13.5258 12.9914 17.7507 12.0002 18.7695C11.0108 17.7504 6.90772 13.5258 5.93273 12.5604C3.87264 10.5204 4.33073 7.81952 5.45334 6.65402C6.46074 5.60823 7.47714 5.10001 8.56105 5.10001C9.90475 5.10001 11.1155 6.04919 11.721 7.57712C11.7666 7.69173 11.8769 7.76672 12 7.76672C12.123 7.76672 12.2336 7.69173 12.279 7.57712C12.8844 6.04923 14.0991 5.10001 15.4488 5.10001C16.5542 5.10001 17.57 5.60732 18.5544 6.65191C19.6652 7.83031 20.1176 10.5396 18.0773 12.5604Z"
      fill="#333333"
      stroke="#333333"
      className="fill-path-stroke"
      strokeWidth="0.7"
    />
    <path
      d="M20.7 12.3002H15.7605L15.2496 11.534C15.1383 11.3669 14.8617 11.3669 14.7504 11.534L13.872 12.8519L12.5802 9.49277C12.5325 9.36948 12.4077 9.28996 12.2778 9.30138C12.1458 9.31098 12.036 9.40608 12.0072 9.53538L11.0001 14.0675L9.57267 10.9745C9.52918 10.8803 9.43978 10.8149 9.33656 10.8026C9.23247 10.7873 9.13076 10.8317 9.06596 10.9127L7.95598 12.3002H3.29999C3.1344 12.3002 3 12.4343 3 12.6002C3 12.7661 3.1344 12.9002 3.29999 12.9002H8.09998C8.19117 12.9002 8.27727 12.8587 8.33429 12.7877L9.23099 11.6666L10.8276 15.1259C10.8771 15.2327 10.9842 15.3002 11.1 15.3002C11.1105 15.3002 11.1213 15.2996 11.1317 15.2984C11.2599 15.2846 11.3648 15.191 11.3927 15.0653L12.3771 10.6364L13.5201 13.6079C13.5602 13.7126 13.6556 13.7861 13.767 13.7984C13.8791 13.8104 13.9875 13.7603 14.0495 13.6667L15 12.2411L15.3504 12.7667C15.4061 12.8501 15.4998 12.9002 15.6 12.9002H20.7C20.8656 12.9002 21 12.7661 21 12.6002C21 12.4343 20.8656 12.3002 20.7 12.3002Z"
      fill="#333333"
      stroke="#333333"
      className="fill-path-stroke"
      strokeWidth="0.7"
    />
  </svg>
);
export default BpIcon;
