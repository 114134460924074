import { Avatar } from "antd";
import React from "react";
import { errorToastMessage } from "../../helpers/toastMessage";
import { getImageInitials } from "../../helpers/user";
import http from "../../http";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { setModalDetails } from "../../redux/reducers/modalSlice";

const PatientDetails = () => {
  const dispatch = useAppDispatch();
  const { user, userId } = useAppSelector(
    (state) => state.console.patientMetric
  );

  const loadQuestionnaires = async () => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.get(`questionnaires/user-completion/${userId}`);
      const data = res.data.data;
      dispatch(
        setModalDetails({
          type: "PATIENT_QUESTIONNAIRE_RESPONSE_LIST",
          modalProps: {
            questionnaire: data,
            show: true,
          },
        })
      );
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

  return (
    <div className="row shadow-s mx-0 mb-3 p-3 bg-white rounded personalDetails">
      <div className="pl-0 detail-cell">
        <div className="d-flex align-items-center">
          {user?.profileImage ? (
            <Avatar shape="circle" size="large" src={user.profileImage} />
          ) : (
            <Avatar shape="circle" size="large">
              {getImageInitials(user?.firstName, user?.lastName)}
            </Avatar>
          )}
          <div className="ms-2 d-flex flex-column justify-content-center">
            <div className="name-label">
              {user?.firstName + " " + user?.lastName}
            </div>
            <div className="email-label">{user?.email}</div>
          </div>
        </div>
      </div>
      <div className="detail-cell">
        <div>
          <div className="label">Phone number</div>
          <div className="value">{user?.mobile || "+65 1234567"}</div>
        </div>
      </div>

      <div className="detail-cell">
        <div>
          <div className="label">Date of Birth</div>
          <div className="value">05-Mar-1990</div>
        </div>
      </div>

      <div className="detail-cell">
        <div>
          <div className="label">Current Weight</div>
          <div className="value">185.0Lbs(Nov 6, 2021)</div>
        </div>
      </div>
      <div className="detail-cell">
        <div>
          <div className="label">Height</div>
          <div className="value">66.0 inches</div>
        </div>
      </div>
      <div className="detail-cell">
        <div>
          <div className="label">BMI</div>
          <div className="value">31.5(Obese)</div>
        </div>
      </div>
      <div className="detail-cell">
        <div>
          <div className="label color-primary cp" onClick={loadQuestionnaires}>
            Questionnaires
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
