import { Line } from "react-chartjs-2";

const graphData = [
  { date: "22/05/2020", answers: 150 },
  { date: "23/05/2020", answers: 170 },
  { date: "24/05/2020", answers: 225 },
  { date: "25/05/2020", answers: 230 },
  { date: "26/05/2020", answers: 145 },
  { date: "27/05/2020", answers: 120 },
  { date: "28/05/2020", answers: 144 },
  { date: "29/05/2020", answers: 150 },
  { date: "30/05/2020", answers: 230 },
  { date: "31/05/2020", answers: 300 },
  { date: "01/06/2020", answers: 245 },
];

let width: any, height: any, gradient: any;

function getGradient(ctx: any, chartArea: any) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, "rgba(196, 196, 196, 0)");
    gradient.addColorStop(0.91, "rgba(58, 199, 153, 0.08)");
    gradient.addColorStop(1, "rgba(255, 255, 255, 0.08)");
  }
  return gradient;
}

const data = {
  labels: graphData.map((entry) => {
    return entry.date;
  }),
  datasets: [
    {
      borderColor: "#3AC799",
      data: graphData.map((entry) => entry.answers),
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointHoverBackgroundColor: "#3AC799",
      pointHoverBorderColor: "#3AC799",
      borderWidth: 2,
      fill: true,
      backgroundColor: function (context: any) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          // This case happens on initial chart load
          return null;
        }
        return getGradient(ctx, chartArea);
      },
    },
  ],
};

const options = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: false,
      },
      grid: {
        display: false,
      },
      display: false,
    },
    x: {
      grid: {
        borderDash: [8, 4],
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsbold",
            size: 11,
            lineHeight: "16px",
            weight: 500,
          };
        },
        autoSkip: false,
        maxRotation: 65,
        minRotation: 45,
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "#3AC799",
      titleColor: "#ffffff",
      bodyColor: "#ffffff",
      bodyFont: {
        size: 14,
        weight: "bold",
      },
      caretSize: 0,
      displayColors: false,
      callbacks: {
        label: function (context: any) {
          if (context.parsed.y !== null) {
            return context.parsed.y + " Answers";
          }
          return "";
        },
        title: function () {
          return "";
        },
      },
    },
  },
};

const AnswerLineChart = () => {
  return (
    <div className="questionnaire-graph">
      <Line data={data} options={options} height={300} />
    </div>
  );
};

export default AnswerLineChart;
