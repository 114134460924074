import { Input } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import CustomButton from "../Common/CustomButton";
import { debounce } from "lodash";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { setForumSearchTerm } from "../../redux/reducers/forumSlice";

const ForumHeader = ({ type, id }: any) => {
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector((state) => state.forum.searchTerm);
  const [search, setSearch] = useState<string>("");
  const forumDetails = useAppSelector((state) => state.forum.forumDetails);

  useEffect(() => {
    setSearch(searchTerm);
  }, [searchTerm]);

  const modifySearchTerm = useMemo(() => {
    return debounce((val) => {
      dispatch(setForumSearchTerm(val));
    }, 500);
  }, [dispatch]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearch(val);
    modifySearchTerm(val);
  };

  const handleButtonClick = () => {
    if (type === "Users") {
      dispatch(
        setModalDetails({
          type: "ADD_PARTICIPANT_TO_FORUM",
          modalProps: {
            show: true,
            id: id,
          },
        })
      );
    } else {
      dispatch(
        setModalDetails({
          type: "ADD_FORUM_COMMENT",
          modalProps: {
            show: true,
            id: id,
          },
        })
      );
    }
  };

  return (
    <div className="d-block d-md-flex justify-content-between align-items-center">
      <span className="d-flex align-items-center mb-4">
        <h2 className="font-l mb-0 fw-bold me-3">{forumDetails.title}</h2>
        <Input
          value={search}
          onChange={changeHandler}
          style={{ width: "300px" }}
          placeholder={"Search " + type}
          className="search-input"
        />
      </span>
      {(type === "Comments" || forumDetails.type === "closed") && (
        <CustomButton
          primary={true}
          clickHandler={handleButtonClick}
          buttonText={`Add ${type}`}
          addButton={true}
          buttonType={"button"}
          className="btn-lg mb-4"
        />
      )}
    </div>
  );
};

export default ForumHeader;
