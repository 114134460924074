import React, { useEffect, useState, useCallback, useRef } from "react";
import { Avatar, Table, Menu, Dropdown } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { errorToastMessage } from "../../helpers/toastMessage";
import {
  fetchAdministratorDetails,
  modifyUserDetails,
} from "../../redux/actions/adminAction";
import roles from "../../constants/roles";
import { SortOrder } from "antd/lib/table/interface";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { useHistory } from "react-router-dom";
import { getImageInitials } from "../../helpers/user";

const TherapistListView: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState<any | null>(null);
  const [filter, setFilter] = useState("");
  const { searchTerm, therapistCount, therapistUsers, toggleLoader } =
    useAppSelector((state) => state.administrator);
  const toggleRef = useRef(toggleLoader);

  const fetchData = useCallback(
    async (search) => {
      try {
        await dispatch(
          fetchAdministratorDetails(
            roles.Therapist["be_value"],
            search,
            1,
            "",
            null
          )
        );
        setCurrentPage(1);
        setFilter("");
        setSort(null);
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, setCurrentPage, setFilter, setSort]
  );

  useEffect(() => {
    fetchData(searchTerm);
  }, [searchTerm, fetchData]);

  useEffect(() => {
    if (toggleLoader !== toggleRef.current) {
      fetchData(searchTerm);
      toggleRef.current = toggleLoader;
    }
  }, [searchTerm, fetchData, toggleLoader]);

  const onTableChange = async (pagination: any, filters: any, sorters: any) => {
    let page = 1;
    let filter = "";
    if (filters.status) {
      setFilter(filters.status[0]);
      filter = filters.status[0];
    } else {
      setFilter("");
    }
    let sort = null;
    if (sorters.order) {
      sort = {
        orderBy: sorters["order"] === "ascend" ? "asc" : "desc",
        sortBy: "firstName",
        //hardcoded because BE accepts only firstName as param
      };
      setSort(sort);
    } else {
      setSort(null);
    }
    if (pagination.current) {
      setCurrentPage(pagination.current);
      page = pagination.current;
    }
    try {
      await dispatch(
        fetchAdministratorDetails(
          roles.Therapist["be_value"],
          searchTerm,
          page,
          filter,
          sort
        )
      );
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const modifyUser = async (data: any) => {
    try {
      const obj = {
        status: data.status === "inactive" ? "active" : "inactive",
      };
      await dispatch(modifyUserDetails(obj, data.id));
      await dispatch(
        fetchAdministratorDetails(
          roles.Therapist["be_value"],
          searchTerm,
          currentPage,
          filter,
          sort
        )
      );
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const modifySlots = (therapistId: string) => {
    dispatch(
      setModalDetails({
        type: "THERAPIST_SLOT_MODAL",
        modalProps: {
          show: true,
          therapistId,
        },
      })
    );
  };

  const editTherapist = (id: string) => {
    history.push(`/therapist-profile/edit/${id}`);
  };

  const navigateToCalendar = (id: string) => {
    history.push(`/administrator/calendar/${id}`);
  };

  const navigateToAssociatedUsers = (id: string) => {
    history.push(`/associated-users/${id}`);
  };

  const menu = (data: any) => (
    <Menu className="action-dropdown">
      <Menu.Item key="0" onClick={() => editTherapist(data.id)}>
        Edit Info
      </Menu.Item>
      <Menu.Item key="1" onClick={() => modifyUser(data)}>
        {data.status === "inactive" ? "Activate" : "Deactivate"}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => modifySlots(data.id)}>
        Manage Appointments
      </Menu.Item>
      <Menu.Item key="3" onClick={() => navigateToCalendar(data.id)}>
        View Calendar
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: "30%",
      sortOrder:
        sort?.sortBy === "firstName"
          ? sort.orderBy === "asc"
            ? ("ascend" as SortOrder)
            : ("descend" as SortOrder)
          : undefined,
      render: (_1: any, record: any) => {
        return (
          <div className="d-flex">
            {record.profileImage ? (
              <Avatar shape="circle" size="large" src={record.profileImage} />
            ) : (
              <Avatar shape="circle" size="large">
                {getImageInitials(record.firstName, record.lastName)}
              </Avatar>
            )}
            <div className="ms-3">
              <p className="m-0 fw-medium">
                {record.firstName + " " + record.lastName}
              </p>
              <span className="text-muted font-xs">{record.email}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "phoneNumber",
      key: "mobile",
      width: "30%",
    },
    {
      title: "Associated Users",
      dataIndex: "patientReferences",
      key: "users",
      render: (patientReferences: any, record: any) => {
        return patientReferences?.length ? (
          <span
            className="cp primary-color"
            onClick={() => navigateToAssociatedUsers(record.id)}
          >
            {patientReferences.length}
          </span>
        ) : (
          <span>-</span>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: "20%",
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Inactive",
          value: "inactive",
        },
      ],
      filterMultiple: false,
      filteredValue: filter ? [filter] : null,
      render: (text: string) => (
        <span
          className={
            text === "active" ? "active-tag badge" : "inactive-tag badge"
          }
        >
          {text}
        </span>
      ),
    },
    {
      title: "",
      width: "10%",
      className: "text-end",
      render: (_1: any, record: any) => {
        return (
          <span onClick={(e) => e.stopPropagation()} className="d-inline-block">
            <Dropdown
              overlay={menu(record)}
              placement="bottomRight"
              trigger={["click"]}
            >
              <i className="more-icon cp"></i>
            </Dropdown>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Table
        dataSource={therapistUsers}
        columns={columns}
        showSorterTooltip={false}
        className="singer-custom-table"
        onChange={onTableChange}
        rowKey={(record) => record.id}
        pagination={{
          current: currentPage,
          total: therapistCount,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
          },
          position: ["bottomLeft"],
        }}
      />
    </>
  );
};

export default TherapistListView;
