import { Tabs } from "antd";
import { useAppSelector } from "../../../redux/hooks";
import AppLayout from "../../AppLayout/AppLayout";
import Navigation from "../../Navigation/Navigation";
import Dashboard from "../Dashboard";
import DashBoardOverview from "./DashboardOverview";
import DigitalDataOverview from "./DigitalDataOverview";
import EducationOverview from "./EducationOverview";
import NotesOverview from "./NotesOverview";
import QuestionnaireOverview from "./QuestionnaireOverview";
import QuestionnaireResponse from "./QuestionnaireResponse";
import ReminderOverview from "./ReminderOverview";

const { TabPane } = Tabs;

const ProgramOverview = () => {
  const responseId = useAppSelector(
    (state) => state.dashboard.questionnaireResponseId
  );

  return (
    <AppLayout themelight>
      <Navigation />
      {/* <h2 className="font-l mb-5 fw-bold">Program Overview</h2> */}
      <div className="dashboard-question-wrapper">
        <Tabs className="singer-cutsom-tab program-overview">
          <TabPane tab="Platform Metrics" key="platform">
            <Dashboard />
          </TabPane>
          <TabPane tab="Program Metrics" key="program">
            <DashBoardOverview />
          </TabPane>
          <TabPane tab="Questionnaires" key="questionnaires">
            <QuestionnaireOverview />
          </TabPane>
          <TabPane tab="Education Lessons" key="edu-lessons">
            <EducationOverview />
          </TabPane>
          <TabPane tab="Digital Data" key="digitaldata">
            <DigitalDataOverview />
          </TabPane>
          <TabPane tab="Reminders" key="reminders">
            <ReminderOverview />
          </TabPane>
          <TabPane tab="Notes" key="notes">
            <NotesOverview />
          </TabPane>
        </Tabs>
        {responseId && <QuestionnaireResponse />}
      </div>
    </AppLayout>
  );
};

export default ProgramOverview;
