import React, { useState } from "react";
import { Modal, Upload, Tooltip, Input, DatePicker } from "antd";
import { Formik } from "formik";
import InputField from "../../Common/CustomInput";
import { toastMessage } from "../../../helpers/toastMessage";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { UploadImage } from "../../../helpers/imageUpload";
import {
  addTipsOfday,
  UpdateTipsOfDay,
} from "../../../redux/actions/educationAction";
import Button from "../../Common/CustomButton";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

let yup = require("yup");

let schema = yup.object().shape({
  title: yup.string().trim().required("Name is Required"),
  externalUrl: yup.string().trim().required("External URL is Required"),
  date: yup.string().required("date is Required"),
  description: yup.string().trim().required("Description is Required"),
  //   status: yup.string().required(),
  imageUrl: yup.string().nullable().optional(),
});

const AddTipsOfDayModal = (props: any) => {
  const dispatch = useAppDispatch();
  const { tipData, show } = useAppSelector((state) => state.modal.modalProps);
  const [imgData, setImgData] = useState({
    expiresAt: "",
    fileName: "",
    postUploadImageUrl: "",
    presignedUploadUrl: "",
    type: "",
  });

  const initialValues = props.tipData
    ? {
        title: props.tipData.title,
        description: props.tipData.description,
        imageUrl: props.tipData.imageUrl,
        // status: "active",
        externalUrl: props.tipData.externalUrl,
        date: props.tipData.date,
      }
    : {
        title: "",
        description: "",
        imageUrl: "",
        // status: "active",
        externalUrl: "",
        date: moment().format("YYYY-MM-DD"),
      };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      toastMessage("warning", "Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleUpload = (data: any) => {
    setImgData(data[0]);
  };

  const handleAvater = (fileObj: any) => {
    UploadImage(fileObj, handleUpload, "education_lesson_image")();
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = (values: any) => {
    const formData = { ...values };
    formData.imageUrl =
      imgData.postUploadImageUrl !== ""
        ? imgData.postUploadImageUrl
        : formData.imageUrl;
    if (formData.imageUrl === "" || !formData.imageUrl) {
      delete formData.imageUrl;
    }
    if (tipData) {
      dispatch(UpdateTipsOfDay(formData, tipData.id));
    } else {
      dispatch(addTipsOfday(formData));
    }
  };

  return (
    <Modal
      title={tipData ? `Edit ${tipData.title}` : "Add new Tip of the day"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="add-education-wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-container mt-0">
                    <div className="form-label">Title*</div>
                    <div className="floating-label">
                      <InputField
                        name="title"
                        placeholder=""
                        changeEvent={handleChange}
                        className="floating-input"
                        value={values.title}
                        blurEvent={handleBlur}
                      />
                      <div className="input-error spaced-error">
                        {errors.title && touched.title && errors.title}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-container mt-0">
                    <div className="form-label">Date*</div>
                    <DatePicker
                      className="form-ant-picker"
                      allowClear={false}
                      value={moment(values.date, "YYYY-MM-DD")}
                      onChange={(date) => {
                        if (date) {
                          setFieldValue("date", date.format("YYYY-MM-DD"));
                        }
                      }}
                    />
                    <div className="input-error spaced-error">
                      {errors.date && touched.date && errors.date}
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="input-container mt-0">
                    <label className="form-label">External URL</label>
                    <Tooltip placement="right" title={values.externalUrl}>
                      <div className="floating-label">
                        <InputField
                          name="externalUrl"
                          placeholder=""
                          changeEvent={handleChange}
                          className="floating-input"
                          value={values.externalUrl}
                          blurEvent={handleBlur}
                        />
                      </div>
                    </Tooltip>
                    <div className="input-error spaced-error">
                      {errors.externalUrl &&
                        touched.externalUrl &&
                        errors.externalUrl}
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="input-container mt-0">
                    <label className="form-label">Description</label>
                    <Tooltip placement="right" title={values.description}>
                      <div className="floating-label">
                        <InputField
                          name="description"
                          placeholder=""
                          changeEvent={handleChange}
                          className="floating-input"
                          value={values.description}
                          blurEvent={handleBlur}
                        />
                      </div>
                    </Tooltip>
                    <div className="input-error spaced-error">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </div>
                </div>

                <div className="col-md-8 image-row form-group">
                  <label htmlFor="lastname">Upload module picture</label>
                  <div className="row">
                    <div className="col-md-5">
                      {imgData.postUploadImageUrl !== "" ? (
                        <div className="image-container">
                          <img src={imgData.postUploadImageUrl} alt="module" />
                        </div>
                      ) : values.imageUrl !== null && values.imageUrl !== "" ? (
                        <>
                          <div className="image-container">
                            <img src={values.imageUrl} alt="module" />
                          </div>
                        </>
                      ) : (
                        <div className="image-wrapper">
                          <div className="placeholder-icon"></div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-7 ps-0 upload-wrapper">
                      <Input
                        disabled
                        placeholder={
                          imgData.fileName === ""
                            ? "No file selected"
                            : imgData.fileName
                        }
                      />
                      <Upload
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        customRequest={handleAvater}
                        accept=".gif,.jpg,.jpeg,.png"
                      >
                        <button type="button" className="upload-button">
                          Upload File
                        </button>
                      </Upload>
                    </div>
                  </div>
                  <div className="mt-3">
                    Upload an image of max 5 mb, accepted formats are
                    .jpg,.jpeg,.png{" "}
                  </div>
                </div>

                <div className="button-container text-end">
                  <span
                    className="button-cancel ms-2 cp"
                    onClick={closeHandler}
                  >
                    Cancel
                  </span>
                  {/* <button className="draft m-2">Save Draft</button> */}
                  <Button
                    primary={false}
                    buttonType="submit"
                    buttonText="Save"
                    className="m-2 button-save cp"
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddTipsOfDayModal;
