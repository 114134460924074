import React, { useEffect } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { setConsoleChart } from "../../../redux/reducers/consoleSlice";
import ChartEntries from "./ChartEntries";
import ChartHeader from "./ChartHeader";
import ChartSelector from "./ChartSelector";

const PatientChartWrapper = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setConsoleChart("Steps"));
    return () => {
      dispatch(setConsoleChart(null));
    };
  }, [dispatch]);
  return (
    <div className="col-lg-4 col-12">
      <div className="shadow-s mb-5 bg-white rounded">
        <ChartHeader />
        <div id="charts" className="ps-1 pe-1" style={{ height: "300px" }}>
          <ChartSelector />
        </div>
        <div className="ps-3 pe-3">
          <hr />
        </div>
        <ChartEntries />
      </div>
    </div>
  );
};

export default PatientChartWrapper;
