import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import history from "../../history";
import http from "../../http";
import { setAppLoader } from "../reducers/loaderSlice";
import {
  addProgramExercise,
  addProgramFood,
  setProgramDetails,
} from "../reducers/programSlice";
import { AppThunk } from "../store";

export const editProgram =
  (formData: any): AppThunk =>
  async (dispatch, getState) => {
    const finalForm = {
      ...formData,
    };
    const {
      programId,
      addLessons,
      deleteLessons,
      lessonData,
      addQuestions,
      deleteQuestion,
      addFoodDiary,
      addExerciseDiary,
      deleteExerciseDiary,
      deleteFoodDiary,
    } = getState().program;
    let lessonIndex = lessonData.length - addLessons.length + 1;
    const addEducationLessons = addLessons.map((lesson) => {
      return {
        educationLessonId: lesson.id,
        duration: +lesson.duration,
        position: lessonIndex++,
      };
    });
    const addQuestionnaires = addQuestions.map((question) => {
      return {
        questionnaireId: question.id,
        duration: +question.duration,
      };
    });
    if (addEducationLessons.length > 0) {
      finalForm.addEducationLessons = addEducationLessons;
    }
    if (deleteLessons.length > 0) {
      finalForm.removeEducationLessons = deleteLessons;
    }
    if (addQuestionnaires.length > 0) {
      finalForm.addQuestionnaires = addQuestionnaires;
    }
    if (deleteQuestion.length > 0) {
      finalForm.removeQuestionnaires = deleteQuestion;
    }
    if (addFoodDiary.length > 0) {
      finalForm.addDiaryFoods = addFoodDiary.map((food) => {
        return food.id;
      });
    }
    if (deleteFoodDiary.length > 0) {
      finalForm.removeDiaryFoods = deleteFoodDiary;
    }
    if (deleteExerciseDiary.length > 0) {
      finalForm.removeDiaryExercises = deleteExerciseDiary;
    }
    if (addExerciseDiary.length > 0) {
      finalForm.addDiaryExercises = addExerciseDiary.map((exercise) => {
        return exercise.id;
      });
    }
    console.log(finalForm);
    await http.put(`/programs/${programId}`, finalForm);
    toastMessage("success", "Program edited successfully");
    dispatch(fetchProgramDetails());
  };

export const fetchProgramDetails =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.get(
        `/programs/singer`
        // ade58392-631c-4435-908d-d4a0ebbfea75
      );
      const response = res.data.data;
      if (!response) {
        throw new Error("Program not found");
      }
      dispatch(
        setProgramDetails({
          id: response.id,
          basic: {
            title: response.title || "",
            description: response.description || "",
            notes: response.notes || "",
            duration: response.duration || 0,
            consentUrl: response.consentUrl || "",
          },
          lessons: response.educationLessons.map((lesson: any) => {
            return {
              ...lesson,
              duration: lesson.program_education_lesson.duration,
            };
          }),
          questions: response.questionnaires.map((question: any) => {
            return {
              ...question,
              duration: question.program_questionnaire.duration,
            };
          }),
          foods: response.foodItems.map((food: any) => food.food),
          exercises: response.exerciseItems.map(
            (exercise: any) => exercise.exercise
          ),
        })
      );
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
      history.push("/dashboard");
    }
  };

export const addProgramFoodHandler =
  (): AppThunk => async (dispatch, getState) => {
    const foods = getState().program.dummyFoodArray;
    const oldFoods = getState().program.foodDiaryData;
    const addFoodArr = foods.filter((food) => {
      const duplicate = oldFoods.some((oldFood) => {
        return oldFood.id === food.id;
      });
      return !duplicate;
    });
    dispatch(addProgramFood(addFoodArr));
    sessionStorage.setItem("singer-program-no-load", "true");
    history.push("/programs");
  };

export const addProgramExerciseHandler =
  (): AppThunk => async (dispatch, getState) => {
    const exercises = getState().program.dummyExerciseArray;
    const oldExercises = getState().program.exerciseDiaryData;
    const addExerciseArr = exercises.filter((exercise) => {
      const duplicate = oldExercises.some((oldExercise) => {
        return oldExercise.id === exercise.id;
      });
      return !duplicate;
    });
    dispatch(addProgramExercise(addExerciseArr));
    sessionStorage.setItem("singer-program-no-load", "true");
    history.push("/programs");
  };
