import React, { useEffect, useState } from "react";
// import "../../../../pages/css/questions/questionModule.scss";
// import "../../../../pages/css/questions/questionBuilder.scss";
import { useHistory, useLocation } from "react-router-dom";
import { Checkbox, Modal } from "antd";
import { connect } from "react-redux";
import Navigation from "../../Navigation/Navigation";
import { uuid } from "uuidv4";
import { setModalDetails } from "../../../redux/reducers/modalSlice";
import Button from "../../../components/Common/CustomButton";
import DropArea from "./DropArea";
import ChoiceItem from "./ChoiceItem";

import * as questionActions from "../../../redux/actions/questionAction";
import { choices, fieldMap } from "./dataList";
import {
  questionSubmitFormat,
  questionPreviewFormat,
  logicJumpFormat,
} from "../../../helpers/question";
import { updateQuestion } from "../../../redux/actions/questionAction";
import { toastMessage, errorToastMessage } from "../../../helpers/toastMessage";
import AppLayout from "../../AppLayout/AppLayout";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const confirm = Modal.confirm;

type Props = {
  qInfo: any;
  QuestionnaireStatus: string;
  questions: Array<object>;
  setModalDetails: Function;
  createQuestionBuilder: Function;
  getBuilderQuestions: Function;
  setBuilderQuestions: Function;
  beforeLockBuilder: Function;
  setPreviewQuestions: Function;
  getQuestionnaireInfo: Function;
  updateQuestion: Function;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const QuestionBuilder = (props: Props) => {
  const history = useHistory();
  const query = useQuery();
  const qid: string = query.get("id") || "";
  const name = query.get("name");
  const viewType = query.get("view") || "staticView";

  const [questions, setQuestions] = useState<any[]>([]);
  const viewTypes = ["onlyView", "canEdit"];
  const [locked, setLocked] = useState(false);
  const [savedAsDraft, setSavedAsDraft] = useState(false);
  useEffect(() => {
    if (viewTypes.includes(viewType)) {
      props.getBuilderQuestions(qid);
      return () => {
        props.setBuilderQuestions();
      };
    }
  }, [savedAsDraft]);

  useEffect(() => {
    props.getQuestionnaireInfo(qid);
  }, [savedAsDraft]);

  useEffect(() => {
    if (viewTypes.includes(viewType)) {
      setQuestions(props.questions);
    }
  }, [props.questions.length]);

  useEffect(() => {
    if (props.QuestionnaireStatus === "published") {
      setLocked(true);
    }
  }, [props.QuestionnaireStatus]);

  const handleDrop = (type: string, index: number) => {
    const itemPosition = questions.length + 1;
    let newItem = {};
    if (qid === "demographic") {
      newItem = {
        ...fieldMap[type],
        position: itemPosition,
        type,
        id: uuid(),
        questionCode: null,
      };
    } else {
      newItem = {
        ...fieldMap[type],
        position: itemPosition,
        type,
        id: uuid(),
      };
    }
    console.log(newItem);
    if (type === "group") {
      if (qid === "demographic") {
        newItem = {
          ...newItem,
          questions: [
            {
              ...fieldMap["short_text"],
              position: 1,
              id: uuid(),
              questionCode: null,
            },
          ],
        };
      } else {
        newItem = {
          ...newItem,
          questions: [{ ...fieldMap["short_text"], position: 1, id: uuid() }],
        };
      }
    }

    if (!index) {
      const prev = questions.slice(0, +index + 1);
      const next = questions.slice(+index + 1, questions.length);
      const newQuestions = [...prev, newItem, ...next];
      setQuestions(newQuestions);
    } else {
      if (type === "group") {
        if (qid === "demographic") {
          newItem = {
            ...newItem,
            questions: [
              { ...fieldMap["short_text"], id: uuid(), questionCode: null },
            ],
          };
        } else {
          newItem = {
            ...newItem,
            questions: [{ ...fieldMap["short_text"], id: uuid() }],
          };
        }
      }
      const newQuestions = [...questions, newItem];
      setQuestions(newQuestions);
    }
  };

  const duplicateQuestion = (index: number, type: string) => {
    const prev = questions.slice(0, index + 1);
    const itemPosition = index + 2;
    const next = questions
      .slice(index + 1, questions.length)
      .map((q: any, i: number) => {
        return { ...q, position: itemPosition + i + 1 };
      });
    const newItem = {
      ...questions[index],
      position: itemPosition,
      id: uuid(),
      type: type,
    };

    setQuestions([...prev, newItem, ...next]);
  };

  const duplicationGroupQ = (group: any, choice: any) => {
    let newGroup = group;

    let subGroupIndex = group.questions.findIndex(
      (x: any) => x.id === choice.id
    );

    const prev = group.questions.slice(0, subGroupIndex + 1);
    const itemPosition = subGroupIndex + 2;
    const next = group.questions
      .slice(subGroupIndex + 1, group.questions.length)
      .map((q: any, i: number) => {
        return { ...q, position: itemPosition + i + 1 };
      });

    const newItem = {
      ...choice,
      position: itemPosition,
      id: uuid(),
      type: choice.type,
    };

    newGroup.questions = [...prev, newItem, ...next];

    setQuestions(
      questions.map((ques) => {
        return ques.id === newGroup.id ? newGroup : ques;
      })
    );
  };

  const deleteQuestion = (index: number) => {
    const newQuestions = questions.filter((_: any, i: number) => i !== index);
    setQuestions(newQuestions);
  };

  const setInnerFields = (index: number, newField: any) => {
    const prev = questions.slice(0, index);
    const next = questions.slice(index + 1, questions.length);
    const newQuestions = [...prev, newField, ...next];
    setQuestions(newQuestions);
  };

  const submitDisabled = questions.length > 0 ? false : true;

  const checkWhiteSpace = (ques: any[]) => {
    const res = ques.map((item, index) => {
      if (item.title.trim() === "") {
        throw new Error(`No white spaces are allowed in Q${index + 1} title`);
      }

      return item;
    });

    return res;
  };

  const handleSubmit = async (status: string = "active") => {
    try {
      if (submitDisabled) {
        throw new Error("You must have atleast one questionnare.");
      }

      console.log(questions);

      let ques = await questionSubmitFormat(questions);
      const logicJumps = await logicJumpFormat(questions);

      ques = checkWhiteSpace(ques);

      const formObject = {
        id: qid,
        name: name,
        questions: ques,
        logicJumps: logicJumps,
        status,
      };
      props.createQuestionBuilder(formObject, setSavedAsDraft);
    } catch (err) {
      errorToastMessage(err as Error);
    }
  };

  const handlePreview = async () => {
    try {
      if (submitDisabled) {
        throw new Error("You must have atleast one questionnare.");
      }
      if (questions.length !== props.questions.length) {
        toastMessage(
          "warning",
          "Are you sure, you want to move to preview page without saving? You may lose the questions built"
        );
      } else {
        const ques = await questionSubmitFormat(questions);
        const flattenQues = await questionPreviewFormat(ques);
        props.setPreviewQuestions(flattenQues);
        history.push(`/question/preview?id=${qid}&name=${name}&view=preView`);
      }
    } catch (err) {
      errorToastMessage(err as Error);
    }
  };

  const handleUpdate = () => {
    let body = {
      name: name,
      status: "active",
    };
    props.updateQuestion(body, qid);
  };

  const handleLocked = async () => {
    if (props.QuestionnaireStatus !== "published") {
      try {
        if (submitDisabled) {
          throw new Error("You must have atleast one questionnare.");
        }

        const ques = await questionSubmitFormat(questions);
        const logicJumps = await logicJumpFormat(questions);

        let body = {
          name: name,
          status: "published",
        };

        const formObject = {
          id: qid,
          name: name,
          questions: ques,
          logicJumps: logicJumps,
          status: "draft",
        };

        props.beforeLockBuilder(formObject, qid, body);
      } catch (err) {
        errorToastMessage(err as Error);
      }
    }
  };

  const handleCheckStatus = () => {
    if (props.QuestionnaireStatus === "published") {
      history.push(`/question/score?id=${qid}&name=${name}`);
    }
    if (props.QuestionnaireStatus !== "published") {
      toastMessage("warning", "Lock Questionnaire to add Score expressions");
    }
  };

  function showConfirm() {
    confirm({
      title: "Lock Questionnaire",
      content:
        "You will not be able to unlock and edit. Are you sure you want to Lock the Questionnaire?",
      onOk() {
        setLocked(true);
        handleLocked();
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  }

  const moveItem = (dragIndex: any, hoverIndex: any) => {
    const ques = questions[dragIndex];
    setQuestions((prevState) => {
      const newItems = prevState.filter((item: any) => item.id !== ques.id);
      newItems.splice(hoverIndex, 0, ques);
      return [...newItems];
    });
  };

  const handleSequenceDrop = (dragIndex: any, hoverIndex: any) => {
    const ques = questions[dragIndex];
    setQuestions((prevState) => {
      const newItems = prevState.filter((item: any) => item.id !== ques.id);
      newItems.splice(hoverIndex, 0, ques);
      let result = newItems.map((q: any, i: number) => {
        return { ...q, position: i + 1 };
      });
      return [...result];
    });
  };

  console.log(questions, "QUestionsList");

  return (
    <div className="question-modules question-builder">
      <Navigation />
      <div className="content-height ">
        <div className="">
          <h1 className="font-l fw-bold mb-4">Question Builder</h1>
          <h6>
            You will build your questionaire here by dragging and dropping
            different fields to the left palatte
          </h6>
        </div>

        {qid === "demographic"
          ? null
          : props.qInfo.hasOwnProperty("description") && (
              <div className="sub-header">
                <div className="title-form">{name}</div>
                <p className="mt-2">{props.qInfo.description}</p>
                <button
                  type="button"
                  className="upload-button btn-lg"
                  onClick={() => {
                    props.setModalDetails({
                      type: "VIEW_QUESTIONNAIRE_INFO",
                      modalProps: {
                        show: true,
                        questionData: props.qInfo,
                        readType: true,
                      },
                    });
                  }}
                >
                  Show more settings
                </button>
              </div>
            )}

        <div className="row builder-interface mx-0">
          <div
            className={
              typeof props.QuestionnaireStatus !== "undefined" &&
              props.QuestionnaireStatus === "published"
                ? "grey-layout disabled-div-qb col-md-4  choices p-4"
                : "grey-layout col-md-4  choices p-4"
            }
          >
            <h5 className="font-ml mb-3">Add a new question</h5>
            <p>
              Drag and drop new question types to the left. Edit or rearrange
              them after.
            </p>
            <div className="row choice-items">
              {choices.map((choice) => (
                <ChoiceItem key={choice.key} choice={choice} />
              ))}
            </div>
          </div>

          <DropArea
            questions={questions}
            handleDrop={handleDrop}
            duplicate={duplicateQuestion}
            duplicationGroupQ={duplicationGroupQ}
            delete={deleteQuestion}
            setInnerFields={setInnerFields}
            moveItem={moveItem}
            handleSequenceDrop={handleSequenceDrop}
            // questionList={getQuestionList()}
            viewType={viewType}
            questionnaireStatus={props.QuestionnaireStatus}
            qid={qid}
          />

          <div className="choice-buttons col-md-12 col-lg-12 p-0">
            {qid === "demographic" ? null : (
              <div className="choice-check-box">
                <Checkbox
                  checked={props.QuestionnaireStatus === "published"}
                  onChange={showConfirm}
                />
                <span>Lock Questionnaire</span>
              </div>
            )}
            <Button
              buttonText="Cancel"
              primary={false}
              className="cancel-button"
              clickHandler={() => setQuestions([])}
            />
            {qid === "demographic" ? null : (
              <Button
                buttonText="Save as draft"
                primary={true}
                className="mx-2"
                clickHandler={() => handleSubmit("draft")}
              />
            )}
            {qid === "demographic" ? null : (
              <Button
                buttonText="Preview"
                className="mx-2 btn-success"
                clickHandler={() => handlePreview()}
              />
            )}
            <Button
              buttonText="Submit"
              className="mx-2 btn-success"
              clickHandler={() => {
                props.QuestionnaireStatus !== "published"
                  ? handleSubmit("active")
                  : handleUpdate();
              }}
            />
            {qid === "demographic" ? null : (
              <Button
                buttonText="Score"
                // disabled={!locked}
                className="mx-2 btn-success"
                clickHandler={() => handleCheckStatus()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  questions: state.question.builderQuestions,
  QuestionnaireStatus: state.question.QuestionnaireStatus,
  qInfo: state.question.qInfo,
});

const mapDispatchToProps = {
  ...questionActions,
  updateQuestion,
  setModalDetails,
};

const QuestionBuilderContext: React.FC<Props> = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <AppLayout themelight={true}>
        <QuestionBuilder {...props} />
      </AppLayout>
    </DndProvider>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionBuilderContext);
