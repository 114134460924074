import moment from "moment";

export const messageFormatter = (document: any, userId: any, docId: string) => {
  const message = { ...document };
  if (message.sentBy !== userId) {
    message.received = true;
  }
  if (message?.sentAt?.seconds) {
    const time = moment.unix(message.sentAt.seconds);
    if (time.isSame(moment(), "day")) {
      message.date = "Today";
    } else {
      message.date = time.format("DD/MM/YYYY");
    }
    message.time = time.format("hh:mm A");
  }
  if (docId) {
    message.id = docId;
  }
  return message;
};

export const computeRoomName = (user1: string, user2: string) => {
  if (user1 && user2) {
    const cmp = user1.localeCompare(user2);
    let chatRoom = "chat_";
    if (cmp === -1) {
      chatRoom += user1 + "_" + user2;
    } else {
      chatRoom += user2 + "_" + user1;
    }
    return chatRoom;
  } else {
    return "";
  }
};
