import { Dispatch } from "redux";
import http from "../../http";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import { hideModal } from "../reducers/modalSlice";
import {
  setDocuments,
  setFolderDocuments,
  setArchiveDocuments,
} from "../reducers/documentsSlice";
import { setAppLoader } from "../reducers/loaderSlice";
import { AppThunk } from "../store";

export const getDocuments = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setAppLoader(true));
    const searchItem = getState().documents.searchTerm;
    const [sort, order] = getState().documents.sortOrder.split("-");
    const url = `/documents?page=${1}&size=${1}&orderBy=${order}&sortBy=${sort}`;
    const searchParam = searchItem ? `${url}&search=${searchItem}` : url;
    const res = await http.get(searchParam);
    let array1 = res.data.data[0];
    let array2 = res.data.data[1];
    const array3 = array1 ? array1.concat(array2) : array1;
    dispatch(
      setDocuments({
        documents: array3,
        docCount: array3.length,
      })
    );
    dispatch(setAppLoader(false));
  } catch (err) {
    dispatch(setAppLoader(false));
    errorToastMessage(err);
  }
};

export const getArchivedDocuments =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(setAppLoader(true));
      const searchItem = getState().documents.searchTerm;
      const [sort, order] = getState().documents.sortOrder.split("-");
      const url = `/documents/archive?orderBy=${order}&sortBy=${sort}`;
      const searchParam = searchItem ? `${url}&search=${searchItem}` : url;
      const res = await http.get(searchParam);
      let array1 = res.data.data[0];
      let array2 = res.data.data[1];
      const array3 = array1 ? array1.concat(array2) : array1;
      dispatch(setArchiveDocuments(array3));
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

export const createDocumentFile =
  (obj: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.post(`/documents/files`, obj);
      if (res.data.data.folderId) {
        dispatch(getFolderDocumentsFile(res.data.data.folderId));
      } else {
        dispatch(getDocuments());
      }
      dispatch(hideModal());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

export const getFolderDocumentsFile =
  (id: any): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setAppLoader(true));
      const [sort, order] = getState().documents.sortOrder.split("-");
      const url = `/documents/folders/${id}?orderBy=${order}&sortBy=${sort}`;
      const searchItem = getState().documents.nestedSearchTerm;
      const searchParam = searchItem ? `${url}?search=${searchItem}` : url;
      const res = await http.get(searchParam);
      let fileArray = res.data.data.files;
      let folderArray = res.data.data.subFolders;
      const folderDocs = fileArray ? folderArray.concat(fileArray) : fileArray;
      dispatch(setFolderDocuments(folderDocs));
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

export const createDocumentFolder =
  (obj: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.post(`/documents/folders`, obj);
      const folderId = res.data.data.parentFolderId;
      if (folderId) {
        dispatch(getFolderDocumentsFile(folderId));
      } else {
        dispatch(getDocuments());
      }
      dispatch(hideModal());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

export const deleteDocumentFile =
  (id: any, folderId: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      await http.delete(`/documents/files/${id}`);
      toastMessage("success", "Document Deleted Successfully");
      if (folderId) {
        dispatch(getFolderDocumentsFile(folderId));
      } else {
        dispatch(getArchivedDocuments());
      }
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

export const deleteFolderDocument =
  (id: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.delete(`/documents/folders/${id}`);
      toastMessage("success", "Folder Deleted Successfully");
      const folderId = res.data.data.parentFolderId;
      if (folderId) {
        dispatch(getFolderDocumentsFile(folderId));
      } else {
        dispatch(getArchivedDocuments());
      }
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

export const updateDocumentFile =
  (id: string, obj: any) => (dispatch: Dispatch<any>) => {
    http
      .put(`/documents/files/${id}`, obj)
      .then((res) => {
        const obj = res.data.data[0];
        const folderId = obj?.folderId;
        if (folderId) {
          dispatch(getFolderDocumentsFile(folderId));
        } else {
          dispatch(getDocuments());
        }
        dispatch(hideModal());
      })
      .catch((err) => {
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
      });
  };

export const updateDocumentFolder =
  (id: string, obj: any) => (dispatch: Dispatch<any>) => {
    http
      .put(`/documents/folders/${id}`, obj)
      .then((res) => {
        // console.log(res.data.data, "rrrrrrrrrrrrrrr");
        const folderId = res.data.data[0].parentFolderId;
        if (folderId) {
          dispatch(getFolderDocumentsFile(folderId));
        } else {
          dispatch(getDocuments());
        }
        dispatch(hideModal());
      })
      .catch((err) => {
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
      });
  };

export const archiveListsFile = (id: string) => (dispatch: Dispatch<any>) => {
  http
    .patch(`/documents/archive/files/${id}`)
    .then((res) => {
      // console.log(res.data, "archive documents");
      dispatch(getDocuments());
      toastMessage("success", res.data.message);
    })
    .catch((err) => {
      console.log(err);
      toastMessage(
        "error",
        err!.response !== undefined
          ? err!.response!.data!.message[0]
          : "Network error:  Failed to load response data"
      );
    });
};

export const archiveListsFolder = (id: string) => (dispatch: Dispatch<any>) => {
  http
    .patch(`/documents/archive/folders/${id}`)
    .then((res) => {
      // console.log(res.data, "archive folders");
      dispatch(getDocuments());
      toastMessage("success", res.data.message);
    })
    .catch((err) => {
      console.log(err);
      toastMessage(
        "error",
        err!.response !== undefined
          ? err!.response!.data!.message[0]
          : "Network error:  Failed to load response data"
      );
    });
};

export const restoreDocumentsFile =
  (id: string) => (dispatch: Dispatch<any>) => {
    http
      .patch(`/documents/restore/files/${id}`)
      .then((res) => {
        // console.log(res.data, "archive folders");
        dispatch(getArchivedDocuments());
        toastMessage("success", res.data.message);
      })
      .catch((err) => {
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
      });
  };
export const restoreDocumentsFolder =
  (id: string) => (dispatch: Dispatch<any>) => {
    http
      .patch(`/documents/restore/folders/${id}`)
      .then((res) => {
        // console.log(res.data, "archive folders");
        dispatch(getArchivedDocuments());
        toastMessage("success", res.data.message);
      })
      .catch((err) => {
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
      });
  };
