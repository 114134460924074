import * as types from "../actionTypes";

const initState = {
  scoresList: [],
  count: 0,
  scoreInfo: {},
};

export default (state = initState, action: any) => {
  switch (action.type) {
    case types.SET_SCORES_LIST:
      return {
        ...state,
        scoresList: action.payload,
        count: action.count,
      };

    case types.INFINITE_GET_SCORES:
      return {
        ...state,
        scoresList: [...state.scoresList, ...action.payload.scores],
        count: action.payload.count,
      };

    case types.SEARCH_SCORES:
      return {
        ...state,
        scoresList: action.payload,
        count: action.count,
      };

    case types.GET_SCORE_INFO:
      return {
        ...state,
        scoreInfo: action.payload,
      };

    default:
      return state;
  }
};
