import React, { useEffect, useMemo, useState } from "react";
import { Input, Tooltip, Upload } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { debounce } from "lodash";
import CustomButton from "../Common/CustomButton";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { importLesson } from "../../redux/actions/importAction";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { setContentSearchTerm } from "../../redux/actions/educationAction";

type Props = {
  type: string;
};

const ContentHeader: React.FC<Props> = ({ type }) => {
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector((state) => state.lessons.contentSearch);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setSearch(searchTerm);
  }, [searchTerm]);

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setContentSearchTerm(val));
      }, 500),
    [dispatch]
  );

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearch(val);
    modifySearchTerm(val);
  };

  const handleButtonClick = () => {
    if (type === "Lesson") {
      dispatch(
        setModalDetails({
          type: `ADD_EDUCATIONS`,
          modalProps: {
            show: true,
          },
        })
      );
    } else if (type === "Recommended") {
      dispatch(
        setModalDetails({
          type: `ADD_RECOMMENDED`,
          modalProps: {
            show: true,
          },
        })
      );
    } else if (type === "Tip of the day") {
      dispatch(
        setModalDetails({
          type: `ADD_TIPS_OF_DAY`,
          modalProps: {
            show: true,
          },
        })
      );
    }
  };

  const fileUpload = (file: any) => {
    dispatch(setAppLoader(true));
    const reader = new FileReader();

    reader.onload = (e: any) => {
      var result = JSON.parse(JSON.stringify(e.target.result));
      dispatch(importLesson(JSON.parse(result)));
    };
    reader.readAsText(file);

    return false;
  };

  return (
    <div className="d-block d-md-flex justify-content-between align-items-center mb-4">
      <span className="d-flex align-items-center">
        <h2 className="font-l fw-bold me-4 mt-2">Content</h2>
        <Input
          value={search}
          onChange={changeHandler}
          style={{ width: "300px" }}
          placeholder="Search..."
          className="search-input"
        />
      </span>
      <span className="d-flex align-items-center">
        {type === "Lesson" && (
          <Tooltip title="Accepted formats: JSON">
            <Upload
              showUploadList={false}
              multiple={true}
              accept="application/json"
              beforeUpload={fileUpload}
            >
              <CustomButton
                primary
                buttonText="Import/Upload"
                className="btn-lg me-2"
              />
            </Upload>
          </Tooltip>
        )}
        <CustomButton
          primary={true}
          clickHandler={handleButtonClick}
          buttonText={`Add ${type}`}
          addButton={true}
          buttonType={"button"}
          className="ntu-button-edu-width btn-lg"
        />
      </span>
    </div>
  );
};

export default ContentHeader;
