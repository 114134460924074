import React, { useEffect, useState, useRef } from "react";
import { Menu, Dropdown } from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setModalDetails } from "../../../redux/reducers/modalSlice";
import { toastMessage } from "../../../helpers/toastMessage";
import {
  getEducationLessons,
  searchEducationLesson,
  deleteEducationLesson,
  educationModuleDuplicate,
  activateDeactivateLesson,
  resetContentSearch,
} from "../../../redux/actions/educationAction";
import {
  exportLesson,
  importLesson,
} from "../../../redux/actions/importAction";
// import "../../../pages/css/questions/questionModule.scss";
import Button from "../../Common/CustomButton";

const EducationModule = (props: any) => {
  const history = useHistory();
  const toggleRef = useRef<boolean>(props.toggleLoader);
  const [values, setValues] = useState({
    pageSize: 12,
    current: 1,
  });
  const { educationLessons, lessonsCount, getEducationLessons, toggleLoader } =
    props;

  useEffect(() => {
    getEducationLessons(1, false, props.search);
    setValues({
      pageSize: 12,
      current: 1,
    });
  }, [getEducationLessons, props.search, setValues]);

  useEffect(() => {
    if (toggleLoader !== toggleRef.current) {
      getEducationLessons(1, false, props.search);
      setValues({
        pageSize: 12,
        current: 1,
      });
      toggleRef.current = toggleLoader;
    }
  }, [toggleLoader, getEducationLessons, props.search, setValues]);

  const rows = educationLessons.map((item: any, index: number) => {
    return item.hasOwnProperty("id")
      ? {
          key: index + 1,
          title: item.name,
          pages: item.pageCount,
          image: item.imageUrl,
          description: item.description,
          status: item.status,
          id: item.id,
        }
      : {};
  });

  // const statusObj: any = {
  //   Active: "active",
  //   Draft: "draft",
  //   Archived: "archived",
  // };

  // Infinite Scroll

  const loadMore = () => {
    if (lessonsCount > educationLessons.length) {
      props.getEducationLessons(values.current + 1, true, props.search);
      setValues({
        ...values,
        current: values.current + 1,
      });
    }
  };

  // const checkStorage = () => {
  //   if (typeof window !== "undefined") {
  //     return localStorage.getItem("role");
  //   }
  // };

  const menu = (data: any) =>
    data.status === "draft" || data.status === "inactive" ? (
      <Menu style={{ width: "120px" }}>
        <Menu.Item
          key="1"
          onClick={() => {
            props.deleteEducationLesson(data.id);
          }}
        >
          Delete
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() =>
            props.setModalDetails({
              type: "EDIT_EDUCATIONS",
              modalProps: {
                show: true,
                educationData: data,
              },
            })
          }
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            props.educationModuleDuplicate(data.id);
          }}
        >
          Duplicate
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={() => {
            if (data.pages > 0) {
              props.exportLesson(data.id);
            } else {
              toastMessage("warning", "Empty Lesson cannot be exported");
            }
          }}
        >
          Export
        </Menu.Item>
      </Menu>
    ) : (
      <Menu style={{ width: "120px" }}>
        <Menu.Item
          key="1"
          onClick={() => {
            let body = {
              name: data.title,
              status: data.status === "archived" ? "active" : "archived",
            };
            props.activateDeactivateLesson(data.id, body);
          }}
        >
          {data.status === "archived" ? "Activate" : "Archive"}
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() =>
            props.setModalDetails({
              type: "EDIT_EDUCATIONS",
              modalProps: {
                show: true,
                educationData: data,
              },
            })
          }
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            props.educationModuleDuplicate(data.id);
          }}
        >
          Duplicate
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={() => {
            if (data.pages > 0) {
              props.exportLesson(data.id);
            } else {
              toastMessage("warning", "Empty Lesson cannot be exported");
            }
          }}
        >
          Export
        </Menu.Item>
      </Menu>
    );

  return (
    <div className="question-modules">
      <div className="container-fluid p-0">
        <div className="row no-gutter">
          <div className="col-xl-8 col-sm-12 search-education-block mb-4">
            <h3 className="font-m mb-0 me-3">
              All <span>{lessonsCount}</span> Educational Modules
            </h3>
          </div>
        </div>
      </div>

      <div className="row card-section mt-4">
        {educationLessons.length > 0 ? (
          rows.map((module: any) => (
            <div
              className="col-md-4 cp"
              key={module.key}
              onClick={() => history.push(`/education/pages/${module.id}`)}
            >
              <div className="question-card">
                <div className="img-wrapper">
                  <img
                    alt="cover"
                    src={
                      module.image
                        ? module.image
                        : "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E"
                    }
                    onError={(ev: any) => {
                      ev.target.src =
                        "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                      ev.target.onerror = null;
                    }}
                  />
                  {module.status === "draft" ? (
                    <span className="tag">DRAFT</span>
                  ) : module.status === "archived" ? (
                    <span className="tag">ARCHIVED</span>
                  ) : null}
                </div>
                <p>{module.pages} PAGES</p>
                <h6>{module.title}</h6>
                <span onClick={(e) => e.stopPropagation()}>
                  <Dropdown overlay={menu(module)} trigger={["click"]}>
                    <i className="more more-icon cp"></i>
                  </Dropdown>
                </span>
              </div>
            </div>
          ))
        ) : props.search !== "" ? (
          <div className="no-search">
            <h2>Search Results</h2>
            <p>{`Your Search - ${props.search} - did not match any Education modules`}</p>
            <div className="suggestions">
              <p>Suggestions:</p>
              <ul style={{ listStyleType: "disc", marginLeft: "1.8rem" }}>
                <li>Make sure all the keywords are spelled correctly</li>
                <li>Try different keywords</li>
                <li>Try more general keywords</li>
              </ul>
            </div>
            <a
              className="d-flex align-items-center mt-2"
              onClick={props.resetContentSearch}
            >
              <i className="arrow-left-icon ms-2"></i>Go back to home page
            </a>
          </div>
        ) : (
          educationLessons.length === 0 && (
            <div className="no-module-content">
              <h1 className="font-l fw-bold">No Education modules created</h1>
            </div>
          )
        )}
      </div>

      {lessonsCount > educationLessons.length && (
        <div className="d-flex align-items-center justify-content-center">
          <Button
            buttonText="Load More"
            buttonType="button"
            primary={true}
            clickHandler={loadMore}
            className="btn-lg px-4 btn-success"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  educationLessons: state.lessons.educationLessons,
  lessonsCount: state.lessons.lessonsCount,
  search: state.lessons.contentSearch,
  toggleLoader: state.lessons.toggleLoader,
});

export default connect(mapStateToProps, {
  setModalDetails,
  getEducationLessons,
  searchEducationLesson,
  deleteEducationLesson,
  educationModuleDuplicate,
  activateDeactivateLesson,
  exportLesson,
  importLesson,
  resetContentSearch,
})(EducationModule);
