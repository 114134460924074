import React, { useEffect, useMemo, useState } from "react";
import { Input } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { debounce } from "lodash";
import CustomButton from "../Common/CustomButton";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { setParticipantSearchTerm } from "../../redux/reducers/participantSlice";
import roles from "../../constants/roles";
import { useHistory } from "react-router-dom";

const ReportsHeader = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const searchTerm = useAppSelector((state) => state.participant.searchTerm);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setSearch(searchTerm);
  }, [searchTerm]);

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setParticipantSearchTerm(val));
      }, 500),
    [dispatch]
  );

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearch(val);
    modifySearchTerm(val);
  };

  const handleButtonClick = () => {
    dispatch(
      setModalDetails({
        type: `ADD_REPORT_MODAL`,
        modalProps: {
          show: true,
        },
      })
    );
  };

  return (
    <div className="d-block d-md-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center mb-4">
        <h2 className="font-l mb-0 fw-bold">Monthly review reports</h2>
        <Input
          value={search}
          onChange={changeHandler}
          style={{ width: "300px", marginLeft: "20px" }}
          placeholder="Search..."
          className="search-input"
        />
      </div>
      <CustomButton
        primary={true}
        clickHandler={handleButtonClick}
        buttonText="Add Reports"
        addButton={true}
        buttonType={"button"}
        className="btn-lg mb-4"
      />
    </div>
  );
};

export default ReportsHeader;
