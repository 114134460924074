import { useEffect } from "react";
import { fetchProgramOverview } from "../../../redux/actions/dashboardAction";
import { useAppDispatch } from "../../../redux/hooks";
import { FemaleParticipantIcon, MaleParticipantIcon } from "../../Common/Icons";
import AnswerLineChart from "./AnswerLineChart";
import ParticipantDonutChart from "./ParticipantDonutChart";

const DashBoardOverview = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchProgramOverview());
  }, [dispatch]);

  return (
    <div className="dashboard-wrapper">
      <div className="overview-stats">
        <div className="stat-container">
          <div className="stat-value">3652</div>
          <div className="stat-label">Answers</div>
        </div>
        <div className="stat-container">
          <div className="stat-value">680</div>
          <div className="stat-label">Questions</div>
        </div>
        <div className="stat-container">
          <div className="stat-value">1434</div>
          <div className="stat-label">Users took part</div>
        </div>
        <div className="stat-container">
          <div className="stat-value">1863</div>
          <div className="stat-label">Users</div>
        </div>
        <div className="stat-container">
          <div className="stat-value">48.3%</div>
          <div className="stat-label">Completion rate</div>
        </div>
        <div className="stat-container">
          <div className="stat-value">2%</div>
          <div className="stat-label">Questionnaire bounce rate</div>
        </div>
      </div>
      <AnswerLineChart />
      <div className="participants">
        <div className="participants-header">Users</div>
        <div className="gender-icons">
          <FemaleParticipantIcon />
          <FemaleParticipantIcon />
          <FemaleParticipantIcon />
          <FemaleParticipantIcon />
          <FemaleParticipantIcon />
          <FemaleParticipantIcon />
          <FemaleParticipantIcon />
          <MaleParticipantIcon />
          <MaleParticipantIcon />
          <MaleParticipantIcon />
        </div>
        <div className="gender-icons">
          <div>
            <span className="label">Female</span>
            <span className="value">75%</span>
          </div>
          <div>
            <span className="label">Male</span>
            <span className="value">25%</span>
          </div>
        </div>
        <ParticipantDonutChart />
      </div>
    </div>
  );
};

export default DashBoardOverview;
