import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DiaryState {
  foodList: any[];
  foodCount: number;
  exerciseList: any[];
  exerciseCount: number;
  toggleLoader: boolean;
  searchTerm: string;
  selectedFood: any;
}

const initialState: DiaryState = {
  foodList: [],
  foodCount: 0,
  exerciseList: [],
  exerciseCount: 0,
  toggleLoader: false,
  searchTerm: "",
  selectedFood: {
    ids: [],
    items: [],
  },
};

export const diarySlice = createSlice({
  name: "diary",
  initialState,
  reducers: {
    setFoodDetails: (
      state,
      action: PayloadAction<{ data: any[]; count: number }>
    ) => {
      state.foodList = action.payload.data;
      state.foodCount = action.payload.count;
    },
    setExerciseDetails: (
      state,
      action: PayloadAction<{ data: any[]; count: number }>
    ) => {
      state.exerciseList = action.payload.data;
      state.exerciseCount = action.payload.count;
    },
    toggleDiaryLoader: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
    setDiarySearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setSelectedFood: (state, action: PayloadAction<any>) => {
      state.selectedFood = action.payload;
    },
  },
});

export const {
  setFoodDetails,
  setExerciseDetails,
  toggleDiaryLoader,
  setDiarySearchTerm,
  setSelectedFood,
} = diarySlice.actions;

export default diarySlice.reducer;
