import React, { useState } from "react";
import { Tabs } from "antd";
import AdministratorHeader from "./AdministratorHeader";
import TherapistListView from "./TherapistListView";
import AdminListView from "./AdminListView";
import roles from "../../constants/roles";
import AppLayout from "../../components/AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";

const { TabPane } = Tabs;

const Administrator: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>(
    sessionStorage.getItem("admin-view-tab") || roles.Therapist["fe_value"]
  );

  const tabChangeHandler = (key: string) => {
    setSelectedTab(key);
    sessionStorage.setItem("admin-view-tab", key);
  };

  return (
    <AppLayout>
      <Navigation />
      <AdministratorHeader type={selectedTab} />
      <Tabs
        activeKey={selectedTab}
        onChange={tabChangeHandler}
        destroyInactiveTabPane
        className="singer-cutsom-tab"
      >
        <TabPane
          tab={roles.Therapist["fe_value"]}
          key={roles.Therapist["fe_value"]}
        >
          <TherapistListView />
        </TabPane>
        <TabPane tab={roles.Admin["fe_value"]} key={roles.Admin["fe_value"]}>
          <AdminListView />
        </TabPane>
        <TabPane tab={"Principal Investigator"} key={"Principal Investigator"}>
          <AdminListView />
        </TabPane>
        <TabPane tab={"QA"} key={"QA"}>
          <AdminListView />
        </TabPane>
        <TabPane tab={"Lead Investigator"} key={"Lead Investigator"}>
          <AdminListView />
        </TabPane>
        <TabPane tab={"Research Site Staff"} key={"Research Site Staff"}>
          <AdminListView />
        </TabPane>
      </Tabs>
    </AppLayout>
  );
};

export default Administrator;
