import moment from "moment";

export const appointmentQueryConstructor: (tab: string) => string = (tab) => {
  const today_from_date = moment().startOf("day").utc().toISOString();
  const today_to_date = moment().endOf("day").utc().toISOString();
  const week_from_date = today_from_date;
  const week_to_date = moment().add(7, "days").endOf("day").utc().toISOString();
  const query = `?tab=${tab}&today_from_date=${today_from_date}&today_to_date=${today_to_date}&week_from_date=${week_from_date}&week_to_date=${week_to_date}`;
  return query;
};

export const appointmentPerTimePeriodConstructor: (tab: string) => string = (
  tab
) => {
  let days = 0;
  if (tab === "week") {
    days = 7;
  } else if (tab === "month") {
    days = 30;
  }
  const from_date = moment().startOf("day").utc().toISOString();
  const to_date = moment().add(days, "days").endOf("day").utc().toISOString();
  const query = `?tab=${tab}&from_date=${from_date}&to_date=${to_date}`;
  return query;
};

export const timepickerOptions = () => {
  const timePicker: number[] = [];

  for (let i = 0; i < 48; i++) {
    timePicker.push(i * 30 * 60);
  }
  return timePicker;
};

export const appointmentAnalyticsPeriodConstructor: (tab: string) => any = (
  tab
) => {
  let days = 0;
  if (tab === "week") {
    days = 6;
  } else if (tab === "month") {
    days = 30;
  }
  const from_date = moment().subtract(days, "days").startOf("day").utc();
  const to_date = moment().endOf("day").utc();
  const query = `?from_date=${from_date.toISOString()}&to_date=${to_date.toISOString()}`;
  return {
    query,
    from_date: from_date,
    to_date: to_date,
  };
};
