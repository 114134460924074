import React, { useState } from "react";
import { Modal, Select, Spin } from "antd";
import { Formik } from "formik";
import Button from "../../Common/CustomButton";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { addProgramLesson } from "../../../redux/reducers/programSlice";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { debounce } from "lodash";
import http from "../../../http";
import CustomInput from "../../Common/CustomInput";
const yup = require("yup");

let schema = yup.object().shape({
  id: yup.string().required("Please Select a Lesson"),
  duration: yup.number().required("Lesson Availability is required"),
  name: yup.string().required("Please Select a date"),
});

const ProgramLessonModal = () => {
  const { title, show } = useAppSelector((state) => state.modal.modalProps);
  const { lessonData } = useAppSelector((state) => state.program);
  const dispatch = useAppDispatch();

  const [options, setOptions] = useState<any[]>();
  const [fetching, setFetching] = useState<boolean>(false);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value: string) => {
      try {
        setOptions([]);
        if (!value) {
          return;
        }
        setFetching(true);
        const res = await http.get(
          `/education_lessons?page=1&size=20&search=${value}&status=active`
        );
        const lessons = res.data.data.educationLessons.map((lesson: any) => {
          return {
            label: lesson.name,
            value: lesson.id,
          };
        });
        setOptions(lessons);
        setFetching(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setFetching(false);
      }
    };
    return debounce(loadOptions, 500);
  }, [setFetching, setOptions]);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = (values: any) => {
    const duplicate = lessonData.some((lesson) => {
      return lesson.id === values.id;
    });
    if (duplicate) {
      toastMessage("error", "Lesson already added");
      return;
    }
    dispatch(addProgramLesson(values));
    dispatch(hideModal());
  };

  return (
    <Modal
      title={title}
      visible={show}
      onCancel={closeHandler}
      footer={null}
      width={"38rem"}
    >
      <Formik
        initialValues={{
          id: undefined,
          duration: 1,
          name: "",
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          submitHandler(values);
        }}
      >
        {({
          values,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          setFieldValue,
        }) => (
          <form
            className="overflow-auto content-height container-fluid m-0"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-md-9 mb-3">
                <div className="form-group">
                  <label htmlFor="action-name" className="fw-medium">
                    Lesson
                  </label>
                  <Select
                    showSearch
                    //@ts-ignore
                    value={
                      values.id
                        ? {
                            key: values.id,
                            label: values.name,
                            value: values.id,
                          }
                        : undefined
                    }
                    labelInValue
                    onChange={(val: any) => {
                      setFieldValue("id", val.value);
                      setFieldValue("name", val.label);
                    }}
                    onBlur={handleBlur}
                    filterOption={false}
                    onSearch={debounceFetcher}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    options={options}
                    className="mt-2"
                  />
                  <div className="input-error text-left">
                    {errors.id && touched.id && errors.id}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-9 mb-3">
                <div className="form-group">
                  <label htmlFor="action-name" className="fw-medium">
                    Availablility from start of the program
                  </label>
                  <CustomInput
                    name="duration"
                    value={values.duration}
                    changeEvent={handleChange}
                    blurEvent={handleBlur}
                    className="mt-2"
                  />
                  <div className="input-error text-left">
                    {errors.duration && touched.duration && errors.duration}
                  </div>
                </div>
              </div>
            </div>
            <div className="button-container d-flex justify-content-end align-items-center mt-5">
              <span className="button-cancel ms-2 cp" onClick={closeHandler}>
                Cancel
              </span>
              <Button
                buttonType="submit"
                buttonText="Save"
                className="m-2 button-save cp"
              />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default ProgramLessonModal;
