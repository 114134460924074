import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { db } from "../../../firebase";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { messageFormatter } from "../../../helpers/chat";
import { Spin } from "antd";
import { errorToastMessage } from "../../../helpers/toastMessage";
import ReactPlayer from "react-player";
import { DownloadOutlined } from "@ant-design/icons";
import roles from "../../../constants/roles";

const ChatContent = ({ roomName }: any) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any[]>([]);
  const topDocRef = useRef(null);
  const chatRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const scrollTypeRef = useRef("bottom");
  const [disableChat, setDisabledChat] = useState(false);

  useEffect(() => {
    let unsubscribe: any;
    let startAt: any;
    const role = localStorage.getItem("role");
    if (role === roles.Admin["be_value"]) {
      setDisabledChat(true);
      setLoading(false);
      return;
    }
    setLoading(true);
    const chat = db.collection("chat").doc(roomName).collection("messages");
    chat
      .orderBy("sentAt", "desc")
      .limit(10)
      .get()
      .then((querySnapshot) => {
        let initialData: any[] = [];
        let index = 1;
        const userId = localStorage.getItem("userId");
        let topRef = null;
        querySnapshot.forEach((doc) => {
          const message = messageFormatter(doc.data(), userId, doc.id);
          initialData.push(message);
          topRef = doc;
          if (index === 1) {
            startAt = doc;
            index++;
          }
        });
        initialData = initialData.reverse();
        setData(initialData);
        if (topRef) {
          topDocRef.current = topRef;
        }
        if (initialData.length === 10) {
          setShowMore(true);
        }
        setLoading(false);
        let newChat = startAt
          ? chat.orderBy("sentAt").startAfter(startAt)
          : chat;
        unsubscribe = newChat.onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const userId = localStorage.getItem("userId");
            if (!change.doc.metadata.hasPendingWrites) {
              const newdata = messageFormatter(
                change.doc.data(),
                userId,
                change.doc.id
              );
              scrollTypeRef.current = "bottom";
              setData((prev) => [...prev, newdata]);
            }
          });
        });
      })
      .catch((error) => {
        errorToastMessage(error);
        setLoading(false);
      });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [setData, setLoading, setShowMore, roomName, setDisabledChat]);

  useLayoutEffect(() => {
    if (
      !loading &&
      data.length > 0 &&
      scrollTypeRef.current === "bottom" &&
      chatRef.current
    ) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [data, loading]);

  const loadMore = () => {
    if (topDocRef.current) {
      dispatch(setAppLoader(true));
      db.collection("chat")
        .doc(roomName)
        .collection("messages")
        .orderBy("sentAt", "desc")
        .startAfter(topDocRef.current)
        .limit(10)
        .get()
        .then((querySnapshot) => {
          let topRef = null;
          scrollTypeRef.current = "top";
          let index = 0;
          const userId = localStorage.getItem("userId");
          let tempData: any[] = [];
          querySnapshot.forEach((doc) => {
            const message = messageFormatter(doc.data(), userId, doc.id);
            tempData.push(message);
            index++;
            topRef = doc;
          });
          if (topRef) {
            topDocRef.current = topRef;
          }
          tempData = tempData.reverse();
          if (tempData.length > 0) {
            setData((prev) => [...tempData, ...prev]);
          }
          if (index !== 10) {
            setShowMore(false);
          }
          dispatch(setAppLoader(false));
        })
        .catch((err) => {
          errorToastMessage(err);
          dispatch(setAppLoader(false));
        });
    } else {
      setShowMore(false);
    }
  };

  const downloadHandler = (url: any) => {
    window.open(url, "_blank");
  };

  return (
    <div className="chat-holder p-3" id="custom-scrollbar" ref={chatRef}>
      {loading ? (
        <div className="loading-container">
          <Spin />
        </div>
      ) : disableChat ? (
        <div className="empty-chat">
          Only therapists can chat with the Patients
        </div>
      ) : (
        <>
          {showMore ? (
            <div className="show-more" onClick={loadMore}>
              Load More
            </div>
          ) : null}
          {data.length === 0 ? (
            <div className="empty-chat">
              Empty Chat. Send a message to start the conversation.
            </div>
          ) : (
            data.map((message, index) => {
              const nextMessage = data[index + 1];
              let dateLabel = null;
              let startLabel = null;
              if (nextMessage) {
                if (nextMessage.date !== message.date) {
                  dateLabel = nextMessage.date;
                }
              }
              if (index === 0 && message.date !== "Today") {
                startLabel = message.date;
              }
              return (
                <>
                  {startLabel && (
                    <div className="next-day-label">
                      <span className="label">{startLabel}</span>
                    </div>
                  )}
                  <div
                    key={message.id}
                    className={message.received ? "chat-others" : "chat-me"}
                  >
                    {message.resourceUrl &&
                    message.resourceType &&
                    message.resourceType !== "link" ? (
                      <div className="chat-attachment-wrapper">
                        {message.resourceType === "image" && (
                          <img
                            src={message.resourceUrl}
                            alt="attachment"
                            className="attachment-image"
                          />
                        )}
                        {message.resourceType === "video" && (
                          <ReactPlayer
                            className="attachment-video"
                            controls
                            width="100%"
                            url={message.resourceUrl}
                          />
                        )}
                        {message.resourceType === "attachment" && (
                          <div className="attachment-file">
                            <span
                              className="attachment-file-name"
                              title={message.resourceName}
                            >
                              {message.resourceName}
                            </span>
                            <DownloadOutlined
                              onClick={() =>
                                downloadHandler(message.resourceUrl)
                              }
                            />
                          </div>
                        )}
                        {message.text ? (
                          <div className="chat-section attachment-text">
                            <p className="m-0">{message.text}</p>
                          </div>
                        ) : null}
                      </div>
                    ) : message.text ? (
                      <div className="chat-section">
                        {message.resourceUrl && message.resourceType ? (
                          <p
                            className="m-0 chat-link"
                            title={message.resourceUrl}
                            onClick={() => downloadHandler(message.resourceUrl)}
                          >
                            {message.text}
                          </p>
                        ) : (
                          <p className="m-0">{message.text}</p>
                        )}
                      </div>
                    ) : null}
                    <span className="font-s text-muted text-uppercase time-text">
                      {message.time}
                    </span>
                  </div>
                  {dateLabel && (
                    <div className="next-day-label">
                      <span className="label">{dateLabel}</span>
                    </div>
                  )}
                </>
              );
            })
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(ChatContent);
