import React from "react";
// import "../../../pages/css/questions/questionPreview.scss";
// import { Input, Select, Checkbox, Switch, Slider, DatePicker } from "antd";

type Props = {
  questions: any;
};

const RenderQuestion = (props: Props) => {
  // helper functions
  function checkObj(obj: any) {
    if (Object.keys(obj).length !== 0) {
      if (obj.hasOwnProperty("choices")) {
        return obj.choices;
      }
      return obj;
    }

    return null;
  }

  function indxToAlpha(indx: number) {
    return String.fromCharCode(65 + indx);
  }

  // questions types and the appropriate view
  const InputType = ({ question }: any) => {
    const { type } = question;
    return (
      <div className="input-control">
        <input
          type="text"
          style={{ width: type === "number" ? "300px" : "100%" }}
          placeholder="Enter Text Here"
          autoComplete="off"
        />
      </div>
    );
  };

  const ChoiceType = ({ question }: any) => {
    const { position, choices } = question;
    return (
      <div className="input-control choice-items">
        {checkObj(choices) ? (
          checkObj(choices).map((choice: any, index: number) => (
            <div key={choice.id} className="me-2">
              <input
                id={`choice-${index}-q${position}`}
                className="toggle choice"
                name={`q${position}`}
                value={choice.label}
                type="radio"
              />
              <label
                htmlFor={`choice-${index}-q${position}`}
                className="text-label"
              >
                <span>{indxToAlpha(index)}</span> {choice.label}
              </label>
            </div>
          ))
        ) : (
          <div className="no-items">No Choice Items</div>
        )}
      </div>
    );
  };

  const questionTypes: any = {
    text: InputType,
    multi_select: ChoiceType,
    single_select: ChoiceType,
  };

  return (
    <div className="upform">
      <form>
        <div className="upform-main">
          {Array.isArray(props.questions) &&
            props.questions.length > 0 &&
            props.questions.map((q) => {
              if (questionTypes[q.type] === undefined) return null;
              const TypeComponent = questionTypes[q.type];
              return (
                <div className="input-block" key={q.id}>
                  <div className="label-pw">
                    <span>
                      Q{q.position}. {q.title}
                    </span>
                    {q.required && (
                      <span className="input-block-required">*</span>
                    )}
                  </div>
                  <TypeComponent question={q} />
                </div>
              );
            })}
        </div>
      </form>
    </div>
  );
};

export default RenderQuestion;

// const renderFields = (field: any) => {
//   switch (field.type) {
//     case "S":
//       return <h6>{field.fields[0].value}</h6>;
//     case "ST":
//       return (
//         <div className="input-group">
//           <label>{field.fields[0].value}</label>
//           <Input />
//         </div>
//       );
//     case "LT":
//       return (
//         <div className="input-group">
//           <label>{field.fields[0].value}</label>
//           <Input.TextArea />
//         </div>
//       );
//     case "YN":
//       return (
//         <div>
//           <label>{field.fields[0].value}</label>
//           <br />
//           <div>
//             No <Switch /> Yes
//           </div>
//         </div>
//       );
//     case "CB":
//       return (
//         <div className="checkbox-input input-group">
//           <p>{field.value}</p>
//           {field.fields.map((f: any) => (
//             <p key={f.value}>
//               <Checkbox className="me-1" />
//               {f.label}
//               <br />
//             </p>
//           ))}
//         </div>
//       );
//     case "DD":
//       return (
//         <div className="input-group">
//           <label>{field.value}</label>
//           <Select>
//             {field.fields.map((f: any) => (
//               <Select.Option key={f.value} value={f.value}>
//                 {f.label}
//               </Select.Option>
//             ))}
//           </Select>
//         </div>
//       );
//     case "D":
//       return (
//         <div className="input-group">
//           <label>{field.fields[0].value}</label>
//           <DatePicker />
//         </div>
//       );
//     case "N":
//       return (
//         <div className="input-group">
//           <label>{field.fields[0].value}</label>
//           <Input type="number" />
//         </div>
//       );
//     case "OS":
//       return (
//         <div>
//           <label>{field.fields[0].value}</label>
//           <Slider />
//         </div>
//       );
//     case "QG":
//       return (
//         <div className="input-group">
//           <label>{field.fields[0].value}</label>
//           <Input />
//         </div>
//       );
//     case "PC":
//       return (
//         <div className="input-group">
//           <label>{field.value}</label>
//           <Select>
//             {field.fields.map((f: any) => (
//               <Select.Option key={f.value} value={f.value}>
//                 {f.label}
//               </Select.Option>
//             ))}
//           </Select>
//         </div>
//       );
//     case "MCQ":
//       return (
//         <div className="input-group">
//           <label>{field.value}</label>
//           <Select>
//             {field.fields.map((f: any) => (
//               <Select.Option key={f.value} value={f.value}>
//                 {f.label}
//               </Select.Option>
//             ))}
//           </Select>
//         </div>
//       );
//     default:
//       return <></>;
//   }
// };
