import React from "react";
import roles from "../../constants/roles";
import { useAppSelector } from "../../redux/hooks";
import AddTherapistModal from "./AddTherapistModal/AddTherapistModal";
import CalendarAvailability from "./CalendarAvailability/CalendarAvailability";
import CreateDocumetsModal from "./CreateDocumentsModal/CreateDocumentsModal";
import CreateFoldersModal from "./CreateFolderModal/CreateFolderModal";
import AddEducationModal from "./AddEducationModal/AddEducationModal";
import AddQuestionModal from "./AddQuestionModal/AddQuestionModal";
import ViewQuestionnaireInfo from "./ViewQuestionnaireInfo/ViewQuestionnaireInfo";
import ScorePreviewModal from "./ScorePreview/ScorePreviewModal";
import AddRecommendedModal from "./AddRecommendedModal/AddRecommendedModal";
import AddTipsOfDayModal from "./AddTipsOfday/AddTipsOfdayModal";
import AddPatientModal from "./AddPatientModal/AddPatientModal";
import TherapistSlotModal from "./TherapistSlotModal/TherapistSlotModal";
import AddExerciseDiaryModal from "./AddExerciseModal/AddExerciseModal";
import AddFoodDiaryModal from "./AddFoodDiary/AddFoodDiaryModal";
import ProgramLessonModal from "./ProgramLessonModal/ProgramLessonModal";
import ProgramQuestionModal from "./ProgramQuestionModal/ProgramQuestionModal";
import DietDetailModal from "./GraphDetailModal/DietDetailModal";
import MedicineDetailModal from "./GraphDetailModal/MedicineDetailModal";
import LessonCompletedModal from "./LessonCompletedModal/LessonCompletedModal";
import AllParticipantDetails from "./AllParticipantDetails/AllParticipantDetails";
import AddForumModal from "./AddForumModal/AddForumModal";
import AddPatientForumModal from "./AddPatientForumModal/AddPatientForumModal";
import AllModeratorDetails from "./AllModeratorDetails/AllModeratorDetails";
import AddForumCommentModal from "./AddForumComment/AddForumCommentModal";
import ChatHyperLinkModal from "./ChatHyperlink/ChatHyperlinkModal";
import AddReportModal from "./AddReportModal/AddReportModal";
import QuestionnaireResponseList from "./QuestionnaireResponses/QuestionnaireResponsesList";
import QuestionnaireResponse from "./QuestionnaireResponses/QuestionnaireResponse";
import RightsModal from "./RightsModal/RightsModal";
import ReportViewModal from "./ReportViewModal/ReportViewModal";

const addTherapistStr = `ADD_${roles.Therapist["fe_value"]}_MODAL`;
const addAdminStr = `ADD_${roles.Admin["fe_value"]}_MODAL`;
const addPatientStr = `ADD_${roles.Participant["fe_value"]}_MODAL`;
const MODAL_COMPONENTS: any = {
  CALENDAR_AVAILABILITY: CalendarAvailability,
  [addTherapistStr]: AddTherapistModal,
  [addAdminStr]: AddTherapistModal,
  [addPatientStr]: AddPatientModal,
  CREATE_DOCUMENT: CreateDocumetsModal,
  CREATE_FOLDER: CreateFoldersModal,
  ADD_EDUCATIONS: AddEducationModal,
  EDIT_EDUCATIONS: AddEducationModal,
  ADD_QUESTIONS: AddQuestionModal,
  EDIT_QUESTIONS: AddQuestionModal,
  VIEW_QUESTIONNAIRE_INFO: ViewQuestionnaireInfo,
  SCORE_PREVIEW: ScorePreviewModal,
  ADD_RECOMMENDED: AddRecommendedModal,
  ADD_TIPS_OF_DAY: AddTipsOfDayModal,
  THERAPIST_SLOT_MODAL: TherapistSlotModal,
  ADD_FOOD_MODAL: AddFoodDiaryModal,
  ADD_EXERCISE_MODAL: AddExerciseDiaryModal,
  ADD_PROGRAM_LESSON: ProgramLessonModal,
  ADD_PROGRAM_QUESTION: ProgramQuestionModal,
  DIET_DETAIL_MODAL: DietDetailModal,
  MEDICINE_DETAIL_MODAL: MedicineDetailModal,
  LESSON_COMPLETION: LessonCompletedModal,
  ALL_PARTICIPANTS_DETAILS: AllParticipantDetails,
  ADD_FORUM_MODALS: AddForumModal,
  ADD_PARTICIPANT_TO_FORUM: AddPatientForumModal,
  ALL_MODERATOR_DETAILS: AllModeratorDetails,
  ADD_FORUM_COMMENT: AddForumCommentModal,
  SEND_CHAT_LINK: ChatHyperLinkModal,
  PATIENT_QUESTIONNAIRE_RESPONSE_LIST: QuestionnaireResponseList,
  PATIENT_QUESTIONNAIRE_RESPONSE: QuestionnaireResponse,
  ADD_REPORT_MODAL: AddReportModal,
  RIGHTS_MODAL: RightsModal,
  REPORT_VIEW_MODAL: ReportViewModal,
};

const RootModal = () => {
  const { type, modalProps } = useAppSelector((state) => state.modal);
  if (!type) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[type];
  return <SpecificModal {...modalProps} />;
};

export default RootModal;
