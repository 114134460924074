import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { Dropdown, Menu, Table } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { errorToastMessage } from "../../helpers/toastMessage";
import {
  deleteParticipantGroups,
  getUserGroups,
} from "../../redux/actions/adminAction";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import moment from "moment";
import confirm from "antd/lib/modal/confirm";
import roles from "../../constants/roles";
import { useHistory } from "react-router-dom";

const ParticipantGroups: React.FC<any> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { searchTerm, groupCount, groups, toggleLoader } = useAppSelector(
    (state) => state.participant
  );
  const toggleRef = useRef(toggleLoader);

  const fetchClientData = useCallback(
    async (searchTerm, page) => {
      try {
        await dispatch(getUserGroups(page, searchTerm));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, setCurrentPage]
  );

  useEffect(() => {
    fetchClientData(searchTerm, currentPage);
  }, [searchTerm, currentPage, fetchClientData]);

  useEffect(() => {
    if (toggleLoader !== toggleRef.current) {
      fetchClientData(searchTerm, 1);
      toggleRef.current = toggleLoader;
    }
  }, [searchTerm, currentPage, fetchClientData, toggleLoader]);

  const onTableChange = async (pagination: any) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }
  };

  const navigateToGroup = (id: string, name: string) => {
    history.push(`/participants/group/${id}?name=${name}`);
  };

  const columns = useMemo(() => {
    const cols: any[] = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (name: any) => {
          return (
            <div className="d-flex">
              <div className="ms-3">
                <p className="text-s m-0 fw-medium">{name}</p>
              </div>
            </div>
          );
        },
      },
      {
        title: "Creation Date",
        dataIndex: "createdAt",
        key: "date",
        render: (text: string) => (
          <span>{moment(text).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        title: "Number of Users",
        dataIndex: "participantCount",
        key: "participantCount",
      },
    ];
    const adminAccess =
      localStorage.getItem("role") === roles.Admin["be_value"];
    if (adminAccess) {
      const showConfirm = (group: any) => {
        confirm({
          title: "Delete User group",
          content:
            "Are you sure you want to delete this user group? (This could impact other sections).",
          onOk() {
            dispatch(deleteParticipantGroups(group.id));
          },
          onCancel() {},
          okText: "Yes",
          cancelText: "No",
          type: "confirm",
        });
      };
      const menu = (data: any) => (
        <Menu>
          <Menu.Item key="1" onClick={() => showConfirm(data)}>
            Delete
          </Menu.Item>
        </Menu>
      );
      cols.push({
        title: "",
        width: "5%",
        className: "text-end",
        render: (_: any, data: any) => {
          return (
            <span
              onClick={(e) => e.stopPropagation()}
              className="d-inline-block"
            >
              <Dropdown
                overlay={menu(data)}
                placement="bottomRight"
                trigger={["click"]}
              >
                <i className="more-icon cp"></i>
              </Dropdown>
            </span>
          );
        },
      });
    }
    return cols;
  }, []);

  return (
    <>
      <Table
        dataSource={groups}
        columns={columns}
        showSorterTooltip={false}
        className="singer-custom-table"
        onChange={onTableChange}
        rowKey={(record) => record.id}
        onRow={(record) => {
          return {
            onClick: () => {
              navigateToGroup(record.id, record.name);
            },
          };
        }}
        pagination={{
          current: currentPage,
          total: groupCount,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
          },
          position: ["bottomLeft"],
        }}
      />
    </>
  );
};

export default ParticipantGroups;
