import React, { useEffect, useMemo, useState } from "react";
import { Input } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { debounce } from "lodash";
import CustomButton from "../Common/CustomButton";
import { setDiarySearchTerm } from "../../redux/reducers/diarySlice";
import {
  addProgramExerciseHandler,
  addProgramFoodHandler,
} from "../../redux/actions/programAction";

type Props = {
  type: string;
};

const DiarySelectionHeader: React.FC<Props> = ({ type }) => {
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector((state) => state.diary.searchTerm);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setSearch(searchTerm);
  }, [searchTerm]);

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setDiarySearchTerm(val));
      }, 500),
    [dispatch]
  );

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearch(val);
    modifySearchTerm(val);
  };

  const handleButtonClick = () => {
    if (type === "Food") {
      dispatch(addProgramFoodHandler());
    } else {
      dispatch(addProgramExerciseHandler());
    }
  };

  return (
    <div className="d-block d-md-flex justify-content-between align-items-center">
      <span className="d-flex align-items-center">
        <h2 className="font-l fw-bold me-4 mb-4">{type + " Diary"}</h2>
        <Input
          value={search}
          onChange={changeHandler}
          style={{ width: "300px" }}
          placeholder="Search..."
          className="search-input mb-4"
        />
      </span>
      <CustomButton
        primary={true}
        clickHandler={handleButtonClick}
        buttonText={`Done`}
        buttonType={"button"}
        className="btn-lg mb-4"
      />
    </div>
  );
};

export default DiarySelectionHeader;
