import React from "react";
import { connect } from "react-redux";

type Props = {
  scores: any[];
  handleExp: Function;
  handleEditExp: Function;
  questions?: any[];
  handleConstants: Function;
  handleRemoveOperand: Function;
};

const ScoreCalculator = (props: Props) => {
  const calcButtons = [
    {
      func: "AC",
      classValue: "#A0A5B8",
      label: "AC",
    },
    {
      func: "constant",
      classValue: "#A0A5B8",
      label: "CONST",
    },
    {
      type: "paranthesis",
      func: "(",
      classValue: "#A0A5B8",
      label: "(",
    },
    {
      type: "paranthesis",
      func: ")",
      classValue: "#A0A5B8",
      label: ")",
    },
    {
      func: "%",
      classValue: "#A0A5B8",
      label: "%",
    },
    {
      func: "*",
      classValue: "#00B9FC",
      iconValue: "score-multiply-icon",
      multiLabel: "x",
    },
    {
      func: "-",
      classValue: "#00B9FC",
      iconValue: "score-subtract-icon",
    },
    {
      func: "+",
      classValue: "#00B9FC",
      iconValue: "score-add-icon",
    },
    {
      func: "/",
      classValue: "#00B9FC",
      iconValue: "score-divide-icon",
    },
  ];

  const handleChange = (value: any, item: any) => {
    if (value === "" && item.type !== "constant") {
      props.handleRemoveOperand(item);
    } else if (item.type === "constant" && value !== "") {
      props.handleConstants(item, value);
    } else if (value === "" && item.type === "constant") {
      props.handleRemoveOperand(item);
    }
  };

  let justQuestions =
    props.questions && props.questions.length > 0
      ? props.questions.map((item) => {
          return (
            item.type !== "group" && {
              questionId: item.id,
              questionNo: `Q${item.position}`,
            }
          );
        })
      : [];

  justQuestions.length > 0 &&
    justQuestions.map((item, index) => {
      if (typeof item === "boolean") {
        let item = props.questions && props.questions[index];
        let result =
          item.questions.length > 0 &&
          item.questions.map((ques: any) => {
            return {
              questionId: ques.id,
              questionNo: `Q${item.position}.${ques.position}`,
            };
          });

        justQuestions.splice(index, 1, ...result);
      }
    });

  return (
    <div className="score-calculator p-3">
      <h5>Operator</h5>
      <div className="score-box">
        <span className="score-x">X =</span>
        {props.scores.length > 0 &&
          Array.isArray(justQuestions) &&
          props.scores.map((item: any, _: any) => {
            return (
              <input
                className={
                  item.type === "constant"
                    ? "const-input"
                    : item.type == "question"
                    ? "question-input"
                    : "operator-input"
                }
                type="text"
                value={
                  item.type === "question"
                    ? (
                        justQuestions.find(
                          (ques: any) => ques.questionId === item.value
                        ) as any
                      )?.questionNo
                    : item.value !== "*"
                    ? item.value
                    : calcButtons[5].multiLabel
                }
                onClick={() => props.handleEditExp(item)}
                onChange={(e) => handleChange(e.target.value, item)}
              />
            );
          })}
      </div>
      <div className="score-calc-buttons row mx-0 mt-3">
        {calcButtons.map((item: any, index: any) => {
          return (
            <div className="col-md-4 p-1">
              <div
                key={index}
                className="score-calc-item cp"
                style={{ background: `${item.classValue}` }}
                onClick={() => props.handleExp(item)}
              >
                {item.hasOwnProperty("label") ? (
                  item.label
                ) : (
                  <i className={item.iconValue}></i>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  questions: state.question.builderQuestions,
});

export default connect(mapStateToProps, null)(ScoreCalculator);
