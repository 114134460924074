import React from "react";
import { Tabs } from "antd";
import AppLayout from "../../AppLayout/AppLayout";
import Navigation from "../../Navigation/Navigation";
import AllAppointmentsTab from "./AllAppointmentsTab";
import { useLocation, useParams } from "react-router-dom";

const { TabPane } = Tabs;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AllAppointments = () => {
  const name = useQuery().get("name");
  const params = useParams<any>();
  const { therapistId } = params;

  return (
    <AppLayout>
      <Navigation />
      <h2 className="mb-4 font-l fw-bold">
        {(name ? name + "'s" : "All") + " Appointments"}
      </h2>
      <Tabs destroyInactiveTabPane className="singer-cutsom-tab">
        <TabPane tab="Today" key="today">
          <AllAppointmentsTab tabType="today" therapistId={therapistId} />
        </TabPane>
        <TabPane tab="Week" key="week">
          <AllAppointmentsTab tabType="week" therapistId={therapistId} />
        </TabPane>
        <TabPane tab="Month" key="month">
          <AllAppointmentsTab tabType="month" therapistId={therapistId} />
        </TabPane>
      </Tabs>
    </AppLayout>
  );
};

export default AllAppointments;
