import React from "react";

type Props = {
  tabType: string;
};

const EmptyAppointment: React.FC<Props> = ({ tabType }) => {
  return (
    <div className="empty-appointment mt-5">
      <EmptyIcon />
      <span className="mt-3">
        {tabType === "pending"
          ? "No Unconfirmed Singer Appointments"
          : "No Singer Appointments for " + tabType}
      </span>
    </div>
  );
};

const EmptyIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#E4F8FF" />
      <path
        d="M67.2857 32.7143H62.5714V29.5715C62.5714 28.7036 61.8679 28 61 28C60.1321 28 59.4285 28.7036 59.4285 29.5715V32.7143H40.5714V29.5715C40.5714 28.7036 39.8678 28 39 28C38.1322 28 37.4286 28.7036 37.4286 29.5715V32.7143H32.7143C30.1107 32.7143 28 34.8249 28 37.4286V67.2857C28 69.8893 30.1107 72 32.7143 72H67.2857C69.8893 72 72 69.8893 72 67.2857V37.4286C72 34.8249 69.8893 32.7143 67.2857 32.7143ZM68.8571 67.2857C68.8571 68.1536 68.1535 68.8572 67.2856 68.8572H32.7143C31.8464 68.8572 31.1428 68.1536 31.1428 67.2857V46.8571H68.8571V67.2857ZM68.8571 43.7143H31.1428V37.4286C31.1428 36.5607 31.8464 35.8571 32.7143 35.8571H37.4286V38.9999C37.4286 39.8678 38.1321 40.5714 39 40.5714C39.8679 40.5714 40.5715 39.8678 40.5715 38.9999V35.8571H59.4286V38.9999C59.4286 39.8678 60.1322 40.5714 61.0001 40.5714C61.868 40.5714 62.5715 39.8678 62.5715 38.9999V35.8571H67.2858C68.1537 35.8571 68.8573 36.5607 68.8573 37.4286V43.7143H68.8571Z"
        fill="#00B9FC"
      />
      <path
        d="M40.5717 50H37.4289C36.561 50 35.8574 50.7036 35.8574 51.5715C35.8574 52.4394 36.561 53.1429 37.4289 53.1429H40.5717C41.4396 53.1429 42.1432 52.4394 42.1432 51.5715C42.1432 50.7036 41.4396 50 40.5717 50Z"
        fill="#00B9FC"
      />
      <path
        d="M51.5717 50H48.4289C47.561 50 46.8574 50.7036 46.8574 51.5715C46.8574 52.4394 47.561 53.1429 48.4289 53.1429H51.5717C52.4396 53.1429 53.1432 52.4394 53.1432 51.5715C53.1432 50.7036 52.4396 50 51.5717 50Z"
        fill="#00B9FC"
      />
      <path
        d="M62.5717 50H59.4289C58.561 50 57.8574 50.7036 57.8574 51.5715C57.8574 52.4394 58.561 53.1429 59.4289 53.1429H62.5717C63.4396 53.1429 64.1432 52.4394 64.1432 51.5715C64.1431 50.7036 63.4395 50 62.5717 50Z"
        fill="#00B9FC"
      />
      <path
        d="M40.5717 56.2861H37.4289C36.561 56.2861 35.8574 56.9897 35.8574 57.8576C35.8574 58.7255 36.561 59.429 37.4289 59.429H40.5717C41.4396 59.429 42.1432 58.7255 42.1432 57.8576C42.1432 56.9897 41.4396 56.2861 40.5717 56.2861Z"
        fill="#00B9FC"
      />
      <path
        d="M51.5717 56.2861H48.4289C47.561 56.2861 46.8574 56.9897 46.8574 57.8576C46.8574 58.7255 47.561 59.429 48.4289 59.429H51.5717C52.4396 59.429 53.1432 58.7255 53.1432 57.8576C53.1432 56.9897 52.4396 56.2861 51.5717 56.2861Z"
        fill="#00B9FC"
      />
      <path
        d="M62.5717 56.2861H59.4289C58.561 56.2861 57.8574 56.9897 57.8574 57.8576C57.8574 58.7255 58.561 59.429 59.4289 59.429H62.5717C63.4396 59.429 64.1432 58.7255 64.1432 57.8576C64.1431 56.9897 63.4395 56.2861 62.5717 56.2861Z"
        fill="#00B9FC"
      />
      <path
        d="M40.5717 62.5713H37.4289C36.561 62.5713 35.8574 63.2748 35.8574 64.1428C35.8574 65.0107 36.561 65.7141 37.4289 65.7141H40.5717C41.4396 65.7141 42.1432 65.0106 42.1432 64.1427C42.1432 63.2747 41.4396 62.5713 40.5717 62.5713Z"
        fill="#00B9FC"
      />
      <path
        d="M51.5717 62.5713H48.4289C47.561 62.5713 46.8574 63.2748 46.8574 64.1427C46.8574 65.0107 47.561 65.7142 48.4289 65.7142H51.5717C52.4396 65.7142 53.1432 65.0107 53.1432 64.1427C53.1432 63.2748 52.4396 62.5713 51.5717 62.5713Z"
        fill="#00B9FC"
      />
      <path
        d="M62.5717 62.5713H59.4289C58.561 62.5713 57.8574 63.2748 57.8574 64.1427C57.8574 65.0107 58.561 65.7142 59.4289 65.7142H62.5717C63.4396 65.7142 64.1432 65.0107 64.1432 64.1427C64.1432 63.2748 63.4395 62.5713 62.5717 62.5713Z"
        fill="#00B9FC"
      />
    </svg>
  );
};
export default EmptyAppointment;
