import { Table } from "antd";
import { useEffect, useState } from "react";
import { errorToastMessage } from "../../../helpers/toastMessage";
import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";

const columns = [
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
  },
];

const NotesOverview = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        dispatch(setAppLoader(true));
        const response = await http.get(`/programs/singer?fields=notes`);
        setData([response.data.data]);
        dispatch(setAppLoader(false));
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    };
    fetchDetails();
  }, [dispatch, setData]);

  return (
    <Table
      dataSource={data}
      columns={columns}
      showSorterTooltip={false}
      className="singer-custom-table"
      pagination={false}
    />
  );
};

export default NotesOverview;
