import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChartTypes, TimeType } from "../../types";

export interface ConsoleState {
  type: ChartTypes | null;
  time: TimeType;
  chartData: any;
  patientMetric: any;
  patientList: any[];
  patientListCount: number;
  userId: string | null;
  chartEntries: any[];
}

const initialState: ConsoleState = {
  type: null,
  time: "week",
  chartData: {},
  patientMetric: {},
  patientList: [],
  patientListCount: 0,
  userId: null,
  chartEntries: [],
};

export const consoleSlice = createSlice({
  name: "console",
  initialState,
  reducers: {
    setConsoleChart: (state, action: PayloadAction<ChartTypes | null>) => {
      state.type = action.payload;
    },
    setConsoleTime: (state, action: PayloadAction<TimeType>) => {
      state.time = action.payload;
    },
    setConsoleChartData: (state, action: PayloadAction<any>) => {
      state.chartData = action.payload;
    },
    setPatientMetrics: (state, action: PayloadAction<any>) => {
      state.patientMetric = action.payload;
      state.userId = action.payload.userId || null;
    },
    setPatientList: (
      state,
      action: PayloadAction<{ data: any[]; count: number }>
    ) => {
      state.patientList = action.payload.data;
      state.patientListCount = action.payload.count;
    },
    setChartEntries: (state, action: PayloadAction<any[]>) => {
      state.chartEntries = action.payload;
    },
  },
});

export const {
  setConsoleChart,
  setConsoleTime,
  setConsoleChartData,
  setPatientMetrics,
  setPatientList,
  setChartEntries,
} = consoleSlice.actions;

export default consoleSlice.reducer;
