import { useState, useEffect } from "react";
import { toastMessage } from "../../helpers/toastMessage";
import CustomButton from "../Common/CustomButton";
import { Upload } from "antd";
import { connect } from "react-redux";
import CustomInput from "../Common/CustomInput";
import AppLayout from "../AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import { UploadImage } from "../../helpers/imageUpload";
import { getUserInfo, editProfile } from "../../redux/actions/userAction";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { useHistory } from "react-router-dom";

type Props = {
  UploadImage: Function;
};

let yup = require("yup");

let schema = yup.object().shape({
  firstName: yup.string().trim().required("First Name is Required"),
  lastName: yup.string().trim().required("Last Name is Required"),
  profileImage: yup.string().nullable().optional(),
});

const EditProfile = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userInfo } = useAppSelector((state) => state.user);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    profileImage: "",
  });

  const [imgData, setImgData] = useState({
    expiresAt: "",
    fileName: "",
    postUploadImageUrl: "",
    presignedUploadUrl: "",
    type: "",
  });

  const closeHandler = () => {
    history.goBack();
  };

  useEffect(() => {
    if (userInfo) {
      setInitialValues({
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        profileImage: userInfo.profileImage,
      });
    }
  }, [userInfo, setInitialValues]);

  useEffect(() => {
    let id = typeof window !== "undefined" && localStorage.getItem("userId");
    if (id) {
      dispatch(getUserInfo(id));
    }
  }, [dispatch]);

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      toastMessage("warning", "Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleUpload = (data: any) => {
    setImgData(data[0]);
  };

  const handleAvater = (fileObj: any) => {
    props.UploadImage(fileObj, handleUpload, "profile_image");
  };

  return (
    <AppLayout>
      <Navigation title=" " />
      <div className="main-wrapper">
        <div className="edit-profile p-sm-5 p-3 mx-auto">
          <h4 className="font-l fw-bold mb-5">Edit Information</h4>
          <Formik
            enableReinitialize
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={(values) => {
              values.profileImage =
                imgData.postUploadImageUrl !== ""
                  ? imgData.postUploadImageUrl
                  : values.profileImage;

              let id =
                typeof window !== "undefined" && localStorage.getItem("userId");
              if (
                values.firstName === initialValues.firstName &&
                values.lastName === initialValues.lastName &&
                values.profileImage === initialValues.profileImage
              ) {
                toastMessage("warning", "There are no changes to edit");
              } else {
                dispatch(editProfile(id, values));
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row mb-5">
                  <div className="col-md-4 image-name">
                    {imgData.postUploadImageUrl !== "" ? (
                      <div className="image-container">
                        <img
                          src={imgData.postUploadImageUrl}
                          className="image-wrapper-l"
                        />
                      </div>
                    ) : initialValues.profileImage !== null &&
                      initialValues.profileImage !== "" ? (
                      <>
                        <div className="image-container">
                          <img
                            src={initialValues.profileImage}
                            className="image-wrapper-l"
                          />
                        </div>
                      </>
                    ) : (
                      <div className="image-wrapper-l">
                        <div className="profile-placeholder-icon"></div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-8" style={{ padding: "2.8rem 1rem" }}>
                    <Upload
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      customRequest={handleAvater}
                      accept=".gif,.jpg,.jpeg,.png"
                    >
                      <button type="button" className="upload-button">
                        Choose a file
                      </button>
                    </Upload>
                    <div className="mt-2">
                      Acceptable formats: jpg, png only
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 form-group mb-4">
                    <label htmlFor="lastname" className="field-name form-label">
                      First Name
                    </label>
                    <div className="floating-label">
                      <CustomInput
                        name="firstName"
                        value={values.firstName}
                        changeEvent={handleChange}
                        blurEvent={handleBlur}
                      />
                      <div className="input-error">
                        {errors.firstName &&
                          touched.firstName &&
                          errors.firstName}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4 form-group">
                    <label htmlFor="lastname" className="form-label">
                      Last Name
                    </label>
                    <div className="floating-label">
                      <CustomInput
                        name="lastName"
                        value={values.lastName}
                        changeEvent={handleChange}
                        blurEvent={handleBlur}
                      />
                      <div className="input-error">
                        {errors.lastName && touched.lastName && errors.lastName}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-end mt-4">
                  <CustomButton
                    buttonText="Cancel"
                    primary={false}
                    clickHandler={closeHandler}
                    className="cancel-button btn-lg me-3"
                  />
                  <CustomButton
                    primary={true}
                    className="btn-lg"
                    buttonType="submit"
                    buttonText="Save Changes"
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </AppLayout>
  );
};

export default connect(null, { UploadImage })(EditProfile);
