import React from "react";
// import "../../../../pages/css/questions/questionResponse.scss";
import { Modal, Input, Select } from "antd";
import Button from "../../../components/Common/CustomButton";
import { conditions } from "./dataList";

type Props = {
  show: boolean;
  hideModal: Function;
  question?: any;
  questions?: any;
  index?: number;
  handleResponseCode?: Function;
  setInnerFields?: Function;
  previewDisabling?: boolean;
};

const nameType: any = {
  lt: "maxValue",
  lte: "maxValue",
  eq: "minValue",
  gte: "minValue",
  gt: "minValue",
};

const stateObj: any = {
  lt: {
    condition: "lt",
    code: "",
    maxValue: "",
  },
  lte: {
    condition: "lte",
    code: "",
    maxValue: "",
  },
  between: {
    condition: "between",
    code: "",
    minValue: "",
    maxValue: "",
  },
  eq: {
    condition: "eq",
    code: "",
    minValue: "",
  },
  gte: {
    condition: "gte",
    code: "",
    minValue: "",
  },
  gt: {
    condition: "gt",
    code: "",
    minValue: "",
  },
};

const initState = {
  condition: "lt",
  code: "",
  maxValue: "",
};

const NumberType = (props: any) => {
  const { question, index } = props;
  const { responseCodes } = question.properties;

  const handleCondition = (value: string, idx: number) => {
    const newVal = {
      ...question,
      properties: {
        responseCodes: responseCodes.map((r: any, i: number) =>
          i === idx ? { ...stateObj[value] } : r
        ),
      },
    };

    props.setInnerFields(index, newVal);
  };

  const handleInput = (e: any, idx: number) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      const newVal = {
        ...question,
        properties: {
          responseCodes: responseCodes.map((r: any, i: number) =>
            i === idx ? { ...r, [e.target.name]: e.target.value } : r
          ),
        },
      };
      props.setInnerFields(index, newVal);
    }
  };

  const addField = () => {
    const newVal = {
      ...question,
      properties: {
        responseCodes: [...responseCodes, initState],
      },
    };
    props.setInnerFields(index, newVal);
  };

  const removeField = (idx: number) => {
    if (responseCodes.length > 1) {
      const newVal = {
        ...question,
        properties: {
          responseCodes: responseCodes.filter((_: any, i: number) => i !== idx),
        },
      };
      props.setInnerFields(index, newVal);
    }
  };

  return (
    <div className="response-container">
      <div className="row m-0">
        <div className="col-4 px-1">
          <label>If answer is</label>
        </div>
        <div className="col-3 px-1 offset-3">
          <label>Response Value</label>
        </div>
      </div>

      {Array.isArray(responseCodes) &&
        responseCodes.length > 0 &&
        responseCodes.map((code, idx) => {
          return (
            <div className="row m-0 mb-3 align-items-center" key={idx}>
              <div className="col-4 px-1">
                <Select
                  defaultValue={code.condition}
                  onChange={(value) => handleCondition(value, idx)}
                  style={{ width: "100%" }}
                >
                  {conditions["NUM"].map(({ label, value }: any) => (
                    <Select.Option value={value} key={value + label}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="col-3 px-1">
                {code.condition === "between" ? (
                  <div className="dual-input">
                    <Input
                      name="minValue"
                      value={code["minValue"]}
                      placeholder="Min"
                      onChange={(e) => handleInput(e, idx)}
                      maxLength={3}
                      readOnly={props.previewDisabling}
                    />
                    <span>-</span>
                    <Input
                      name="maxValue"
                      value={code["maxValue"]}
                      placeholder="Max"
                      onChange={(e) => handleInput(e, idx)}
                      maxLength={3}
                      readOnly={props.previewDisabling}
                    />
                  </div>
                ) : (
                  <Input
                    name={`${nameType[code.condition]}`}
                    value={code[nameType[code.condition]]}
                    placeholder="Value"
                    onChange={(e) => handleInput(e, idx)}
                    maxLength={3}
                    readOnly={props.previewDisabling}
                  />
                )}
              </div>
              <div className="col-3 px-1">
                <Input
                  name="code"
                  value={code.code}
                  onChange={(e) => handleInput(e, idx)}
                  placeholder="Code"
                  readOnly={props.previewDisabling}
                />
              </div>
              <div className="col-2">
                <div className="d-flex justify-content-end">
                  <i className="add-green-icon ms-2 cp" onClick={addField}></i>
                  <i
                    className="delete-green-icon cp"
                    onClick={() => removeField(idx)}
                  ></i>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const ChoiceType = (props: any) => {
  const {
    question: { fields },
    handleResponseCode,
  } = props;
  return (
    <div className="response-container">
      {Array.isArray(fields) &&
        fields.length > 0 &&
        fields.map((f, idx) => (
          <div className="choice-response" key={idx}>
            <div className="row">
              <div className="col-7">If choice is</div>
              <div className="col-5">Response Code</div>
            </div>
            <div className="row">
              <div className="col-7">
                <Input
                  className="response-choice"
                  placeholder={`Choice ${idx + 1}`}
                  value={f.label}
                  readOnly
                />
              </div>
              <div className="col-5">
                <Input
                  name="code"
                  value={f.code}
                  onChange={(e) => handleResponseCode(idx, e)}
                  placeholder="Code"
                  maxLength={3}
                  readOnly={props.previewDisabling}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const textType = (props: any) => {
  const {
    question: { fields },
    handleResponseCode,
  } = props;
  return (
    <div className="response-container">
      {Array.isArray(fields) &&
        fields.length > 0 &&
        fields.map((f, idx) => (
          <div className="choice-response" key={idx}>
            <div className="row">
              <div className="col-6">
                <label className="form-label">For the below text</label>
                <Input
                  className="form-control"
                  placeholder={`Text ${idx + 1}`}
                  value={f.value}
                  readOnly
                />
              </div>
              <div className="col-6">
                <label className="form-label">Response Code</label>
                <Input
                  name="code"
                  className="form-control"
                  value={f.code}
                  onChange={(e) => handleResponseCode(idx, e)}
                  placeholder="Code"
                  maxLength={3}
                  readOnly={props.previewDisabling}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const yesNoType = (props: any) => {
  const {
    question: { fields },
    handleResponseCode,
  } = props;
  return (
    <div className="response-container">
      {Array.isArray(fields) &&
        fields.length > 0 &&
        fields.map((f, idx) => (
          <div className="choice-response" key={idx}>
            <div className="row">
              <div className="col-7">For the below option</div>
              <div className="col-5">Response Code</div>
            </div>
            <div className="row">
              <div className="col-7">
                <Input
                  className="response-choice"
                  placeholder={`Text ${idx + 1}`}
                  value={f.label}
                  readOnly
                />
              </div>
              <div className="col-5">
                <Input
                  name="code"
                  value={f.code}
                  onChange={(e) => handleResponseCode(idx, e)}
                  placeholder="Code"
                  maxLength={3}
                  readOnly={props.previewDisabling}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const responseType: any = {
  multiple_choice: ChoiceType,
  dropdown: ChoiceType,
  likert_scale: ChoiceType,
  checkbox: ChoiceType,
  picture_choice: ChoiceType,
  short_text: textType,
  long_text: textType,
  yes_no: yesNoType,
  date: textType,
  number: NumberType,
  opinion_scale: NumberType,
};

const ResponseLogic = (props: Props) => {
  const ResponseComponent = responseType[props.question.type];

  return (
    <Modal
      title="Response code logic"
      className="response-code-modal"
      visible={props.show}
      onCancel={() => props.hideModal()}
      maskClosable={false}
      width="580px"
      footer={null}
    >
      {responseType.hasOwnProperty(props.question.type) && (
        <>
          <ResponseComponent {...props} />
          {!props.previewDisabling && (
            <div className="text-end">
              <Button
                buttonText="Save"
                // primary={false}
                className="btn-lg btn-success"
                clickHandler={() => props.hideModal()}
              />
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default ResponseLogic;
