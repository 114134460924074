const StepsIcon = ({ width, height }: { width: string; height: string }) => (
  <svg
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 4.5C8.6 4.5 9.5 3.6 9.5 2.5C9.5 1.4 8.6 0.5 7.5 0.5C6.4 0.5 5.5 1.4 5.5 2.5C5.5 3.6 6.4 4.5 7.5 4.5ZM3.8 7.9L1.24 20.81C1.11 21.42 1.59 22 2.22 22H2.3C2.77 22 3.17 21.68 3.28 21.22L4.9 14L7 16V21C7 21.55 7.45 22 8 22C8.55 22 9 21.55 9 21V15.36C9 14.81 8.78 14.29 8.38 13.91L6.9 12.5L7.5 9.5C8.57 10.74 10.12 11.63 11.86 11.91C12.46 12 13 11.52 13 10.91C13 10.42 12.64 10.01 12.15 9.93C10.63 9.68 9.37 8.78 8.7 7.6L7.7 6C7.14 5.11 6.02 4.75 5.05 5.16L1.22 6.78C0.48 7.1 0 7.82 0 8.63V11C0 11.55 0.45 12 1 12C1.55 12 2 11.55 2 11V8.6L3.8 7.9Z"
      fill="#333333"
      className="fill-path"
    />
  </svg>
);
export default StepsIcon;
