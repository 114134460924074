import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { GetScoreInfo } from "../../../redux/actions/scoreAction";
import {
  scoresFromResponse,
  scoreEvalFromResponse,
} from "../../../helpers/score";
import http from "../../../http";

import { getBuilderQuestions } from "../../../redux/actions/questionAction";

// import "../../../../pages/css/scores/scorePreview.scss";

type Props = {
  qid?: any;
  name?: string;
  show: boolean;
  title: string;
  scoreId: any;
  scoreInfo: any;
  hideModal: Function;
  getBuilderQuestions: Function;
  GetScoreInfo: Function;
  questions: any;
};

const calcButtons = [
  {
    func: "AC",
    classValue: "#A0A5B8",
    label: "AC",
  },
  {
    func: "constant",
    classValue: "#A0A5B8",
    label: "CONST",
  },
  {
    type: "paranthesis",
    func: "(",
    classValue: "#A0A5B8",
    label: "(",
  },
  {
    type: "paranthesis",
    func: ")",
    classValue: "#A0A5B8",
    label: ")",
  },
  {
    func: "%",
    classValue: "#A0A5B8",
    label: "%",
  },
  {
    func: "*",
    classValue: "#00B9FC",
    iconValue: "score-multiply-icon",
    multiLabel: "x",
  },
  {
    func: "-",
    classValue: "#00B9FC",
    iconValue: "score-subtract-icon",
  },
  {
    func: "+",
    classValue: "#00B9FC",
    iconValue: "score-add-icon",
  },
  {
    func: "/",
    classValue: "#00B9FC",
    iconValue: "score-divide-icon",
  },
];

const scoreConditionTypes: any = {
  gt: "Greater than",
  gte: "Greater than or equal to",
  lt: "Less than",
  lte: "Less than or equal to",
  between: "Between",
  eq: "Equal to",
};

const ScorePreview = (props: Props) => {
  const [name, setName] = useState("");
  let [scores, setScores] = useState<any[]>([]);
  let [scoreEval, setEval] = useState<any[]>([]);

  const { getBuilderQuestions, qid, scoreId } = props;
  useEffect(() => {
    getBuilderQuestions(qid);
  }, [getBuilderQuestions, qid]);

  // useEffect(() => {
  //   if (props.scoreId !== undefined) {
  //     props.GetScoreInfo(props.scoreId);
  //   }
  // }, []);

  // //to retreive score info from the list
  // useEffect(() => {
  //   if (props.scoreInfo.hasOwnProperty("id") && props.scoreId !== undefined) {
  //     setScores(scoresFromResponse(props.scoreInfo.scoreExpressionNodes));
  //     setEval(scoreEvalFromResponse(props.scoreInfo.scoreExpressionConditions));
  //     setName(props.scoreInfo.title);
  //   }
  // }, [props.scoreInfo]);

  const updateStateInfo = useCallback(
    async (scoreId) => {
      const response = await http.get(`/score_expressions/${scoreId}`);
      let scoreInfoValues = await response.data.data;
      setScores(scoresFromResponse(scoreInfoValues.scoreExpressionNodes));
      setEval(scoreEvalFromResponse(scoreInfoValues.scoreExpressionConditions));
      setName(scoreInfoValues.title);
    },
    [setScores, setEval, setName]
  );

  useEffect(() => {
    if (scoreId !== undefined) {
      updateStateInfo(scoreId);
    }
  }, [scoreId, updateStateInfo]);

  const handleTitle = (item: any) => {
    if (item.hasOwnProperty("title")) {
      return item.title;
    } else if (item.hasOwnProperty("fields")) {
      return item.fields[0].value;
    }
  };

  let justQuestions =
    props.questions.length > 0 &&
    props.questions.map((item: any) => {
      return (
        item.type !== "group" && {
          questionId: item.id,
          questionNo: `Q${item.position}`,
          questionTitle: handleTitle(item),
        }
      );
    });

  justQuestions.length > 0 &&
    justQuestions.map((item: any, index: number) => {
      if (typeof item === "boolean") {
        let item = props.questions[index];
        let result =
          item.questions.length > 0 &&
          item.questions.map((ques: any) => {
            return {
              questionId: ques.id,
              questionNo: `Q${item.position}.${ques.position}`,
              questionTitle: handleTitle(ques),
            };
          });

        justQuestions.splice(index, 1, ...result);
      }
    });

  console.log(props.scoreId, scores, "ScoreINfooooasdadsajjkas");
  return (
    <Modal
      title={`${name} Preview`}
      visible={props.show}
      onCancel={() => props.hideModal()}
      width={"70rem"}
      footer={null}
    >
      <div className="score-preview">
        <h5 className="font-ml fw-semibold">Questions</h5>
        <div className="score-preview-body row">
          <div className="score-preview-questions col-md-5 ps-0">
            {justQuestions.length > 0 &&
              justQuestions.map((item: any, index: any) => {
                return (
                  <div className="score-preview-q row cp" key={index}>
                    <div className="question-number col-md-2">
                      {item.questionNo}
                    </div>
                    <div className="question-title col-md-10">
                      {item.questionTitle}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="score-preview-data col-md-7 ps-4">
            <div className="score-preview-exp">
              <h5 className="mb-3 fw-semibold">Expression</h5>
              <div className="score-box">
                <span className="score-x">X =</span>
                {scores.length > 0 &&
                  Array.isArray(justQuestions) &&
                  scores.map((item: any, _: any) => {
                    return (
                      <input
                        className={
                          item.type === "constant"
                            ? "const-input"
                            : item.type == "question"
                            ? "question-input"
                            : "operator-input"
                        }
                        type="text"
                        value={
                          item.type === "question"
                            ? justQuestions.find(
                                (ques: any) => ques.questionId === item.value
                              ).questionNo
                            : item.value !== "*"
                            ? item.value
                            : calcButtons[5].multiLabel
                        }
                        readOnly
                      />
                    );
                  })}
              </div>
            </div>
            <div className="score-preview-conditions">
              <h5>Scores</h5>
              {scoreEval.map((_: any, index: any) => {
                const conditionType =
                  scoreConditionTypes[scoreEval[index].condition];
                return (
                  <div className="score-conditions" key={`${index}-score-eval`}>
                    <div className="d-flex align-items-center">
                      <span className="me-4">If X</span>
                      <div className="me-4 score-border-cond">
                        {conditionType}
                      </div>
                      <span className="me-4 score-border-cond">
                        {conditionType === "Between"
                          ? `${scoreEval[index].minValue} and ${scoreEval[index].maxValue}`
                          : conditionType === "Less than" ||
                            conditionType === "Less than or equal to"
                          ? `${scoreEval[index].maxValue}`
                          : `${scoreEval[index].minValue}`}
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ marginTop: "3rem" }}
                    >
                      <span className="me-4">{`Then Score`}</span>
                      <div className="score-border-cond">{`${scoreEval[index].score}`}</div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  questions: state.question.builderQuestions,
  scoreInfo: state.scores.scoreInfo,
});

export default connect(mapStateToProps, {
  getBuilderQuestions,
  hideModal,
  GetScoreInfo,
})(ScorePreview);
