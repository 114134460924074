import moment from "moment";
import { ChartTypes, TimeType } from "../types";
import { store } from "../redux/store";
import { setModalDetails } from "../redux/reducers/modalSlice";
import http from "../http";
import { errorToastMessage } from "./toastMessage";
import { setAppLoader } from "../redux/reducers/loaderSlice";

export const colorCodingDecoder = (colorCode: number) => {
  if (colorCode === 0) {
    return "healthy-range";
  } else if (colorCode === 1) {
    return "moderate-health-range";
  } else if (colorCode === 2) {
    return "unhealthy-range";
  } else if (colorCode === 3) {
    return "unknown-health-range";
  }
};

const convertMinsToHrsMins = (mins: number) => {
  let h: number = Math.floor(mins / 60);
  let m: number | string = mins % 60;
  m = m < 10 ? "0" + m : m;
  return `${h}:${m}`;
};

export const currentMetricDecoder = (value: any, type: ChartTypes) => {
  if (value === 0) {
    value = null;
  }
  if (type === "Alcohol") {
    return value ? value + " ml" : "-";
  } else if (type === "Blood Pressure") {
    return value ? value : "-";
  } else if (type === "Diet") {
    return value ? value + "%" : "-";
  } else if (type === "Exercise") {
    return value ? convertMinsToHrsMins(value) : "-";
  } else if (type === "Glucose") {
    return value ? value : "-";
  } else if (type === "HbA1C") {
    return value ? value : "-";
  } else if (type === "Medicine") {
    return value ? value + "%" : "-";
  } else if (type === "Sleep") {
    return value ? convertMinsToHrsMins(value) : "-";
  } else if (type === "Smoke") {
    return value ? value : "-";
  } else if (type === "Steps") {
    return value ? value : "-";
  } else if (type === "Weight") {
    return value ? value : "-";
  }
};

export const fetchTimeQuery = (time: TimeType) => {
  //The from date is inclusive so subtracting 1
  let startDate = moment()
    .startOf("day")
    .subtract(6, "days")
    .utc()
    .toISOString();
  if (time === "month") {
    startDate = moment()
      .startOf("day")
      .subtract(1, "month")
      .utc()
      .toISOString();
  }
  const endDate = moment().endOf("day").utc().toISOString();
  return `&range=${time}&from=${startDate}&to=${endDate}`;
};

export const transformStepsData = (graphData: any[], time: TimeType) => {
  const labels = graphData.map((entry) => {
    if (time === "week") {
      return moment(entry.index).format("ddd");
    } else {
      return moment(entry.index).format("DD/MM");
    }
  });
  return {
    labels: labels,
    datasets: [
      {
        borderColor: "#00B9FC",
        data: graphData.map((entry) => +entry.count),
        pointBackgroundColor: "#00B9FC",
        pointBorderColor: "#00B9FC",
        pointBorderWidth: 2,
        borderWidth: 2,
        tension: 0.25,
      },
    ],
  };
};

export const transformSleepData = (graphData: any[], time: TimeType) => {
  const labels = graphData.map((entry) => {
    if (time === "week") {
      return moment(entry.index).format("ddd");
    } else {
      return moment(entry.index).format("DD/MM");
    }
  });
  return {
    labels: labels,
    datasets: [
      {
        data: graphData.map((entry) => +entry.count),
        backgroundColor: graphData.map((entry) =>
          entry.intensity === 1 ? "#30D073" : "#FCB500"
        ),
        borderColor: graphData.map((entry) =>
          entry.intensity === 1 ? "#30D073" : "#FCB500"
        ),
        borderRadius: "2px",
        barThickness: time === "week" ? 15 : 7,
        minBarLength: 7,
      },
    ],
  };
};

export const transformDietData = (
  graphData: any[],
  time: TimeType,
  userId: string
) => {
  const labels = graphData.map((entry) => {
    if (time === "week") {
      return moment(entry.index).format("ddd");
    } else {
      return moment(entry.index).format("DD/MM");
    }
  });
  return {
    labels: labels,
    datasets: [
      {
        data: graphData.map((entry) => {
          if (time === "week") {
            return {
              x: moment(entry.index).format("ddd"),
              y: +entry.count,
              date: entry.index,
              userId: userId,
            };
          } else {
            return {
              x: moment(entry.index).format("DD/MM"),
              y: +entry.count,
              date: entry.index,
              userId: userId,
            };
          }
        }),
        backgroundColor: graphData.map((entry) =>
          entry.intensity === 1 ? "#30D073" : "#FCB500"
        ),
        borderColor: graphData.map((entry) =>
          entry.intensity === 1 ? "#30D073" : "#FCB500"
        ),
        borderRadius: "2px",
        barThickness: time === "week" ? 15 : 7,
        minBarLength: 7,
      },
    ],
  };
};

export const transformAlcoholData = (graphData: any[], time: TimeType) => {
  const labels = graphData.map((entry) => {
    if (time === "week") {
      return moment(entry.index).format("ddd");
    } else {
      return moment(entry.index).format("DD/MM");
    }
  });
  return {
    labels: labels,
    datasets: [
      {
        data: graphData.map((entry) => +entry.count),
        backgroundColor: graphData.map((entry) =>
          entry.intensity === 1 ? "#FCB500" : "#30D073"
        ),
        borderColor: graphData.map((entry) =>
          entry.intensity === 1 ? "#FCB500" : "#30D073"
        ),
        borderRadius: "2px",
        barThickness: time === "week" ? 15 : 7,
        minBarLength: 7,
      },
    ],
  };
};

export const transformExerciseData = (graphData: any[], time: TimeType) => {
  const labels = graphData.map((entry) => {
    if (time === "week") {
      return moment(entry.index).format("ddd");
    } else {
      return moment(entry.index).format("DD/MM");
    }
  });
  return {
    labels: labels,
    datasets: [
      {
        data: graphData.map((entry) => +entry.count),
        backgroundColor: graphData.map((entry) =>
          entry.intensity === 1 ? "#30D073" : "#FCB500"
        ),
        borderColor: graphData.map((entry) =>
          entry.intensity === 1 ? "#30D073" : "#FCB500"
        ),
        borderRadius: "2px",
        barThickness: time === "week" ? 15 : 7,
        minBarLength: 7,
      },
    ],
  };
};

export const transformMedicineData = (
  graphData: any[],
  time: TimeType,
  userId: string
) => {
  const labels = graphData.map((entry) => {
    if (time === "week") {
      return moment(entry.index).format("ddd");
    } else {
      return moment(entry.index).format("DD/MM");
    }
  });
  return {
    labels: labels,
    datasets: [
      {
        data: graphData.map((entry) => {
          // return +entry.count;
          if (time === "week") {
            return {
              x: moment(entry.index).format("ddd"),
              y: +entry.count,
              date: entry.index,
              userId: userId,
            };
          } else {
            return {
              x: moment(entry.index).format("DD/MM"),
              y: +entry.count,
              date: entry.index,
              userId: userId,
            };
          }
        }),
        backgroundColor: graphData.map((entry) =>
          entry.intensity === 1 ? "#30D073" : "#FCB500"
        ),
        borderColor: graphData.map((entry) =>
          entry.intensity === 1 ? "#30D073" : "#FCB500"
        ),
        borderRadius: "2px",
        barThickness: time === "week" ? 15 : 7,
        minBarLength: 7,
      },
    ],
  };
};

export const transformHbData = (graphData: any[], time: TimeType) => {
  const labels = graphData.map((entry) => {
    // if (time === "week") {
    //   return moment(entry.index).format("ddd");
    // } else {
    return moment(entry.index).format("DD/MM");
    // }
  });
  return {
    labels: labels,
    datasets: [
      {
        borderColor: "#00B9FC",
        data: graphData.map((entry) => +entry.count),
        pointBackgroundColor: "#00B9FC",
        pointBorderColor: "#00B9FC",
        pointBorderWidth: 2,
        borderWidth: 2,
        tension: 0.25,
      },
    ],
  };
};

export const transformSmokeData = (graphData: any[], time: TimeType) => {
  const labels = graphData.map((entry) => {
    if (time === "week") {
      return moment(entry.index).format("ddd");
    } else {
      return moment(entry.index).format("DD/MM");
    }
  });
  return {
    labels: labels,
    datasets: [
      {
        data: graphData.map((entry) => +entry.count),
        backgroundColor: graphData.map((entry) =>
          entry.intensity === 1 ? "#FCB500" : "#30D073"
        ),
        borderColor: graphData.map((entry) =>
          entry.intensity === 1 ? "#FCB500" : "#30D073"
        ),
        borderRadius: "2px",
        barThickness: time === "week" ? 15 : 7,
        minBarLength: 7,
      },
    ],
  };
};

export const transformWeightData = (graphData: any[], time: TimeType) => {
  const labels = graphData.map((entry) => {
    if (time === "week") {
      return moment(entry.index).format("ddd");
    } else {
      return moment(entry.index).format("DD/MM");
    }
  });
  return {
    labels: labels,
    datasets: [
      {
        borderColor: "#00B9FC",
        data: graphData.map((entry) => +entry.count),
        pointBackgroundColor: "#00B9FC",
        pointBorderColor: "#00B9FC",
        pointBorderWidth: 2,
        borderWidth: 2,
        tension: 0.25,
      },
    ],
  };
};

export const transformBPdata = (graphData: any[], time: TimeType) => {
  const labels = graphData.map((entry) => {
    if (time === "week") {
      return moment(entry.index).format("ddd");
    } else {
      return moment(entry.index).format("DD/MM");
    }
  });
  return {
    labels: labels,
    datasets: [
      {
        label: "Systolic",
        borderColor: "#00B9FC",
        data: graphData.map((entry) => entry.systolic),
        pointBackgroundColor: "#00B9FC",
        pointBorderColor: "#00B9FC",
        pointBorderWidth: 2,
        borderWidth: 2,
        tension: 0.25,
        pointStyle: "circle",
      },
      {
        label: "Diastolic",
        borderColor: "#9B51E0",
        data: graphData.map((entry) => entry.diastolic),
        pointStyle: "rectRot",
        pointBackgroundColor: "#9B51E0",
        // pointBorderColor: "#9B51E0",
        // pointBorderWidth: 2,
        borderWidth: 2,
        tension: 0.25,
      },
    ],
  };
};

export const transformGlucoseData = (graphData: any[], time: TimeType) => {
  const labels = graphData.map((entry) => {
    if (time === "week") {
      return moment(entry.index).format("ddd");
    } else {
      return moment(entry.index).format("DD/MM");
    }
  });
  return {
    labels: labels,
    datasets: [
      {
        borderColor: "#00B9FC",
        data: graphData.map((entry) => +entry.count),
        pointBackgroundColor: "#00B9FC",
        pointBorderColor: "#00B9FC",
        pointBorderWidth: 2,
        borderWidth: 2,
        tension: 0.25,
        showLine: false,
      },
    ],
  };
};

export const chartClickHandler = async (
  chart: ChartTypes,
  date: string,
  userId: string
) => {
  try {
    store.dispatch(setAppLoader(true));
    const start = moment(date).startOf("day").toISOString();
    const end = moment(date).endOf("day").toISOString();
    const query = `?from=${start}&to=${end}`;
    if (chart === "Diet") {
      const res = await http.get("/mh/diet/" + userId + query);
      store.dispatch(
        setModalDetails({
          type: "DIET_DETAIL_MODAL",
          modalProps: { show: true, dietDetails: res.data.data },
        })
      );
    } else if (chart === "Medicine") {
      const res = await http.get("/mh/medication/" + userId + query);
      store.dispatch(
        setModalDetails({
          type: "MEDICINE_DETAIL_MODAL",
          modalProps: { show: true, medicine: res.data.data, date: date },
        })
      );
    }
    store.dispatch(setAppLoader(false));
  } catch (err) {
    errorToastMessage(err);
    store.dispatch(setAppLoader(false));
  }
};
