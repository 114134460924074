import { ArrowRightOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import AppointmentTab from "./AppointmentsTab";
import AppLayout from "../../components/AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";

const { TabPane } = Tabs;

const TherapistAppointments: React.FC = () => {
  const { countData } = useAppSelector((state) => state.appointment);
  const name = localStorage.getItem("fullName");

  return (
    <AppLayout>
      <Navigation />
      <div className="appointment-wrapper">
        <h2 className="mb-3">Good morning, {name}</h2>
        <div className="d-flex align-items-center mb-3">
          <h2>Appointments</h2>
          <Link
            to="/appointments/calendar"
            className="d-flex align-items-center link-primary no-underline ms-3"
          >
            View Calendar
            <ArrowRightOutlined />
          </Link>
          <Link
            to="/appointments/all"
            className="d-flex align-items-center link-primary no-underline ms-3"
          >
            All Appointments
            <ArrowRightOutlined />
          </Link>
        </div>
        <Tabs
          defaultActiveKey="1"
          className="singer-cutsom-tab appointment-tab"
          destroyInactiveTabPane
        >
          <TabPane tab={`Today (${countData.todayCount})`} key="1">
            <AppointmentTab tabType="today" />
          </TabPane>
          <TabPane tab={`Week (${countData.weekCount})`} key="2">
            <AppointmentTab tabType="week" />
          </TabPane>
          <TabPane tab={`Unconfirmed (${countData.unconfirmedCount})`} key="3">
            <AppointmentTab tabType="pending" />
          </TabPane>
        </Tabs>
      </div>
    </AppLayout>
  );
};

export default TherapistAppointments;
