import React from "react";
import { Formik } from "formik";
import CustomButton from "../Common/CustomButton";
import PasswordInput from "../Common/PasswordInput";
import AuthLayout from "../AuthLayout/AuthLayout";
import { Row, Col } from "antd";
import logo from "../../assets/img/logo-singer.svg";
import { changePassword } from "../../redux/actions/userAction";
import { errorToastMessage } from "../../helpers/toastMessage";
import { useAppDispatch } from "../../redux/hooks";

let yup = require("yup");

let schema = yup.object().shape({
  currentPassword: yup.string().required("Old Password is Required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("New Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ChangePassword = () => {
  const dispatch = useAppDispatch();

  const submitHandler = async (values: any, setSubmitting: Function) => {
    try {
      setSubmitting(true);
      let resultObj = {
        currentPassword: values.currentPassword,
        newPassword: values.password,
      };
      await dispatch(changePassword(resultObj));
      setSubmitting(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitting(false);
    }
  };

  return (
    <div className="d-block auth-layout">
      <Row className="h-100">
        <Col sm={24} md={12}>
          <div className="auth-left-container d-flex align-items-center justify-content-center h-100">
            <div className="flex-grow-1">
              <img
                src={logo}
                alt="SINGER-logo"
                width="200px"
                className="mb-5"
              />
              <div className="px-md-4">
                <h2 className="font-l fw-bold mb-5">Change Password?</h2>
                <Formik
                  initialValues={{
                    currentPassword: "",
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={schema}
                  onSubmit={(values, { setSubmitting }: any) => {
                    submitHandler(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    errors,
                    touched,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {/* input-container */}
                      <div className="input-container mb-4">
                        <div className="floating-label input-password">
                          <PasswordInput
                            changeEvent={handleChange}
                            value={values.currentPassword}
                            blurEvent={handleBlur}
                            placeHolder="Old Password"
                            name="currentPassword"
                          />
                          <div className="input-error">
                            {errors.currentPassword &&
                              touched.currentPassword &&
                              errors.currentPassword}
                          </div>
                        </div>
                      </div>
                      <div className="input-container mb-4">
                        <div className="floating-label input-password">
                          <PasswordInput
                            name="password"
                            changeEvent={handleChange}
                            value={values.password}
                            blurEvent={handleBlur}
                            placeHolder="New Password"
                          />
                          <div className="input-error">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </div>
                        </div>
                      </div>
                      <div className="input-container mb-4">
                        <div className="floating-label input-password">
                          <PasswordInput
                            changeEvent={handleChange}
                            value={values.confirmPassword}
                            blurEvent={handleBlur}
                            name="confirmPassword"
                            placeHolder="Confirm Password"
                          />
                          <div className="input-error">
                            {errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="bottom-pos-btn-container">
                        <CustomButton
                          buttonType="submit"
                          buttonText="Change Password"
                          className="btn-lg"
                          disabled={isSubmitting}
                          primary
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={12} className="bg-primary-blue p-3 p-lg-4">
          <AuthLayout
            headerText="Know your health better!"
            paragraphText="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
          />
        </Col>
      </Row>
    </div>
  );
};

export default ChangePassword;
