const SleepIcon = ({ width, height }: { width: string; height: string }) => (
  <svg
    width={width || "24"}
    height={height || "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 13C8.66 13 10 11.66 10 10C10 8.34 8.66 7 7 7C5.34 7 4 8.34 4 10C4 11.66 5.34 13 7 13ZM19 7H13C11.9 7 11 7.9 11 9V14H3V6C3 5.45 2.55 5 2 5C1.45 5 1 5.45 1 6V19C1 19.55 1.45 20 2 20C2.55 20 3 19.55 3 19V17H21V19C21 19.55 21.45 20 22 20C22.55 20 23 19.55 23 19V11C23 8.79 21.21 7 19 7Z"
      fill="#333333"
      className="fill-path"
    />
  </svg>
);
export default SleepIcon;
