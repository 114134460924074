import http from "../../http";
import { toastMessage } from "../../helpers/toastMessage";

import {
  questionResponseFormat,
  questionSubmitFormat,
  logicJumpFormat,
  educationResponse,
  educationRequest,
} from "../../helpers/question";

import { getQuestionList } from "./questionAction";
import { Dispatch } from "redux";
import { setAppLoader } from "../reducers/loaderSlice";
import { toggleLessonLoader } from "./educationAction";

const downloadFile = async (myData: any) => {
  const fileName = myData.name;
  const json = JSON.stringify(myData);
  const blob = new Blob([json], { type: "application/json" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// export questionnaire
export const exportQuestionnaire = (qid: any) => () => {
  http
    .get(`/questionnaires/${qid}/questions`)
    .then((res) => {
      downloadFile(res.data.data);
    })
    .catch((err) => {
      console.log(err);
      toastMessage(
        "error",
        err!.response !== undefined
          ? err!.response!.data!.message[0]
          : "Network error:  Failed to load response data"
      );
    });
};

//import questionnaire
export const importQuestionnaire =
  (Qbody: any) => (dispatch: Dispatch<any>) => {
    if (Qbody.hasOwnProperty("questions")) {
      let body = constructBody(Qbody);
      console.log("^^^^^^^^^^^^^");
      http
        .post("/questionnaires", body)
        .then((res) => {
          dispatch(createNewQuestionBuilder(Qbody, res.data.data.id));
        })
        .catch((err) => {
          console.log(err);
          toastMessage(
            "error",
            err!.response !== undefined
              ? err!.response!.data!.message[0]
              : "Network error:  Failed to load response data"
          );
        });
    } else {
      toastMessage("warning", "Please use HERO format questionnaire JSON file");
    }
  };

const createNewQuestionBuilder =
  (Qbody: any, qid: any) => async (dispatch: Dispatch<any>) => {
    let questions = await questionResponseFormat(
      Qbody.questions,
      Qbody.logicJumps
    );

    let ques = await questionSubmitFormat(questions);
    console.log(ques, questions, "createNewQB");
    let logicJumps = await logicJumpFormat(questions);

    const formObject = {
      id: qid,
      name: Qbody.name,
      questions: ques,
      logicJumps: logicJumps,
      status: "draft",
    };

    http
      .post(`/questionnaires/${formObject.id}/build`, formObject)
      .then(({ data }) => {
        toastMessage("success", data.message);
        if (formObject.status === "draft") {
          dispatch(getQuestionList(1));
        }
      })
      .catch((err) => {
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
      });
  };

const constructBody = (values: any) => {
  let obj = {
    name: values.name,
    imageUrl: values.imageUrl !== null ? values.imageUrl : "",
    measure: values.measure,
    links: values.links !== null ? values.links : [],
    minTime: values.minTime !== null ? values.minTime : 0,
    maxTime: values.maxTime !== null ? values.maxTime : 0,
    year: values.year !== null ? values.year : "",
    reliability: values.reliability !== null ? values.reliability : "",
    description: values.description,
    useInStudy: values.useInStudy !== null ? values.useInStudy : "",
  };

  if (obj.imageUrl === "") {
    delete obj.imageUrl;
  }

  if (
    obj.links !== undefined &&
    obj.links.length > 0 &&
    obj.links[0].label === "" &&
    obj.links[0].url === ""
  ) {
    delete obj.links;
  }

  if (obj.description === "" || obj.description === null) {
    delete obj.description;
  }

  if (obj.measure === "" || obj.measure === null) {
    delete obj.measure;
  }

  if (obj.year === "" || obj.year === null) {
    delete obj.year;
  }

  if (obj.reliability === "" || obj.reliability === null) {
    delete obj.reliability;
  }

  if (obj.useInStudy === "" || obj.useInStudy === null) {
    delete obj.useInStudy;
  }

  return obj;
};

// LESSON EXPORT/IMPORT -------------------------------------------------------------

export const exportLesson = (eid: any) => () => {
  http
    .get(`/education_lessons/${eid}`)
    .then((res) => {
      downloadFile(res.data.data);
    })
    .catch((err) => {
      console.log(err);
      toastMessage(
        "error",
        err!.response !== undefined
          ? err!.response!.data!.message[0]
          : "Network error:  Failed to load response data"
      );
    });
};

export const importLesson = (Ebody: any) => (dispatch: Dispatch<any>) => {
  let body = constructLessonBody(Ebody);

  if (Ebody.hasOwnProperty("pages")) {
    http
      .post(`/education_lessons`, body)
      .then((res) => {
        if (Ebody.pages.length > 0) {
          dispatch(createNewPages(Ebody, res.data.data.id));
        }
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
      });
  } else {
    dispatch(setAppLoader(false));
    toastMessage(
      "warning",
      "Please use HERO format education lesson JSON file"
    );
  }
};

const createNewPages =
  (Ebody: any, lessonId: any) => (dispatch: Dispatch<any>) => {
    Ebody.pages.sort(function (a: any, b: any) {
      return a.position - b.position;
    });

    Ebody.pages.forEach(function (item: any) {
      item.sections.sort(function (a: any, b: any) {
        return a.position - b.position;
      });
    });

    let pagesBody = Ebody.pages.map((item: any) => {
      return {
        title: item.title,
        imageUrl: item.imageUrl,
        sections: handlePageSections(item.sections),
        position: item.position,
      };
    });

    let requests = pagesBody.map((body: any) =>
      http.post(`/education_lessons/${lessonId}/pages`, body)
    );

    Promise.all(requests)
      .then((responses) => {
        console.log(responses);
        toastMessage("success", "Education Lesson created successfully");
        dispatch(toggleLessonLoader());
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
      });
  };

const constructLessonBody = (values: any) => {
  const obj = {
    name: values.name !== null ? values.name : "",
    description: values.description !== null ? values.description : "",
    imageUrl: values.imageUrl !== null ? values.imageUrl : "",
  };

  if (obj.imageUrl === "") {
    delete obj.imageUrl;
  }

  if (obj.description === "") {
    delete obj.description;
  }

  return obj;
};

const handlePageSections = (sections: any) => {
  let tempSections = educationResponse(sections);

  let result = educationRequest(tempSections);

  return result;
};
