import React, { useState, useEffect } from "react";
import AuthLayout from "../AuthLayout/AuthLayout";
import { Row, Col } from "antd";
import logo from "../../assets/img/logo-singer.svg";
import { useAppDispatch } from "../../redux/hooks";
import { errorToastMessage } from "../../helpers/toastMessage";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { verifyEmail } from "../../redux/actions/userAction";

const VerifyEmail = () => {
  const dispatch = useAppDispatch();
  const [verifying, setVerifying] = useState("error");
  const location = useLocation();

  useEffect(() => {
    const verifyData = async () => {
      try {
        const { emailId, tokenId } = queryString.parse(location.search);
        await dispatch(
          verifyEmail({
            email: emailId,
            emailVerifyToken: tokenId,
          })
        );
        setVerifying("completed");
      } catch (err) {
        errorToastMessage(err as Error);
        setVerifying("error");
      }
    };
    if (location.search) {
      verifyData();
    }
  }, [dispatch, location.search, setVerifying]);

  return (
    <div className="d-block auth-layout">
      <Row className="h-100">
        <Col sm={24} md={12}>
          <div className="auth-left-container d-flex align-items-center justify-content-center h-100">
            <div className="flex-grow-1">
              <img
                src={logo}
                alt="SINGER-logo"
                width="200px"
                className="mb-5"
              />
              <div className="px-md-4">
                <div className="mobile-screen-icon"></div>
                <h1 className="font-l fw-bold mb-5">
                  {verifying === "pending" && "Verifying your email address"}
                  {verifying === "completed" &&
                    "Your email address has been verified"}
                  {verifying === "error" &&
                    "There was a problem verifying your email. Please try again"}
                </h1>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={12} className="bg-primary-blue p-3 p-lg-4">
          <AuthLayout
            headerText="Know your health better!"
            paragraphText="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
          />
        </Col>
      </Row>
    </div>
  );
};

export default VerifyEmail;
