import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Modal, Input, Select } from "antd";
import { setModalDetails } from "../../../redux/reducers/modalSlice";
import CustomButton from "../../Common/CustomButton";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { sleep } from "../../../redux/actions/userAction";
import { Formik } from "formik";
import * as Yup from "yup";
import { hideModal } from "../../../redux/reducers/modalSlice";
import {
  createDocumentFolder,
  updateDocumentFolder,
} from "../../../redux/actions/documentAction";
import { connect } from "react-redux";

type Props = {
  title: string;
  documentData?: any;
  createDocumentFolder: Function;
  updateDocumentFolder: Function;
  pageUrlId: string;
};

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

const CreateFolderModal = (props: Props) => {
  const parentFolderId = props.pageUrlId ? props.pageUrlId : "";

  const { modalProps } = useAppSelector((state) => state.modal);
  const [status, setStatus] = useState<string>(modalProps.status);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const changeSelectHandler = (val: string) => {
    console.log(val);
    // setStatus()
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      //Http call
      await sleep(500);
      modalProps.dataChanged(true);
      closeHandler();
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  const initialValues = props.documentData
    ? {
        name: props.documentData.name,
      }
    : {
        name: "",
      };

  return (
    <Modal
      visible={modalProps.show}
      closable={true}
      footer={false}
      title={modalProps.title}
      width={"25rem"}
      onCancel={closeHandler}>
      <div className="py-1">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            // console.log(parentFolderId);
            let obj;
            if (parentFolderId) {
              obj = {
                name: values.name,
                parentFolderId: parentFolderId,
              };
            } else {
              obj = {
                name: values.name,
              };
            }
            if (props.documentData) {
              props.updateDocumentFolder(props.documentData.id, values);
            } else {
              props.createDocumentFolder(obj);
            }
          }}>
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              <div className="row">
                <div className="col-md-12 first form-group">
                  <div className="form-group">
                    <div className=" form-label">Name</div>
                    <div className="floating-label">
                      <Input
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                      />
                    </div>
                    <div className="input-error">
                      {errors.name && touched.name && errors.name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end mt-4">
                <span
                  role="button"
                  className="button-cancel me-3"
                  onClick={closeHandler}
                  tabIndex={0}>
                  Cancel
                </span>
                <CustomButton
                  className=""
                  primary={true}
                  buttonType="submit"
                  buttonText="Create"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default connect(null, { createDocumentFolder, updateDocumentFolder })(
  CreateFolderModal
);
