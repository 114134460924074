import { CloseOutlined, SendOutlined } from "@ant-design/icons";

const ChatAttachment = ({
  attachment,
  clearAttachment,
  sendAttachment,
}: any) => {
  return (
    <div className="chat-attachment">
      <div className="chat-attachment-content">
        <span className="label">File Attached :</span>
        <span className="file-name">{attachment.name}</span>
        <span className="attachment-actions">
          <SendOutlined className="action-icon" onClick={sendAttachment} />
          <CloseOutlined className="action-icon" onClick={clearAttachment} />
        </span>
      </div>
    </div>
  );
};

export default ChatAttachment;
