import { Formik } from "formik";
import * as Yup from "yup";
import { Checkbox, Select, Input, Card, Switch, Divider } from "antd";
import AppLayout from "../AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import CustomButton from "../Common/CustomButton";
import { useHistory } from "react-router-dom";
import CustomInput from "../Common/CustomInput";

const { Option } = Select;
const { TextArea } = Input;

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  trackedAction: Yup.string().required("Required"),
});

const options = [
  {
    label: "Send Alert notification to the User",
    value: "participant",
  },
  {
    label: "Send Alert notification to the Therapist",
    value: "therapist",
  },
  {
    label: "Send Alert notification to the Admin",
    value: "admin",
  },
];

const CreateAction = (props: any) => {
  const history = useHistory();

  const backHandler = () => {
    history.push("/actions");
  };

  return (
    <AppLayout>
      <Navigation />
      <Formik
        initialValues={{
          name: "",
          description: "",
          alertEnabled: true,
          lowerValue: "",
          higherValue: "",
          trackedAction: "",
          alertNotifications: [],
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          history.push("/actions");
        }}
      >
        {({
          values,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-4 d-flex align-items-center">
              <i
                className="icon-back arrow-left-icon me-2 cp"
                onClick={backHandler}
              ></i>
              <h2 className="font-l mb-0 fw-bold">Create New Action</h2>
            </div>
            <div>
              <div className="create-action">
                <div className="row mx-n3">
                  <div className="col-md-6 mb-4 px-3">
                    <Card
                      title="Action Information"
                      className="shadow-lg border-0 mb-0"
                      headStyle={{
                        fontSize: "18px",
                        border: "none",
                        fontWeight: "bold",
                      }}
                    >
                      <div className="input-field w-100 mb-4">
                        <div className="field-name form-label">
                          Action Title
                        </div>
                        <CustomInput
                          name="name"
                          value={values.name}
                          changeEvent={handleChange}
                          blurEvent={handleBlur}
                          className="floating-input form-control"
                        />
                        <div className="input-error text-left mt-1">
                          {errors.name && touched.name && errors.name}
                        </div>
                      </div>
                      <div className="input-field w-100 mb-4">
                        <div className="field-name form-label">Tracking</div>
                        <div className="floating-label">
                          <Select
                            value={values.trackedAction}
                            onChange={(value) =>
                              setFieldValue("trackedAction", value)
                            }
                            onBlur={handleBlur}
                            id="action-tracking"
                          >
                            <Option value="sleep"> Sleep </Option>
                            <Option value="steps"> Steps </Option>
                            <Option value="glucose"> Glucose </Option>
                            <Option value="weight"> Weight</Option>
                            <Option value="medication"> Medication </Option>
                            <Option value="food"> Food </Option>
                          </Select>
                          <div className="input-error text-left mt-1">
                            {errors.trackedAction &&
                              touched.trackedAction &&
                              errors.trackedAction}
                          </div>
                        </div>
                      </div>
                      <div className="input-field w-100 mb-4">
                        <div className="field-name form-label">
                          Action Description
                        </div>
                        <div className="floating-label">
                          <TextArea
                            rows={4}
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="action-name"
                          />
                          <div className="input-error text-left mt-1">
                            {errors.description &&
                              touched.description &&
                              errors.description}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="col-md-6 mb-4 px-3">
                    <Card
                      title="Default Action Alerts"
                      className="shadow-lg border-0 mb-4"
                      headStyle={{
                        fontSize: "18px",
                        border: "none",
                        fontWeight: "bold",
                      }}
                      extra={
                        <Switch
                          defaultChecked
                          onChange={(checked) =>
                            setFieldValue("alertEnabled", checked)
                          }
                          checked={values.alertEnabled}
                        />
                      }
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="field-name form-label">
                            Default Lower Value
                          </div>
                          <div className="floating-label">
                            <CustomInput
                              type="number"
                              name="lowerValue"
                              value={values.lowerValue}
                              changeEvent={handleChange}
                              blurEvent={handleBlur}
                              disabled={!values.alertEnabled}
                            />
                            <div className="input-error text-left mt-1">
                              {errors.lowerValue &&
                                touched.lowerValue &&
                                errors.lowerValue}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="field-name form-label">
                            Default Higher Value
                          </div>
                          <div className="floating-label">
                            <CustomInput
                              type="number"
                              name="higherValue"
                              value={values.higherValue}
                              changeEvent={handleChange}
                              blurEvent={handleBlur}
                              disabled={!values.alertEnabled}
                            />
                            <div className="input-error text-left mt-1">
                              {errors.higherValue &&
                                touched.higherValue &&
                                errors.higherValue}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card
                      title="Default Notifications"
                      className="shadow-lg border-0 mb-4"
                      headStyle={{
                        fontSize: "18px",
                        border: "none",
                        fontWeight: "bold",
                      }}
                    >
                      <Checkbox.Group
                        className="d-flex flex-column"
                        options={options}
                        defaultValue={["client"]}
                        onChange={(checkedValues) =>
                          setFieldValue("alertNotifications", checkedValues)
                        }
                      />
                    </Card>
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <div className=" justify-content-end d-flex">
              <CustomButton
                buttonText="Cancel"
                primary={false}
                clickHandler={backHandler}
                className="cancel-button btn-lg"
              />
              <CustomButton
                primary={true}
                buttonType="submit"
                buttonText="Save"
                className="ms-3 btn-lg btn-success"
              />
            </div>
          </form>
        )}
      </Formik>
    </AppLayout>
  );
};

export default CreateAction;
