import { Formik } from "formik";
import { Input, Radio } from "antd";
import { submitQuestionResponse } from "../../redux/actions/webAction";
import { connect } from "react-redux";

type Props = {
  idx: number;
  questions: any[];
  lessonId: any;
  projectId: any;
  submitQuestionResponse: Function;
  handleSkip: Function;
  status: any;
};

const RenderMobQuestions = (props: Props) => {
  console.log(props);
  const initialValues = {
    questionsChecked: [] as string[],
    questionsSelect: "",
    questionText: "",
  };

  return (
    <div className="render-question">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          let result = props.questions
            .filter((question) => {
              if (question.type === "text") {
                if (values.questionText) {
                  return {
                    questionId: question.id,
                    programId: props.projectId,
                    educationLessonSectionId: question.educationLessonSectionId,
                    questionType: question.type,
                    response: { textValue: values.questionText },
                  };
                }
              } else if (question.type === "single_select") {
                if (values.questionsSelect) {
                  return {
                    questionId: question.id,
                    programId: props.projectId,
                    educationLessonSectionId: question.educationLessonSectionId,
                    questionType: question.type,
                    response: { questionChoiceIds: [values.questionsSelect] },
                  };
                }
              } else if (question.type === "multi_select") {
                if (values.questionsChecked.length) {
                  return {
                    questionId: question.id,
                    programId: props.projectId,
                    educationLessonSectionId: question.educationLessonSectionId,
                    questionType: question.type,
                    response: { questionChoiceIds: values.questionsChecked },
                  };
                }
              }
            })
            .map((question) => {
              if (question.type === "text") {
                return {
                  questionId: question.id,
                  programId: props.projectId,
                  educationLessonSectionId: question.educationLessonSectionId,
                  questionType: question.type,
                  response: { textValue: values.questionText },
                };
              } else if (question.type === "single_select") {
                return {
                  questionId: question.id,
                  programId: props.projectId,
                  educationLessonSectionId: question.educationLessonSectionId,
                  questionType: question.type,
                  response: { questionChoiceIds: [values.questionsSelect] },
                };
              } else if (question.type === "multi_select") {
                return {
                  questionId: question.id,
                  programId: props.projectId,
                  educationLessonSectionId: question.educationLessonSectionId,
                  questionType: question.type,
                  response: { questionChoiceIds: values.questionsChecked },
                };
              }
            });

          let obj = {
            response: result,
          };
          props.submitQuestionResponse(props.lessonId, obj, props.projectId);
        }}
      >
        {({ values, handleSubmit, handleChange, handleBlur, dirty }) => (
          <form onSubmit={handleSubmit}>
            {props.questions.map((item) => {
              return item.type === "text" ? (
                <div key={item.id} className="input-container">
                  <div className="input-header">{item.title}</div>
                  <div className="input-control">
                    <Input
                      name="questionText"
                      placeholder="Enter Text Here"
                      onChange={handleChange}
                      value={values.questionText}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              ) : item.type === "single_select" ? (
                <div key={item.id} className="input-container">
                  <div className="input-header">{item.title}</div>
                  <div className="input-radio">
                    <Radio.Group
                      onChange={handleChange}
                      value={values.questionsSelect}
                      name="questionsSelect"
                    >
                      {item.choices.map((choice: any) => {
                        return (
                          <div key={choice.id} className="radio-container">
                            <Radio value={choice.id}>
                              <span>{choice.label}</span>
                            </Radio>
                          </div>
                        );
                      })}
                    </Radio.Group>
                  </div>
                </div>
              ) : (
                <div key={item.id} className="input-container">
                  <div className="input-header">{item.title}</div>
                  <div className="input-check">
                    {item.choices.map((choice: any) => {
                      return (
                        <div key={choice.id} className="checked-container">
                          <label htmlFor="" className="ant-checkbox-wrapper">
                            <span
                              className={
                                !values.questionsChecked.includes(choice.id)
                                  ? "ant-checkbox"
                                  : "ant-checkbox ant-checkbox-checked"
                              }
                            >
                              <input
                                className="ant-checkbox-input"
                                type="checkbox"
                                id={choice.id}
                                name="questionsChecked"
                                onChange={handleChange}
                                checked={values.questionsChecked.includes(
                                  choice.id
                                )}
                                onBlur={handleBlur}
                                value={choice.id}
                              />
                              <span className="ant-checkbox-inner"></span>
                            </span>
                            <span>{choice.label}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div className="text-center">
              <button
                type="submit"
                className={
                  !dirty
                    ? "ntu-button ques-survery-background-disabled font-white cp px-4"
                    : "ntu-button ques-survery-background font-white cp px-4"
                }
                disabled={!dirty}
              >
                SAVE
              </button>
              <button
                type="button"
                className="ntu-button
                ques-survery-background font-white
              cp px-4 mt-3 me-3"
                onClick={() => props.handleSkip(props.idx)}
              >
                SKIP
              </button>
            </div>
          </form>
        )}
      </Formik>
      {props.status === "completed" && (
        <div className="del-overlay">
          <div className="del-overlay-options">
            <p className="del-overlay-header">Thanks!</p>
            <p className="del-overlay-text">
              You have submitted your response.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(null, { submitQuestionResponse })(RenderMobQuestions);
