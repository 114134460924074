import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { fetchTherapistSlots } from "../../redux/actions/appointmentAction";
import { errorToastMessage } from "../../helpers/toastMessage";
import AppLayout from "../../components/AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import TherapistCalendar from "./TherapistCalendar";
import { useLocation, useParams } from "react-router-dom";
import { setAppLoader } from "../../redux/reducers/loaderSlice";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CalendarWrapper: React.FC<{ children: React.ReactNode }> = () => {
  const reschedule = useQuery().get("reschedule");
  const { therapistId } = useParams<any>();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        await dispatch(fetchTherapistSlots(therapistId));
        setLoading(false);
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [dispatch, setLoading, therapistId]);

  return (
    <AppLayout>
      <Navigation />
      {loading ? null : (
        <TherapistCalendar therapistId={therapistId} reschedule={reschedule} />
      )}
    </AppLayout>
  );
};

export default CalendarWrapper;
