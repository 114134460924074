import { CloseOutlined } from "@ant-design/icons";

const ChatMedia = ({ attachment, clearAttachment }: any) => {
  return (
    <div className="chat-attachment">
      <div className="chat-attachment-media">
        <span className="close-icon" onClick={clearAttachment}>
          <CloseOutlined />
        </span>
        {attachment.type === "image" && (
          <img src={attachment.resourceUrl} alt="attachment" />
        )}
        {attachment.type === "video" && (
          <video src={attachment.resourceUrl} preload="metadata" />
        )}
      </div>
    </div>
  );
};

export default ChatMedia;
