import React from "react";
import { useDrag } from "react-dnd";

type Props = {
  card: any;
  selectedCard: string;
  setSelectedCard: any;
};

const CardEducation = (props: Props) => {
  const { card } = props;

  const [, dragRef] = useDrag({
    item: {
      type: card.name,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });

  return (
    <div
      key={card.key + "card-details"}
      ref={dragRef}
      className={
        props.selectedCard === card.name
          ? "card-section-details card-section-color cp"
          : "card-section-details  cp"
      }
      onClick={() => props.setSelectedCard(card.name)}
    >
      <div
        className={
          props.selectedCard === card.name
            ? `${card.activeClass}`
            : `${card.classValue}`
        }
      ></div>
      <div className="card-title">{card.name}</div>
      <div className="card-desc">{card.description}</div>
    </div>
  );
};

export default CardEducation;
