import React, { useState } from "react";
// import "../../../pages/css/questions/addQuestions.scss";
import { connect } from "react-redux";
import { Formik, FieldArray } from "formik";
import { toastMessage, errorToastMessage } from "../../../helpers/toastMessage";
import { Modal, Upload, Input, Tooltip } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { UploadImage } from "../../../helpers/imageUpload";
import NTUButton from "../../Common/CustomButton";

import {
  addQuestionModule,
  updateQuestionModule,
} from "../../../redux/actions/questionAction";

let yup = require("yup");

let schema = yup.object().shape({
  name: yup.string().required("Module Name is Required"),
  // links: yup.array().of(
  //   yup.object().shape({
  //     label: yup.string().required("Label field is required."),
  //     url: yup.string().required("Url field is required"),
  //   })
  // ),
});

type Props = {
  hideModal: Function;
  UploadImage: Function;
  addQuestionModule: Function;
  show: boolean;
  modalType: string;
  questionData?: any;
  readType?: any;
  handlePageChange: Function;
  updateQuestionModule: Function;
};

const AddQuestions = (props: Props) => {
  const [imgData, setImgData] = useState({
    expiresAt: "",
    fileName: "",
    postUploadImageUrl: "",
    presignedUploadUrl: "",
    type: "",
  });

  const defaultLinkValue = {
    label: "",
    url: "",
  };

  const handleInitialValues = (questionData: any) => {
    let resultant;
    if (questionData.links !== null && questionData.links.length > 0) {
      resultant = { ...questionData };
      return resultant;
    } else {
      resultant = { ...questionData, links: [{ label: "", url: "" }] };
      return resultant;
    }
  };

  const initialValues = props.questionData
    ? handleInitialValues(props.questionData)
    : {
        name: "",
        description: "",
        imageUrl: "",
        measure: "",
        links: [{ label: "", url: "" }],
        minTime: 0,
        maxTime: 0,
        year: "",
        reliability: "",
        useInStudy: "",
      };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      toastMessage("warning", "Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleUpload = (data: any) => {
    setImgData(data[0]);
  };

  const handleAvater = (fileObj: any) => {
    props.UploadImage(fileObj, handleUpload, "questionnaire_image");
  };

  return (
    <Modal
      title={
        props.modalType === "EDIT_QUESTIONS"
          ? "Edit Questionnaire"
          : "Add Questionnaire"
      }
      visible={props.show}
      onCancel={() => props.hideModal()}
      width={"45rem"}
      footer={null}
    >
      <div className="add-question">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            values.imageUrl =
              imgData.postUploadImageUrl !== ""
                ? imgData.postUploadImageUrl
                : values.imageUrl;

            values.minTime = Number(values.minTime);
            values.maxTime = Number(values.maxTime);

            try {
              if (values.name.trim() === "") {
                throw new Error(
                  `No white spaces are allowed in Questionnaire name`
                );
              }
            } catch (err) {
              errorToastMessage(err as Error);
            }

            if (props.questionData) {
              const data = {
                name: values.name,
                description: values.description,
                imageUrl: values.imageUrl,
                status: props.questionData.status,
                measure: values.measure,
                links: values.links,
                minTime: values.minTime,
                maxTime: values.maxTime,
                year: values.year,
                reliability: values.reliability,
                useInStudy: values.useInStudy,
              };

              if (data.imageUrl === "") {
                delete data.imageUrl;
              }

              if (data.links[0].label === "" && data.links[0].url === "") {
                delete data.links;
              }
              if (data.description === "") {
                data.description = ".";
              }

              if (data.name.trim().length > 0) {
                props.updateQuestionModule(
                  data,
                  props.questionData.id,
                  props.hideModal
                );
                props.handlePageChange();
              }
            } else {
              if (
                values.description.trim().length === 0 &&
                values.name.trim().length > 0
              ) {
                let obj = {
                  name: values.name,
                  imageUrl: values.imageUrl,
                  measure: values.measure,
                  links: values.links,
                  minTime: values.minTime,
                  maxTime: values.maxTime,
                  year: values.year,
                  reliability: values.reliability,
                  useInStudy: values.useInStudy,
                };

                if (obj.imageUrl === "") {
                  delete obj.imageUrl;
                }

                if (obj.links[0].label === "" && obj.links[0].url === "") {
                  delete obj.links;
                }
                props.addQuestionModule(obj, props.hideModal);
                props.handlePageChange();
              } else if (values.name.trim().length > 0) {
                if (values.imageUrl === "") {
                  delete values.imageUrl;
                }

                if (
                  values.links[0].label === "" &&
                  values.links[0].url === ""
                ) {
                  delete values.links;
                }

                props.addQuestionModule(values, props.hideModal);
                props.handlePageChange();
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row add-education-wrapper">
                {/* row 1 */}
                <div className="col-md-12 mb-4">
                  <div className="input-group form-group">
                    <label htmlFor="lastname" className="form-label">
                      Questionnaire Name *
                    </label>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="input-error">
                      {errors.name && touched.name && errors.name}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-group form-group">
                    <label htmlFor="lastname">Description</label>
                    <Tooltip placement="right" title={values.description}>
                      <Input
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Tooltip>
                  </div>
                </div>
                {/* 2nd row */}
                <div className="col-md-6 mt-4 form-group">
                  <label htmlFor="lastname">Measure</label>
                  <Input
                    name="measure"
                    value={values.measure}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-md-3 mt-4 form-group">
                  <label htmlFor="lastname">Time(Min)</label>
                  <Input
                    name="minTime"
                    value={values.minTime}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-md-3 mt-4 form-group">
                  <label htmlFor="lastname">Time(Max)</label>
                  <Input
                    name="maxTime"
                    value={values.maxTime}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {/* 3rd row  */}

                <FieldArray name="links">
                  {({ push, remove }) => (
                    <div
                      className="row field-array"
                      style={{
                        width: "100%",
                        padding: "0.5rem 0rem 0.5rem 1rem",
                      }}
                    >
                      <h3 className="font-ml my-3 fw-semibold">Links</h3>
                      <div className="container-fluid">
                        {(values["links"] !== null ||
                          values.hasOwnProperty("links")) &&
                          values["links"].map((details: any, index: string) => {
                            return (
                              <div className="row">
                                {/* links label */}
                                <div
                                  className="col-md-5 mt-3 mb-4 form-group"
                                  key={index + "links"}
                                >
                                  <label htmlFor="lastname">Label</label>
                                  <Input
                                    type="text"
                                    name={`links[${index}].label`}
                                    onChange={handleChange}
                                    value={details.label}
                                    onBlur={handleBlur}
                                  />
                                </div>
                                {/* links url */}
                                <div
                                  className="col-md-5 mt-3 pr-0 form-group mb-4"
                                  key={index + "links url"}
                                >
                                  <label htmlFor="lastname">Url</label>
                                  <Input
                                    type="text"
                                    name={`links[${index}].url`}
                                    onChange={handleChange}
                                    value={details.url}
                                    onBlur={handleBlur}
                                  />
                                </div>
                                <div className="col-md-2 mt-4 d-flex align-items-center">
                                  {Number(index) > 0 ? (
                                    <span
                                      className="cross-icon me-4 cp"
                                      onClick={() => remove(Number(index))}
                                    />
                                  ) : null}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div>
                        <NTUButton
                          buttonText="Add Link"
                          addButton={true}
                          className="text-end"
                          primary={true}
                          clickHandler={() => push(defaultLinkValue)}
                        />
                      </div>
                    </div>
                  )}
                </FieldArray>
                {/* 4th row */}
                <div className="col-md-6 mt-3">
                  <div className="input-group form-group">
                    <label htmlFor="lastname">Year</label>
                    <Input
                      name="year"
                      value={values.year}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="input-group form-group">
                    <label htmlFor="lastname">Reliability</label>
                    <Input
                      name="reliability"
                      value={values.reliability}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* <div className="input-error">

                        {errors.reliability &&
                          touched.reliability &&
                          errors.reliability}
                      </div> */}
                  </div>
                </div>
                {/* 5th row */}
                <div className="col-md-12 mt-4">
                  <div className="input-group form-group mt-2">
                    <label htmlFor="lastname">
                      Questionnaire used in study
                    </label>
                    <Input
                      name="useInStudy"
                      value={values.useInStudy}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col-md-6"></div>
                <br />
                {/* 6rd row */}
                <div className="col-md-8 image-row mt-4">
                  <label htmlFor="lastname">Upload questionnaire picture</label>
                  <div className="row">
                    <div className="col-md-5">
                      {imgData.postUploadImageUrl !== "" ? (
                        <div className="image-container">
                          <img
                            src={imgData.postUploadImageUrl}
                            alt="question"
                          />
                        </div>
                      ) : values.imageUrl !== null && values.imageUrl !== "" ? (
                        <>
                          <div className="image-container">
                            <img src={values.imageUrl} alt="question" />
                          </div>
                        </>
                      ) : (
                        <div className="image-wrapper">
                          <div className="placeholder-icon"></div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-7 ps-0 upload-wrapper">
                      <Input
                        disabled
                        placeholder={
                          imgData.fileName === ""
                            ? "No file selected"
                            : imgData.fileName
                        }
                      />
                      <Upload
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        customRequest={handleAvater}
                        accept=".gif,.jpg,.jpeg,.png"
                      >
                        <button type="button" className="upload-button">
                          Upload File
                        </button>
                      </Upload>
                    </div>
                  </div>
                  <div className="mt-3">
                    Upload an image of max 5 mb, accepted formats are
                    .jpg,.jpeg,.png{" "}
                  </div>
                </div>
                <div className="button-container text-end">
                  <span
                    className="button-cancel me-2 btn-lg cp"
                    onClick={() => props.hideModal()}
                  >
                    Cancel
                  </span>
                  <button
                    className="ant-btn custom-button primary-background  cp"
                    type="submit"
                  >
                    {props.modalType === "EDIT_QUESTIONS" ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default connect(null, {
  hideModal,
  addQuestionModule,
  updateQuestionModule,
  UploadImage,
})(AddQuestions);
