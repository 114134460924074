import { Dispatch } from "redux";
import history from "../../history";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import { educationResponse } from "../../helpers/question";
import * as types from "../actionTypes";
import http from "../../http";
import { SET_EDUCATION_LESSONS, EDUCATION_EDIT_VIEW } from "../actionTypes";
import { setAppLoader } from "../reducers/loaderSlice";
import { AxiosResponse } from "axios";
import { hideModal } from "../reducers/modalSlice";
import { AppThunk } from "../store";

export const setLessonsAction =
  (lessons: any, editView: boolean) => (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_EDUCATION_LESSONS,
      payload: {
        lessons,
        editView,
      },
    });
  };

export const setLessonDetails = (lesson: any) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: types.EDUCATION_SEARCH_PAGES_LIST,
    payload: lesson,
  });
};

export const setEditPageSections =
  (sectionArray: any, isPageEdited: any = true) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: types.SET_EDIT_PAGE_SECTIONS,
      payload: sectionArray,
      isPageEdited: isPageEdited,
    });
  };

export const setEditViewAction = () => (dispatch: Dispatch) => {
  dispatch({
    type: EDUCATION_EDIT_VIEW,
  });
};

export const setLessonScroll =
  (lessons: any, count: any) => (dispatch: Dispatch) => {
    dispatch({
      type: types.SCROLL_EDUCATION_LESSONS,
      payload: lessons,
      count: count,
    });
  };

export const getEducationLessons =
  (pageNo: number, scrollable?: boolean, search?: string, pageSize?: number) =>
  (dispatch: Dispatch) => {
    dispatch(setAppLoader(true));
    pageSize = pageSize !== undefined ? pageSize : 12;
    const url = `/education_lessons?page=${pageNo}&size=${pageSize}`;
    const extraparam = search ? `${url}&search=${search}` : url;
    http
      .get(extraparam)
      .then((res) => {
        if (scrollable) {
          dispatch({
            type: types.SCROLL_EDUCATION_LESSONS,
            payload: res.data.data.educationLessons,
            count: res.data.data.count,
          });
        } else {
          dispatch({
            type: types.GET_EDUCATION_LESSONS,
            payload: res.data.data.educationLessons,
            count: res.data.data.count,
          });
        }
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const addEducationLesson = (body: any) => (dispatch: Dispatch<any>) => {
  dispatch(setAppLoader(true));
  http
    .post(`/education_lessons`, body)
    .then((res) => {
      console.log(res.data, "addEducationLesson");
      toastMessage("success", res.data.message);
      dispatch(hideModal());
      history.push(`/education/pages/${res.data.data.id}`);
    })
    .catch((err) => {
      console.log(err);
      dispatch(setAppLoader(false));
      toastMessage(
        "error",
        err!.response !== undefined
          ? err!.response!.data!.message[0]
          : "Network error:  Failed to load response data"
      );
    });
};

export const searchEducationLesson =
  (searchItem: any, status?: string) => (dispatch: Dispatch<any>) => {
    dispatch(setAppLoader(true));
    const pageNo = 1;
    const pageSize = 10;

    const url = `/education_lessons?page=${pageNo}&size=${pageSize}&search=${searchItem}`;
    const extraParam = status === undefined ? url : `${url}&status=${status}`;

    http
      .get(extraParam)
      .then((res) => {
        dispatch({
          type: types.SEARCH_EDUCATION_LESSONS,
          payload: res.data.data.educationLessons,
          count: res.data.data.count,
        });
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
        dispatch(setAppLoader(false));
      });
  };

export const deleteEducationLesson =
  (id: string) => (dispatch: Dispatch<any>) => {
    dispatch(setAppLoader(true));
    http
      .delete(`/education_lessons/${id}`)
      .then((res) => {
        toastMessage("success", res.data.message);
        dispatch(toggleLessonLoader());
      })
      .catch((err) => {
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
        dispatch(setAppLoader(false));
      });
  };

export const getLessonDetails = (id: string) => (dispatch: Dispatch<any>) => {
  dispatch(setAppLoader(true));
  http
    .get(`/education_lessons/${id}`)
    .then((res) => {
      dispatch({
        type: types.EDUCATION_PAGES_LIST,
        payload: res.data.data,
      });
      dispatch(setAppLoader(false));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setAppLoader(false));
      toastMessage(
        "error",
        err!.response !== undefined
          ? err!.response!.data!.message[0]
          : "Network error:  Failed to load response data"
      );
    });
};

export const createLessonPages = (body: any, id: string) => () => {
  http
    .post(`/education_lessons/${id}/pages`, body)
    .then((res) => {
      toastMessage("success", res.data.message);
      history.push(`/education/pages/${id}`);
    })
    .catch((err) => {
      console.log(err);
      toastMessage(
        "error",
        err!.response !== undefined
          ? err!.response!.data!.message[0]
          : "Network error:  Failed to load response data"
      );
    });
};

export const updateOnePageLesson =
  (lessonId: any, pageId: any, body: any) => () => {
    http
      .put(`/education_lessons/${lessonId}/pages/${pageId}`, body)
      .then((res) => {
        console.log(res.data, "UpdateOnePageLesson");
        toastMessage("success", res.data.message);
        history.push(`/education/pages/${lessonId}`);
      })
      .catch((err) => {
        console.log(err);
        toastMessage(
          "error",
          err!.response !== undefined
            ? err!.response!.data!.message[0]
            : "Network error:  Failed to load response data"
        );
      });
  };

export const UpdateEducationModule =
  (body: any, id: string, modal?: any) => (dispatch: Dispatch<any>) => {
    dispatch(setAppLoader(true));
    http
      .put(`/education_lessons/${id}`, body)
      .then((res) => {
        toastMessage("success", res.data.message);
        if (modal) {
          dispatch(hideModal());
          dispatch(toggleLessonLoader());
        } else {
          dispatch(getLessonDetails(id));
        }
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const educationModuleDuplicate =
  (id: string) => (dispatch: Dispatch<any>) => {
    dispatch(setAppLoader(true));
    http
      .post(`/education_lessons/${id}/duplicate`)
      .then((res) => {
        toastMessage("success", res.data.message);
        dispatch(toggleLessonLoader());
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const deletePageFromLesson =
  (lessonId: any, pageId: any) => (dispatch: Dispatch<any>) => {
    dispatch(setAppLoader(true));
    http
      .delete(`/education_lessons/${lessonId}/pages/${pageId}`)
      .then((res) => {
        toastMessage("success", res.data.message);
        dispatch(getLessonDetails(lessonId));
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        console.log(err);
        errorToastMessage(err as Error);
      });
  };

export const GetOnePageLesson =
  (lessonId: any, pageId: any) => (dispatch: Dispatch) => {
    dispatch(setAppLoader(false));
    http
      .get(`/education_lessons/${lessonId}/pages/${pageId}`)
      .then((res) => {
        const result = educationResponse(res.data.data.sections);
        // console.log(result, "resultFromLesson")
        dispatch({
          type: types.SET_EDIT_PAGE_SECTIONS,
          payload: result,
          isPageEdited: false,
        });
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err);
      });
  };

export const activateDeactivateLesson =
  (lessonId: any, body: any): AppThunk =>
  (dispatch) => {
    dispatch(setAppLoader(true));
    http
      .put(`/education_lessons/${lessonId}`, body)
      .then((res) => {
        console.log(res.data);
        dispatch(toggleLessonLoader());
      })
      .catch((err) => {
        errorToastMessage(err as Error);
      });
  };

export const getRecommendedResources =
  (page: number, append: boolean, search?: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAppLoader(true));
      let url = `/info-card/readings?page=${page}&size=12`;
      if (search) {
        url += `&search=${search}`;
      }
      const res: AxiosResponse = await http.get(url);
      dispatch({
        type: types.SET_RECOMMENDED_DATA,
        payload: {
          data: res.data.data.dailyTips,
          count: res.data.data.count,
          append: append,
        },
      });
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const addRecommendedResources =
  (body: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setAppLoader(true));
      await http.post(`/info-card/readings`, body);
      toastMessage("success", "Recommended Resource created successfully");
      dispatch(hideModal());
      dispatch(toggleLessonLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const UpdateRecommendedResource =
  (body: any, id: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setAppLoader(true));
      await http.put(`/info-card/readings/${id}`, body);
      toastMessage("success", "Recommended Resource updated successfully");
      dispatch(hideModal());
      dispatch(toggleLessonLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const getTipsOfDay =
  (page: number, append: boolean, search?: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAppLoader(true));
      let url = `/info-card/daily-tips?page=${page}&size=12`;
      if (search) {
        url += `&search=${search}`;
      }
      const res: AxiosResponse = await http.get(url);
      dispatch({
        type: types.SET_DAILY_TIPS,
        payload: {
          data: res.data.data.dailyTips,
          count: res.data.data.count,
          append: append,
        },
      });
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const addTipsOfday = (body: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setAppLoader(true));
    await http.post(`/info-card/daily-tips`, body);
    toastMessage("success", "Tip of the day created successfully");
    dispatch(hideModal());
    dispatch(toggleLessonLoader());
  } catch (err) {
    dispatch(setAppLoader(false));
    errorToastMessage(err as Error);
  }
};

export const UpdateTipsOfDay =
  (body: any, id: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setAppLoader(true));
      await http.put(`/info-card/daily-tips/${id}`, body);
      toastMessage("success", "Tip of the day updated successfully");
      dispatch(hideModal());
      dispatch(toggleLessonLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const deleteRecommmendedResource =
  (id: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setAppLoader(true));
      await http.delete(`/info-card/readings/${id}`);
      toastMessage("success", "Recommended Resources deleted successfully");
      dispatch(toggleLessonLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const deleteDailyTips =
  (id: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setAppLoader(true));
      await http.delete(`/info-card/daily-tips/${id}`);
      toastMessage("success", "Daily tip deleted successfully");
      dispatch(toggleLessonLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const setContentSearchTerm =
  (contentSearch: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SET_CONTENT_SEARCH,
      payload: contentSearch,
    });
  };

export const resetContentSearch = () => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: types.SET_CONTENT_SEARCH,
    payload: "",
  });
};

export const toggleLessonLoader = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: types.SET_CONTENT_TOGGLE_LOADER,
  });
};
