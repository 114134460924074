import React from "react";

type Props = {
  headerText?: string;
  paragraphText?: string;
};

export const AuthLayout: React.FC<Props> = (props) => {
  return (
    <div className="position-relative h-100">
      <div className="left-top-image  d-none d-lg-block"></div>
      <div className="right-top-image d-none d-lg-block"></div>
      <div className="position-relative auth-text-holder justify-content-center">
        <div className="mw-400">
          <h1 className="text-white font-l fw-bold">{props.headerText}</h1>
          <p className="text-white">{props.paragraphText}</p>
        </div>
      </div>
      <div className="left-bottom-image  d-none d-lg-block"></div>
      <div className="right-bottom-image  d-none d-lg-block"></div>
    </div>
  );
};

export default AuthLayout;
