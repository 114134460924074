import { Table } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { useCallback, useEffect, useMemo, useState } from "react";
import { errorToastMessage } from "../../../helpers/toastMessage";
import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";

const DigitalDataOverview = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState<any | null>(null);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  const fetchDetails = useCallback(
    async (page: number, sort: any) => {
      try {
        dispatch(setAppLoader(true));
        let query = `?page=${page}&size=8`;
        // if (sort) {
        //   query += `&orderBy=${sort.orderBy}&sortBy=${sort.sortBy}`;
        // }
        const response = await http.get(`/actions${query}`);
        const res = response.data.data;
        setTotal(res.total);
        setData(res.actions);
        dispatch(setAppLoader(false));
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    },
    [dispatch, setTotal, setData]
  );

  useEffect(() => {
    fetchDetails(1, null);
  }, [fetchDetails]);

  const onTableChange = async (pagination: any, filters: any, sorters: any) => {
    let page = 1;
    let sort = null;
    if (sorters.order) {
      sort = {
        orderBy: sorters["order"] === "ascend" ? "asc" : "desc",
        sortBy: "name",
      };
      setSort(sort);
    } else {
      setSort(null);
    }
    if (pagination.current) {
      setCurrentPage(pagination.current);
      page = pagination.current;
    }
  };

  const columns = useMemo(() => {
    return [
      {
        title: "Module",
        dataIndex: "name",
        key: "name",
        sorter: true,
        sortOrder:
          sort?.sortBy === "name"
            ? sort.orderBy === "asc"
              ? ("ascend" as SortOrder)
              : ("descend" as SortOrder)
            : undefined,
      },
      {
        title: "Frequency(seconds)",
        dataIndex: "frequency",
        key: "frequency",
      },
    ];
  }, [sort]);

  return (
    <Table
      dataSource={data}
      columns={columns}
      showSorterTooltip={false}
      className="singer-custom-table"
      onChange={onTableChange}
      rowKey={(record) => record.id}
      pagination={{
        current: currentPage,
        total: total,
        showSizeChanger: false,
        pageSize: 8,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
        },
        position: ["bottomLeft"],
      }}
    />
  );
};

export default DigitalDataOverview;
