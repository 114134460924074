import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getScoresList,
  DeleteScore,
  searchScores,
} from "../../redux/actions/scoreAction";
import { ConfigProvider, Empty } from "antd";
import { setModalDetails } from "../../redux/reducers/modalSlice";

import { Table, Pagination, Menu, Dropdown } from "antd";
import Navigation from "../Navigation/Navigation";
import Button from "../Common/CustomButton";
import SearchInput from "../Common/SearchInput";
import AppLayout from "../AppLayout/AppLayout";
import { useHistory, useLocation } from "react-router-dom";
import history from "../../history";

type Props = {
  setModalDetails: Function;
  getScoresList: Function;
  DeleteScore: Function;
  searchScores: Function;
  scoresList: any[];
  count: number;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ScoreList = (props: Props) => {
  const query = useQuery();
  const qid: string = query.get("id") || "";
  const name = query.get("name") || "";
  const viewType = query.get("view");
  const history = useHistory();
  const [values, setValues] = useState({
    pageSize: 5,
    current: 1,
  });
  const { getScoresList } = props;

  useEffect(() => {
    getScoresList(values.current, qid);
  }, [qid, getScoresList]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  const handlePagination = (page: number) => {
    props.getScoresList(page, qid);
    setValues({
      ...values,
      current: page,
    });
  };

  const onChange = (value: any) => {
    if (value === "") {
      props.getScoresList(1, qid);
    } else {
      let resultValue = value;
      props.searchScores(resultValue, qid);
    }
  };

  const menu = (data: any) => (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => props.DeleteScore(data.id, qid, values.current)}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const actionIcon = (data: any) => {
    return (
      <span onClick={(e) => e.stopPropagation()}>
        <Dropdown
          overlay={menu(data)}
          placement="bottomRight"
          trigger={["click"]}
        >
          <i className="more-icon cp"></i>
        </Dropdown>
      </span>
    );
  };

  const dateFormat = (date: string) => {
    let desiredFormat = date.split("T");
    return desiredFormat[0].split("-").reverse().join("/");
  };

  let columns = [
    {
      title: "Score name",
      dataIndex: "name",
      width: "25%",
    },
    {
      title: "Date",
      dataIndex: "date",
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      render: (text: string) => (
        <span
          className={
            text === "active" ? "active-tag badge" : "inactive-tag badge"
          }
        >
          {text}
        </span>
      ),
    },
    {
      title: "",
      render: (_: any, data: any) => actionIcon(data),
    },
  ];

  let rows = props.scoresList.map((item: any, index) => {
    return item.hasOwnProperty("id")
      ? {
          key: index + 1,
          id: item!.id,
          name: item!.title,
          date: dateFormat(item!.createdAt),
          status: item!.status,
        }
      : {};
  });

  const onSelect = (record: any) => {
    console.log(record, "scores1111");
    if (viewType === "staticView" || record.status === "active") {
      props.setModalDetails({
        type: "SCORE_PREVIEW",
        modalProps: {
          show: true,
          title: "Score Preview",
          qid: qid,
          qname: name,
          scoreId: record.id,
        },
      });
    } else if (record.status === "draft") {
      history.push(
        `/question/score/create?id=${qid}&name=${name}&scoreId=${record.id}`
      );
    }
  };

  const scoreBackHandler = () => {
    if (viewType === "staticView") {
      history.push(`/question/preview?id=${qid}&name=${name}&view=staticView`);
    } else {
      history.push(`/question/builder?id=${qid}&name=${name}&view=canEdit`);
    }
  };

  return (
    <AppLayout themelight={true}>
      <div className="scores">
        <Navigation />
        {/* <Navigation
          title={name}
          addCrumb={true}
          scoreBack={true}
          scorePreview={viewType === "staticView" ? true : false}
          qid={qid}
          qname={name}
          subtitle="Score"
        /> */}

        <h1 className="font-ml mb-4">
          <span
            className="d-flex align-items-center"
            role="button"
            onKeyDown={() => console.log("back keyed down")}
            tabIndex={-1}
            onClick={scoreBackHandler}
          >
            <i className="arrow-left-icon me-3 cp"></i>
            {name}
          </span>
        </h1>
        <div className="score-actions mb-4">
          <div className="search searchInput-mini ">
            <SearchInput onChange={onChange} placeholder="Search..." />
          </div>
          <div>
            {viewType !== "staticView" ? (
              <Button
                primary
                buttonText="Create Score"
                className="btn-lg"
                addButton={true}
                clickHandler={() =>
                  history.push(`/question/score/create?id=${qid}&name=${name}`)
                }
              />
            ) : null}
          </div>
        </div>

        <div className="table-scores-container">
          <ConfigProvider
            renderEmpty={() => (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No response found"
              />
            )}
          >
            <Table
              columns={columns}
              dataSource={rows}
              rowClassName={(record) =>
                record.status === <span>Active</span> ? "active" : "inactive"
              }
              onRow={(record: any) => ({ onClick: () => onSelect(record) })}
              className="cp singer-custom-table"
              pagination={{
                current: values.current,
                total: props.count,
                showSizeChanger: false,
                pageSize: values.pageSize,
                hideOnSinglePage: true,
                showTotal: (total, range) => {
                  return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
                },
                position: ["bottomLeft"],
                onChange: handlePagination,
              }}
            />
          </ConfigProvider>
        </div>
      </div>
    </AppLayout>
  );
};

const mapStateToProps = (state: any) => ({
  scoresList: state.scores.scoresList,
  count: state.scores.count,
});

export default connect(mapStateToProps, {
  setModalDetails,
  getScoresList,
  DeleteScore,
  searchScores,
})(ScoreList);
