export const getImageInitials = (firstName: string, lastName: string) => {
  let initial = "";
  initial += firstName && firstName[0] ? firstName[0].toUpperCase() : "";
  initial += lastName && lastName[0] ? lastName[0].toUpperCase() : "";
  return initial;
};

export const getImageInitialsFullname = (name: string) => {
  if(name){
    const firstName = name.split(" ")[0];
    const lastName = name.split(" ")[1];
    return getImageInitials(firstName, lastName);
  }else{
    return ""
  }
};
