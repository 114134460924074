import React from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { hideModal } from "../../../redux/reducers/modalSlice";

const LessonCompletedModal = (props: any) => {
  return (
    <Modal
      title=""
      visible={props.show}
      onCancel={() => props.hideModal()}
      footer={null}
      width={"20rem"}
    >
      <div className="completion-lesson">
        <div className="thumbs-up"></div>
        <h4>Nice!</h4>
        <div className="completion-lesson-subtitle">{`You have completed this lesson`}</div>
      </div>
    </Modal>
  );
};

export default connect(null, { hideModal })(LessonCompletedModal);
