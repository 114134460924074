const MedicineNotTakenIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.3335 17.0002C3.3335 9.45631 9.45631 3.3335 17.0002 3.3335C24.544 3.3335 30.6668 9.45631 30.6668 17.0002C30.6668 24.544 24.544 30.6668 17.0002 30.6668C9.45631 30.6668 3.3335 24.544 3.3335 17.0002ZM5.16683 17.0002C5.16683 23.5238 10.4765 28.8335 17.0002 28.8335C23.5238 28.8335 28.8335 23.5238 28.8335 17.0002C28.8335 10.4765 23.5238 5.16683 17.0002 5.16683C10.4765 5.16683 5.16683 10.4765 5.16683 17.0002Z"
      fill="#BDBDBD"
      stroke="#BDBDBD"
    />
    <path
      d="M23.3002 10.7102C22.9102 10.3202 22.2802 10.3202 21.8902 10.7102L17.0002 15.5902L12.1102 10.7002C11.7202 10.3102 11.0902 10.3102 10.7002 10.7002C10.3102 11.0902 10.3102 11.7202 10.7002 12.1102L15.5902 17.0002L10.7002 21.8902C10.3102 22.2802 10.3102 22.9102 10.7002 23.3002C11.0902 23.6902 11.7202 23.6902 12.1102 23.3002L17.0002 18.4102L21.8902 23.3002C22.2802 23.6902 22.9102 23.6902 23.3002 23.3002C23.6902 22.9102 23.6902 22.2802 23.3002 21.8902L18.4102 17.0002L23.3002 12.1102C23.6802 11.7302 23.6802 11.0902 23.3002 10.7102Z"
      fill="#BDBDBD"
    />
  </svg>
);
export default MedicineNotTakenIcon;
