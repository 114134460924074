import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Modal, Input, Select } from "antd";
import { setModalDetails } from "../../../redux/reducers/modalSlice";
import CustomButton from "../../Common/CustomButton";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { setTherapistAvailablity } from "../../../redux/actions/appointmentAction";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";

const CalendarAvailability: React.FC = () => {
  const { modalProps } = useAppSelector((state) => state.modal);
  const [status, setStatus] = useState<string>(modalProps.status);
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(setModalDetails({ type: "", modalProps: null }));
  };

  const changeSelectHandler = (val: string) => {
    setStatus(val);
  };

  const submitHandler = async () => {
    try {
      dispatch(setAppLoader(true));
      await dispatch(
        setTherapistAvailablity(
          status,
          modalProps.date,
          modalProps.time,
          modalProps.appointmentId
        )
      );
      modalProps.dataChanged(true);
      closeHandler();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal
      visible={modalProps.show}
      title="Edit Availability"
      closable={true}
      footer={false}
      width={563}
      onCancel={closeHandler}
      className="calendar-availability-modal"
    >
      <div className="available-fields-wrapper">
        <div className="available-field">
          <span className="label">Date</span>
          <Input className="input-field" value={modalProps.date} />
        </div>
        <div className="available-field">
          <span className="label">Time</span>
          <Input className="input-field" value={modalProps.time} />
        </div>
        <div className="available-field">
          <div className="label">Availability</div>
          <Select
            onChange={changeSelectHandler}
            value={status}
            className="input-field"
          >
            <Select.Option value={status}>{status}</Select.Option>
          </Select>
        </div>
      </div>
      <div className="modal-action-container">
        <CustomButton
          buttonText="Save"
          buttonType="button"
          primary={true}
          clickHandler={submitHandler}
        />
      </div>
    </Modal>
  );
};

export default CalendarAvailability;
