import React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import PasswordInput from "../Common/PasswordInput";
import CustomButton from "../Common/CustomButton";
import AuthLayout from "../AuthLayout/AuthLayout";
import { Row, Col } from "antd";
import logo from "../../assets/img/logo-singer.svg";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { errorToastMessage } from "../../helpers/toastMessage";
import { setYourPassword } from "../../redux/actions/userAction";
let schema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("New Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const SetPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const { token } = useParams<{ token: string }>();

  const submitHandler = async (values: any, setSubmitting: Function) => {
    try {
      setSubmitting(true);
      let resultObj = {
        password: values.password,
        token: token,
      };
      await dispatch(setYourPassword(resultObj));
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitting(false);
    }
  };

  return (
    <div className="d-block auth-layout">
      <Row className="h-100">
        <Col sm={24} md={12}>
          <div className="auth-left-container d-flex align-items-center justify-content-center h-100">
            <div className="flex-grow-1">
              <img
                src={logo}
                alt="SINGER-logo"
                width="200px"
                className="mb-5"
              />
              <div className="px-md-4">
                <div className="font-l fw-bold mb-5">Set your own password</div>
                <Formik
                  initialValues={{ password: "", confirmPassword: "" }}
                  validationSchema={schema}
                  onSubmit={(values, { setSubmitting }: any) => {
                    submitHandler(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    errors,
                    touched,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {/* input-container */}
                      <div className="input-container mb-4">
                        <div className="floating-label input-password">
                          <PasswordInput
                            name="password"
                            placeHolder="New Password"
                            value={values.password}
                            changeEvent={handleChange}
                            blurEvent={handleBlur}
                          />

                          <div className="input-error">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </div>
                        </div>
                      </div>
                      <div className="input-container">
                        <div className="floating-label input-password">
                          <PasswordInput
                            name="confirmPassword"
                            placeHolder="Confirm Password"
                            value={values.confirmPassword}
                            changeEvent={handleChange}
                            blurEvent={handleBlur}
                          />

                          <div className="input-error">
                            {errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="bottom-pos-btn-container mt-2">
                        <CustomButton
                          buttonType="submit"
                          buttonText="Set Password"
                          className="login-button btn-block"
                          loading={isSubmitting}
                          primary={true}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={12} className="bg-primary-blue p-3 p-lg-4">
          <AuthLayout
            headerText="Know your health better!"
            paragraphText="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
          />
        </Col>
      </Row>
    </div>
  );
};

export default SetPassword;
