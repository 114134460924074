import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import modalReducer from "./reducers/modalSlice";
import userReducer from "./reducers/userSlice";
import appointmentReducer from "./reducers/appointmentSlice";
import administratorSlice from "./reducers/administratorSlice";
import participantSlice from "./reducers/participantSlice";
import loaderSlice from "./reducers/loaderSlice";
import educationReducer from "./reducers/educationReducer";
import questionReducer from "./reducers/questionsReducer";
import documentsSlice from "./reducers/documentsSlice";
import scoreReducer from "./reducers/scoreReducer";
import diarySlice from "./reducers/diarySlice";
import programSlice from "./reducers/programSlice";
import consoleSlice from "./reducers/consoleSlice";
import dashboardSlice from "./reducers/dashboardSlice";
import forumSlice from "./reducers/forumSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    modal: modalReducer,
    appointment: appointmentReducer,
    administrator: administratorSlice,
    participant: participantSlice,
    loader: loaderSlice,
    documents: documentsSlice,
    lessons: educationReducer,
    question: questionReducer,
    scores: scoreReducer,
    diary: diarySlice,
    program: programSlice,
    console: consoleSlice,
    dashboard: dashboardSlice,
    forum: forumSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
