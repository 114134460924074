import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Button from "../../Common/CustomButton";
import { connect } from "react-redux";
import { useDrop } from "react-dnd";
import Navigation from "../../Navigation/Navigation";
import AppLayout from "../../AppLayout/AppLayout";
import { toastMessage } from "../../../helpers/toastMessage";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  setLessonsAction,
  setEditViewAction,
  createLessonPages,
  getLessonDetails,
  GetOnePageLesson,
  updateOnePageLesson,
  setEditPageSections,
} from "../../../redux/actions/educationAction";
import CardEducation from "../cardEducation";
import EditSection from "../editSection";
import { cards } from "../educationDataList";
import SectionItem from "../sectionItem";
import { educationRequest, educationResponse } from "../../../helpers/question";
// import "../../../pages/css/education/newSection.scss";
import { Modal } from "antd";
const confirm = Modal.confirm;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type Props = {
  setLessonsAction: Function;
  sectionChange: boolean;
  setEditViewAction: Function;
  createLessonPages: Function;
  GetOnePageLesson: Function;
  updateOnePageLesson: Function;
  setEditPageSections: Function;
  sections: any;
  lessonDetails: any;
  getLessonDetails: any;
  editPageSections: any;
  isPageEdited: any;
};

const NewSection = (props: Props) => {
  const history = useHistory();
  const queryParams = useQuery();

  const lastSegment = useParams<{ assetId: string }>().assetId;
  const pageUrlId = queryParams.get("pageId");

  const [sections, setSections] = useState<any>([]);
  const [selectedCard, setSelectedCard] = useState("");
  const { getLessonDetails, GetOnePageLesson } = props;
  useEffect(() => {
    if (pageUrlId && props.sections.length === 0) {
      GetOnePageLesson(lastSegment, pageUrlId);
    } else if (props.sections.length > 0) {
      const result = educationResponse(props.sections[0].sections);
      setSections(result);
    }
    getLessonDetails(lastSegment);
  }, [
    lastSegment,
    pageUrlId,
    props.sections,
    setSections,
    getLessonDetails,
    GetOnePageLesson,
  ]);

  useEffect(() => {
    if (props.editPageSections.length > 0) {
      setSections(props.editPageSections);
    }
  }, [props.editPageSections]);

  const handleMultiData = () => {
    const sectionResult = educationRequest(sections);
    var count = 0;

    sectionResult.forEach((item) => {
      if (item.value !== "") {
        count = count + 1;
      }
    });

    if (count !== sectionResult.length) {
      toastMessage("warning", "Please fill in the empty fields");
    } else {
      let obj = {
        title:
          sectionResult.find((section) => section.type === "title") !==
          undefined
            ? sectionResult.find((section) => section.type === "title").value
            : "NEW PAGE",
        imageUrl:
          sectionResult.find((section) => section.type === "picture") !==
          undefined
            ? sectionResult.find((section) => section.type === "picture").value
            : "https://picsum.photos/200/300",
        sections: sectionResult,
        position: props.lessonDetails.pages.length + 1,
      };

      props.createLessonPages(obj, lastSegment);
    }
  };

  const handleUpdatePageData = () => {
    // lastSegment = lastSegment.substring(0, lastSegment.indexOf("?"));
    const sectionResult = educationRequest(sections);

    let obj = {
      title:
        sectionResult.find((section) => section.type === "title") !== undefined
          ? sectionResult.find((section) => section.type === "title").value
          : "NEW PAGE",
      imageUrl:
        sectionResult.find((section) => section.type === "picture") !==
        undefined
          ? sectionResult.find((section) => section.type === "picture").value
          : "https://picsum.photos/200/300",
      sections: sectionResult,
      position: props.lessonDetails.pages.find(
        (page: any) => page.id === pageUrlId
      ).position,
    };

    props.updateOnePageLesson(lastSegment, pageUrlId, obj);
  };

  const handleSection = (newSection: any, index: number) => {
    const New = sections.map((item: any, i: number) => {
      return index === i ? newSection : item;
    });

    if (props.editPageSections.length > 0) {
      props.setEditPageSections(New);
    } else {
      setSections(New);
    }
  };

  const handleDrop = (
    item: { type: string; origin: number },
    index?: number | boolean
  ) => {
    if (index !== false) {
      const selectedItem = { ...sections[item.origin] };

      const changedSections = sections.filter(
        (_: any, i: number) => i !== item.origin
      );

      const prevItems = changedSections.slice(0, index);
      const nextItems = changedSections.slice(index, changedSections.length);

      const modifiedArr = [...prevItems, selectedItem, ...nextItems].map(
        (item: any, index: number) => {
          return { ...item, key: index };
        }
      );

      setSections(modifiedArr);

      if (props.editPageSections.length > 0) {
        props.setEditPageSections(modifiedArr);
      }
      // setPrevIndex(index);
    } else if (index === false) {
      const newSection = {
        ...cards.find((card: any) => card.name === item.type),
        key: sections.length,
      };

      setSections([...sections, newSection]);

      if (props.editPageSections.length > 0) {
        props.setEditPageSections([...sections, newSection]);
      }
    }
  };

  const filterSections = (obj: any) => {
    let result;
    let key = sections.find((item: any) => item.key === obj.key).key;
    let intermediateResult = sections.filter(
      (item: any) => item.key !== obj.key
    );

    if (intermediateResult.length === 1) {
      intermediateResult[0].key = key;
      result = intermediateResult;
    } else {
      result = intermediateResult.map((item: any, index: number) => {
        return index >= key ? { ...item, key: key++ } : item;
      });
    }

    setSections(result);
  };

  const [, drop] = useDrop({
    accept: cards.map((card: any) => card.name),
    drop: (dropItem: any) => {
      if (!dropItem.sort) {
        handleDrop(dropItem, false);
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  const handleEditSections = () => {
    if (sections.length > 0) {
      props.setEditViewAction();
    } else {
      toastMessage(
        "warning",
        "Please drag and drop sections to the section list"
      );
    }
  };

  const newSections = (
    <>
      <div className="col-xl-7 col-12 p-0 pe-xl-5 main-body-column1">
        <div className="header-choose ps-3">
          Choose a block to add to your page:
        </div>
        <div className="card-section row mx-md-n3">
          {cards.map((card: any) => {
            return (
              <div className="col-xl-4 col-md-6 p-3">
                <CardEducation
                  key={card.key}
                  card={card}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-xl-5 col-sm-6 col-12 main-body-column2">
        <h3>Page Layout</h3>

        <div className="secction-list-container" ref={drop}>
          <div className="section-list-header">
            Drop and arrange Blocks below
          </div>
          <div className="section-list-item">
            {sections.length > 0 &&
              sections
                .sort(function (a: any, b: any) {
                  return Number(a.key) - Number(b.key);
                })
                .map((section: any, index: number) => {
                  return (
                    <SectionItem
                      key={section.key}
                      index={index}
                      section={section}
                      handleDrop={handleDrop}
                      handleFilter={filterSections}
                    />
                  );
                })}
          </div>
          <div className="p-4 d-flex justify-content-end">
            <Button
              buttonText="Clear Sections"
              className="cancel-button float-end btn-lg me-3"
              clickHandler={() => setSections([])}
            />
            <Button
              primary={true}
              buttonType="submit"
              // disabled={sections.length > 0 ? false : true}
              buttonText="Edit Sections"
              className="primary-background float-end btn-lg"
              clickHandler={() => handleEditSections()}
            />
          </div>
        </div>
      </div>
    </>
  );

  const handlePreview = () => {
    const sectionResult = educationRequest(sections);
    let obj = {
      title:
        sectionResult.find((section) => section.type === "title") !== undefined
          ? sectionResult.find((section) => section.type === "title").value
          : "NEW PAGE",
      imageUrl:
        sectionResult.find((section) => section.type === "picture") !==
        undefined
          ? sectionResult.find((section) => section.type === "picture").value
          : "data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%20282.69%20228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20fill%3D%22%23A5A5A5%22%20cx%3D%22115.3%22%20cy%3D%2235.75%22%20r%3D%2235.75%22%2F%3E%3Cpath%20fill%3D%22%23A5A5A5%22%20d%3D%22M188.7%2C228h-81.34c-10.27%2C0-16.24-11.86-10.28-20.41l38.69-55.48l42.65-61.2%20c5.03-7.22%2C15.53-7.22%2C20.56%2C0l42.64%2C61.17l38.7%2C55.51c5.96%2C8.55-0.02%2C20.4-10.28%2C20.4H188.7z%22%2F%3E%3Cpath%20fill%3D%22%23A5A5A5%22%20d%3D%22M2.48%2C206.79l55.44-78.81c4.27-6.07%2C12.64-7.54%2C18.72-3.29l112.83%2C78.81%20c10.8%2C7.54%2C5.46%2C24.51-7.71%2C24.51l-168.27%2C0C2.58%2C228-3.8%2C215.71%2C2.48%2C206.79z%22%2F%3E%3C%2Fsvg%3E",
      sections: sectionResult,
      position: props.lessonDetails.pages.length + 1,
    };

    props.setLessonsAction([obj], false);
    if (props.editPageSections.length > 0) {
      history.push(`/education/preview?pageId=${pageUrlId}`);
    } else {
      history.push(`/education/preview?pageId=12345`);
    }
  };

  const editSections = (
    <>
      {sections
        .sort(function (a: any, b: any) {
          return Number(a.key) - Number(b.key);
        })
        .map((section: any, index: number) => {
          return (
            <>
              <EditSection
                item={section}
                handleSection={handleSection}
                index={index}
                editSections={props.editPageSections}
              />
            </>
          );
        })}
    </>
  );

  const backButtonHandler = () => {
    if (sectionChange) {
      history.push(`/education/pages/${lastSegment}`);
    } else {
      confirm({
        title: "Education",
        content:
          "Please save/update the changes done. If you leave before saving, your changes will be lost.",
        onOk() {},
        onCancel() {
          history.push(`/education/pages/${lastSegment}`);
        },
        okText: "Ok",
        cancelText: "Go anyway",
      });
    }
  };
  const { sectionChange } = props;

  return (
    <div className="new-section-wrapper">
      <Navigation />
      {/* {!sectionChange ? (
        <Navigation
          title="Education"
          prev={`/education/pages/${lastSegment}`}
          confirmMessage={true}
          warningMessage="Please save/update the changes done. If you leave before saving, your changes will be lost."
          textSave="Ok"
        />
      ) : (
        <Navigation prev={`/education/pages/${lastSegment}`} />
      )} */}

      <div className="content-height ">
        <div className="new-section-header mb-5 d-block d-lg-flex ">
          <span
            className="arrow-left-icon me-2 cp mt-2"
            onClick={backButtonHandler}
          ></span>
          {props.editPageSections.length > 0 ? (
            <h1 className="font-l fw-bold">Edit Page</h1>
          ) : (
            <h1 className="font-l fw-bold pb-4">Create New Page</h1>
          )}
          <h6 className="ms-lg-5">
            Use Blocks to create your pages. Drag, drop and rearrange your
            <br />
            Blocks on the right, before clicking ‘Edit Sections’ to add content
            <br />
            to your Sections.
          </h6>
        </div>
        {/* main body */}
        <div className="row main-body pt-5">
          {sectionChange ? newSections : editSections}
        </div>

        {!sectionChange && (
          <>
            <div className="Buttons-lessons text-end me-3">
              <span
                className="cancel-button me-3 cp"
                onClick={() => props.setEditViewAction()}
              >
                Cancel
              </span>
              <Button
                primary={false}
                buttonText="Preview"
                className="button-save btn-lg me-3"
                clickHandler={handlePreview}
              />
              {props.editPageSections.length > 0 ? (
                <Button
                  primary={true}
                  buttonType="submit"
                  buttonText="Update"
                  className="button-save btn-lg"
                  clickHandler={handleUpdatePageData}
                />
              ) : (
                <Button
                  primary={true}
                  buttonType="submit"
                  buttonText="Save"
                  className="button-save btn-lg"
                  clickHandler={handleMultiData}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sectionChange: state.lessons.is_edit_view,
  sections: state.lessons.lessons,
  lessonDetails: state.lessons.lessonDetails,
  editPageSections: state.lessons.editPageSections,
  isPageEdited: state.lessons.isPageEdited,
});

const EducationBuilderContext: React.FC<Props> = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <AppLayout themelight={true}>
        <NewSection {...props} />
      </AppLayout>
    </DndProvider>
  );
};

export default connect(mapStateToProps, {
  setLessonsAction,
  setEditViewAction,
  createLessonPages,
  getLessonDetails,
  GetOnePageLesson,
  updateOnePageLesson,
  setEditPageSections,
})(EducationBuilderContext);
