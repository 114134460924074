import React, { useEffect } from "react";
// import "../../../pages/css/questions/questionPreview.scss";
import {
  InputType,
  BooleanType,
  DateType,
  OpinionType,
  LikertType,
} from "./preview/SingleType";
import { setModalDetails } from "../../../redux/reducers/modalSlice";
import { useLocation } from "react-router-dom";
import {
  ChoiceType,
  DropdownType,
  PictureType,
  GroupType,
  CheckBoxType,
} from "./preview/ChoiceType";
import { connect } from "react-redux";
import * as questionActions from "../../../redux/actions/questionAction";
import { useHistory } from "react-router-dom";
import NtuButton from "../../Common/CustomButton";
import Navigation from "../../Navigation/Navigation";
import AppLayout from "../../AppLayout/AppLayout";

const questionTypes: any = {
  short_text: InputType,
  long_text: InputType,
  number: InputType,
  multiple_choice: ChoiceType,
  likert_scale: LikertType,
  checkbox: CheckBoxType,
  picture_choice: PictureType,
  yes_no: BooleanType,
  opinion_scale: OpinionType,
  date: DateType,
  dropdown: DropdownType,
  group: GroupType,
};

type Props = {
  qInfo: any;
  questions: Array<any>;
  logicJumps: Array<any>;
  getPreviewQuestions: Function;
  setPreviewQuestions: Function;
  getQuestionnaireInfo: Function;
  setModalDetails: Function;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const QuestionPreview = (props: Props) => {
  const query = useQuery();
  const qid = query.get("id");
  const name = query.get("name");
  const viewType = query.get("view") || "staticView";
  console.log(qid, name, viewType);

  const history = useHistory();

  const { getPreviewQuestions, setPreviewQuestions, getQuestionnaireInfo } =
    props;

  useEffect(() => {
    getPreviewQuestions(qid);
    return () => {
      setPreviewQuestions();
    };
  }, [getPreviewQuestions, setPreviewQuestions, qid]);

  useEffect(() => {
    getQuestionnaireInfo(qid);
  }, [getQuestionnaireInfo, qid]);

  return (
    <AppLayout themelight={true}>
      <div className="question-preview">
        <Navigation />
        <div className="content-height ">
          <div className="d-md-flex align-items-center justify-content-between mb-5">
            <div className="">
              <h3 className="font-l d-flex align-items-center fw-bold">
                <span
                  className="arrow-left-icon me-2 cp"
                  onClick={() => history.push(`/question`)}
                ></span>
                {viewType === "staticView" ? name : "Questionnaire Preview"}
              </h3>
              <h6 className="ms-2 p-0 text-muted">
                A list of questionnaires
                {viewType === "staticView" ? "" : " you have created so far"}.
              </h6>
            </div>

            <div className="preview-button">
              <NtuButton
                buttonText="Preview Score"
                primary
                clickHandler={() =>
                  history.push(
                    `/question/score?id=${qid}&name=${name}&view=staticView`
                  )
                }
                className="preview-screen btn-lg"
              />
            </div>
          </div>
          {props.qInfo.hasOwnProperty("description") && (
            <div className="sub-header">
              <div className=" font-ml">{name}</div>
              <p className="mt-2">{props.qInfo.description}</p>
              <button
                type="button"
                className="upload-button btn-lg"
                onClick={() => {
                  props.setModalDetails({
                    type: "VIEW_QUESTIONNAIRE_INFO",
                    modalProps: {
                      show: true,
                      questionData: props.qInfo,
                      readType: true,
                    },
                  });
                }}
              >
                Show more settings
              </button>
            </div>
          )}

          <div className="upform mt-5">
            <form>
              <div className="upform-main">
                {Array.isArray(props.questions) &&
                  props.questions.length > 0 &&
                  props.questions.map((q) => {
                    if (questionTypes[q.type] === undefined) return;
                    const TypeComponent = questionTypes[q.type];
                    return (
                      <div className="input-block" key={q.id}>
                        <div
                          className={
                            q.type === "group" ? "label-group" : "label"
                          }
                        >
                          <span>
                            Q{q.position}. {q.title}
                          </span>
                          {q.required && (
                            <span className="input-block-required">*</span>
                          )}
                        </div>
                        <TypeComponent
                          question={q}
                          allQuestions={props.questions}
                          logicJumps={props.logicJumps}
                        />
                      </div>
                    );
                  })}
              </div>
            </form>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

const mapStateToProps = (state: any) => {
  return {
    questions: state.question.previewQuestions,
    logicJumps: state.question.previewLogicJumps,
    qInfo: state.question.qInfo,
  };
};

const mapDispatchToProps = {
  ...questionActions,
  setModalDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPreview);
