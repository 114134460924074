import { Modal, Radio, Table, Tabs } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import moment from "moment";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import CustomButton from "../../Common/CustomButton";

const { TabPane } = Tabs;

const data = [
  { institute: "All Institutes" },
  { institute: "Main Institutes" },
  { institute: "Test Institutes" },
];
const RightsModal = () => {
  const dispatch = useAppDispatch();
  const { show } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const columns = useMemo(() => {
    return [
      {
        title: "Institute",
        dataIndex: "institute",
        key: "institute",
      },
      {
        title: "User role",
        dataIndex: "userRole",
        key: "userRole",
        render: (date: any) => {
          return <span>None</span>;
        },
      },
      {
        title: "Add",
        key: "add",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "View",
        key: "view",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "Edit",
        key: "edit",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "Email",
        key: "email",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "Randomize records",
        key: "rand",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "View randomization records",
        key: "View randomization records",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "Sign",
        key: "sign",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "Lock",
        key: "lock",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "Verify",
        key: "verify",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "Query",
        key: "query",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "Archive",
        key: "archive",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "Export",
        key: "export",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "Send surveys",
        key: "send surveys",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
      {
        title: "View surveys",
        key: "view surveys",
        render: (_1: any, record: any) => {
          return <Checkbox />;
        },
      },
    ];
  }, []);

  return (
    <Modal
      title={"User Rights"}
      visible={show}
      onCancel={closeHandler}
      width={"100rem"}
      footer={null}
    >
      <div>
        <Tabs className="therapist-slot-tab singer-tabs-color">
          <TabPane tab="Institute Rights" key="Institute Rights">
            <Table columns={columns} dataSource={data} pagination={false} />
          </TabPane>
          <TabPane tab="Management Rights" key="Management Rights">
            <div className="d-flex">
              <span className="me-5 fw-semibold" style={{ width: "150px" }}>
                Manage Records
              </span>
              <Radio.Group
                defaultValue="no"
                className="d-flex  mb-4 justify-content-center"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
            <div className="d-flex">
              <span className="me-5 fw-semibold" style={{ width: "150px" }}>
                Manage Forms
              </span>
              <Radio.Group
                defaultValue="no"
                className="d-flex  mb-4 justify-content-center"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
            <div className="d-flex">
              <span className="me-5 fw-semibold" style={{ width: "150px" }}>
                Manage Users
              </span>
              <Radio.Group
                defaultValue="no"
                className="d-flex  mb-4 justify-content-center"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
            <div className="d-flex">
              <span className="me-5 fw-semibold" style={{ width: "150px" }}>
                Manage Settings
              </span>
              <Radio.Group
                defaultValue="no"
                className="d-flex  mb-4 justify-content-center"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
          </TabPane>
        </Tabs>
        <div className="text-end mt-3">
          <span
            role="button"
            className="button-cancel"
            onClick={closeHandler}
            tabIndex={0}
          >
            Cancel
          </span>
          <CustomButton
            clickHandler={closeHandler}
            buttonText="Save"
            primary={true}
            className="btn-success"
          />
        </div>
      </div>
    </Modal>
  );
};

export default RightsModal;
