import { useLocation } from "react-router-dom";
import AppLayout from "../AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import { Collapse } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import {
  ChoiceType,
  DropdownType,
  PictureType,
  GroupType,
  CheckBoxType,
} from "../Questions/QuestionPreview/preview/ChoiceType";
import {
  InputType,
  BooleanType,
  DateType,
  OpinionType,
  LikertType,
} from "../Questions/QuestionPreview/preview/SingleType";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { questionPreviewFormat } from "../../helpers/question";
import http from "../../http";
import CustomButton from "../Common/CustomButton";
const { Panel } = Collapse;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const visit0 = [
  {
    name: "Screening Visit",
    id: "d6c423d4-cd6a-4dff-835a-e79020be0163",
    visit: "visit 0",
  },
  {
    name: "Basic Details",
    id: "af7cb7de-038b-4a88-a3fa-68f93dd508c5",
    visit: "visit 0",
  },
  {
    name: "Electrocardiogram",
    id: "98b0c87f-3983-4ec2-8141-ec0cb43854ba",
    visit: "visit 0",
  },
  {
    name: "CAIDE",
    id: "0120be02-6597-41ea-87eb-bc58af766252",
    visit: "visit 0",
  },
  {
    name: "Libra Score",
    id: "f560e8a6-3b9a-4d5d-a19c-daa73b4b9122",
    visit: "visit 0",
  },
  {
    name: "Geriatic Depression Scale: Short Form",
    id: "d1019635-dd45-471f-9f86-236ed0f04b96",
    visit: "visit 0",
  },
  {
    name: "Eligibility Criteria",
    id: "55a9539b-621e-4e1c-a891-9cb76be54956",
    visit: "visit 0",
  },
];

const visit1 = [
  {
    name: "Baseline Visit",
    id: "b6b615a8-22eb-42ae-9620-bb131c213d3b",
    visit: "visit 1",
  },
  {
    name: "Demographics",
    id: "ed194a58-faca-48bb-919d-e6a71c0ad06c",
    visit: "visit 1",
  },
  {
    name: "Medical/Social/Psychological/Cognitive history",
    id: "e556f510-df08-46c9-b7c7-13c461f7404f",
    visit: "visit 1",
  },
  {
    name: "Physical Evaluation",
    id: "27da7f31-f85a-4b1d-8240-9977e8be75d0",
    visit: "visit 1",
  },
];

const visit2 = [
  {
    name: "Physical Evaluation",
    id: "801e0a0b-bf97-4c24-8f6f-29db1d1a4d5f",
    visit: "visit 2",
  },
  {
    name: "Medical Status Update",
    id: "049d0b4b-3278-4eb1-854d-38b5724ae478",
    visit: "visit 2",
  },
];

const visit3 = [
  {
    name: "Physical Evaluation",
    id: "a5f767dc-b2e5-49e7-b92b-9c166767efa3",
    visit: "visit 3",
  },
  {
    name: "Medical Status Update",
    id: "38d0e481-830e-47c4-8baf-222c8b549ddf",
    visit: "visit 3",
  },
];

const questionTypes: any = {
  short_text: InputType,
  long_text: InputType,
  number: InputType,
  multiple_choice: ChoiceType,
  likert_scale: LikertType,
  checkbox: CheckBoxType,
  picture_choice: PictureType,
  yes_no: BooleanType,
  opinion_scale: OpinionType,
  date: DateType,
  dropdown: DropdownType,
  group: GroupType,
};

const CRF = () => {
  const dispatch = useAppDispatch();
  const name = useQuery().get("name");
  const [selected, setSelected] = useState(visit0[0]);
  const [questions, setQuestions] = useState<any[]>([]);
  const [logicJumps, setLogicJumps] = useState<any[]>([]);

  const { id } = selected;
  useEffect(() => {
    const fetchQues = async () => {
      try {
        setQuestions([]);
        setLogicJumps([]);
        dispatch(setAppLoader(true));
        let { data } = await http.get(`/questionnaires/${id}/questions`);
        let questions = await questionPreviewFormat(data.data.questions);
        setQuestions(questions);
        setLogicJumps(data.data.logicJumps);
        dispatch(setAppLoader(false));
      } catch (err) {
        console.log(err);
        setQuestions([]);
        setLogicJumps([]);
        dispatch(setAppLoader(false));
      }
    };
    if (id) {
      fetchQues();
    }
  }, [id]);

  return (
    <AppLayout>
      <Navigation />
      <h2 className="font-l mb-0 fw-bold me-3">
        <span className="primary-color">CRF</span> &gt; {name}
      </h2>
      <div className="crf-container">
        <div className="crf-sider styled-scrollbar">
          <Collapse accordion expandIconPosition="right">
            <Panel header="Visit 0" key="1">
              {visit0.map((visit, index) => {
                const isSelected = visit.id === selected.id;
                return (
                  <div
                    key={visit.id}
                    onClick={() => setSelected(visit)}
                    className={isSelected ? "selected-block block" : "block"}
                  >
                    <div
                      className={isSelected ? "selected-visit visit" : "visit"}
                    >
                      {visit.name}
                    </div>
                    <div
                      className={
                        isSelected ? "selected-visit-start start" : "start"
                      }
                    >
                      {index === 0 ? "Started" : "Not Started"}
                    </div>
                  </div>
                );
              })}
            </Panel>
            <Panel header="Visit 1" key="2">
              {visit1.map((visit, index) => {
                const isSelected = visit.id === selected.id;
                return (
                  <div
                    key={visit.id}
                    onClick={() => setSelected(visit)}
                    className={isSelected ? "selected-block block" : "block"}
                  >
                    <div
                      className={isSelected ? "selected-visit visit" : "visit"}
                    >
                      {visit.name}
                    </div>
                    <div
                      className={
                        isSelected ? "selected-visit-start start" : "start"
                      }
                    >
                      {"Not Started"}
                    </div>
                  </div>
                );
              })}
            </Panel>
            <Panel header="Visit 2" key="3">
              {visit2.map((visit, index) => {
                const isSelected = visit.id === selected.id;
                return (
                  <div
                    key={visit.id}
                    onClick={() => setSelected(visit)}
                    className={isSelected ? "selected-block block" : "block"}
                  >
                    <div
                      className={isSelected ? "selected-visit visit" : "visit"}
                    >
                      {visit.name}
                    </div>
                    <div
                      className={
                        isSelected ? "selected-visit-start start" : "start"
                      }
                    >
                      {"Not Started"}
                    </div>
                  </div>
                );
              })}
            </Panel>
            <Panel header="Visit 3" key="4">
              {visit3.map((visit, index) => {
                const isSelected = visit.id === selected.id;
                return (
                  <div
                    key={visit.id}
                    onClick={() => setSelected(visit)}
                    className={isSelected ? "selected-block block" : "block"}
                  >
                    <div
                      className={isSelected ? "selected-visit visit" : "visit"}
                    >
                      {visit.name}
                    </div>
                    <div
                      className={
                        isSelected ? "selected-visit-start start" : "start"
                      }
                    >
                      {"Not Started"}
                    </div>
                  </div>
                );
              })}
            </Panel>
          </Collapse>
        </div>
        <div className="crf-preview-container styled-scrollbar">
          <div className="upform">
            <form>
              <div className="upform-main">
                {questions.map((q) => {
                  if (questionTypes[q.type] === undefined) {
                    return <></>;
                  }
                  const TypeComponent = questionTypes[q.type];
                  return (
                    <div className="input-block" key={q.id}>
                      <div
                        className={q.type === "group" ? "label-group" : "label"}
                      >
                        <span>
                          Q{q.position}. {q.title}
                        </span>
                        {q.required && (
                          <span className="input-block-required">*</span>
                        )}
                      </div>
                      <TypeComponent
                        question={q}
                        allQuestions={questions}
                        logicJumps={logicJumps}
                      />
                    </div>
                  );
                })}
              </div>
            </form>
          </div>
          <div className="justify-content-end d-flex crf-button-container">
            <CustomButton
              buttonText="Cancel"
              primary={false}
              className="cancel-button btn-lg"
            />
            <CustomButton
              primary={true}
              buttonType="submit"
              buttonText="Save"
              className="ms-3 btn-lg btn-success"
            />
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default CRF;
