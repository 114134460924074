import React from "react";
import Routes from "./routes";
import RootModal from "./components/Modals/RootModal";
import Loader from "./components/Common/Loader";

const App = () => {
  return (
    <main>
      <Routes />
      <RootModal />
      <Loader />
    </main>
  );
};

export default App;
