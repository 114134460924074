import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getArchivedDocuments } from "../../redux/actions/documentAction";
import DocumentTable from "./DocumentTable";

const ArchivedDocumnet: React.FC = () => {
  const dispatch = useAppDispatch();
  const { archiveLists, searchTerm, sortOrder } = useAppSelector(
    (state) => state.documents
  );

  useEffect(() => {
    dispatch(getArchivedDocuments());
  }, [dispatch, searchTerm, sortOrder]);

  return (
    <>
      <DocumentTable documents={archiveLists} type="archive" />
    </>
  );
};

export default ArchivedDocumnet;
