import React, { useEffect, useRef, useState } from "react";
import { Menu, Dropdown } from "antd";
import { setModalDetails } from "../../../redux/reducers/modalSlice";
import {
  getRecommendedResources,
  deleteRecommmendedResource,
  resetContentSearch,
} from "../../../redux/actions/educationAction";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Button from "../../Common/CustomButton";

const RecommendedResource = (props: any) => {
  const [current, setCurrent] = useState(1);

  const dispatch = useAppDispatch();
  const { recommendedContent, recommendedCount, contentSearch, toggleLoader } =
    useAppSelector((state) => state.lessons);
  const toggleRef = useRef<boolean>(toggleLoader);

  useEffect(() => {
    dispatch(getRecommendedResources(1, false, contentSearch));
    setCurrent(1);
  }, [dispatch, contentSearch, setCurrent]);

  useEffect(() => {
    if (toggleLoader !== toggleRef.current) {
      dispatch(getRecommendedResources(1, false, contentSearch));
      setCurrent(1);
      toggleRef.current = toggleLoader;
    }
  }, [dispatch, contentSearch, setCurrent, toggleLoader]);

  // const statusObj: any = {
  //   Active: "active",
  //   Draft: "draft",
  //   Archived: "archived",
  // };

  // Infinite Scroll

  const loadMore = async () => {
    if (recommendedCount > recommendedContent.length) {
      await dispatch(getRecommendedResources(current + 1, true, contentSearch));
      setCurrent((cur) => cur + 1);
    }
  };

  const menu = (data: any) => (
    <Menu style={{ width: "120px" }}>
      <Menu.Item
        key="1"
        onClick={() => {
          dispatch(deleteRecommmendedResource(data.id));
        }}
      >
        Delete
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() =>
          dispatch(
            setModalDetails({
              type: "ADD_RECOMMENDED",
              modalProps: {
                show: true,
                recommendedData: data,
              },
            })
          )
        }
      >
        Edit
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="question-modules">
      <div className="container-fluid p-0">
        <div className="row no-gutter">
          <div className="col-xl-8 col-sm-12 search-education-block mb-4">
            <h3 className="font-m mb-0 me-3">
              All <span>{recommendedCount}</span> Recommended Reading
            </h3>
          </div>
        </div>
      </div>

      <div className="row card-section mt-4">
        {recommendedContent.length > 0 ? (
          recommendedContent.map((module: any) => (
            <div className="col-md-4 cp" key={module.id}>
              <div className="question-card">
                <div className="img-wrapper">
                  <img
                    alt="cover"
                    src={
                      module.imageUrl
                        ? module.imageUrl
                        : "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E"
                    }
                    onError={(ev: any) => {
                      ev.target.src =
                        "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                      ev.target.onerror = null;
                    }}
                  />
                  {module.status === "draft" ? (
                    <span className="tag">DRAFT</span>
                  ) : module.status === "archived" ? (
                    <span className="tag">ARCHIVED</span>
                  ) : null}
                </div>
                <h6>{module.title}</h6>
                <span onClick={(e) => e.stopPropagation()}>
                  <Dropdown overlay={menu(module)} trigger={["click"]}>
                    <i className="more more-icon cp"></i>
                  </Dropdown>
                </span>
              </div>
            </div>
          ))
        ) : contentSearch !== "" ? (
          <div className="no-search">
            <h2>Search Results</h2>
            <p>{`Your Search - ${contentSearch} - did not match any Recommended Resources`}</p>
            <div className="suggestions">
              <p>Suggestions:</p>
              <ul style={{ listStyleType: "disc", marginLeft: "1.8rem" }}>
                <li>Make sure all the keywords are spelled correctly</li>
                <li>Try different keywords</li>
                <li>Try more general keywords</li>
              </ul>
            </div>
            <a
              className="d-flex align-items-center mt-2"
              onClick={() => {
                dispatch(resetContentSearch());
              }}
            >
              <i className="arrow-left-icon ms-2"></i>Go back to home page
            </a>
          </div>
        ) : (
          recommendedContent.length === 0 && (
            <div className="no-module-content">
              <h1 className="font-l fw-bold">
                No Recommended Resources created
              </h1>
            </div>
          )
        )}
      </div>
      {recommendedCount > recommendedContent.length && (
        <div className="d-flex align-items-center justify-content-center">
          <Button
            buttonText="Load More"
            buttonType="button"
            clickHandler={loadMore}
            className="btn-lg btn-success"
          />
        </div>
      )}
    </div>
  );
};

export default RecommendedResource;
