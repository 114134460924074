import { Progress } from "antd";
import { useAppSelector } from "../../redux/hooks";

const QuestionnaireCards = () => {
  const { questionnaireMetrics } = useAppSelector((state) => state.dashboard);

  return (
    <div className="questionnaire-card-wrapper">
      <div className="questionnaire-cards">
        <div className="dashboard-title">Questionnaire Results overview</div>
        {questionnaireMetrics.map((result) => {
          return (
            <div key={result.id} className="questionnaire-cards-content">
              <div>{result.name}</div>
              <Progress
                percent={+result.percentage}
                className="questionnaire-progress-bar"
              />
            </div>
          );
        })}
      </div>
      <div className="questionnaire-cards">
        <div className="dashboard-title">Questionnaire Completion overview</div>
        {questionnaireMetrics.map((result) => {
          return (
            <div key={result.id} className="questionnaire-cards-content">
              <div>{result.name}</div>
              <Progress
                percent={result.percentage}
                className="questionnaire-progress-bar"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuestionnaireCards;
