import axios, { AxiosResponse } from "axios";
import roles from "../../constants/roles";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import {
  setAdminToggleLoader,
  setAdminUsers,
  setTherapistInfo,
  setTherapistTimings,
  setTherapistUsers,
} from "../reducers/administratorSlice";
import {
  setActiveParticipantUsers,
  setFilterParticipants,
  setInactiveParticipantUsers,
  setParticipantGroups,
  toggleParticipantLoader,
} from "../reducers/participantSlice";
import { setAppLoader } from "../reducers/loaderSlice";
import { hideModal } from "../reducers/modalSlice";
import { AppThunk } from "../store";
import history from "../../history";

export const fetchAdministratorDetails =
  (
    type: string,
    searchTerm: string,
    page: number,
    filter: string,
    order: any
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    let url = `/users?page=${page}&size=8&role=${type}`;
    if (filter) {
      url += `&status=${filter}`;
    }
    if (order) {
      url += `&orderBy=${order.orderBy}&sortBy=${order.sortBy}`;
    }
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }
    const res = await http.get(url);
    const data = res.data.data;
    if (type === roles.Admin["be_value"]) {
      dispatch(
        setAdminUsers({
          users: data.users,
          count: data.count,
        })
      );
    } else if (type === roles.Therapist["be_value"]) {
      dispatch(
        setTherapistUsers({
          users: data.users,
          count: data.count,
        })
      );
    }
    dispatch(setAppLoader(false));
  };

export const addAdministrator =
  (form: any): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    const res: AxiosResponse = await http.post(`/users`, form);
    toastMessage("success", res.data.message);
    dispatch(hideModal());
    if (form.role === roles.Participant["be_value"]) {
      dispatch(toggleParticipantLoader());
    } else {
      dispatch(setAdminToggleLoader());
    }
  };

export const fetchParticipantDetails =
  (
    type: string,
    searchTerm: string,
    page: number,
    order: any,
    therapistId?: string
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    let url = `/users?page=${page}&size=8&status=${type}&role=${roles.Participant["be_value"]}`;
    if (order) {
      url += `&orderBy=${order.orderBy}&sortBy=${order.sortBy}`;
    }
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }
    if (therapistId) {
      url += `&therapistId=${therapistId}`;
    }
    const res = await http.get(url);
    const data = res.data.data;
    if (type === "active") {
      dispatch(
        setActiveParticipantUsers({
          users: data.users,
          count: data.count,
        })
      );
    } else if (type === "inactive") {
      dispatch(
        setInactiveParticipantUsers({
          users: data.users,
          count: data.count,
        })
      );
    }
    dispatch(setAppLoader(false));
  };

export const modifyUserDetails =
  (form: any, id: string): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    const res: AxiosResponse = await http.put(`/users/${id}`, form);
    toastMessage("success", res.data.message);
  };

export const fetchTherapistTimings =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    const res: AxiosResponse = await http.get(`/ap/timings?therapistId=${id}`);
    const timings = res.data.data;
    if (!timings) {
      dispatch(hideModal());
      toastMessage("error", "Slot unavailable");
      return;
    }
    const obj = {
      startTime: +(timings.onlineStartOffsets[0] || 0),
      endTime: +(
        timings.onlineEndOffsets[timings.onlineEndOffsets.length - 1] || 3600
      ),
      offlineStart: timings.offlineStartOffsets[0]
        ? +timings.offlineStartOffsets[0]
        : undefined,
      offlineEnd: timings.offlineEndOffsets[0]
        ? +timings.offlineEndOffsets[0]
        : undefined,
      interval: +timings.interval,
    };
    dispatch(
      setTherapistTimings({
        timings: obj,
      })
    );
    dispatch(setAppLoader(false));
  };

export const modifyTherapistSlot =
  (
    type: string,
    startTime: number,
    endTime: number,
    therapistId: string
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setAppLoader(true));
    const oldTimings = getState().administrator.timings;
    let formObj: any = {};
    formObj.userId = therapistId;
    if (type === "slot") {
      formObj.onlineStartOffsets = [startTime];
      formObj.onlineEndOffsets = [endTime];
      if (!oldTimings.offlineStart || !oldTimings.offlineEnd) {
        formObj.offlineStartOffsets = [];
        formObj.offlineEndOffsets = [];
      } else if (
        oldTimings.offlineStart < startTime ||
        oldTimings.offlineStart > endTime ||
        oldTimings.offlineEnd > endTime
      ) {
        formObj.offlineStartOffsets = [];
        formObj.offlineEndOffsets = [];
      } else {
        formObj.offlineStartOffsets = [oldTimings.offlineStart];
        formObj.offlineEndOffsets = [oldTimings.offlineEnd];
        formObj.onlineStartOffsets.push(oldTimings.offlineEnd);
        formObj.onlineEndOffsets = [oldTimings.offlineStart, endTime];
      }
    } else {
      formObj.onlineStartOffsets = [oldTimings.startTime, endTime];
      formObj.onlineEndOffsets = [startTime, oldTimings.endTime];
      formObj.offlineStartOffsets = [startTime];
      formObj.offlineEndOffsets = [endTime];
    }
    await http.patch("/ap/timings", formObj);
    dispatch(fetchTherapistTimings(therapistId));
  };

export const getTherapistInfo =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res: AxiosResponse = await http.get(`/users/${id}/misc`);
      dispatch(setTherapistInfo(res.data.data));
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
      const role = localStorage.getItem("role");
      if (role === roles.Admin["be_value"]) {
        history.push("/administrator");
      } else {
        history.push("/dashboard");
      }
    }
  };

export const updateTherapistInfo =
  (id: string, form: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      console.log(id, form);
      const res: AxiosResponse = await http.put(`/users/${id}`, form);
      const role = localStorage.getItem("role");
      dispatch(setAppLoader(false));
      if (role === roles.Admin["be_value"]) {
        history.push("/administrator");
        toastMessage("success", "Therapist details updated successfully");
      } else {
        history.push("/dashboard");
        toastMessage("success", "Profile updated successfully");
        localStorage.setItem(
          "fullName",
          `${res.data.data.firstName} ${res.data.data.lastName}`
        );
        localStorage.setItem("profileImage", res.data.data.profileImage);
      }
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

export const getUserGroups =
  (pageNo: number, searchItem?: string): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    const pageSize = 8;
    let url = `/participant_groups?page=${pageNo}&size=${pageSize}`;
    url = searchItem ? `${url}&search=${searchItem}` : url;
    const res = await http.get(url);
    dispatch(
      setParticipantGroups({
        groups: res.data.data.participantGroups,
        count: res.data.data.count,
      })
    );
    dispatch(setAppLoader(false));
  };

export const deleteParticipantGroups =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.delete(`/participant_groups/${id}`);
      toastMessage("success", res.data.message);
      dispatch(toggleParticipantLoader());
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };

export const participantApplyFilters =
  (pageNo: number, filterValues?: any, searchValues?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const filters = filterValues !== undefined ? filterValues : [];
      const search = searchValues !== undefined ? searchValues : "";
      let body: any = {
        filters: filters,
      };
      if (search !== "") {
        body["search"] = search;
      }
      const res = await http.post(
        `/participants/apply_filters?page=${pageNo}&size=8`,
        body
      );
      dispatch(
        setFilterParticipants({
          users: res.data.data.participants,
          count: res.data.data.count,
        })
      );
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

export const createParticipantGroup =
  (body: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.post("/participant_groups", body);
      toastMessage("success", res.data.message);
      history.push("/participants");
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };
