import React, { useEffect, useMemo, useState } from "react";
import AppLayout from "../AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import ParticipantListView from "../Participants/ParticipantListView";
import { Input } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { debounce } from "lodash";
import { setParticipantSearchTerm } from "../../redux/reducers/participantSlice";

const AssociatedUsers: React.FC = () => {
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector((state) => state.participant.searchTerm);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setSearch(searchTerm);
  }, [searchTerm]);

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setParticipantSearchTerm(val));
      }, 500),
    [dispatch]
  );

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearch(val);
    modifySearchTerm(val);
  };

  return (
    <AppLayout>
      <Navigation />
      <div className="d-block d-md-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center mb-4">
          <h2 className="font-l mb-0 fw-bold">Associated Users</h2>
          <Input
            value={search}
            onChange={changeHandler}
            style={{ width: "300px", marginLeft: "20px" }}
            placeholder="Search..."
            className="search-input"
          />
        </div>
      </div>
      <ParticipantListView type="active" />
    </AppLayout>
  );
};

export default AssociatedUsers;
