import {
  SET_EDUCATION_LESSONS,
  EDUCATION_EDIT_VIEW,
  GET_EDUCATION_LESSONS,
  SEARCH_EDUCATION_LESSONS,
  SCROLL_EDUCATION_LESSONS,
  EDUCATION_PAGES_LIST,
  SET_EDIT_PAGE_SECTIONS,
  EDUCATION_SEARCH_PAGES_LIST,
  SET_BOOKMARKS,
  SET_RECOMMENDED_DATA,
  SET_DAILY_TIPS,
  SET_CONTENT_SEARCH,
  SET_CONTENT_TOGGLE_LOADER,
} from "../actionTypes";

export interface EducationState {
  lessons: any[];
  is_edit_view: boolean;
  educationLessons: any[];
  lessonsCount: number;
  lessonDetails: object;
  editPageSections: any[];
  isPageEdited: boolean;
  bookmarks: any[];
  recommendedContent: any[];
  recommendedCount: number;
  tipsContent: any[];
  tipsCount: number;
  contentSearch: string;
  toggleLoader: boolean;
}

const initState: EducationState = {
  lessons: [],
  is_edit_view: true,
  educationLessons: [],
  lessonsCount: 0,
  lessonDetails: {},
  editPageSections: [],
  isPageEdited: false,
  bookmarks: [],
  recommendedContent: [],
  recommendedCount: 0,
  tipsContent: [],
  tipsCount: 0,
  contentSearch: "",
  toggleLoader: false,
};

const educationReducer = (state = initState, action: any) => {
  switch (action.type) {
    case SET_EDUCATION_LESSONS:
      return {
        ...state,
        lessons: action.payload.lessons,
        is_edit_view: action.payload.editView,
      };

    case EDUCATION_EDIT_VIEW:
      return {
        ...state,
        is_edit_view: !state.is_edit_view,
      };
    case GET_EDUCATION_LESSONS:
      return {
        ...state,
        educationLessons: action.payload,
        lessonsCount: action.count,
      };

    case SCROLL_EDUCATION_LESSONS:
      return {
        ...state,
        educationLessons: [...state.educationLessons, ...action.payload],
        lessonsCount: action.count,
      };
    case SEARCH_EDUCATION_LESSONS:
      return {
        ...state,
        educationLessons: action.payload,
        lessonsCount: action.count,
      };

    case EDUCATION_PAGES_LIST:
      return {
        ...state,
        lessonDetails: { ...action.payload },
      };

    case EDUCATION_SEARCH_PAGES_LIST:
      return {
        ...state,
        lessonDetails: { ...action.payload },
      };

    case SET_EDIT_PAGE_SECTIONS:
      return {
        ...state,
        editPageSections: action.payload,
        isPageEdited: action.isPageEdited,
      };

    case SET_BOOKMARKS:
      return {
        ...state,
        bookmarks: action.payload,
      };
    case SET_RECOMMENDED_DATA:
      if (action.payload.append) {
        return {
          ...state,
          recommendedContent: [
            ...state.recommendedContent,
            ...action.payload.data,
          ],
          recommendedCount: action.payload.count,
        };
      }
      return {
        ...state,
        recommendedContent: [...action.payload.data],
        recommendedCount: action.payload.count,
      };
    case SET_DAILY_TIPS:
      if (action.payload.append) {
        return {
          ...state,
          tipsContent: [...state.tipsContent, ...action.payload.data],
          tipsCount: action.payload.count,
        };
      }
      return {
        ...state,
        tipsContent: [...action.payload.data],
        tipsCount: action.payload.count,
      };
    case SET_CONTENT_SEARCH:
      return {
        ...state,
        contentSearch: action.payload,
      };
    case SET_CONTENT_TOGGLE_LOADER:
      return {
        ...state,
        toggleLoader: !state.toggleLoader,
      };
    default:
      return state;
  }
};

export default educationReducer;
