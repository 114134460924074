import React from "react";
import { Tabs } from "antd";
import AppLayout from "../../AppLayout/AppLayout";
import Navigation from "../../Navigation/Navigation";
import { useLocation, useParams } from "react-router-dom";
import PatientAppointmentsTab from "./PatientAppointmentTabs";

const { TabPane } = Tabs;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PatientAppointments = () => {
  const name = useQuery().get("name");
  const params = useParams<any>();
  const { patientId } = params;

  return (
    <AppLayout>
      <Navigation />
      <h2 className="mb-4 font-l fw-bold">{name}</h2>
      <Tabs destroyInactiveTabPane className="singer-cutsom-tab">
        <TabPane tab="Today" key="today">
          <PatientAppointmentsTab tabType="today" patientId={patientId} />
        </TabPane>
        <TabPane tab="Week" key="week">
          <PatientAppointmentsTab tabType="week" patientId={patientId} />
        </TabPane>
        <TabPane tab="Month" key="month">
          <PatientAppointmentsTab tabType="month" patientId={patientId} />
        </TabPane>
      </Tabs>
    </AppLayout>
  );
};

export default PatientAppointments;
