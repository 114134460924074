import { Progress } from "antd";
import { useAppSelector } from "../../../redux/hooks";
import { QuestionIcon } from "../../Common/Icons";

const QuestionnaireResponse = () => {
  const projectOverviewQuestions: any[] = useAppSelector(
    (state) => state.dashboard.questionnaireResponse
  );

  const handleQuestionPercentage = (active: any, total: number) => {
    let pactive =
      active.choiceMetric !== null ? active.choiceMetric.responseCount : 0;
    const value = ((pactive / total) * 100).toFixed(2);
    return value;
  };

  return (
    <div className="question-response-wrapper styled-scrollbar">
      <div className="d-flex justify-content-between align-items-center">
        <div className="question-title">Questions</div>
        <div className="answer-title">Answers</div>
      </div>
      <div className="question-response-container">
        {projectOverviewQuestions.length > 0 ? (
          projectOverviewQuestions.map((item: any, _: any) => {
            return (
              <div className="question-response-card">
                <div className="question-icon">
                  <QuestionIcon />
                </div>
                <div>
                  <div className="question">{`${item.question.title}?`}</div>
                  <div className="responses">{`${item.responseCount} answers`}</div>
                  {(item.question.type === "multiple_choice" ||
                    item.question.type === "dropdown") &&
                    item.question.choices.length > 0 && (
                      <div className="option-wrapper">
                        {item.question.choices.map((choiceItem: any) => {
                          return (
                            <div className="options">
                              <div className="questions-option">
                                {choiceItem.label}
                                <span className="ms-2 option-percentage">{`${handleQuestionPercentage(
                                  choiceItem,
                                  item.responseCount
                                )}%`}</span>
                              </div>
                              <Progress
                                percent={Number(
                                  handleQuestionPercentage(
                                    choiceItem,
                                    item.responseCount
                                  )
                                )}
                                showInfo={false}
                                size="small"
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="empty-questions">No Questions Answered</div>
        )}
      </div>
    </div>
  );
};

export default QuestionnaireResponse;
