import { Menu, Select, Table } from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AppLayout from "../AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import { auditData } from "./data";

const roles = ["All Users", "Admin", "Therapist", "User"];

const eventTypes = [
  "All Event Types",
  "Field created",
  "Report Step created",
  "Field edited",
  "Field deleted",
  "Report Step deleted",
  "Report Step edited",
];

const AuditLog = () => {
  const [data, setData] = useState<any>([]);
  const [eventType, setEventType] = useState("All Event Types");
  const [role, setRole] = useState("All Users");

  const columns = useMemo(() => {
    return [
      {
        title: "User",
        key: "user",
        render: (_1: any, record: any) => {
          return (
            <div>
              <p className="text-s m-0 fw-medium">
                {record.firstName + " " + record.lastName}
              </p>
              <span className="text-muted">{record.email}</span>
            </div>
          );
        },
      },
      {
        title: "Event Type",
        dataIndex: "eventType",
        key: "eventType",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "createdAt",
        render: (text: string) => (
          <span>{moment(text).format("YYYY-MM-DD hh:mm:ss A")}</span>
        ),
      },
      {
        title: "Record ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Primary Information",
        dataIndex: "primaryInformation",
        key: "Primary Information",
      },
      {
        title: "Secondary Information",
        dataIndex: "secondaryInformation",
        key: "Secondary Information",
      },
    ];
  }, []);

  useEffect(() => {
    const data = auditData.filter((record) => {
      let pass = true;
      if (role != "All Users" && role != record.role) {
        pass = false;
      }
      if (eventType != "All Event Types" && eventType != record.eventType) {
        pass = false;
      }
      return pass;
    });
    console.log(data.length);
    setData(data);
  }, [eventType, role, setData]);

  return (
    <AppLayout>
      <Navigation />
      <span className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="font-l fw-bold">Audit Logs</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ margin: "0px 10px" }}>Filter on</span>
          <Select
            style={{ width: "250px" }}
            size="large"
            value={role}
            onChange={(val) => setRole(val)}
          >
            {roles.map((role: string) => {
              return (
                <Select.Option key={role} value={role}>
                  {role}
                </Select.Option>
              );
            })}
          </Select>
          <span style={{ margin: "0px 10px" }}>Filter on Event Type</span>
          <Select
            style={{ width: "250px" }}
            size="large"
            value={eventType}
            onChange={(val) => setEventType(val)}
          >
            {eventTypes.map((event: string) => {
              return (
                <Select.Option key={event} value={event}>
                  {event}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </span>
      <Table
        dataSource={data}
        columns={columns}
        showSorterTooltip={false}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={{
          total: data.length,
          showSizeChanger: false,
          pageSize: 10,
          hideOnSinglePage: true,
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
          },
          position: ["bottomLeft"],
        }}
      />
    </AppLayout>
  );
};

export default AuditLog;
