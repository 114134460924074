import { ArrowRightOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import AppLayout from "../AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import ReportsHeader from "./ReportsHeader";
import graphBar from "../../assets/img/graph-bar.png";
import graphCircle from "../../assets/img/graph-circle.png";
import { setModalDetails } from "../../redux/reducers/modalSlice";

const { TabPane } = Tabs;
// const reportsList = [
//   {
//     id: "uj",
//     title:
//       "Site average recruitment rate per month, should be between 2 and 5 subjects per month",
//     imageUrl: "",
//   },
//   {
//     id: "uj",
//     title:
//       "Site average recruitment rate per month, should be between 2 and 5 subjects per month",
//     imageUrl: "",
//   },
// ];

const TherapistAppointments: React.FC = () => {
  const { countData } = useAppSelector((state) => state.appointment);
  const name = localStorage.getItem("fullName");
  const dispatch = useAppDispatch();
  return (
    <AppLayout>
      <Navigation />
      <ReportsHeader />
      <div className="appointment-wrapper card-modules">
        <div className="row card-section mt-4">
          <div className="col-lg-3  col-sm-6 cp mb-4" key="1">
            <div className="custom-card">
              <div className="img-wrapper">
                <img
                  alt="cover"
                  src={graphBar}
                  onClick={() =>
                    dispatch(
                      setModalDetails({
                        type: `REPORT_VIEW_MODAL`,
                        modalProps: {
                          show: true,
                          view: "chart1",
                          title: "Subjects Recruitment Rate",
                        },
                      })
                    )
                  }
                />
              </div>
              <h6>Subjects Recruitment Rate</h6>
              <p>
                Site average recruitment rate per month, should be between 2 and
                5 subjects per month
              </p>
            </div>
          </div>
          <div className="col-lg-3  col-sm-6 cp mb-4" key="2">
            <div className="custom-card">
              <div className="img-wrapper">
                <img
                  alt="cover"
                  src={graphBar}
                  onError={(ev: any) => {
                    ev.target.src =
                      "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                    ev.target.onerror = null;
                  }}
                />
              </div>
              <h6>Subjects Screening Failure Rate</h6>
              <p>
                Check for patients not meeting inclusion/exclusion criteria.
              </p>
            </div>
          </div>
          <div className="col-lg-3  col-sm-6 cp mb-4" key="3">
            <div className="custom-card">
              <div className="img-wrapper">
                <img
                  alt="cover"
                  src={graphCircle}
                  className="border-0"
                  onError={(ev: any) => {
                    ev.target.src =
                      "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                    ev.target.onerror = null;
                  }}
                />
              </div>
              <h6>Automatic Queries Per Subject</h6>
              <p>
                Active sites number of total queries plus average of patient
              </p>
            </div>
          </div>
          <div className="col-lg-3  col-sm-6 cp mb-4" key="4">
            <div className="custom-card">
              <div className="img-wrapper">
                <img
                  alt="cover"
                  src={graphBar}
                  onError={(ev: any) => {
                    ev.target.src =
                      "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                    ev.target.onerror = null;
                  }}
                />
              </div>
              <h6>Subjects Recruitment Rate</h6>
              <p>
                Site average recruitment rate per month, should be between 2 and
                5 subjects per month
              </p>
            </div>
          </div>
          <div className="col-lg-3  col-sm-6 cp mb-4" key="5">
            <div className="custom-card">
              <div className="img-wrapper">
                <img
                  alt="cover"
                  src={graphCircle}
                  className="border-0"
                  onError={(ev: any) => {
                    ev.target.src =
                      "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                    ev.target.onerror = null;
                  }}
                  onClick={() =>
                    dispatch(
                      setModalDetails({
                        type: `REPORT_VIEW_MODAL`,
                        modalProps: {
                          show: true,
                          view: "chart2",
                          title: "Adherence to Exclusion Criterion #1",
                        },
                      })
                    )
                  }
                />
              </div>
              <h6>Adherence to Exclusion Criterion #1</h6>
              <p>
                Check for giant aneurysms (i.e, any of the three dimensions
                &#62; 25mm)
              </p>
            </div>
          </div>
          <div className="col-lg-3  col-sm-6 cp mb-4" key="6">
            <div className="custom-card">
              <div className="img-wrapper">
                <img
                  alt="cover"
                  src={graphCircle}
                  className="border-0"
                  onError={(ev: any) => {
                    ev.target.src =
                      "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                    ev.target.onerror = null;
                  }}
                  onClick={() =>
                    dispatch(
                      setModalDetails({
                        type: `REPORT_VIEW_MODAL`,
                        modalProps: {
                          show: true,
                          view: "chart3",
                          title: "Adherence to Exclusion Criterion #4",
                        },
                      })
                    )
                  }
                />
              </div>
              <h6>Adherence to Exclusion Criterion #4</h6>
              <p>Check for dissecting, fusiform, or non-saccular aneurysms</p>
            </div>
          </div>
          <div className="col-lg-3  col-sm-6 cp mb-4" key="7">
            <div className="custom-card">
              <div className="img-wrapper">
                <img
                  alt="cover"
                  src={graphBar}
                  onError={(ev: any) => {
                    ev.target.src =
                      "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                    ev.target.onerror = null;
                  }}
                />
              </div>
              <h6>Adherence to Exclusion Criterion #3</h6>
              <p>Check for patients younger than 18.</p>
            </div>
          </div>
          <div className="col-lg-3  col-sm-6 cp mb-4" key="8">
            <div className="custom-card">
              <div className="img-wrapper">
                <img
                  alt="cover"
                  src={graphCircle}
                  className="border-0"
                  onError={(ev: any) => {
                    ev.target.src =
                      "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                    ev.target.onerror = null;
                  }}
                />
              </div>
              <h6>
                Volumes <br />
                Evaluation
              </h6>
              <p>Check for likelihood of measures and calculations.</p>
            </div>
          </div>
          <div className="col-lg-3  col-sm-6 cp mb-4" key="9">
            <div className="custom-card">
              <div className="img-wrapper">
                <img
                  alt="cover"
                  src={graphBar}
                  onError={(ev: any) => {
                    ev.target.src =
                      "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                    ev.target.onerror = null;
                  }}
                />
              </div>
              <h6>
                Missing <br />
                Uploads
              </h6>
              <p>
                Patients having completed a procedure and/or follow-up visit
                withoud the images
              </p>
            </div>
          </div>
          <div className="col-lg-3  col-sm-6 cp mb-4" key="10">
            <div className="custom-card">
              <div className="img-wrapper">
                <img
                  alt="cover"
                  src={graphCircle}
                  className="border-0"
                  onError={(ev: any) => {
                    ev.target.src =
                      "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                    ev.target.onerror = null;
                  }}
                />
              </div>
              <h6>Visits Outside Window Period</h6>
              <p>Check for non compiliance in visit schedules</p>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default TherapistAppointments;
