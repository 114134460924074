const SmokeIcon = ({ width, height }: { width: string; height: string }) => (
  <svg
    width={width || "24"}
    height={height || "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 15.9998H3.5C2.67 15.9998 2 16.6698 2 17.4998C2 18.3298 2.67 18.9998 3.5 18.9998H15.5C16.33 18.9998 17 18.3298 17 17.4998C17 16.6698 16.33 15.9998 15.5 15.9998ZM18.85 7.72985C19.47 7.11985 19.85 6.27985 19.85 5.34985C19.85 3.83985 18.85 2.55985 17.47 2.13985C16.99 1.99985 16.5 2.35985 16.5 2.85985C16.5 3.18985 16.71 3.47985 17.02 3.56985C17.79 3.79985 18.35 4.50985 18.35 5.34985C18.35 6.16985 17.82 6.85985 17.08 7.10985C16.75 7.21985 16.5 7.49985 16.5 7.84985V7.99985C16.5 8.36985 16.77 8.68985 17.14 8.74985C19.07 9.05985 20.5 10.7498 20.5 12.7698V14.2498C20.5 14.6598 20.84 14.9998 21.25 14.9998C21.66 14.9998 22 14.6598 22 14.2498V12.7598C22 10.5398 20.72 8.61985 18.85 7.72985ZM16.03 10.1998H14.73C13.71 10.1998 12.79 9.46985 12.66 8.44985C12.54 7.49985 13.12 6.74985 13.96 6.51985C14.28 6.42985 14.5 6.13985 14.5 5.79985C14.5 5.30985 14.04 4.93985 13.57 5.07985C12.15 5.48985 11.12 6.80985 11.15 8.35985C11.18 10.1998 12.77 11.6498 14.61 11.6498H16.03C17.08 11.6498 18 12.3898 18 13.6998V14.2498C18 14.6598 18.33 14.9998 18.75 14.9998H18.76C19.17 14.9998 19.51 14.6698 19.51 14.2498V13.3598C19.5 11.5498 17.9 10.1998 16.03 10.1998ZM18 15.9998H19.5V18.9998H18V15.9998ZM20.5 15.9998H22V18.9998H20.5V15.9998Z"
      fill="#333333"
      className="fill-path"
    />
  </svg>
);
export default SmokeIcon;
