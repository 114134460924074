import React, { useState } from "react";
import { Modal, Select } from "antd";
import "react-phone-input-2/lib/style.css";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { addAdministrator } from "../../../redux/actions/adminAction";
import { errorToastMessage } from "../../../helpers/toastMessage";
import chart1 from "../../../assets/img/chart1.png";
import chart2 from "../../../assets/img/chart2.png";
import chart3 from "../../../assets/img/chart3.png";

type Props = {
  title: string;
  view: string;
};

const ReportViewModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<any[]>();
  const [fetching, setFetching] = useState<boolean>(false);
  const { show } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async (values: any) => {
    try {
      let obj = {
        ...values,
        phoneNumber: `+${values.mobileNumber}`,
      };
      delete obj["mobileNumber"];
      await dispatch(addAdministrator(obj));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal
      title={`${props.title}`}
      visible={show}
      onCancel={closeHandler}
      closable={true}
      width={"80%"}
      footer={null}
    >
      <div className="add-project-owner">
        {props.view == "chart1" && (
          <div>
            <img src={chart1} className="img-responsive" />
          </div>
        )}
        {props.view == "chart2" && (
          <div>
            <img src={chart2} className="img-responsive" />
          </div>
        )}
        {props.view == "chart3" && (
          <div>
            <img src={chart3} className="img-responsive" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ReportViewModal;
