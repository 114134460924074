import { Table } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { errorToastMessage } from "../../../helpers/toastMessage";
import http from "../../../http";
import { fetchQuestionnaireDetails } from "../../../redux/actions/dashboardAction";
import { useAppDispatch } from "../../../redux/hooks";
import { setQuestionniareResponse } from "../../../redux/reducers/dashboardSlice";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";

const QuestionnaireOverview = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    return () => {
      dispatch(
        setQuestionniareResponse({
          id: null,
          data: [],
        })
      );
    };
  }, [dispatch]);

  const onTableChange = async (pagination: any) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }
  };

  const fetchDetails = useCallback(
    async (page: number) => {
      try {
        dispatch(setAppLoader(true));
        let query = `?page=${page}&size=8`;
        const response = await http.get(`/questionnaires/completion${query}`);
        const res = response.data.data;
        setTotal(res.count);
        setData(res.questionnaires);
        dispatch(setAppLoader(false));
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    },
    [dispatch, setTotal, setData]
  );

  useEffect(() => {
    fetchDetails(currentPage);
  }, [fetchDetails, currentPage]);

  const columns = useMemo(() => {
    const loadQuestionnaire = (id: string) => {
      dispatch(fetchQuestionnaireDetails(id));
    };

    return [
      {
        title: "Module",
        dataIndex: "name",
        key: "name",
        render: (text: string, record: any) => {
          return (
            <span
              className="questionnaire-link"
              onClick={() => loadQuestionnaire(record.questionnaireId)}
            >
              {text}
            </span>
          );
        },
      },
      {
        title: "Users Completed",
        dataIndex: "total",
        key: "total",
      },
    ];
  }, []);

  return (
    <Table
      dataSource={data}
      columns={columns}
      showSorterTooltip={false}
      className="singer-custom-table"
      onChange={onTableChange}
      rowKey={(record) => record.questionnaireId}
      pagination={{
        current: currentPage,
        total: total,
        showSizeChanger: false,
        pageSize: 8,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
        },
        position: ["bottomLeft"],
      }}
    />
  );
};

export default QuestionnaireOverview;
