import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DashboardState {
  basicMetrics: any;
  questionnaireMetrics: any[];
  questionnaireResponse: any[];
  questionnaireResponseId: string | null;
}

const initialState: DashboardState = {
  basicMetrics: {
    activeParticipants: 0,
    totalParticipants: 0,
    activeQuestionnaires: 0,
    totalQuestionnaires: 0,
    activeEducationLessons: 0,
    totalEducationLessons: 0,
    activeForums: 0,
    totalForums: 0,
    signedInUsers: 0,
    stepsTrackingUsers: 0,
    foodTrackingUsers: 0,
  },
  questionnaireMetrics: [],
  questionnaireResponse: [],
  questionnaireResponseId: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setBasicDetails: (state, action: PayloadAction<any>) => {
      state.basicMetrics = action.payload;
    },
    setQuestionniareDetails: (state, action: PayloadAction<any>) => {
      state.questionnaireMetrics = action.payload;
    },
    setQuestionniareResponse: (state, action: PayloadAction<any>) => {
      state.questionnaireResponseId = action.payload.id;
      state.questionnaireResponse = action.payload.data;
    },
  },
});

export const {
  setBasicDetails,
  setQuestionniareDetails,
  setQuestionniareResponse,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
