import React from "react";
import { Modal } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import moment from "moment";
import MedicineTakenIcon from "../../PatientsList/Patienticons/MedicineTakenIcon";
import MedicineNotTakenIcon from "../../PatientsList/Patienticons/MedicineNotTakenIcon";

const MedicineDetailModal = (props: any) => {
  const dispatch = useAppDispatch();
  const { show, medicine, date } = useAppSelector(
    (state) => state.modal.modalProps
  );

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      title={"Medicine Details"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="mb-2">
        <span className="fw-bold me-2">Date: </span>
        <span>{moment(date).format("MMMM DD YYYY")}</span>
      </div>
      <div className="fw-bold mb-2">Medicines</div>
      {medicine.map((medicine: any) => {
        const taken =
          medicine.timesPerDay - medicine.userMedicationReadings.length;
        return (
          <div className="medicine-detail mb-2">
            <div>{medicine.name}</div>
            <div>
              {taken <= 0 ? <MedicineTakenIcon /> : <MedicineNotTakenIcon />}
            </div>
          </div>
        );
      })}
    </Modal>
  );
};

export default MedicineDetailModal;
