import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { appointmentPerTimePeriodConstructor } from "../../../helpers/Appointment";
import http from "../../../http";
import { AxiosResponse } from "axios";
import { Avatar, Table } from "antd";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import roles from "../../../constants/roles";
import { getImageInitials } from "../../../helpers/user";
import { setModalDetails } from "../../../redux/reducers/modalSlice";

type Props = {
  tabType: string;
  therapistId: string;
};

const AllAppointmentsTab: React.FC<Props> = ({ tabType, therapistId }) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(
    async (page, tabType, therapistId) => {
      try {
        dispatch(setAppLoader(true));
        let query = appointmentPerTimePeriodConstructor(tabType);
        query += `&page=${page}&size=8&unconfirmed=false&status=confirmed`;
        if (therapistId) {
          query += `&inviterId=${therapistId}`;
        }
        let url = `/ap/generic${query}`;
        const res: AxiosResponse = await http.get(url);
        setData(res.data.data.rows);
        setTotal(res.data.data.count);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [setData, setTotal, dispatch]
  );

  useEffect(() => {
    fetchData(1, tabType, therapistId);
  }, [fetchData, tabType, therapistId]);

  const onTableChange = async (pagination: any) => {
    let page = 1;
    if (pagination.current) {
      setCurrentPage(pagination.current);
      page = pagination.current;
    }
    fetchData(page, tabType, therapistId);
  };

  const columns = useMemo(() => {
    let temp: any[] = [
      {
        title: "Name",
        render: (_1: any, record: any) => {
          const participant = record.invitees[0].invitee;
          const inviteeLength = record.invitees.length - 1;
          const loadAllParticipants = () => {
            dispatch(
              setModalDetails({
                type: "ALL_PARTICIPANTS_DETAILS",
                modalProps: {
                  show: true,
                  participants: record.invitees,
                },
              })
            );
          };
          return (
            <div className="d-flex">
              {participant.profileImage ? (
                <Avatar
                  shape="circle"
                  size="large"
                  src={participant.profileImage}
                />
              ) : (
                <Avatar shape="circle" size="large">
                  {getImageInitials(
                    participant.firstName,
                    participant.lastName
                  )}
                </Avatar>
              )}
              <div className="ms-3">
                <span className="d-flex align-items-center">
                  <p className="text-s m-0 fw-medium">
                    {participant.firstName + " " + participant.lastName}
                  </p>
                  {inviteeLength > 0 && (
                    <div>
                      <span className="ms-1 me-1 more-users">{"&"}</span>
                      <span
                        className="more-users-count"
                        onClick={loadAllParticipants}
                      >
                        {inviteeLength > 1
                          ? `${inviteeLength} others`
                          : "1 other"}
                      </span>
                    </div>
                  )}
                </span>
                <span className="text-muted font-xs">{participant.email}</span>
              </div>
            </div>
          );
        },
      },
      {
        title: "Contact",
        render: (_1: any, record: any) => {
          const participant = record.invitees[0].invitee;
          return <span>{participant.phoneNumber}</span>;
        },
      },
    ];
    if (tabType !== "today") {
      temp.push({
        title: "Date",
        dataIndex: "scheduledStartTime",
        render: (date: any, record: any) => {
          return <span>{moment(date).format("MMM D, YYYY")}</span>;
        },
      });
    }
    temp.push({
      title: "Time",
      dataIndex: "scheduledStartTime",
      render: (date: any, record: any) => {
        return <span>{moment(date).format("hh:mm A")}</span>;
      },
    });
    if (localStorage.getItem("role") !== roles.Therapist["be_value"]) {
      temp.push({
        title: "Coach Assigned",
        key: "inviter",
        render: (_1: any, record: any) => {
          const name = record.inviter.firstName + " " + record.inviter.lastName;
          return (
            <NavLink
              className="no-underline link-normal"
              to={`/appointments/therapist/${record.inviterId}?name=${name}`}
            >
              {name}
            </NavLink>
          );
        },
      });
    }
    temp.push({
      title: "Actions",
      key: "actions",
      render: (_1: any, record: any) => {
        const participant = record.invitees[0];
        const name =
          participant.invitee.firstName + " " + participant.invitee.lastName;
        return (
          <NavLink
            className="no-underline link-primary"
            to={`/appointments/patient/${participant.inviteeId}?name=${name}`}
          >
            View Details
          </NavLink>
        );
      },
    });
    return temp;
  }, [tabType, dispatch]);

  return (
    <Table
      dataSource={data}
      columns={columns}
      className="singer-custom-table"
      onChange={onTableChange}
      rowKey={(record) => record.id}
      pagination={{
        current: currentPage,
        total: total,
        showSizeChanger: false,
        pageSize: 8,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
        },
        position: ["bottomLeft"],
      }}
    />
  );
};

export default AllAppointmentsTab;
