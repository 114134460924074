import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InputField from "../Common/CustomInput";
import Navigation from "../Navigation/Navigation";
import Button from "../Common/CustomButton";
import { connect } from "react-redux";
import ListScoreQuestions from "./listScoreQuestions";
import ScoreCalculator from "./scoreCalculator";
import ScoreValidation from "./scoreValidation";
import {
  createScoreExp,
  GetScoreInfo,
  updateScoreExp,
} from "../../redux/actions/scoreAction";
import {
  scoresFromResponse,
  scoreEvalFromResponse,
  isEqual,
} from "../../helpers/score";
import { toastMessage } from "../../helpers/toastMessage";
import { useLocation } from "react-router-dom";
import AppLayout from "../AppLayout/AppLayout";
type Props = {
  scoreInfo: any;
  createScoreExp: Function;
  GetScoreInfo: Function;
  updateScoreExp: Function;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreateStore = (props: Props) => {
  const history = useHistory();
  const query = useQuery();
  const qid: string = query.get("id") || "";
  const propName = query.get("name");
  const viewType = query.get("view");
  const scoreId = query.get("scoreId");

  const [name, setName] = useState("");
  let [scores, setScores] = useState<any[]>([]);
  const [values, setValues] = useState<{ clickedItem: any; editItem: boolean }>(
    {
      clickedItem: {},
      editItem: false,
    }
  );

  let [scoreEval, setEval] = useState<any[]>([]);
  const { GetScoreInfo } = props;

  useEffect(() => {
    if (scoreId) {
      GetScoreInfo(scoreId);
    }
  }, [scoreId, GetScoreInfo]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  //to retreive score info from the list
  useEffect(() => {
    if (props.scoreInfo.hasOwnProperty("id") && scoreId) {
      setScores(scoresFromResponse(props.scoreInfo.scoreExpressionNodes));
      setEval(scoreEvalFromResponse(props.scoreInfo.scoreExpressionConditions));
      setName(props.scoreInfo.title);
    }
  }, [props.scoreInfo, scoreId, setScores, setEval, setName]);

  //EXPRESSION BUILDER

  // add question or operator to expression
  const handleExpression = (item: any) => {
    let expressionType = item.hasOwnProperty("questionId")
      ? "question"
      : item.hasOwnProperty("type")
      ? "paranthesis"
      : "operator";
    let expressionValue = item.hasOwnProperty("func")
      ? item.func
      : item.questionId;

    if (expressionValue === "AC") {
      setScores([]);
    } else {
      if (!values.editItem) {
        if (expressionValue === "constant") {
          setScores((prevState) => [
            ...prevState,
            {
              type: "constant",
              value: 100,
              position: prevState.length + 1,
            },
          ]);
        } else {
          setScores((prevState) => [
            ...prevState,
            {
              type: expressionType,
              value: expressionValue,
              position: prevState.length + 1,
            },
          ]);
        }
      } else {
        let tempArray =
          expressionValue === "constant"
            ? [
                {
                  type: "constant",
                  value: 100,
                  position: 1,
                },
              ]
            : [
                {
                  type: expressionType,
                  value: expressionValue,
                  position: 1,
                },
              ];

        let newArr = scores;
        let position = Number(values.clickedItem["position"]);
        newArr.splice(position, 0, ...tempArray);
        newArr.forEach((item, index) => (item.position = index + 1));

        setScores(newArr);
        setValues((prevState) => ({
          ...values,
          clickedItem: { ...prevState.clickedItem, position: position + 1 },
        }));
      }
    }
  };

  // on click of question or operator in expression
  const handleEditExpression = (item: any) => {
    setValues({
      ...values,
      clickedItem: item,
      editItem: true,
    });
  };

  // on removal of operand
  const handleRemoveOperand = (item: any) => {
    let newArray = scores.filter((score: any) => {
      return score.position !== item.position;
    });

    newArray.forEach((item, index) => (item.position = index + 1));
    setScores(newArray);
    setValues({
      ...values,
      clickedItem: {},
      editItem: false,
    });
  };

  //handle constants
  const handleConstants = (item: any, val: any) => {
    let newArray = scores.map((score: any) => {
      return score.position === item.position
        ? {
            ...score,
            value: Number(val),
          }
        : score;
    });

    setScores(newArray);
  };

  // SCORE EVALUATION

  // Add score and evaluate
  const handleUpdateScore = (
    idx: number,
    type: string,
    key: string,
    value: any
  ) => {
    if (type === "ADD") {
      setEval([
        ...scoreEval,
        {
          condition: "",
          score: 0,
        },
      ]);
    } else if (type === "CHANGE") {
      let newScoreEval = scoreEval.map((f, i) =>
        i === idx ? { ...f, [key]: value } : f
      );
      setEval(newScoreEval);
    }
  };

  // remove score object from the eval array
  const handleRemoveScore = (idx: any) => {
    const FilteredArray = scoreEval.filter(
      (_: any, index: number) => index !== idx
    );

    setEval(FilteredArray);
  };

  // Submit score exp with nodes and conditions

  const handleScoreSubmit = (givenStatus: any) => {
    let updatedEval = scoreEval.map((item: any, index) => {
      return { ...item, position: index + 1 };
    });

    if (scoreId) {
      let body = {
        title: name,
        description: name,
        status: givenStatus,
      };
      props.updateScoreExp(scoreId, body);
    } else {
      let body = {
        title: name,
        description: name,
        status: givenStatus,
        questionnaireId: qid,
        scoreExpressionNodes: scores,
        scoreExpressionConditions: updatedEval,
      };

      props.createScoreExp(body, qid, propName);
    }
  };

  //update score exp with nodes and conditions

  const handleScoreUpdate = (givenStatus: any) => {
    let body;
    let updatedEval = scoreEval.map((item: any, index) => {
      return { ...item, position: index + 1 };
    });

    if (name !== props.scoreInfo.title) {
      body = {
        title: name,
        description: name,
        status: givenStatus,
      };
      props.updateScoreExp(scoreId, body);
    }

    let scoreChange = isEqual(
      scores,
      scoresFromResponse(props.scoreInfo.scoreExpressionNodes)
    );
    let evalChange = isEqual(
      scoreEval,
      scoreEvalFromResponse(props.scoreInfo.scoreExpressionConditions)
    );

    if (!scoreChange && evalChange) {
      body = {
        title: name,
        description: name,
        status: givenStatus,
        scoreExpressionNodes: scores,
      };
      props.updateScoreExp(scoreId, body);
    } else if (scoreChange && !evalChange) {
      body = {
        title: name,
        description: name,
        status: givenStatus,
        scoreExpressionConditions: updatedEval,
      };

      props.updateScoreExp(scoreId, body);
    } else if (!scoreChange && !evalChange) {
      body = {
        title: name,
        description: name,
        status: givenStatus,
        scoreExpressionNodes: scores,
        scoreExpressionConditions: updatedEval,
      };

      props.updateScoreExp(scoreId, body);
    } else {
      toastMessage("warning", "No changes to Update");
    }

    console.log(body, "handleScoreUpdateBody");
  };

  //cancel score exp with nodes and condition

  const handleCancelScore = () => {
    if (props.scoreInfo.hasOwnProperty("id") && scoreId) {
      setScores(scoresFromResponse(props.scoreInfo.scoreExpressionNodes));
      setEval(scoreEvalFromResponse(props.scoreInfo.scoreExpressionConditions));
      setName(props.scoreInfo.title);
    } else {
      setScores([]);
      setEval([]);
      setName("");
    }
  };

  console.log(scoreEval, "scoresEval");
  return (
    <AppLayout themelight={true}>
      <div className="create-score">
        <Navigation />
        <h3 className="font-ml fw-medium d-flex align-items-center mb-4">
          <span
            className="arrow-left-icon me-2 cp"
            onClick={() =>
              history.push(`/question/score?id=${qid}&name=${propName}`)
            }
          ></span>
          {`${propName} Score`}
        </h3>
        <div className="score-header px-1">
          <div className="input-name search searchInput-mini">
            <label className="form-label ">Score Name* :</label>
            <InputField
              name="name"
              placeholder="Enter the score name"
              changeEvent={(e) => setName(e.target.value)}
              className="floating-label"
              value={name}
            />
          </div>
        </div>

        <div className="score-main row">
          <div className="col-md-4">
            <ListScoreQuestions
              qid={qid}
              name={propName}
              handleExp={handleExpression}
            />
          </div>
          <div className="col-md-4">
            <ScoreCalculator
              scores={scores}
              handleExp={handleExpression}
              handleEditExp={handleEditExpression}
              handleRemoveOperand={handleRemoveOperand}
              handleConstants={handleConstants}
            />
          </div>
          <div className="col-md-4">
            <ScoreValidation
              updateScore={handleUpdateScore}
              scoreEval={scoreEval}
              handleRemoveScore={handleRemoveScore}
            />
          </div>
        </div>
        {viewType !== "staticView" ? (
          <div className="text-end mt-2">
            <Button
              buttonText="Cancel"
              primary={false}
              className="btn-lg mx-2"
              clickHandler={handleCancelScore}
            />
            {props.scoreInfo.status === "draft" && scoreId ? (
              <Button
                buttonText="Update draft"
                primary={true}
                className="mx-2 btn-lg"
                clickHandler={() => handleScoreUpdate("draft")}
              />
            ) : (
              <Button
                buttonText="Save as draft"
                primary={true}
                className="mx-2 btn-lg"
                clickHandler={() => handleScoreSubmit("draft")}
              />
            )}
            <Button
              buttonText="Submit"
              className="mx-2 btn-lg btn-success"
              clickHandler={() => handleScoreSubmit("active")}
            />
          </div>
        ) : null}
      </div>
    </AppLayout>
  );
};

const mapStateToProps = (state: any) => ({
  scoreInfo: state.scores.scoreInfo,
});

export default connect(mapStateToProps, {
  createScoreExp,
  GetScoreInfo,
  updateScoreExp,
})(CreateStore);
