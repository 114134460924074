import { useAppSelector } from "../../../redux/hooks";

const ChartEntries = () => {
  const { chartEntries } = useAppSelector((state) => state.console);
  return (
    <div className="p-3 entry-container">
      <h6 className="m-0 font-m fw-bold mb-3">Entries</h6>
      {chartEntries.length > 0 ? (
        chartEntries.map((entry) => {
          return (
            <div
              className="d-flex justify-content-between align-items-center border-bottom py-2 font-sm"
              key={entry.label}
            >
              <span className="entries-label">{entry.label}</span>
              <span className="entries-value">{entry.value}</span>
            </div>
          );
        })
      ) : (
        <div>No entries found</div>
      )}
    </div>
  );
};

export default ChartEntries;
