import { Input, Table } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { useEffect, useMemo, useState } from "react";
import { toastMessage } from "../../../helpers/toastMessage";
import { getImageInitials } from "../../../helpers/user";
import {
  createParticipantGroup,
  participantApplyFilters,
} from "../../../redux/actions/adminAction";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setFilterParticipants } from "../../../redux/reducers/participantSlice";
import AppLayout from "../../AppLayout/AppLayout";
import CustomInput from "../../Common/CustomInput";
import Navigation from "../../Navigation/Navigation";
import UserFilter from "./UserFilter";

const GroupCreate = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState("");
  const [groupChecked, setGroupChecked] = useState(false);
  const [filters, setFilters] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [updatedFilters, setUpdatedFilters] = useState([]);
  const { filterParticipants, filterParticipantsCount } = useAppSelector(
    (state) => state.participant
  );

  useEffect(() => {
    dispatch(
      setFilterParticipants({
        users: [],
        count: 0,
      })
    );
  }, [dispatch]);
  const handleGroupChecked = (checked: boolean) => {
    setGroupChecked(checked);
  };

  const onTableChange = (pagination: any) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }
  };

  const handleGroupSave = () => {
    if (name.trim() === "") {
      toastMessage("error", "No white spaces are allowed in group name");
      return;
    }
    let body = {
      name: name,
      filters: updatedFilters,
      controlAndInterventionGroups: groupChecked,
    };
    let count = 0;
    if (filters.length > 0) {
      const newArray = filters.map((item: any) =>
        item.module !== "" && item.question !== "" && item.responses !== ""
          ? 0
          : 1
      );
      count = newArray.reduce((a: number, b: number) => a + b, 0);

      if (count === 0) {
        dispatch(createParticipantGroup(body));
      } else {
        toastMessage("warning", "Please fill in the empty fields");
      }
    } else {
      toastMessage("warning", "Add filters to list participants");
    }
  };

  const columns = useMemo(() => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (_1: any, record: any) => {
          return (
            <div className="d-flex">
              {record.profileImage ? (
                <Avatar shape="circle" size="large" src={record.profileImage} />
              ) : (
                <Avatar shape="circle" size="large">
                  {getImageInitials(record.firstName, record.lastName)}
                </Avatar>
              )}
              <div className="ms-3">
                <p className="text-s m-0 fw-medium">
                  {record.firstName + " " + record.lastName}
                </p>
                <span className="text-muted">{record.email}</span>
              </div>
            </div>
          );
        },
      },
      {
        title: "Contact",
        dataIndex: "phoneNumber",
        key: "mobile",
      },
    ];
  }, []);

  const handleSearch = () => {
    dispatch(participantApplyFilters(1, updatedFilters, search));
  };

  const searchOnChange = (event: any) => {
    const val = event.target.value;
    setSearch(val);
  };

  const cancelFilters = () => {
    setFilters([]);
    setUpdatedFilters([]);
    setCurrentPage(1);
    dispatch(participantApplyFilters(1, []));
  };

  const updateFilters = (
    idx: number,
    type: string,
    key: string,
    value: string,
    quesType: string
  ) => {
    if (quesType === "participant_status") {
      if (type === "ADD") {
        setFilters([
          ...filters,
          {
            filterType: value,
            status: "",
            condition: "and",
          },
        ]);
      }

      if (type === "CHANGE") {
        let newFilters = filters.map((f, i) =>
          i === idx ? { ...f, [key]: value } : f
        );

        setFilters(newFilters);
        handleFilterChangedValues(newFilters, idx);
      }
    } else if (quesType === "participant_gender") {
      if (type === "ADD") {
        setFilters([
          ...filters,
          {
            filterType: value,
            gender: "",
            condition: "and",
          },
        ]);
      }

      if (type === "CHANGE") {
        let newFilters = filters.map((f, i) =>
          i === idx ? { ...f, [key]: value } : f
        );

        setFilters(newFilters);
        handleFilterChangedValues(newFilters, idx);
      }
    }
  };

  const handleFilterChangedValues = (newFilters: any, index: any) => {
    if (newFilters[index].status !== "" && newFilters[index].gender !== "") {
      let result = newFilters.map((item: any) => {
        return {
          ...item,
        };
      });
      setUpdatedFilters(result);
      dispatch(participantApplyFilters(1, result));
    }
  };

  const handleRemoveFilter = (idx: any, filterType: any) => {
    const updatedFilteredArray = updatedFilters.filter(
      (_: any, index: number) => index !== idx
    );

    const FilteredArray = filters.filter(
      (_: any, index: number) => index !== idx
    );

    setFilters(FilteredArray);

    setUpdatedFilters(updatedFilteredArray);

    dispatch(participantApplyFilters(currentPage, updatedFilteredArray));
  };

  return (
    <AppLayout>
      <Navigation />
      <div className="users users-group">
        <div className="user-new-group-actions">
          <div></div>
          {/* <div>
            <Input.Search
              name="search"
              value={search}
              onSearch={handleSearch}
              onChange={searchOnChange}
              placeholder="Search..."
              className="search-input"
              enterButton={false}
            />
          </div> */}
          <div className="input-name">
            <CustomInput
              name="name"
              placeholder="Enter the user group name"
              changeEvent={(e) => setName(e.target.value)}
              className="floating-label"
              value={name}
            />
          </div>
        </div>

        <div className="row m-0 side-filter">
          <div className="col-md-8">
            <div className="table-new-group-container">
              <Table
                dataSource={filterParticipants}
                columns={columns}
                className="singer-custom-table"
                onChange={onTableChange}
                rowKey={(record) => record.id}
                pagination={{
                  current: currentPage,
                  total: filterParticipantsCount,
                  showSizeChanger: false,
                  pageSize: 8,
                  hideOnSinglePage: true,
                  showTotal: (total, range) => {
                    return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
                  },
                  position: ["bottomLeft"],
                }}
              />
            </div>
          </div>
          <UserFilter
            filters={filters}
            updateFilters={updateFilters}
            cancel={cancelFilters}
            handleSave={handleGroupSave}
            handleRemoveFilter={handleRemoveFilter}
            handleGroupChecked={handleGroupChecked}
          />
        </div>
      </div>
    </AppLayout>
  );
};

export default GroupCreate;
