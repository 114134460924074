const GlucoseIcon = ({ width, height }: { width: string; height: string }) => (
  <svg
    width={width || "15"}
    height={height || "24"}
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6963 16.6543H11.2183C11.1385 16.6543 11.062 16.6227 11.0057 16.5663C10.9493 16.5099 10.9176 16.4334 10.9176 16.3536C10.9176 16.2739 10.9493 16.1974 11.0057 16.141C11.062 16.0846 11.1385 16.0529 11.2183 16.0529H12.6963C13.4539 16.0529 14.1805 15.752 14.7163 15.2162C15.252 14.6805 15.553 13.9539 15.553 13.1962V4.51478C15.553 3.75713 15.252 3.03051 14.7163 2.49477C14.1805 1.95903 13.4539 1.65806 12.6963 1.65806H6.0807C5.32305 1.65806 4.59643 1.95903 4.06069 2.49477C3.52495 3.03051 3.22397 3.75713 3.22397 4.51478V13.1962C3.22397 13.9539 3.52495 14.6805 4.06069 15.2162C4.59643 15.752 5.32305 16.0529 6.0807 16.0529H7.58423C7.66399 16.0529 7.74047 16.0846 7.79687 16.141C7.85326 16.1974 7.88494 16.2739 7.88494 16.3536C7.88494 16.4334 7.85326 16.5099 7.79687 16.5663C7.74047 16.6227 7.66399 16.6543 7.58423 16.6543H6.0807C5.16354 16.6543 4.28395 16.29 3.63542 15.6415C2.9869 14.9929 2.62256 14.1134 2.62256 13.1962V4.51478C2.62256 3.59762 2.9869 2.71803 3.63542 2.06951C4.28395 1.42098 5.16354 1.05664 6.0807 1.05664H12.6963C13.6134 1.05664 14.493 1.42098 15.1415 2.06951C15.7901 2.71803 16.1544 3.59762 16.1544 4.51478V13.1962C16.1544 14.1134 15.7901 14.9929 15.1415 15.6415C14.493 16.29 13.6134 16.6543 12.6963 16.6543Z"
      fill="black"
      stroke="black"
      strokeWidth="0.4"
      className="fill-path-stroke"
    />
    <path
      d="M13.2633 11.4805H5.51409C5.43433 11.4805 5.35785 11.4488 5.30145 11.3924C5.24506 11.336 5.21338 11.2596 5.21338 11.1798V3.96282C5.21338 3.88306 5.24506 3.80658 5.30145 3.75018C5.35785 3.69379 5.43433 3.66211 5.51409 3.66211H13.2633C13.3431 3.66211 13.4196 3.69379 13.476 3.75018C13.5323 3.80658 13.564 3.88306 13.564 3.96282V11.1798C13.564 11.2596 13.5323 11.336 13.476 11.3924C13.4196 11.4488 13.3431 11.4805 13.2633 11.4805ZM5.81329 10.8791H12.9626V4.26352H5.81479L5.81329 10.8791Z"
      fill="black"
      stroke="black"
      className="fill-path-stroke"
      strokeWidth="0.4"
    />
    <path
      d="M11.092 20.3021H7.68498C7.60523 20.3021 7.52875 20.2704 7.47235 20.214C7.41596 20.1576 7.38428 20.0812 7.38428 20.0014V14.0624C7.38428 13.9827 7.41596 13.9062 7.47235 13.8498C7.52875 13.7934 7.60523 13.7617 7.68498 13.7617H11.092C11.1718 13.7617 11.2482 13.7934 11.3046 13.8498C11.361 13.9062 11.3927 13.9827 11.3927 14.0624V20.0014C11.3927 20.0812 11.361 20.1576 11.3046 20.214C11.2482 20.2704 11.1718 20.3021 11.092 20.3021ZM7.98419 19.7007H10.7913V14.3631H7.98569L7.98419 19.7007Z"
      fill="black"
      stroke="black"
      className="fill-path-stroke"
      strokeWidth="0.4"
    />
    <path
      d="M9.38865 18.9609C9.22747 18.9609 9.06992 18.9131 8.9359 18.8236C8.80189 18.734 8.69744 18.6067 8.63576 18.4578C8.57408 18.3089 8.55795 18.1451 8.58939 17.987C8.62083 17.8289 8.69845 17.6837 8.81241 17.5697C8.92638 17.4558 9.07159 17.3782 9.22967 17.3467C9.38774 17.3153 9.5516 17.3314 9.7005 17.3931C9.84941 17.4548 9.97668 17.5592 10.0662 17.6932C10.1558 17.8272 10.2036 17.9848 10.2036 18.146C10.2036 18.3621 10.1177 18.5694 9.96488 18.7222C9.81206 18.875 9.60478 18.9609 9.38865 18.9609ZM9.38865 17.934C9.34642 17.934 9.30514 17.9465 9.27003 17.97C9.23492 17.9934 9.20756 18.0268 9.1914 18.0658C9.17524 18.1048 9.17101 18.1477 9.17925 18.1891C9.18749 18.2305 9.20782 18.2686 9.23768 18.2984C9.26754 18.3283 9.30558 18.3486 9.347 18.3569C9.38841 18.3651 9.43134 18.3609 9.47035 18.3447C9.50936 18.3286 9.54271 18.3012 9.56617 18.2661C9.58963 18.231 9.60215 18.1897 9.60215 18.1475C9.60215 18.0909 9.57966 18.0365 9.53962 17.9965C9.49958 17.9565 9.44527 17.934 9.38865 17.934Z"
      fill="black"
      className="fill-path"
    />
    <path
      d="M11.9041 14.3631H6.87322C6.79346 14.3631 6.71698 14.3315 6.66058 14.2751C6.60419 14.2187 6.57251 14.1422 6.57251 14.0624C6.57251 13.9827 6.60419 13.9062 6.66058 13.8498C6.71698 13.7934 6.79346 13.7617 6.87322 13.7617H11.9041C11.9838 13.7617 12.0603 13.7934 12.1167 13.8498C12.1731 13.9062 12.2048 13.9827 12.2048 14.0624C12.2048 14.1422 12.1731 14.2187 12.1167 14.2751C12.0603 14.3315 11.9838 14.3631 11.9041 14.3631Z"
      fill="black"
      stroke="black"
      className="fill-path-stroke"
      strokeWidth="0.4"
    />
    <path
      d="M9.38875 10.2623C8.95781 10.2619 8.54464 10.0905 8.23991 9.78579C7.93519 9.48107 7.76383 9.06789 7.76343 8.63695C7.76343 7.18153 9.08504 5.25399 9.14067 5.17882C9.16759 5.13738 9.20432 5.10322 9.24759 5.07936C9.29086 5.05549 9.33934 5.04266 9.38875 5.04199C9.43631 5.04187 9.48327 5.05265 9.52601 5.0735C9.56876 5.09435 9.60615 5.12472 9.63533 5.16228C9.69247 5.23745 11.0201 7.02215 11.0201 8.63695C11.0199 8.85084 10.9775 9.0626 10.8954 9.26009C10.8133 9.45759 10.693 9.63695 10.5415 9.78792C10.39 9.93888 10.2101 10.0585 10.0124 10.1399C9.81455 10.2213 9.60264 10.2629 9.38875 10.2623ZM9.39627 5.88999C9.0279 6.4914 8.36484 7.71679 8.36484 8.63846C8.36484 8.91001 8.47272 9.17045 8.66474 9.36247C8.85676 9.55449 9.11719 9.66236 9.38875 9.66236C9.66031 9.66236 9.92075 9.55449 10.1128 9.36247C10.3048 9.17045 10.4127 8.91001 10.4127 8.63846C10.4127 7.61906 9.76464 6.46885 9.39627 5.88848V5.88999Z"
      fill="black"
      className="fill-path"
    />
  </svg>
);
export default GlucoseIcon;
