import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ForumState {
  searchTerm: string;
  forumDetails: any;
  toggleLoader: boolean;
  participants: any[];
  participantCount: number;
  comments: any[];
  commentsCount: number;
}

const initialState: ForumState = {
  searchTerm: "",
  forumDetails: {
    title: "Forum name",
  },
  toggleLoader: false,
  participants: [],
  participantCount: 0,
  comments: [],
  commentsCount: 0,
};

export const forumSlice = createSlice({
  name: "forum",
  initialState,
  reducers: {
    setForumSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setForumDetails: (state, action: PayloadAction<any>) => {
      state.forumDetails = action.payload;
    },
    setForumToggleLoader: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
    setForumParticipants: (state, action: PayloadAction<any>) => {
      state.participantCount = action.payload.count;
      state.participants = action.payload.participants;
    },
    setForumComments: (state, action: PayloadAction<any>) => {
      state.commentsCount = action.payload.count;
      state.comments = action.payload.forumComments;
    },
  },
});

export const {
  setForumSearchTerm,
  setForumToggleLoader,
  setForumDetails,
  setForumParticipants,
  setForumComments,
} = forumSlice.actions;

export default forumSlice.reducer;
