import React, { useState, useEffect } from "react";
// import "../../../../pages/css/questions/questionBuilder.scss";
import Button from "../../../components/Common/CustomButton";
import { Modal, Select, Input, DatePicker, Tooltip } from "antd";
import { conditions, textMap } from "./dataList";
import { errorToastMessage } from "../../../helpers/toastMessage";
import moment from "moment";

type Props = {
  show: boolean;
  choice?: any;
  questions?: any;
  index: number;
  previewDisabling?: boolean;
  hideModal: Function;
};

const initState: any = {
  TEXT: {
    condition: "contains",
    variable: {
      type: "text",
      value: "",
    },
    nextQuestionId: "",
  },
  CHOICE: {
    condition: "is",
    variable: {
      type: "choice",
      value: "",
    },
    nextQuestionId: "",
  },
  BOOL: {
    condition: "is",
    variable: {
      type: "text",
      value: "",
    },
    nextQuestionId: "",
  },
  NUMBER: {
    condition: "lt",
    variable: {
      type: "number",
      value: 0,
    },
    nextQuestionId: "",
  },
  DATE: {
    condition: "on",
    variable: {
      type: "date",
      value: "",
    },
    nextQuestionId: "",
  },
};

const alwaysState = {
  condition: "always",
  variable: {
    type: "",
    value: "",
  },
  nextQuestionId: "",
};

const DataValidation = (props: Props) => {
  const [logic, setLogic] = useState<any>([]);

  useEffect(() => {
    console.log("mount");
    if (props.choice.branches.length > 0) {
      setLogic(props.choice.branches);
    }
    return () => {
      console.log("un-mount");
      setLogic([]);
    };
  }, []);

  const addLogicBranch = () => {
    // const newField = {
    // 	...props.choice,
    // 	branches: [...props.choice.branches, initState[props.choice.branchType]]
    // };
    // props.setInnerFields(props.index, newField);
    setLogic([...logic, initState[props.choice.branchType]]);
  };

  const removeLogicBranch = (index: number) => {
    // const newField = {
    // 	...props.choice,
    // 	branches: props.choice.branches.filter((_: any, i: number) => i !== index)
    // };
    // props.setInnerFields(props.index, newField);

    const newLogic = logic.filter((_: any, i: number) => i !== index);
    setLogic(newLogic);
  };

  const handleValue = (val: any, idx: number) => {
    // const newField = {
    // 	...props.choice,
    // 	branches: props.choice.branches.map((branch: any, i: number) =>
    // 		i === idx
    // 			? { ...branch, variable: { ...branch.variable, [name]: val } }
    // 			: branch
    // 	)
    // };
    // props.setInnerFields(props.index, newField);

    const newField = logic.map((branch: any, i: number) =>
      i === idx
        ? {
            ...branch,
            variable: {
              ...branch.variable,
              value: props.choice.type === "number" ? Number(val) : val,
            },
          }
        : branch
    );
    setLogic(newField);
  };

  const handleChange = (name: string, val: string, idx: number) => {
    // const newField = {
    // 	...props.choice,
    // 	branches: props.choice.branches.map((branch: any, i: number) =>
    // 		i === idx ? { ...branch, [name]: val } : branch
    // 	)
    // };
    // props.setInnerFields(props.index, newField);
    const newField = logic.map((branch: any, i: number) =>
      i === idx ? { ...branch, [name]: val } : branch
    );
    setLogic(newField);
  };

  const handleDefJump = (val: string) => {
    // const newItem = {
    // 	...props.choice,
    // 	branches: [
    // 		...props.choice.branches,
    // 		{
    // 			condition: 'always',
    // 			nextQuestionId: val
    // 		}
    // 	]
    // };
    // props.setInnerFields(props.index, newItem);
    setLogic([...logic, { ...alwaysState, nextQuestionId: val }]);
  };

  const validateLogic = () => {
    // if (!logic.length) {
    //   throw new Error("Add atleast one logic!");
    // }

    logic.forEach((log: any, index: number) => {
      if (log.condition !== "always" && log.variable.value === "") {
        throw new Error(`Value cannot be Empty at Logic ${index + 1}`);
      }

      if (log.nextQuestionId === "") {
        throw new Error(`Select a jump to question at Logic ${index + 1}`);
      }
    });
  };

  const handleSave = () => {
    try {
      //   validateLogic();
      //   const newField = {
      //     ...props.choice,
      //     branches: [...logic],
      //   };
      //   props.setInnerFields(props.index, newField);
      props.hideModal(false);
    } catch (e) {
      errorToastMessage(e as Error);
    }
  };

  const closeModal = () => {
    props.hideModal(false);
  };

  const renderConditionOptions = (type: string) =>
    conditions[type].map((cond: any) => (
      <Select.Option key={cond.label} value={cond.value} className="form-input">
        {cond.label}
      </Select.Option>
    ));

  const renderAnswer = (branch: any, idx: number) => {
    switch (props.choice.branchType) {
      case "TEXT":
        return (
          <Input
            value={branch.variable.value}
            style={{ width: "100%" }}
            className="form-input"
            onChange={(e: any) => handleValue(e.target.value, idx)}
          />
        );
      case "NUMBER":
        return (
          <Input
            type="number"
            value={branch.variable.value}
            style={{ width: "100%" }}
            onChange={(e: any) => handleValue(e.target.value, idx)}
          />
        );
      case "DATE":
        return (
          <DatePicker
            inputReadOnly
            value={
              branch.variable.value !== ""
                ? moment(branch.variable.value)
                : null
            }
            style={{ width: "100%" }}
            onChange={(date: any) => {
              const dateString = date.format("YYYY-MM-DD");
              handleValue(dateString, idx);
            }}
            // disabledDate={(current) => {
            //   let customDate = new Date();
            //   customDate.setDate(customDate.getDate() - 1);
            //   return current < moment(customDate, "DD/MM/YYYY");
            // }}
          />
        );
      case "BOOL":
        return (
          <Select
            style={{ width: "100%" }}
            value={branch.variable.value}
            onChange={(val) => handleValue(val, idx)}
          >
            <Select.Option value="" className="d-none">
              Select Yes/No
            </Select.Option>
            <Select.Option value="yes">Yes</Select.Option>
            <Select.Option value="no">No</Select.Option>
          </Select>
        );
      default:
        return (
          <Select
            style={{ width: "100%" }}
            value={branch.variable.value}
            onChange={(val) => handleValue(val, idx)}
          >
            <Select.Option value="" className="d-none">
              {`Select ${textMap[props.choice.type].text}`}
            </Select.Option>
            {props.choice.fields.map((field: any) => (
              <Select.Option
                key={field.id}
                value={field.id}
                style={{ pointerEvents: field.label !== "" ? "auto" : "none" }}
              >
                {field.label !== "" ? field.label : "No Values"}
              </Select.Option>
            ))}
          </Select>
        );
    }
  };
  const questionTitle = (question: any, idx: number, grpIdx?: number) => {
    switch (question.branchType) {
      case "CHOICE":
        return grpIdx ? (
          <Tooltip
            color="orange"
            placement="rightTop"
            title={`${grpIdx}.${idx + 1}. ${question.title}`}
          >
            <p>{`${grpIdx}.${idx + 1}. ${question.title}`}</p>
          </Tooltip>
        ) : (
          <Tooltip
            color="orange"
            placement="rightTop"
            title={`${idx + 1}. ${question.title}`}
          >
            <p>{`${idx + 1}. ${question.title}`}</p>
          </Tooltip>
        );
      default:
        return grpIdx ? (
          <Tooltip
            color="orange"
            placement="rightTop"
            title={`${grpIdx}.${idx + 1}. ${question.fields[0].value}`}
          >
            <p>{`${grpIdx}.${idx + 1}. ${question.fields[0].value}`}</p>
          </Tooltip>
        ) : (
          <Tooltip
            color="orange"
            placement="rightTop"
            title={`${idx + 1}. ${question.fields[0].value}`}
          >
            <p>{`${idx + 1}. ${question.fields[0].value}`}</p>
          </Tooltip>
        );
    }
  };
  const renderQuestions = (questions: any) =>
    questions.map((question: any, index: number) =>
      question.id !== props.choice.id ? (
        question.type !== "group" ? (
          <Select.Option key={question.id} value={question.id}>
            {questionTitle(question, index)}
          </Select.Option>
        ) : (
          question.questions.map((nestedQuestion: any, nestedIdx: number) =>
            nestedQuestion.id !== props.choice.id ? (
              <Select.Option key={nestedQuestion.id} value={nestedQuestion.id}>
                {questionTitle(nestedQuestion, nestedIdx, index + 1)}
              </Select.Option>
            ) : null
          )
        )
      ) : null
    );

  const renderBranch = (branch: any, idx: number) => {
    return (
      <div
        key={idx}
        className={
          props.previewDisabling
            ? "q-card logic-card disabled-div-qb"
            : "q-card logic-card"
        }
      >
        <div className="row m-0">
          <div className="col-6">
            <div className="d-flex flex-column">
              <span className="form-label">If condition</span>
              <Select
                style={{ width: "100%" }}
                value={branch.condition}
                onChange={(val: string) => handleChange("condition", val, idx)}
              >
                {renderConditionOptions(props.choice.branchType)}
              </Select>
            </div>
          </div>
          <div className="col-6 p-0 pl-2">
            <div className="d-flex flex-column">
              <span className="form-label">the value</span>
              {renderAnswer(branch, idx)}
            </div>
          </div>
        </div>

        <hr />

        <div className="d-flex justify-content-between align-items-center">
          <div style={styles.first}>
            <span className="logic-text me-3">Then Show a</span>
            <Select style={{ width: "250px", marginLeft: "auto" }}>
              <Select.Option value="Error" key="Error">
                Error
              </Select.Option>
              <Select.Option value="Exclusion" key="Exclusion">
                Exclusion
              </Select.Option>
              <Select.Option value="Warning" key="Warning">
                Warning
              </Select.Option>
              <Select.Option value="Message" key="Message">
                Message
              </Select.Option>
            </Select>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div style={styles.first}>
            <span className="logic-text me-3">Message</span>
            <Input style={{ width: "250px", marginLeft: "auto" }} />
          </div>
        </div>
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <i
            className="delete-green-icon cp"
            onClick={() => removeLogicBranch(idx)}
          ></i>
        </div>
      </div>
    );
  };

  if (props.choice.type === "group") {
    return null;
  }

  // console.log(props.questions.filter((item) => item.type !== 'group'), props.choice, "branchLogicQ");
  return (
    <Modal
      title="Data Validation"
      visible={props.show}
      style={{ top: 20 }}
      onCancel={closeModal}
      footer={null}
    >
      <div className="logic-jump">
        {Array.isArray(logic) && logic.length ? (
          <div>
            <div
              className={
                props.previewDisabling
                  ? "d-none"
                  : "d-flex justify-content-between align-items-center mb-2"
              }
            >
              <Button
                primary={false}
                buttonText="+ Add validation"
                clickHandler={addLogicBranch}
                className="add-jump"
              />
            </div>
            <div className="cards-logic styled-scrollbar">
              {logic.map((branch: any, idx: number) => {
                if (branch.condition !== "always") {
                  return renderBranch(branch, idx);
                }
              })}
            </div>
          </div>
        ) : (
          <div>
            {/* <h3 className="font-m mb-3">
              Show different questions based on people's answers
            </h3> */}
            <Button
              primary={false}
              buttonText="Add Data Validation"
              clickHandler={addLogicBranch}
              className="add-jump"
            />
          </div>
        )}
        {!props.previewDisabling && (
          <div className="pt-3 text-end">
            <Button
              buttonText="Save"
              className="save-btn btn-success"
              clickHandler={handleSave}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const styles = {
  menu: {
    width: "30rem",
    padding: "1rem",
    borderRadius: ".25rem",
    boxShadow: "0 0 .5rem rgba(0,0,0,.2)",
  },
  card: {
    background: "#f5f5f5",
    padding: "1rem",
    borderRadius: ".25rem",
  },
  decision: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
  },
  first: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  then: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default DataValidation;
