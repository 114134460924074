import { Doughnut } from "react-chartjs-2";
import React from "react";

const colors = ["#37C698", "#5E81F3", "#8587F9", "#39B8CB", "#ABEBFC"];
const participantData = {
  labels: ["18-24", "25-34", "36-44", "45-54", "55 and over"],
  datasets: [
    {
      data: [826, 453, 883, 423, 994],
      backgroundColor: colors,
      hoverOffset: 4,
    },
  ],
};

const options = {
  maintainAspectRatio: false,
  responsive: true,
  cutout: "95%",
  spacing: 20,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const ParticipantDonutChart = React.memo(() => {
  return (
    <div className="particpant-chart-container">
      <div className="chart-wrapper">
        <Doughnut data={participantData} options={options} height={300} />
        <div className="chart-center-text">
          <div className="value">34</div>
          <div className="label">Average age</div>
        </div>
      </div>
      <div>
        <div className="donut-chart-legend">
          <div className="point" style={{ backgroundColor: colors[0] }}></div>
          <div className="label">18-24</div>
          <div className="value">5%</div>
        </div>
        <div className="donut-chart-legend">
          <div className="point" style={{ backgroundColor: colors[1] }}></div>
          <div className="label">25-34</div>
          <div className="value">7%</div>
        </div>
        <div className="donut-chart-legend">
          <div className="point" style={{ backgroundColor: colors[2] }}></div>
          <div className="label">36-44</div>
          <div className="value">44%</div>
        </div>
        <div className="donut-chart-legend">
          <div className="point" style={{ backgroundColor: colors[3] }}></div>
          <div className="label">45-54</div>
          <div className="value">22%</div>
        </div>
        <div className="donut-chart-legend">
          <div className="point" style={{ backgroundColor: colors[4] }}></div>
          <div className="label">55 and over</div>
          <div className="value">22%</div>
        </div>
      </div>
    </div>
  );
});

export default ParticipantDonutChart;
