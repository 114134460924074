import { AxiosResponse } from "axios";
import history from "../../history";
import http from "../../http";
import { loginForm } from "../../types";
import { setProfileDetails, setUserInfo } from "../reducers/userSlice";
import { AppThunk } from "../store";
import { toastMessage, errorToastMessage } from "../../helpers/toastMessage";
import { setAppLoader } from "../reducers/loaderSlice";

export const loginAction =
  (form: loginForm): AppThunk =>
  async (dispatch) => {
    const res: AxiosResponse = await http.post("/auth/login", form);
    const response = res.data.data;
    localStorage.setItem("singer-accessToken", response.token);
    localStorage.setItem("userId", response.id);
    localStorage.setItem("role", response.role);
    localStorage.setItem("phone", response.phoneNumber);
    localStorage.setItem(
      "fullName",
      `${response.firstName} ${response.lastName}`
    );
    const img = response.profileImage !== null ? response.profileImage : "";
    localStorage.setItem("profileImage", img);
    dispatch(setProfileDetails(response));
    // toastMessage("success", res.data.message);
    history.push("/dashboard");
  };

export const otpVerifyAction =
  (data: any): AppThunk =>
  async (dispatch) => {
    await http.post("/auth/signup/verify_otp", data);
    toastMessage("success", "OTP has Verified Successfully!! Please Login");
    history.push("/auth/login");
  };

export const resendOtp =
  (data: any): AppThunk =>
  async (dispatch) => {
    const res: AxiosResponse = await http.post("auth/signup/resend_otp", data);
    toastMessage("success", res.data.message);
  };

export const setYourPassword =
  (data: any): AppThunk =>
  async (dispatch) => {
    const res: AxiosResponse = await http.put(`/auth/set_password`, data);
    toastMessage("success", res.data.message);
    history.push("/auth/login");
  };

export const resetPassword =
  (data: any): AppThunk =>
  async (dispatch) => {
    const res: AxiosResponse = await http.put(
      "/auth/forgot_password/reset",
      data
    );
    toastMessage("success", res.data.message);
    history.push("/auth/login");
  };

export const authLogout =
  (body?: any): AppThunk =>
  async (dispatch) => {
    await http.post("/auth/logout", body);
    localStorage.clear();
    history.push("/auth/login");
  };

export const forgotPassword =
  (data: any): AppThunk =>
  async (dispatch) => {
    const res: AxiosResponse = await http.post("/auth/forgot_password", data);
    toastMessage("success", res.data.message);
  };

export const verifyEmail =
  (body: any): AppThunk =>
  async (dispatch) => {
    await http.post("/auth/verify_email", body);
    setTimeout(() => {
      history.push("/auth/login");
    }, 5000);
  };

export const sleep = async (ms: number) => {
  await new Promise((res: Function, rej) => {
    setTimeout(() => {
      res();
    }, ms);
  });
};

export const changePassword =
  (data: any): AppThunk =>
  async (dispatch) => {
    const res: AxiosResponse = await http.put("/auth/change_password", data);
    toastMessage("success", res.data.message);
    history.push("/auth/login");
  };

export const getUserInfo =
  (id: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res: AxiosResponse = await http.get(`/users/${id}`);
      dispatch(setUserInfo(res.data.data));
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
      history.push("/dashboard");
    }
  };

export const editProfile =
  (userId: any, body: any): AppThunk =>
  async (dispatch) => {
    try {
      const res: AxiosResponse = await http.put(`/users/${userId}`, body);
      dispatch(getUserInfo(userId));
      localStorage.setItem(
        "fullName",
        `${res.data.data.firstName} ${res.data.data.lastName}`
      );
      localStorage.setItem("profileImage", res.data.data.profileImage);
      history.push("/dashboard");
      toastMessage("success", res.data.message);
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };
