import { ReactNode } from "react";
import Sidebar from "./Sidebar";
import { Layout } from "antd";
const { Content, Sider } = Layout;

type Props = {
  themelight?: boolean;
  children: ReactNode;
};
const AppLayout = (props: Props) => {
  // console.log(props.themelight, "Themelight");
  return (
    <>
      <Layout
        className={`singer-layout 
        ${props.themelight ? "theme-white" : ""}`}
      >
        <Sider
          breakpoint="md"
          collapsedWidth="0"
          width="95"
          id="custom-scrollbar"
          style={{
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <Sidebar />
        </Sider>
        <Layout>
          <Content className="content right-panel">{props.children}</Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AppLayout;
