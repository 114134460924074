import React, { useState } from "react";
import { Tabs } from "antd";
import AppLayout from "../../components/AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import ParticpantHeader from "./ParticipantHeader";
import ParticipantListView from "./ParticipantListView";
import ParticipantGroups from "./ParticipantGroups";

const { TabPane } = Tabs;

const Participant: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>(
    sessionStorage.getItem("participant-view-tab") || "active"
  );

  const tabChangeHandler = (key: string) => {
    setSelectedTab(key);
    sessionStorage.setItem("participant-view-tab", key);
  };

  return (
    <AppLayout>
      <Navigation />
      <ParticpantHeader type={selectedTab} />
      <Tabs
        activeKey={selectedTab}
        onChange={tabChangeHandler}
        destroyInactiveTabPane
        className="singer-cutsom-tab"
      >
        <TabPane tab="Active Users" key="active">
          <ParticipantListView type="active" />
        </TabPane>
        <TabPane tab="Archived Users" key="inactive">
          <ParticipantListView type="inactive" />
        </TabPane>
        <TabPane tab="User Groups" key="groups">
          <ParticipantGroups />
        </TabPane>
      </Tabs>
    </AppLayout>
  );
};

export default Participant;
