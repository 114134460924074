import { Table, Avatar, Dropdown, Menu } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { errorToastMessage } from "../../helpers/toastMessage";
import { getImageInitials } from "../../helpers/user";
import {
  deleteForumParticipants,
  fetchForumParticipants,
} from "../../redux/actions/forumAction";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";

const ForumParticipants = ({ id }: any) => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { searchTerm, toggleLoader, participantCount, participants } =
    useAppSelector((state) => state.forum);
  const toggleRef = useRef(toggleLoader);

  const fetchParticipantData = useCallback(
    async (id, searchTerm, page) => {
      try {
        await dispatch(fetchForumParticipants(id, searchTerm, page));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    fetchParticipantData(id, searchTerm, 1);
  }, [searchTerm, id, fetchParticipantData]);

  useEffect(() => {
    if (toggleLoader !== toggleRef.current) {
      fetchParticipantData(id, searchTerm, currentPage);
      toggleRef.current = toggleLoader;
    }
  }, [searchTerm, id, fetchParticipantData, toggleLoader, currentPage]);

  const onTableChange = async (pagination: any, filters: any, sorters: any) => {
    let page = 1;
    if (pagination.current) {
      setCurrentPage(pagination.current);
      page = pagination.current;
    }
    try {
      await dispatch(fetchForumParticipants(id, searchTerm, page));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const deleteHandler = useCallback(
    (patientId) => {
      dispatch(deleteForumParticipants(id, patientId));
    },
    [id, dispatch]
  );

  const columns = useMemo(() => {
    const menu = (data: any) => (
      <Menu className="action-dropdown">
        <Menu.Item key="0" onClick={() => deleteHandler(data.id)}>
          Remove User
        </Menu.Item>
      </Menu>
    );
    return [
      {
        title: "User",
        dataIndex: "name",
        key: "name",
        render: (_1: any, record: any) => {
          return (
            <div className="d-flex">
              {record.profileImage ? (
                <Avatar shape="circle" size="large" src={record.profileImage} />
              ) : (
                <Avatar shape="circle" size="large">
                  {getImageInitials(record.firstName, record.lastName)}
                </Avatar>
              )}
              <div className="ms-3">
                <p className="text-s m-0 fw-medium">
                  {record.firstName + " " + record.lastName}
                </p>
                <span className="text-muted">{record.email}</span>
              </div>
            </div>
          );
        },
      },
      {
        title: "Contact",
        dataIndex: "phoneNumber",
        key: "mobile",
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (text: string) => (
          <span
            className={
              text === "active" ? "active-tag badge" : "inactive-tag badge"
            }
          >
            {text}
          </span>
        ),
      },
      {
        title: "",
        width: "10%",
        className: "text-end",
        render: (_1: any, record: any) => {
          return (
            <span
              onClick={(e) => e.stopPropagation()}
              className="d-inline-block"
            >
              <Dropdown
                overlay={menu(record)}
                placement="bottomRight"
                trigger={["click"]}
              >
                <i className="more-icon cp"></i>
              </Dropdown>
            </span>
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <Table
        dataSource={participants}
        columns={columns}
        className="singer-custom-table"
        onChange={onTableChange}
        rowKey={(record) => record.id}
        pagination={{
          current: currentPage,
          total: participantCount,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
          },
          position: ["bottomLeft"],
        }}
      />
    </>
  );
};

export default ForumParticipants;
