import { Bar } from "react-chartjs-2";
import AppLayout from "../../AppLayout/AppLayout";
import Navigation from "../../Navigation/Navigation";

const chartOptions = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 12,
            lineHeight: "18px",
          };
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: function (context: any) {
          return {
            color: "#333333",
            family: "poppinsregular",
            size: 12,
            lineHeight: "18px",
          };
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        padding: 15,
        font: function (context: any) {
          return {
            color: "#4F4F4F",
            family: "poppinsregular",
            size: 14,
            lineHeight: "22px",
          };
        },
      },
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const graphValues1 = [
  {
    label: "Nov 10",
    value: 65,
    borderColor: "#52C41A",
    backgroundColor: "#52C41A",
  },
  {
    label: "Nov 11",
    value: 22,
    borderColor: "#52C41A",
    backgroundColor: "#52C41A",
  },
  {
    label: "Nov 12",
    value: 72,
    borderColor: "#52C41A",
    backgroundColor: "#52C41A",
  },
  {
    label: "Nov 13",
    value: 50,
    borderColor: "#52C41A",
    backgroundColor: "#52C41A",
  },
  {
    label: "Nov 14",
    value: 30,
    borderColor: "#52C41A",
    backgroundColor: "#52C41A",
  },
  {
    label: "Nov 15",
    value: 57,
    borderColor: "#52C41A",
    backgroundColor: "#52C41A",
  },
  {
    label: "Nov 16",
    value: 67,
    borderColor: "#52C41A",
    backgroundColor: "#52C41A",
  },
];

const graphValues2 = [
  {
    label: "Nov 10",
    value: 15,
    borderColor: "#00B9FC",
    backgroundColor: "#00B9FC",
  },
  {
    label: "Nov 11",
    value: 11,
    borderColor: "#00B9FC",
    backgroundColor: "#00B9FC",
  },
  {
    label: "Nov 12",
    value: 40,
    borderColor: "#00B9FC",
    backgroundColor: "#00B9FC",
  },
  {
    label: "Nov 13",
    value: 22,
    borderColor: "#00b9fc",
    backgroundColor: "#00b9fc",
  },
  {
    label: "Nov 14",
    value: 59,
    borderColor: "#00b9fc",
    backgroundColor: "#00b9fc",
  },
  {
    label: "Nov 15",
    value: 59,
    borderColor: "#00b9fc",
    backgroundColor: "#00b9fc",
  },
  {
    label: "Nov 16",
    value: 60,
    borderColor: "#00b9fc",
    backgroundColor: "#00b9fc",
  },
];

const graphValues3 = [
  {
    label: "Nov 10",
    value: 14,
    borderColor: "#722ED1",
    backgroundColor: "#722ED1",
  },
  {
    label: "Nov 11",
    value: 22,
    borderColor: "#722ED1",
    backgroundColor: "#722ED1",
  },
  {
    label: "Nov 12",
    value: 47,
    borderColor: "#722ED1",
    backgroundColor: "#722ED1",
  },
  {
    label: "Nov 13",
    value: 50,
    borderColor: "#722ED1",
    backgroundColor: "#722ED1",
  },
  {
    label: "Nov 14",
    value: 69,
    borderColor: "#722ED1",
    backgroundColor: "#722ED1",
  },
  {
    label: "Nov 15",
    value: 57,
    borderColor: "#722ED1",
    backgroundColor: "#722ED1",
  },
  {
    label: "Nov 16",
    value: 54,
    borderColor: "#722ED1",
    backgroundColor: "#722ED1",
  },
];

const graphValues4 = [
  {
    label: "Nov 10",
    value: 77,
    borderColor: "#FFA940",
    backgroundColor: "#FFA940",
  },
  {
    label: "Nov 11",
    value: 22,
    borderColor: "#FFA940",
    backgroundColor: "#FFA940",
  },
  {
    label: "Nov 12",
    value: 48,
    borderColor: "#FFA940",
    backgroundColor: "#FFA940",
  },
  {
    label: "Nov 13",
    value: 52,
    borderColor: "#FFA940",
    backgroundColor: "#FFA940",
  },
  {
    label: "Nov 14",
    value: 59,
    borderColor: "#FFA940",
    backgroundColor: "#FFA940",
  },
  {
    label: "Nov 15",
    value: 47,
    borderColor: "#FFA940",
    backgroundColor: "#FFA940",
  },
  {
    label: "Nov 16",
    value: 67,
    borderColor: "#FFA940",
    backgroundColor: "#FFA940",
  },
];

const chartData = {
  labels: graphValues1.map((entry) => {
    return entry.label;
  }),
  datasets: [
    {
      label: "Alert 1",
      data: graphValues1.map((entry) => entry.value),
      backgroundColor: graphValues1.map((entry) => entry.backgroundColor),
      borderColor: graphValues1.map((entry) => entry.borderColor),
      borderRadius: 20,
      barThickness: 20,
    },
    {
      label: "Alert 2",
      data: graphValues2.map((entry) => entry.value),
      backgroundColor: graphValues2.map((entry) => entry.backgroundColor),
      borderColor: graphValues2.map((entry) => entry.borderColor),
      borderRadius: 20,
      barThickness: 20,
    },
    {
      label: "Alert 3",
      data: graphValues3.map((entry) => entry.value),
      backgroundColor: graphValues3.map((entry) => entry.backgroundColor),
      borderColor: graphValues3.map((entry) => entry.borderColor),
      borderRadius: 20,
      barThickness: 20,
    },
    {
      label: "Alert 4",
      data: graphValues4.map((entry) => entry.value),
      backgroundColor: graphValues4.map((entry) => entry.backgroundColor),
      borderColor: graphValues4.map((entry) => entry.borderColor),
      borderRadius: 20,
      barThickness: 20,
    },
  ],
};

const AlertAnalytics = () => {
  return (
    <AppLayout>
      <Navigation />
      <h2 className="font-l mb-4 fw-bold">Alert Analytics</h2>
      <div className="alert-analytics-wrapper" style={{ height: "600px" }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </AppLayout>
  );
};

export default AlertAnalytics;
