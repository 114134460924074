import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Upload, Tooltip } from "antd";
import Navigation from "../../Navigation/Navigation";
import SearchInput from "../../Common/SearchInput";
import { connect } from "react-redux";
import { setModalDetails } from "../../../redux/reducers/modalSlice";

import {
  getQuestionList,
  deleteQuestionModule,
  searchQuestionModules,
  setQuestionnaire,
  questionnaireDuplicate,
  activateDeactivateQuestionnaire,
} from "../../../redux/actions/questionAction";

import {
  exportQuestionnaire,
  importQuestionnaire,
} from "../../../redux/actions/importAction";

import UserSort from "../../Common/UserSort";
import Button from "../../Common/CustomButton";

import { NavLink } from "react-router-dom";
import { toastMessage } from "../../../helpers/toastMessage";
import AppLayout from "../../AppLayout/AppLayout";

type Props = {
  setModalDetails: Function;
  getQuestionList: Function;
  deleteQuestionModule: Function;
  searchQuestionModules: Function;
  questionnaireDuplicate: Function;
  importQuestionnaire: Function;
  exportQuestionnaire: Function;
  activateDeactivateQuestionnaire: Function;
  questions: any;
  count: number;
  setQuestionnaire: Function;
};

const QuestionList = (props: Props) => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("Filter");
  const [showDemographic, setShowDemographic] = useState(true);

  const { getQuestionList } = props;

  useEffect(() => {
    getQuestionList(1);
  }, [getQuestionList]);

  useEffect(() => {
    if (search.trim()) {
      if ("demographic questionnaire".includes(search.toLowerCase())) {
        setShowDemographic(true);
      } else {
        setShowDemographic(false);
      }
    } else {
      setShowDemographic(true);
    }
  }, [search, setShowDemographic]);

  const handlePageChange = () => {
    setPage(1);
    setHasMore(true);
  };

  const checkStorage = () => {
    if (typeof window !== "undefined") {
      return localStorage.getItem("role");
    }
  };

  const menu = (data: any) =>
    data.status !== "active" ? (
      <Menu style={{ width: "120px" }}>
        <Menu.Item
          key="0"
          onClick={() =>
            props.setModalDetails({
              type: "EDIT_QUESTIONS",
              modalProps: {
                show: true,
                questionData: data,
                handlePageChange,
              },
            })
          }
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => {
            if (data.questionCount > 0) {
              props.exportQuestionnaire(data.id);
            } else {
              toastMessage("warning", "Empty Questionnaire cannot be exported");
            }
          }}
        >
          Export
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={() => {
            props.questionnaireDuplicate(data.id);
            handlePageChange();
          }}
        >
          Duplicate
        </Menu.Item>
        <Menu.Item
          key="5"
          onClick={() => {
            props.deleteQuestionModule(data.id);
            handlePageChange();
          }}
        >
          Delete
        </Menu.Item>
      </Menu>
    ) : (
      <Menu style={{ width: "120px" }}>
        <Menu.Item
          key="0"
          onClick={() =>
            props.setModalDetails({
              type: "EDIT_QUESTIONS",
              modalProps: {
                show: true,
                questionData: data,
                handlePageChange,
              },
            })
          }
        >
          Edit
        </Menu.Item>
        {/* <Menu.Item key="1" disabled>
            Review
          </Menu.Item>
          <Menu.Item key="2" disabled>
            Results
          </Menu.Item> */}
        <Menu.Item
          key="3"
          onClick={() => {
            if (data.questionCount > 0) {
              props.exportQuestionnaire(data.id);
            } else {
              toastMessage("warning", "Empty Questionnaire cannot be exported");
            }
          }}
        >
          Export
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={() => {
            props.questionnaireDuplicate(data.id);
            handlePageChange();
          }}
        >
          Duplicate
        </Menu.Item>
        <Menu.Item
          key="5"
          onClick={() => {
            let body = {
              status: data.status === "archived" ? "active" : "archived",
            };
            props.activateDeactivateQuestionnaire(data.id, body);
            handlePageChange();
          }}
        >
          {data.status === "archived" ? "Activate" : "Archive"}
        </Menu.Item>
      </Menu>
    );

  const statusObj: any = {
    Active: "active",
    Draft: "draft",
    Published: "published",
    Archived: "archived",
  };

  const loadMore = () => {
    if (!hasMore) return;
    let nextPage = Math.ceil(props.count / 6);
    let statusVal = filter === "Filter" ? undefined : statusObj[filter];
    if (page < nextPage) {
      props.getQuestionList(page + 1, statusVal, true);
      setPage((prevPage) => prevPage + 1);
      return;
    } else {
      setHasMore(false);
    }
  };

  const onChange = (value: any) => {
    if (value === "") {
      if (filter === "Filter") {
        props.getQuestionList(1);
      } else {
        props.getQuestionList(1, statusObj[filter]);
      }
    } else {
      let resultValue = value;
      setSearch(resultValue);
      if (filter === "Filter") {
        props.searchQuestionModules(resultValue);
      } else {
        props.searchQuestionModules(resultValue, statusObj[filter]);
      }
    }
  };

  const onFilter = (val: any) => {
    let capWord = val.charAt(0).toUpperCase() + val.slice(1);
    setFilter(capWord);
    props.getQuestionList(1, val);
    setHasMore(true);
  };

  return (
    <AppLayout>
      <Navigation />
      <div className="content-height ">
        <h3 className="font-l fw-bold mb-4">Questionnaires</h3>
        <div className="">
          <div className="row">
            <div className="col-xl-8 col-12 mb-4 search-education-block d-flex align-items-center">
              <h3 className="font-m mb-0 me-3">
                All <span>{props.count}</span> Questionnaires
              </h3>
              <div className="search searchInput-mini me-3">
                <SearchInput onChange={onChange} placeholder="Search..." />
              </div>
              <div className="filter ms-3">
                <UserSort
                  value={filter}
                  onChange={onFilter}
                  options={[
                    { key: 1, title: "Active", value: "active" },
                    { key: 2, title: "Draft", value: "draft" },
                    { key: 3, title: "Published", value: "published" },
                    { key: 4, title: "Archived", value: "archived" },
                  ]}
                />
              </div>
            </div>
            <div className="col-xl-4 col-12 mb-4 justify-content-md-end d-flex align-items-center">
              <div className="search-block">
                <div className="search-block-search">
                  <div className="search"></div>
                </div>
                <div className="search-block-import d-flex">
                  {checkStorage() !== "provider" && (
                    <>
                      <Tooltip title="Accepted formats: JSON">
                        <Upload
                          showUploadList={false}
                          multiple={true}
                          accept="application/json"
                          beforeUpload={(file) => {
                            const reader = new FileReader();

                            reader.onload = (e: any) => {
                              var result = JSON.parse(
                                JSON.stringify(e.target.result)
                              );
                              props.importQuestionnaire(JSON.parse(result));
                            };
                            reader.readAsText(file);

                            return false;
                          }}
                        >
                          <Button
                            buttonText="Import/Upload"
                            className="me-3 btn-lg"
                          />
                        </Upload>
                      </Tooltip>
                      <Button
                        buttonText="Add Questionnaire"
                        addButton={true}
                        className="btn-lg"
                        clickHandler={() =>
                          props.setModalDetails({
                            type: "ADD_QUESTIONS",
                            modalProps: { show: true, handlePageChange },
                          })
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="question-modules">
          <div className="">
            {/* <div className="header-block">
            <h1>Questionnaires</h1>
            <h6>A listing of questionnaires you have created so far.</h6>
          </div> */}

            <div className="row card-section">
              {props.questions.length > 0 && showDemographic && (
                <div className="col-12 col-md-6 col-lg-4" key={module.id}>
                  <NavLink
                    to={
                      "/question/builder?id=demographic&name=Demographic questionnaire&view=canEdit"
                    }
                    className="card-holder"
                  >
                    <div className="question-card cp">
                      <div className="img-wrapper">
                        <img
                          alt="cover image"
                          src={"/demographicQuestionnaire.jpg"}
                          onError={(ev: any) => {
                            ev.target.src =
                              "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                            ev.target.onerror = null;
                          }}
                        />
                      </div>
                      {/* <p> </p> */}

                      <h6 style={{ marginTop: "45px" }}>
                        Demographic Questionnaire
                      </h6>
                    </div>
                  </NavLink>
                </div>
              )}
              {props.questions.length > 0 ? (
                props.questions.map((module: any) => {
                  const viewType =
                    module.status === "active" ? "onlyView" : "canEdit";
                  const editLink = `/question/builder?id=${module.id}&name=${module.name}&view=${viewType}`;
                  const previewLink = `/question/preview?id=${module.id}&name=${module.name}&view=staticView`;
                  const link =
                    module.status === "active" ? previewLink : editLink;

                  return (
                    <div className="col-12 col-md-6 col-lg-4" key={module.id}>
                      <NavLink to={link} className="card-holder">
                        <div className="question-card cp">
                          <div className="img-wrapper">
                            <img
                              alt="cover"
                              src={
                                module.imageUrl
                                  ? module.imageUrl
                                  : "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E"
                              }
                              onError={(ev: any) => {
                                ev.target.src =
                                  "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E";
                                ev.target.onerror = null;
                              }}
                            />
                            {module.status === "draft" ? (
                              <span className="tag">DRAFT</span>
                            ) : module.status === "archived" ? (
                              <span className="tag">ARCHIVED</span>
                            ) : module.status === "published" ? (
                              <span className="tag">LOCKED</span>
                            ) : null}
                          </div>
                          <p>{module.questionCount} QUESTIONS</p>

                          <h6>{module.name}</h6>
                          <span onClick={(e) => e.stopPropagation()}>
                            <Dropdown
                              overlay={menu(module)}
                              trigger={["click"]}
                            >
                              <i className="more more-icon cp"></i>
                            </Dropdown>
                          </span>
                        </div>
                      </NavLink>
                    </div>
                  );
                })
              ) : search !== "" ? (
                <div className="no-search">
                  <h2>Search Results</h2>
                  <p>{`Your Search - ${search} - did not match any Questionnaries`}</p>
                  <div className="suggestions">
                    <p>Suggestions:</p>
                    <ul style={{ listStyleType: "disc", marginLeft: "1.8rem" }}>
                      <li>Make sure all the keywords are spelled correctly</li>
                      <li>Try different keywords</li>
                      <li>Try more general keywords</li>
                    </ul>
                  </div>
                  <a
                    className="d-flex align-items-center mt-2"
                    onClick={() => {
                      props.getQuestionList(1);
                    }}
                  >
                    <i className="arrow-left-icon me-2"></i>Go back to home page
                  </a>
                </div>
              ) : (
                props.questions.length === 0 && (
                  <div className="no-module-content">
                    <h1 className="font-l fw-bold">
                      No Questionnaires created
                    </h1>
                  </div>
                )
              )}
            </div>

            {!hasMore ? (
              <div className="d-flex align-items-center justify-content-center">
                <h4>No more Questionnaires!</h4>
              </div>
            ) : (
              props.questions.length > 0 && (
                <div className="d-flex align-items-center justify-content-center">
                  <Button
                    buttonText="Load More"
                    buttonType="button"
                    primary={true}
                    clickHandler={() => loadMore()}
                    className="btn-lg px-4 btn-success"
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

const mapStateToProps = (state: any) => ({
  questions: state.question.questions,
  count: state.question.count,
});

export default connect(mapStateToProps, {
  setModalDetails,
  getQuestionList,
  deleteQuestionModule,
  searchQuestionModules,
  setQuestionnaire,
  questionnaireDuplicate,
  activateDeactivateQuestionnaire,
  importQuestionnaire,
  exportQuestionnaire,
})(QuestionList);
