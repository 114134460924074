import { Spin } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";

const colors = [
  "#1890FF",
  "#13C2C2",
  "#52C41A",
  "#FFA940",
  "#F5222D",
  "#722ED1",
];

const options = {
  maintainAspectRatio: false,
  responsive: true,
  cutout: "80%",
  spacing: 20,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      displayColors: false,
    },
  },
};

const OtherAnalytics = () => {
  return (
    <div className="other-analytics-wrapper">
      <DocumentAnalytics />
      <AlertAnalytics />
    </div>
  );
};

const DocumentAnalytics = React.memo(() => {
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isEmpty, setEmpty] = useState(true);
  const [label, setLabel] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        // const res = await http.get(`/dashboard/alerts`);
        // const data = res.data.data;
        await new Promise((res) => setTimeout(res, 2000));
        const data: any = [
          { name: "General Documents", total: 826 },
          { name: "Consent Forms", total: 453 },
          { name: "Patient Lab Results", total: 883 },
          { name: "Medical Prescription", total: 423 },
          { name: "Clinical Record Forms", total: 994 },
          { name: "Clinical Significance", total: 130 },
        ];
        let total = 0;
        data.forEach((entry: any) => {
          total += +entry.total;
        });
        if (data.length === 0) {
          setEmpty(true);
          setTotal(0);
        } else {
          const chartData = {
            labels: data.map((entry: any) => entry.name),
            datasets: [
              {
                data: data.map((entry: any) => entry.total),
                backgroundColor: colors,
                hoverOffset: 4,
              },
            ],
          };
          setLabel(data);
          setData(chartData);
          setEmpty(false);
          setTotal(total);
        }
        setTotal(total);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err);
      }
    };
    fetchDetails();
  }, [setData, setEmpty, setLoading, setLabel, setTotal]);

  return (
    <div className="other-analytics-card">
      <div className="dashboard-title">Document Analytics</div>
      <div className="graph-legend-wrapper">
        {isLoading ? (
          <span className="graph-spinner">
            <Spin />
          </span>
        ) : null}
        {isEmpty ? (
          <span className="empty-note">No Documents found</span>
        ) : (
          <>
            <div className="donut-graph-wrapper">
              <Doughnut data={data} options={options} height={400} />
              <div className="chart-center-text">
                <div className="label">Total Documents</div>
                <div className="value">{total}</div>
              </div>
            </div>
            <div className="donut-label-wrapper">
              {label.map((entry, index) => {
                const colorIndex = index % colors.length;
                return (
                  <div className="donut-chart-legend">
                    <div
                      className="point"
                      style={{ backgroundColor: colors[colorIndex] }}
                    ></div>
                    <div className="label">{entry.name}</div>
                    <div className="value">{entry.total}</div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

const AlertAnalytics = React.memo(() => {
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isEmpty, setEmpty] = useState(true);
  const [label, setLabel] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const res = await http.get(`/dashboard/alerts`);
        const data = res.data.data;
        let total = 0;
        data.forEach((entry: any) => {
          total += +entry.total;
        });
        if (data.length === 0) {
          setEmpty(true);
          setTotal(0);
        } else {
          const chartData = {
            labels: data.map((entry: any) => entry.name),
            datasets: [
              {
                data: data.map((entry: any) => entry.total),
                backgroundColor: colors,
                hoverOffset: 4,
              },
            ],
          };
          setLabel(data);
          setData(chartData);
          setEmpty(false);
          setTotal(total);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err);
      }
    };
    fetchDetails();
  }, [setData, setEmpty, setLoading, setLabel, setTotal]);

  return (
    <div className="other-analytics-card">
      <div className="dashboard-title">Alert Analytics</div>
      <div className="graph-legend-wrapper">
        {isLoading ? (
          <span className="graph-spinner">
            <Spin />
          </span>
        ) : null}
        {isEmpty ? (
          <span className="empty-note">No Alerts found</span>
        ) : (
          <>
            <div className="donut-graph-wrapper">
              <Doughnut data={data} options={options} height={400} />
              <div className="chart-center-text">
                <div className="label">Total Alerts</div>
                <div className="value">{total}</div>
              </div>
            </div>
            <div className="donut-label-wrapper">
              {label.map((entry, index) => {
                const colorIndex = index % colors.length;
                return (
                  <div className="donut-chart-legend">
                    <div
                      className="point"
                      style={{ backgroundColor: colors[colorIndex] }}
                    ></div>
                    <div className="label">{entry.name}</div>
                    <div className="value">{entry.total}</div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default OtherAnalytics;
