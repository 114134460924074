import { Avatar, Modal, Table } from "antd";
import { useMemo } from "react";
import { getImageInitials } from "../../../helpers/user";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";

const AllModeratorDetails = () => {
  const dispatch = useAppDispatch();
  const { moderators, show } = useAppSelector(
    (state) => state.modal.modalProps
  );

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const columns = useMemo(() => {
    return [
      {
        title: "Moderators",
        render: (_1: any, user: any) => {
          return (
            <div className="d-flex">
              {user.profileImage ? (
                <Avatar shape="circle" size="large" src={user.profileImage} />
              ) : (
                <Avatar shape="circle" size="large">
                  {getImageInitials(user.firstName, user.lastName)}
                </Avatar>
              )}
              <div className="ms-3 d-flex align-items-center">
                <p className="text-s m-0 fw-medium">
                  {user.firstName + " " + user.lastName}
                </p>
                {/* <span className="text-muted font-xs">{user.email}</span> */}
              </div>
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <Modal
      title={"Moderators"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
      className="participant-view-modal"
    >
      <Table
        dataSource={moderators}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
};

export default AllModeratorDetails;
