import React, { useEffect, useMemo, useState } from "react";
import { Input, Divider, Select } from "antd";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { debounce } from "lodash";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import CustomButton from "../Common/CustomButton";
import {
  setDocumentSearch,
  setDocumentSorter,
  setNestedDocumentSearch,
} from "../../redux/reducers/documentsSlice";

type Props = {
  documentParentId?: string;
  type?: string;
};

const DocumentsHeader = (props: Props) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>("");
  const { searchTerm, nestedSearchTerm, sortOrder } = useAppSelector(
    (state) => state.documents
  );
  const type = props.type;

  useEffect(() => {
    if (type === "Documents") {
      setSearch(searchTerm);
    } else {
      setSearch(nestedSearchTerm);
    }
  }, [searchTerm, nestedSearchTerm, type]);

  const modifySearchTerm = useMemo(() => {
    return debounce((val) => {
      if (type === "Documents") {
        dispatch(setDocumentSearch(val));
      } else {
        dispatch(setNestedDocumentSearch(val));
      }
    }, 500);
  }, [dispatch, type]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearch(val);
    modifySearchTerm(val);
  };

  const sortHandler = (value: any) => {
    dispatch(setDocumentSorter(value));
  };

  return (
    <div className="user-actions">
      <div className="searchInput-mini header-wrapper mb-4">
        <div className="d-flex align-items-center">
          <h3 className="font-ml fw-bold me-3">Documents</h3>
          <Input
            value={search}
            onChange={changeHandler}
            style={{ width: "300px" }}
            placeholder="Search..."
            className="search-input"
          />
        </div>
        <div className="d-flex align-items-center">
          {/* <label className="text-nowrap mb-0 me-3">Sort by</label> */}
          <span className="document-sort me-3">Sort by</span>
          <Select
            className="document-sort-select"
            size="large"
            value={sortOrder}
            onChange={sortHandler}
          >
            <Select.Option value="createdAt-desc">Newest First</Select.Option>
            <Select.Option value="createdAt-asc">Newest Last</Select.Option>
            <Select.Option value="creator-asc">Creator (A-Z)</Select.Option>
            <Select.Option value="creator-desc">Creator (Z-A)</Select.Option>
            <Select.Option value="name-asc">File Name (A-Z)</Select.Option>
            <Select.Option value="name-desc">File Name (Z-A)</Select.Option>
          </Select>
        </div>
      </div>
      <Divider className="m-0" />
      <div className="d-flex align-items-center justify-content-md-end my-4">
        {props.type !== "Archive" && (
          <>
            <CustomButton
              buttonText="Upload Document"
              className="btn-lg"
              primary={true}
              clickHandler={() =>
                dispatch(
                  setModalDetails({
                    type: "CREATE_DOCUMENT",
                    modalProps: {
                      show: true,
                      title: "Upload Document ",
                      pageUrlId: props.documentParentId,
                    },
                  })
                )
              }
            />
            <CustomButton
              className="btn-outline-primary btn-lg ms-4"
              buttonText="Upload Folder"
              clickHandler={() =>
                dispatch(
                  setModalDetails({
                    type: "CREATE_FOLDER",
                    modalProps: {
                      show: true,
                      title: "Create Folder",
                      pageUrlId: props.documentParentId,
                    },
                  })
                )
              }
            />
          </>
        )}
      </div>
    </div>
  );
};
export default DocumentsHeader;
