import React, { useEffect, useState } from "react";
import DocumentTable from "./DocumentTable";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getDocuments } from "../../redux/actions/documentAction";
import { errorToastMessage } from "../../helpers/toastMessage";

type Props = {};

const DocumentList = (props: Props) => {
  const dispatch = useAppDispatch();
  const { documents, searchTerm, sortOrder } = useAppSelector(
    (state) => state.documents
  );

  useEffect(() => {
    dispatch(getDocuments());
  }, [dispatch, searchTerm, sortOrder]);

  return (
    <>
      <DocumentTable documents={documents} />
    </>
  );
};

export default DocumentList;
