import { Table, Dropdown, Menu } from "antd";
import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  deleteDocumentFile,
  deleteFolderDocument,
  archiveListsFile,
  archiveListsFolder,
  restoreDocumentsFile,
  restoreDocumentsFolder,
} from "../../redux/actions/documentAction";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { Modal } from "antd";
import roles from "../../constants/roles";

type Props = {
  documents: any[];
  setbreadcrumbs?: Function;
  type?: string;
};

const DocumentTable = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const confirm = Modal.confirm;

  const formatDate = (date: string) => {
    let desiredFormat = date.split("T");
    return desiredFormat[0].split("-").reverse().join("/");
  };

  const createBreadcrumb = (data: any) => {
    // console.log("Data.......... ", data);
    if (props.setbreadcrumbs) {
      props.setbreadcrumbs(data);
    }
  };

  const showConfirmMessage = (data: any) => {
    confirm({
      title: "Are you sure?",
      content: "This document will no longer be available.",
      onOk() {
        data.parentFolderId === undefined
          ? dispatch(deleteDocumentFile(data.id, data.folderId))
          : dispatch(deleteFolderDocument(data.id));
      },
      onCancel() {},
      okText: "Delete",
      cancelText: "Cancel",
    });
  };

  const archiveDocument = (data: any) => {
    data.parentFolderId === undefined
      ? dispatch(archiveListsFile(data.id))
      : dispatch(archiveListsFolder(data.id));
  };
  const restoreDocuments = (data: any) => {
    data.parentFolderId === undefined
      ? dispatch(restoreDocumentsFile(data.id))
      : dispatch(restoreDocumentsFolder(data.id));
  };

  const menu = (data: any) => (
    <Menu>
      {props.type !== "archive" ? (
        <>
          <Menu.Item
            key="0"
            onClick={() => {
              data.parentFolderId === undefined
                ? dispatch(
                    setModalDetails({
                      type: "CREATE_DOCUMENT",
                      modalProps: {
                        show: true,
                        title: "Edit Document",
                        documentData: data,
                        pageUrlId: data.id,
                      },
                    })
                  )
                : dispatch(
                    setModalDetails({
                      type: "CREATE_FOLDER",
                      modalProps: {
                        show: true,
                        title: "Edit Folder",
                        documentData: data,
                        pageUrlId: data.parentFolderId,
                      },
                    })
                  );
            }}
          >
            Edit
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              archiveDocument(data);
            }}
          >
            Archive
          </Menu.Item>
        </>
      ) : (
        <Menu.Item
          key="1"
          onClick={() => {
            restoreDocuments(data);
          }}
        >
          Restore
        </Menu.Item>
      )}
      {props.type === "archive" && (
        <Menu.Item
          className="text-danger"
          key="2"
          onClick={() => {
            showConfirmMessage(data);
          }}
        >
          Delete
        </Menu.Item>
      )}
    </Menu>
  );

  const columns: any[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      editable: true,
      render: function render(value: string, row: any): JSX.Element {
        let imgpath;

        if (row.url !== undefined) {
          let imgType = row.url.split(".").pop();
          // console.log("imageType", imgType)
          if (imgType != "pdf") {
            imgpath = "image-icon";
          } else if (imgType == "csv") {
            imgpath = "csv-icon";
          } else {
            imgpath = "pdf-icon";
          }
        } else {
          imgpath = "folder-icon";
        }
        return (
          <>
            {row.url ? (
              <>
                {props.type === "archive" ? (
                  <div className="d-flex align-items-center">
                    <div className={" me-2 " + imgpath}></div>
                    <h6 className="font-sm m-0 fw-semibold">{value}</h6>
                  </div>
                ) : (
                  <div
                    className="d-flex align-items-center cp"
                    onClick={() => {
                      window.open(row.url, "_blank");
                    }}
                  >
                    <div className={" me-2 " + imgpath}></div>
                    <h6 className="font-sm m-0 fw-semibold">{value}</h6>
                  </div>
                )}
              </>
            ) : (
              <>
                {props.type === "archive" ? (
                  <div className="d-flex align-items-center">
                    <div className={" me-2 " + imgpath}></div>
                    <h6 className="font-sm m-0 fw-semibold">{value}</h6>
                  </div>
                ) : (
                  <div
                    className="d-flex align-items-center cp"
                    onClick={() => {
                      history.push(`/documents/${row.id}`);
                      createBreadcrumb(row);
                    }}
                  >
                    <div className={" me-2 " + imgpath}></div>
                    <h6 className="font-sm m-0 fw-semibold">{value}</h6>
                  </div>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "category",
      align: "center",
      render: (value: string) => {
        return <span>{value || "-"}</span>;
      },
    },
    {
      title: "Date Uploaded",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: function render(val: string): JSX.Element {
        return (
          <div className="my-1">
            <p className="m-0">{formatDate(val)}</p>
          </div>
        );
      },
    },
  ];

  if (localStorage.getItem("role") === roles.Admin["be_value"]) {
    columns.push({
      title: "Created By",
      key: "createdBy",
      dataIndex: "createdBy",
      render: (user: any, record: any) => {
        if (user) return <span>{user.firstName + " " + user.lastName}</span>;
        else return null;
      },
    });
  }

  columns.push({
    title: "",
    key: "",
    width: "20px",
    render: (_1: any, record: any) => {
      return (
        <span onClick={(e) => e.stopPropagation()}>
          <Dropdown
            overlay={menu(record)}
            placement="bottomRight"
            trigger={["click"]}
          >
            <i className="more-icon cp"></i>
          </Dropdown>
        </span>
      );
    },
  });

  return (
    <>
      <Table
        dataSource={props.documents}
        columns={columns}
        className="singer-custom-table"
        pagination={false}
        // pagination={{
        //   current: currentPage,
        //   total: docCount,
        //   showSizeChanger: false,
        //   hideOnSinglePage: true,
        //   position: ["bottomLeft"],
        // }}
      />
    </>
  );
};

export default connect(null, {
  deleteDocumentFile,
  deleteFolderDocument,
  archiveListsFile,
  archiveListsFolder,
})(DocumentTable);
