export declare type ROLE = "Therapist" | "Admin";

const roles = {
  Therapist: {
    be_value: "therapist",
    fe_value: "Therapist",
  },
  Admin: {
    be_value: "system_admin",
    fe_value: "Admin",
  },
  Participant: {
    be_value: "patient",
    fe_value: "User",
  },
};

export default roles;
