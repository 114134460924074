import { Progress } from "antd";
import { useAppSelector } from "../../redux/hooks";

const MainCards = () => {
  const { basicMetrics } = useAppSelector((state) => state.dashboard);

  const handlePercentage = (active: number, total: number) => {
    if (active && total) {
      const value = ((active / total) * 100).toFixed(2);
      return value;
    } else {
      return 0;
    }
  };

  const mainDetails = [
    {
      id: "1",
      title: "Active Users",
      no: `${basicMetrics.activeParticipants}/${basicMetrics.totalParticipants}`,
      percent: handlePercentage(
        basicMetrics.activeParticipants,
        basicMetrics.totalParticipants
      ),
      color: "#2F80ED",
    },
    {
      id: "2",
      title: "Active Questionnaire Module",
      no: `${basicMetrics.activeQuestionnaires}/${basicMetrics.totalQuestionnaires}`,
      percent: handlePercentage(
        basicMetrics.activeQuestionnaires,
        basicMetrics.totalQuestionnaires
      ),
      color: "#9B51E0",
    },
    {
      id: "3",
      title: "Active Education modules",
      no: `${basicMetrics.activeEducationLessons}/${basicMetrics.totalEducationLessons}`,
      percent: handlePercentage(
        basicMetrics.activeEducationLessons,
        basicMetrics.totalEducationLessons
      ),
      color: "#F2994A",
    },
    {
      id: "4",
      title: "Active Forums",
      no: `${basicMetrics.activeForums}/${basicMetrics.totalForums}`,
      percent: handlePercentage(
        basicMetrics.activeForums,
        basicMetrics.totalForums
      ),
      color: "#F3724A",
    },
    {
      id: "5",
      title: "Signed in Users",
      no: `${basicMetrics.signedInUsers}/${basicMetrics.totalParticipants}`,
      color: "#6AD1C7",
    },
    {
      id: "6",
      title: "Active Users",
      no: `${basicMetrics.activeParticipants}/${basicMetrics.totalParticipants}`,
      color: "#FF577A",
    },
    {
      id: "7",
      title: "User Tracking Steps",
      no: `${basicMetrics.stepsTrackingUsers}/${basicMetrics.totalParticipants}`,
      color: "#77D6FF",
    },
    {
      id: "8",
      title: "User Tracking Food",
      no: `${basicMetrics.foodTrackingUsers}/${basicMetrics.totalParticipants}`,
      color: "#8688F9",
    },
  ];

  return (
    <div className="dashboard-card-wrapper">
      {mainDetails.map((card) => (
        <div className="dashboard-card" key={card.id}>
          <div
            className="dashboard-top"
            style={{ background: `${card.color}` }}
          ></div>
          <div className="card-details">
            <div className="dashboard-title">{card.title}</div>
            <div className="info">
              <div>{card.no}</div>
              <div>
                {card.percent !== undefined && (
                  <Progress
                    type="circle"
                    percent={+card.percent}
                    format={(e) => `${e}%`}
                    width={80}
                    strokeColor={card.color}
                    trailColor={"#F8F9FB"}
                    strokeWidth={4}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MainCards;
