import React from "react";
import { Input, Select } from "antd";
// import { choices } from "../questions/builder/dataList";
import { choices } from "./educationDataList";

type Props = {
  choice: any;
  index: number;
  delete: Function;
  setInnerFields: Function;
  handleQuestionType: Function;
};

const TextChoiceField = (props: Props) => {
  const { choice, index } = props;

  const addField = (idx: number) => {
    const type = choice.fields[0].type;
    const toBeAdded = { type: type, label: "", code: "" };

    const newField = {
      ...choice,
      fields: [...choice.fields, toBeAdded],
    };

    console.log(idx, "addFieldNew");
    props.setInnerFields(index, newField);
  };

  const removeField = (idx: number) => {
    if (choice.fields.length > 1) {
      const newField = {
        ...choice,
        fields: choice.fields.filter((_: any, i: number) => i !== idx),
      };
      props.setInnerFields(index, newField);
    }
  };

  const handleChange = (idx: number, e: any, outer?: boolean) => {
    if (!outer) {
      const newVal = {
        ...choice,
        fields: choice.fields.map((f: any, i: number) =>
          i === idx ? { ...f, [e.target.name]: e.target.value } : f
        ),
      };
      props.setInnerFields(index, newVal);
    } else {
      const newVal = {
        ...choice,
        title: e.target.value,
      };
      props.setInnerFields(index, newVal);
    }
  };

  const renderFields = (field: any, idx: any) => {
    switch (field.type) {
      case "input":
        return (
          <div className="field-group" key={idx}>
            <Input
              value={field.value}
              name="value"
              onChange={(e) => handleChange(idx, e)}
            />
          </div>
        );
      case "checkbox":
        return (
          <div className="field-group" key={idx}>
            <br />
            <div className="checkboxes">
              <div className="circle"></div>
              <div className="first-input">
                <Input
                  name="label"
                  value={field.label}
                  onChange={(e) => handleChange(idx, e)}
                />
              </div>
              <div className="second-input">
                <Input
                  name="value"
                  value={field.value}
                  onChange={(e) => handleChange(idx, e)}
                  placeholder="Value"
                />
              </div>
              <div className="add ms-3 me-2">
                <i
                  className="add-green-icon cp"
                  onClick={() => addField(idx)}
                ></i>
              </div>
              <div className="remove">
                <i
                  className="delete-green-icon cp"
                  onClick={() => removeField(idx)}
                ></i>
              </div>
            </div>
          </div>
        );
      case "dropdown":
        return (
          <div className="field-group" key={idx}>
            <br />
            <div className="checkboxes">
              <div className="circle"></div>
              <div>
                <Input
                  name="label"
                  value={field.label}
                  onChange={(e) => handleChange(idx, e)}
                />
              </div>
              <div className="add ms-3 me-2">
                <i
                  className="add-green-icon cp"
                  onClick={() => addField(idx)}
                ></i>
              </div>
              <div className="remove">
                <i
                  className="delete-green-icon cp"
                  onClick={() => removeField(idx)}
                ></i>
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="choice-field mb-2">
      <span
        className="remove-question cp"
        onClick={() => props.delete(props.index)}
      >
        +
      </span>
      <div className="choice-field-select">
        <label>Question Type</label>
        <div className="floating-select">
          <Select
            showArrow
            value={
              choice.hasOwnProperty("type") ? choice.type : choice.branchType
            }
            onChange={(val) => props.handleQuestionType(val, props.index)}
          >
            {choices.map((choice: any) => {
              return (
                <Select.Option key={choice.type} value={choice.type}>
                  {choice.title}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </div>

      <h6>
        {index + 1}. {choice.label}
      </h6>
      {choice.type !== "text" && (
        <p>(Optional) Enter further description here</p>
      )}

      {choice && choice.hasOwnProperty("title") && (
        <Input
          value={choice.title}
          name="title"
          className="mb-2"
          onChange={(e) => handleChange(index, e, true)}
        />
      )}

      {choice.type !== "text" && (
        <h6 className="mt-2">{choice.info || "Enter options here"}</h6>
      )}

      {choice.hasOwnProperty("fields") &&
        choice.fields.map((field: any, idx: any) => renderFields(field, idx))}
    </div>
  );
};

export default TextChoiceField;

// {
/* <div className="my-1">
        <label>{`Question ${props.index + 1}`}</label>
        {choice && choice.hasOwnProperty("value") && (
            <>
              <Input
                value={choice.value}
                name="value"
                onChange={(e) => handleChange(index, e, true)}
              />
            </>
          )}
      </div> */
// }
