import React, { useState } from "react";
import { Modal, Select } from "antd";
import { Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as yup from "yup";
import roles from "../../../constants/roles";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { addAdministrator } from "../../../redux/actions/adminAction";
import CustomInput from "../../Common/CustomInput";
import CustomButton from "../../Common/CustomButton";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { debounce } from "lodash";
import http from "../../../http";
import { Spin } from "antd";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
  mobileNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone Number is Required"),
  therapistId: yup.string().required("Please choose a therapist"),
});

const AddPatientModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<any[]>();
  const [fetching, setFetching] = useState<boolean>(false);
  const { show } = useAppSelector((state) => state.modal.modalProps);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    therapistId: undefined,
    role: roles.Participant["be_value"],
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async (values: any) => {
    try {
      let obj = {
        ...values,
        phoneNumber: `+${values.mobileNumber}`,
      };
      delete obj["mobileNumber"];
      await dispatch(addAdministrator(obj));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value: string) => {
      try {
        setOptions([]);
        if (!value) {
          return;
        }
        setFetching(true);
        const res = await http.get(
          `/users?page=1&size=20&role=${roles.Therapist["be_value"]}&search=${value}`
        );
        const users = res.data.data.users.map((user: any) => {
          return {
            label: user.firstName + " " + user.lastName,
            value: user.id,
          };
        });
        setOptions(users);
        setFetching(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setFetching(false);
      }
    };
    return debounce(loadOptions, 500);
  }, [setFetching, setOptions]);

  return (
    <Modal
      title={`Add ${roles.Participant["fe_value"]}`}
      visible={show}
      onCancel={closeHandler}
      closable={true}
      width={"38rem"}
      footer={null}
    >
      <div className="add-project-owner">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            handleBlur,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              {/* email address */}
              <div className="input-field w-100 mb-4">
                <div className="field-name form-label">Email Address*</div>
                <div className="floating-label">
                  <CustomInput
                    name="email"
                    value={values.email}
                    changeEvent={handleChange}
                    blurEvent={handleBlur}
                    className="floating-input form-control"
                  />
                  <div className="input-error text-left mt-1">
                    {errors.email && touched.email && errors.email}
                  </div>
                </div>
              </div>
              {/* first name and last name */}
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="field-name form-label">First Name*</div>
                  <div className="floating-label">
                    <CustomInput
                      name="firstName"
                      value={values.firstName}
                      changeEvent={handleChange}
                      blurEvent={handleBlur}
                      className="floating-input form-control"
                    />
                    <div className="input-error text-left mt-1">
                      {errors.firstName &&
                        touched.firstName &&
                        errors.firstName}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="field-name form-label">Last Name*</div>
                  <div className="floating-label">
                    <CustomInput
                      name="lastName"
                      value={values.lastName}
                      changeEvent={handleChange}
                      blurEvent={handleBlur}
                      className="floating-input form-control"
                    />
                    <div className="input-error text-left mt-1">
                      {errors.lastName && touched.lastName && errors.lastName}
                    </div>
                  </div>
                </div>
              </div>
              {/* mobile and phone number */}
              <div className="row mb-4">
                <div className="col-md-6 input-field">
                  <div className="field-name form-label">Mobile*</div>
                  <div className="floating-label tel-input-sm ">
                    <PhoneInput
                      country={"sg"}
                      // disableDropdown
                      countryCodeEditable={false}
                      value={values.mobileNumber}
                      onChange={(phone) => setFieldValue("mobileNumber", phone)}
                    />
                    <div className="input-error text-left">
                      {errors.mobileNumber &&
                        touched.mobileNumber &&
                        errors.mobileNumber}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-name form-label">Therapist</div>
                  <Select
                    showSearch
                    className="therapist-selector"
                    filterOption={false}
                    onSearch={debounceFetcher}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    options={options}
                    value={values.therapistId}
                    onChange={(val) => {
                      setFieldValue("therapistId", val);
                    }}
                  />
                  <div className="input-error text-left">
                    {errors.therapistId &&
                      touched.therapistId &&
                      errors.therapistId}
                  </div>
                </div>
              </div>
              <div className="text-end">
                <span
                  role="button"
                  className="button-cancel"
                  onClick={closeHandler}
                  tabIndex={0}
                >
                  Cancel
                </span>
                <CustomButton
                  buttonType="submit"
                  buttonText="Save"
                  primary={true}
                  className="btn-success"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddPatientModal;
