import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyC1sVqDB9cZMRuE5e4JWnPvmsmr3oBtb1Y",
  authDomain: "singer-318606.firebaseapp.com",
  projectId: "singer-318606",
  storageBucket: "singer-318606.appspot.com",
  messagingSenderId: "645148256012",
  appId: "1:645148256012:web:4fab9821b1148e78674b29",
  measurementId: "G-GVRR17633H",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export { db };
export default firebase;
