import React, { useState } from "react";
import { Modal, Upload, Tooltip, Input } from "antd";
import { Formik } from "formik";
import { connect } from "react-redux";
import InputField from "../../Common/CustomInput";
import { toastMessage } from "../../../helpers/toastMessage";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { UploadImage } from "../../../helpers/imageUpload";
import {
  addEducationLesson,
  UpdateEducationModule,
} from "../../../redux/actions/educationAction";
import Button from "../../Common/CustomButton";
// import "../../../pages/css/education/education.scss";

let yup = require("yup");

let schema = yup.object().shape({
  name: yup.string().required("Module Name is Required"),
});

const AddEducations = (props: any) => {
  const [imgData, setImgData] = useState({
    expiresAt: "",
    fileName: "",
    postUploadImageUrl: "",
    presignedUploadUrl: "",
    type: "",
  });

  const initialValues = props.educationData
    ? {
        name: props.educationData.title,
        description: props.educationData.description,
        imageUrl: props.educationData.image,
      }
    : {
        name: "",
        description: "",
        imageUrl: "",
      };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      toastMessage("warning", "Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleUpload = (data: any) => {
    setImgData(data[0]);
  };

  const handleAvater = (fileObj: any) => {
    props.UploadImage(fileObj, handleUpload, "education_lesson_image");
  };

  return (
    <Modal
      title="Add Education Lesson"
      visible={props.show}
      onCancel={() => props.hideModal()}
      width={"45rem"}
      footer={null}
    >
      <div className="add-education-wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            values.imageUrl =
              imgData.postUploadImageUrl !== ""
                ? imgData.postUploadImageUrl
                : values.imageUrl;

            if (props.educationData) {
              const data = {
                name: values.name,
                description: values.description,
                imageUrl: values.imageUrl,
                // status: props.questionData.status,
              };
              if (data.imageUrl === "" || !data.imageUrl) {
                delete data.imageUrl;
              }

              if (data.name.trim().length > 0) {
                props.UpdateEducationModule(data, props.educationData.id, true);
              }
            } else {
              if (
                values.description.trim().length === 0 &&
                values.name.trim().length > 0
              ) {
                let obj = {
                  name: values.name,
                  imageUrl: values.imageUrl,
                };
                if (obj.imageUrl === "") {
                  delete obj.imageUrl;
                }
                props.addEducationLesson(obj);
              } else {
                if (values.imageUrl === "") {
                  delete values.imageUrl;
                }
                if (values.name.trim().length > 0) {
                  props.addEducationLesson(values);
                }
              }
            }
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-container mt-0">
                    <div className="form-label">Education Module Name*</div>
                    <div className="floating-label">
                      <InputField
                        name="name"
                        placeholder=""
                        changeEvent={handleChange}
                        className="floating-input"
                        value={values.name}
                        blurEvent={handleBlur}
                      />
                      <div className="input-error">
                        {errors.name && touched.name && errors.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-container mt-0">
                    <label className="form-label">Description</label>
                    <Tooltip placement="right" title={values.description}>
                      <div className="floating-label">
                        <InputField
                          name="description"
                          placeholder=""
                          changeEvent={handleChange}
                          className="floating-input"
                          value={values.description}
                          blurEvent={handleBlur}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>

                <div className="col-md-8 image-row mt-4 form-group">
                  <label htmlFor="lastname">Upload module picture</label>
                  <div className="row">
                    <div className="col-md-5">
                      {imgData.postUploadImageUrl !== "" ? (
                        <div className="image-container">
                          <img src={imgData.postUploadImageUrl} alt="module" />
                        </div>
                      ) : values.imageUrl !== null && values.imageUrl !== "" ? (
                        <>
                          <div className="image-container">
                            <img src={values.imageUrl} alt="module" />
                          </div>
                        </>
                      ) : (
                        <div className="image-wrapper">
                          <div className="placeholder-icon"></div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-7 ps-0 upload-wrapper">
                      <Input
                        disabled
                        placeholder={
                          imgData.fileName === ""
                            ? "No file selected"
                            : imgData.fileName
                        }
                      />
                      <Upload
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        customRequest={handleAvater}
                        accept=".gif,.jpg,.jpeg,.png"
                      >
                        <button type="button" className="upload-button">
                          Upload File
                        </button>
                      </Upload>
                    </div>
                  </div>
                  <div className="mt-3">
                    Upload an image of max 5 mb, accepted formats are
                    .jpg,.jpeg,.png{" "}
                  </div>
                </div>

                <div className="button-container text-end">
                  <span
                    className="button-cancel me-4 cp"
                    onClick={() => props.hideModal()}
                  >
                    Cancel
                  </span>
                  {/* <button className="draft m-2">Save Draft</button> */}
                  <Button
                    primary={true}
                    buttonType="submit"
                    buttonText="Save"
                    className="cp"
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default connect(null, {
  hideModal,
  UploadImage,
  addEducationLesson,
  UpdateEducationModule,
})(AddEducations);
