import { uuid } from "uuidv4";

export const choices = [
  // {
  //   key: '1',
  //   type: 'S',
  //   title: 'Section',
  //   icon: 'section-icon'
  // },
  {
    key: "2",
    type: "multiple_choice",
    title: "MCQ(Single Selection)",
    icon: "multichoice-icon",
  },
  {
    key: "3",
    title: "MCQ(Multiple Selection)",
    type: "checkbox",
    icon: "checkboxes-icon",
  },
  {
    key: "4",
    type: "short_text",
    title: "Short Text",
    icon: "shorttext-icon",
  },
  {
    key: "5",
    title: "Long Text",
    type: "long_text",
    icon: "longtext-icon",
  },
  {
    key: "6",
    title: "Picture Choice",
    type: "picture_choice",
    icon: "picturechoice-icon",
  },
  {
    key: "7",
    title: "Dropdown List",
    type: "dropdown",
    icon: "dropdown-icon",
  },
  {
    key: "8",
    title: "Likert Scale",
    type: "likert_scale",
    icon: "opinionscale-icon",
  },
  {
    key: "9",
    title: "Yes/No",
    type: "yes_no",
    icon: "yesno-icon",
  },
  // {
  //   key: '7',
  //   title: 'Checkboxes',
  //   type: 'checkbox',
  //   icon: 'checkboxes-icon'
  // },
  {
    key: "10",
    title: "Date",
    type: "date",
    icon: "date-icon",
  },
  {
    key: "11",
    title: "Number",
    type: "number",
    icon: "number-icon",
  },
  {
    key: "13",
    title: "Time",
    type: "likert_scale",
    icon: "time-icon",
  },
  {
    key: "14",
    title: "Calculation",
    type: "likert_scale",
    icon: "calculator-icon",
  },
  {
    key: "15",
    title: "QR Code",
    type: "likert_scale",
    icon: "qr-icon",
  },
  {
    key: "16",
    title: "Upload file",
    type: "likert_scale",
    icon: "upload-file-icon",
  },
  {
    key: "17",
    title: "Grid",
    type: "likert_scale",
    icon: "grid-icon",
  },
  {
    key: "18",
    title: "Date & Time",
    type: "likert_scale",
    icon: "datetime-icon",
  },
  {
    key: "19",
    title: "Link",
    type: "likert_scale",
    icon: "link-icon",
  },
  // {
  // 	key: '11',
  // 	title: 'Opinion Scale',
  // 	type: 'opinion_scale',
  // 	icon: 'opinionscale-icon'
  // },
  {
    key: "12",
    title: "Question Group",
    type: "group",
    icon: "questiongroup-icon",
  },
];

const textField = {
  fileAttachment: {},
  fields: [{ type: "input", value: "", code: "" }],
  type: "short_text",
  label: "Enter question text here",
  properties: {},
  options: {
    showMax: true,
    max: 40,
    attachment: false,
  },
  required: false,
  branches: [],
  branchType: "TEXT",
};
const longTextField = {
  fileAttachment: {},
  fields: [{ type: "input", value: "", code: "" }],
  type: "long_text",
  label: "Enter question text here",
  properties: {},
  options: {
    showMax: true,
    max: 120,
    attachment: false,
  },
  required: false,
  branches: [],
  branchType: "TEXT",
};

const yesNoField = {
  fileAttachment: {},
  fields: [
    { type: "input", value: "", label: "Yes", code: "" },
    { label: "No", code: "" },
  ],
  type: "yes_no",
  label: "Enter question text here",
  properties: {},
  options: {
    showMax: false,
    max: 40,
    attachment: false,
  },
  required: false,
  branches: [],
  branchType: "BOOL",
};

const numberField = {
  fileAttachment: {},
  fields: [{ type: "input", value: "" }],
  type: "short_text",
  label: "Enter question text here",
  properties: {},
  options: {
    showMax: false,
    min: 1,
    max: 40,
    attachment: false,
  },
  required: false,
  branches: [],
  branchType: "TEXT",
};

// const checkField = {
// 	fileAttachment: {},
// 	fields: [{ type: 'checkbox', value: '', label: '' }],
// 	label: 'Enter question text here',
// 	value: '',
// 	properties: {},
// 	options: {
// 		showMax: false,
// 		max: 1,
// 		attachment: false
// 	},
// 	required: false,
// 	branches: [],
// 	branchType: 'CHOICE'
// };

const dropdownField = {
  label: "Enter question text here",
  title: "",
  fileAttachment: {},
  fields: [{ type: "dropdown", id: uuid(), label: "", code: "" }],
  properties: {},
  options: {
    showMax: false,
    max: 1,
    attachment: false,
  },
  required: false,
  branches: [],
  branchType: "CHOICE",
};

const pictureField = {
  label: "Enter question text here",
  fileAttachment: {},
  title: "",
  fields: [{ type: "picture", id: uuid(), value: "", label: "", name: "" }],
  properties: {},
  options: {
    showMax: false,
    max: 1,
    attachment: false,
  },
  required: false,
  branches: [],
  branchType: "CHOICE",
};

const opinionField = {
  fileAttachment: {},
  fields: [{ type: "slider", minRange: 0, maxRange: 5, step: 1 }],
  label: "Enter question text here",
  info: "Select scale",
  title: "",
  properties: {},
  options: {
    showMax: false,
    max: 1,
    attachment: false,
  },
  required: false,
  branches: [],
  branchType: "NUMBER",
};

const groupField = {
  label: "Question Group",
  fileAttachment: {},
  fields: [{ type: "group" }],
  title: "",
  questions: [],
  properties: {},
  options: {
    showMax: false,
    max: 1,
    attachment: false,
  },
  required: false,
  branches: [],
  branchType: "",
};

const likertScaleField = {
  label: "Enter question text here",
  title: "",
  fileAttachment: {},
  fields: [{ type: "dropdown", id: uuid(), label: "", code: "" }],
  properties: {},
  options: {
    showMax: false,
    max: 1,
    attachment: false,
  },
  required: false,
  branches: [],
  branchType: "CHOICE",
};

const multipleSelectionField = {
  label: "Enter question text here",
  title: "",
  fileAttachment: {},
  fields: [{ type: "dropdown", id: uuid(), label: "", code: "" }],
  properties: {},
  options: {
    showMax: false,
    max: 1,
    attachment: false,
  },
  required: false,
  branches: [],
  branchType: "CHOICE",
};

export const fieldMap: any = {
  S: textField,
  short_text: textField,
  long_text: longTextField,
  date: {
    ...textField,
    options: {
      showMax: false,
      max: 40,
      attachment: false,
    },
    branchType: "DATE",
  },
  opinion_scale: {
    ...opinionField,
    properties: {
      responseCodes: [
        {
          condition: "lt",
          maxValue: "",
          code: "",
        },
      ],
    },
  },
  yes_no: yesNoField,
  number: {
    ...numberField,
    branchType: "NUMBER",
    properties: {
      responseCodes: [
        {
          condition: "lt",
          maxValue: "",
          code: "",
        },
      ],
    },
  },
  group: groupField,
  picture_choice: pictureField,
  multiple_choice: dropdownField,
  dropdown: dropdownField,
  likert_scale: likertScaleField,
  checkbox: multipleSelectionField,
  // checkbox: checkField
};

export const textMap: any = {
  multiple_choice: {
    text: "Choice",
  },
  // checkbox: {
  // 	text: 'Checkbox'
  // },
  dropdown: {
    text: "Option",
  },
  likert_scale: {
    text: "Option",
  },
  checkbox: {
    text: "Option",
  },
  picture_choice: {
    text: "Label",
  },
  yes_no: {
    text: "Choice",
  },
};

const textConditions = [
  {
    label: "contains",
    value: "contains",
  },
];

const choiceConditions = [
  {
    label: "is",
    value: "is",
  },
  {
    label: "is not",
    value: "is_not",
  },
];

const numberConditions = [
  {
    label: "Less than",
    value: "lt",
  },
  {
    label: "Less than equal",
    value: "lte",
  },
  {
    label: "Equal",
    value: "eq",
  },
  {
    label: "Not Equal",
    value: "not_eq",
  },
  {
    label: "Greater than equal",
    value: "gte",
  },
  {
    label: "Greater than",
    value: "gt",
  },
];

const dateConditions = [
  {
    label: "is on",
    value: "on",
  },
  {
    label: "is not on",
    value: "not_on",
  },
  {
    label: "is before",
    value: "before",
  },
  {
    label: "is before or on",
    value: "before_or_on",
  },
  {
    label: "is after",
    value: "after",
  },
  {
    label: "is after or on",
    value: "after_or_on",
  },
];

const respNumberConditions = [
  {
    label: "Less than",
    value: "lt",
  },
  {
    label: "Less than equal",
    value: "lte",
  },
  {
    label: "Equal",
    value: "eq",
  },
  {
    label: "Between",
    value: "between",
  },
  {
    label: "Greater than equal",
    value: "gte",
  },
  {
    label: "Greater than",
    value: "gt",
  },
];

export const conditions: any = {
  TEXT: textConditions,
  CHOICE: choiceConditions,
  BOOL: choiceConditions,
  NUMBER: numberConditions,
  DATE: dateConditions,
  NUM: respNumberConditions,
};
