import { Input } from "antd";
import { Formik } from "formik";
import AuthLayout from "../AuthLayout/AuthLayout";
import { Row, Col } from "antd";
import CustomButton from "../Common/CustomButton";
import * as yup from "yup";
import logo from "../../assets/img/logo-singer.svg";
import { useAppDispatch } from "../../redux/hooks";
import { errorToastMessage } from "../../helpers/toastMessage";
import { forgotPassword } from "../../redux/actions/userAction";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
});

const ForgotPassword: React.FC = () => {
  const dispatch = useAppDispatch();

  const submitHandler = async (values: any, setSubmitting: Function) => {
    try {
      setSubmitting(true);
      let resultObj = {
        email: values.email,
      };
      await dispatch(forgotPassword(resultObj));
      setSubmitting(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitting(false);
    }
  };

  return (
    <div className="d-block auth-layout">
      <Row className="h-100">
        <Col sm={24} md={12}>
          <div className="auth-left-container d-flex align-items-center justify-content-center h-100">
            <div className="flex-grow-1">
              <img
                src={logo}
                alt="SINGER-logo"
                width="200px"
                className="mb-5"
              />
              <div className="px-md-4">
                <h2 className="font-l fw-bold mb-5">Forgot Password?</h2>
                <p className="mb-3">
                  Don’t worry, just enter the email address you registered with
                  and we will send you a link to reset your password.
                </p>
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={schema}
                  onSubmit={(values, { setSubmitting }: any) => {
                    submitHandler(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="input-container mb-4">
                        <div className="floating-label">
                          <Input
                            type="text"
                            name="email"
                            placeholder="Enter your email address"
                            onChange={handleChange}
                            className="floating-input"
                            value={values.email}
                            onBlur={handleBlur}
                          />
                          <div className="input-error">
                            {errors.email && touched.email && errors.email}
                          </div>
                        </div>
                      </div>

                      <div className="bottom-pos-btn-container">
                        <CustomButton
                          buttonType="submit"
                          buttonText="Send Password Reset Link"
                          className="login-button  btn-block"
                          loading={isSubmitting}
                          primary={true}
                        />
                      </div>
                      <h6 className="text-center mt-4">
                        <a
                          href="/auth/login"
                          className="fw-semibold link-primary ps-3"
                        >
                          Sign in ?
                        </a>
                      </h6>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={12} className="bg-primary-blue p-3 p-lg-4">
          <AuthLayout
            headerText="Know your health better!"
            paragraphText="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
          />
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
