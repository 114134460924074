import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { appointmentPerTimePeriodConstructor } from "../../../helpers/Appointment";
import http from "../../../http";
import { AxiosResponse } from "axios";
import { Table } from "antd";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import roles from "../../../constants/roles";
import { AppointmentType, ContactType } from "../../../constants/appointment";

type Props = {
  tabType: string;
  patientId: string;
};

const PatientAppointmentsTab: React.FC<Props> = ({ tabType, patientId }) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(
    async (page, tabType, patientId) => {
      try {
        dispatch(setAppLoader(true));
        let query = appointmentPerTimePeriodConstructor(tabType);
        query += `&page=${page}&size=8&unconfirmed=false&status=confirmed`;
        if (patientId) {
          query += `&inviteeId=${patientId}`;
        }
        let url = `/ap/generic${query}`;
        const res: AxiosResponse = await http.get(url);
        setData(res.data.data.rows);
        setTotal(res.data.data.count);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [setData, setTotal, dispatch]
  );

  useEffect(() => {
    fetchData(1, tabType, patientId);
  }, [fetchData, tabType, patientId]);

  const onTableChange = async (pagination: any) => {
    let page = 1;
    if (pagination.current) {
      setCurrentPage(pagination.current);
      page = pagination.current;
    }
    fetchData(page, tabType, patientId);
  };

  const columns = useMemo(() => {
    let temp: any[] = [
      {
        title: "Appointment Type",
        dataIndex: "type",
        render: (data: any) => AppointmentType[data],
      },
      {
        title: "Contact Type",
        dataIndex: "contactType",
        render: (data: any) => ContactType[data],
      },
    ];
    if (tabType !== "today") {
      temp.push({
        title: "Date",
        dataIndex: "scheduledStartTime",
        render: (date: any, record: any) => {
          return <span>{moment(date).format("MMM D, YYYY")}</span>;
        },
      });
    }
    temp.push({
      title: "Time",
      dataIndex: "scheduledStartTime",
      render: (date: any, record: any) => {
        return <span>{moment(date).format("hh:mm A")}</span>;
      },
    });
    if (localStorage.getItem("role") !== roles.Therapist["be_value"]) {
      temp.push({
        title: "Coach Assigned",
        key: "inviter",
        render: (_1: any, record: any) => {
          const name = record.inviter.firstName + " " + record.inviter.lastName;
          return (
            <NavLink
              className="no-underline link-normal"
              to={`/appointments/therapist/${record.inviterId}?name=${name}`}
            >
              {name}
            </NavLink>
          );
        },
      });
    }
    temp.push({
      title: "Notes",
      key: "notes",
      dataIndex: "notes",
    });
    return temp;
  }, [tabType]);

  return (
    <Table
      dataSource={data}
      columns={columns}
      className="singer-custom-table"
      onChange={onTableChange}
      rowKey={(record) => record.id}
      pagination={{
        current: currentPage,
        total: total,
        showSizeChanger: false,
        pageSize: 8,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
        },
        position: ["bottomLeft"],
      }}
    />
  );
};

export default PatientAppointmentsTab;
