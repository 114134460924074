import React, { useState } from "react";
import { Collapse } from "antd";
import AppointmentItem from "./AppointmentItem";
import { DownOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

type GroupProps = {
  data: any[];
  acceptHandler: Function;
  cancelHandler: Function;
  type: string;
};

const AppointmentGroup: React.FC<GroupProps> = ({
  data,
  acceptHandler,
  cancelHandler,
  type,
}) => {
  const [openPanel, setOpenPanel] = useState([]);
  const changeHandler = (key: any) => {
    setOpenPanel(key);
  };

  return (
    <>
      <Collapse
        className="appointment-collapse"
        onChange={changeHandler}
        activeKey={openPanel}
        expandIcon={({ isActive }) => (
          <DownOutlined className="primary-icon" rotate={isActive ? 180 : 0} />
        )}
        expandIconPosition="right"
      >
        {data.map((group) => {
          if (group.appointments.length === 0) {
            return null;
          }
          return (
            <Panel
              className="appointment-panel"
              header={group.date}
              key={group.date}
            >
              {group.appointments.map((appointment: any) => {
                return (
                  <AppointmentItem
                    type={type}
                    key={appointment.id}
                    appointment={appointment}
                    cancelHandler={cancelHandler}
                    acceptHandler={acceptHandler}
                  />
                );
              })}
            </Panel>
          );
        })}
      </Collapse>
    </>
  );
};

export default AppointmentGroup;
