import React, { useState, useRef } from "react";
import composeRefs from "@seznam/compose-react-refs";
import { connect } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import { Input, Upload, Select, Slider, Tooltip } from "antd";
import { textMap, choices, fieldMap } from "./dataList";
import { toastMessage } from "../../../helpers/toastMessage";
import QuestionGroup from "./QuestionGroup";
import { uploadAsset } from "../../../helpers/imageUpload";
import ResponseLogic from "./ResponseLogic";
import BranchLogic from "./BranchLogic";
import * as types from "../../../redux/actionTypes";
import { uuid } from "uuidv4";
import DataValidation from "./DataValidation";

const groupOptions = choices.filter((choice: any) => choice.type !== "group");

type Props = {
  choice: any;
  index: number;
  accepted: any;
  handleDrop: Function;
  duplicate: Function;
  duplicationGroupQ: Function;
  delete: Function;
  setInnerFields: Function;
  chooseField?: boolean;
  questions: any;
  uploadAsset: Function;
  moveItem: Function;
  handleSequenceDrop: Function;
  orginalGroupQs?: any;
  originalGroupIndex?: any;
  questionnaireStatus: any;
  viewType?: string;
  qid?: string;
};

const TextChoiceField = (props: Props) => {
  const ref = useRef<HTMLElement>(null);

  const { choice, index } = props;
  const [showDataValidation, setShowDataValidation] = useState(false);
  const [showBranching, setShowBranching] = useState(false);
  const [showResponseModal, setResponseModal] = useState(false);

  //drop from Q-list
  const [, drop] = useDrop({
    accept: props.accepted,
    drop: (dropItem: any) => {
      if (!props.chooseField) props.handleDrop(dropItem.type, props.index);
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  //drag and drop to re-sequence
  const [{ isDragging }, drag] = useDrag({
    item: { ...choice, type: types.ITEM_TYPE },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropper] = useDrop({
    accept: types.ITEM_TYPE,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item["position"] - 1;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoveredRect = ref?.current?.getBoundingClientRect();
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
      const mousePosition: any = monitor.getClientOffset();
      const hoverClientY = mousePosition.y - hoveredRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      handleMovingItem(dragIndex, hoverIndex);
      item.position = hoverIndex + 1;
    },
    drop: (item: any) => {
      const dragIndex = item["position"] - 1;
      const hoverIndex = props.index;
      props.handleSequenceDrop(dragIndex, hoverIndex);
    },
  });

  const handleMovingItem = (dragIndex: any, hoverIndex: any) => {
    props.moveItem(dragIndex, hoverIndex);
  };

  drag(dropper(ref));

  const addField = (idx?: number) => {
    const type = choice.fields[0].type;
    const toBeAdded = { type: type, id: uuid(), label: "", code: "" };

    // const prev = choice.fields.slice(0, idx + 1);
    // const next = choice.fields.slice(idx, -1);

    const newField = {
      ...choice,
      fields: [...choice.fields, toBeAdded],
    };

    console.log(idx, "addFieldNew");
    props.setInnerFields(index, newField);
  };

  const removeField = (idx: number) => {
    if (choice.fields.length > 1) {
      const newField = {
        ...choice,
        fields: choice.fields.filter((_: any, i: number) => i !== idx),
      };
      props.setInnerFields(index, newField);
    }
  };

  const handleChange = (idx: number, e: any, outer?: boolean) => {
    if (!outer) {
      const newVal = {
        ...choice,
        fields: choice.fields.map((f: any, i: number) =>
          i === idx ? { ...f, [e.target.name]: e.target.value } : f
        ),
      };
      props.setInnerFields(index, newVal);
    } else {
      const newVal = {
        ...choice,
        title: e.target.value,
      };
      props.setInnerFields(index, newVal);
    }
  };

  const handleSelectChange = (idx: number, val: any, outer?: boolean) => {
    if (!outer) {
      const newVal = {
        ...choice,
        fields: choice.fields.map((f: any, i: number) =>
          i === idx ? { ...f, questionCode: val } : f
        ),
      };
      props.setInnerFields(index, newVal);
    } else {
      const newVal = {
        ...choice,
        questionCode: val,
      };
      props.setInnerFields(index, newVal);
    }
  };

  const handleResponseCode = (idx: number, e: any, outer?: boolean) => {
    if (!outer) {
      const regex = /^[0-9\b]+$/;
      if (e.target.value === "" || regex.test(e.target.value)) {
        const newVal = {
          ...choice,
          fields: choice.fields.map((f: any, i: number) =>
            i === idx ? { ...f, [e.target.name]: e.target.value } : f
          ),
        };
        props.setInnerFields(index, newVal);
      }
    } else {
      const newVal = {
        ...choice,
        title: e.target.value,
      };
      props.setInnerFields(index, newVal);
    }
  };

  const handlePictureChoice = async (idx: number, file: File) => {
    const imgUrl = await props.uploadAsset(
      file,
      "questionnaire_image",
      "Upload Successful."
    );
    const newVal = {
      ...choice,
      fields: choice.fields.map((f: any, i: number) =>
        i === idx ? { ...f, value: imgUrl, name: file.name } : f
      ),
    };
    props.setInnerFields(index, newVal);
    return false;
  };

  const handleOpinionScale = (scale: any) => {
    const newVal = {
      ...choice,
      fields: choice.fields.map((f: any) => ({
        ...f,
        minRange: scale[0],
        maxRange: scale[1],
      })),
    };
    props.setInnerFields(index, newVal);
  };

  const handleOptions = (e: any) => {
    const newField = {
      ...choice,
      options: {
        ...choice.options,
        [e.target.name]: e.target.checked ? e.target.checked : e.target.value,
      },
    };

    props.setInnerFields(index, newField);
  };

  const handleMax = (e: any) => {
    if (e.target.id === "plus") {
      const newField = {
        ...choice,
        options: {
          ...choice.options,
          max: choice.options.max + 1,
        },
      };
      props.setInnerFields(index, newField);
    } else {
      const newField = {
        ...choice,
        options: {
          ...choice.options,
          max: choice.options.max - 1,
        },
      };
      props.setInnerFields(index, newField);
    }
  };

  const handleMin = (e: any) => {
    if (e.target.id === "plus") {
      const newField = {
        ...choice,
        options: {
          ...choice.options,
          min: choice.options.min + 1,
        },
      };
      props.setInnerFields(index, newField);
    } else {
      const newField = {
        ...choice,
        options: {
          ...choice.options,
          min: choice.options.min - 1,
        },
      };
      props.setInnerFields(index, newField);
    }
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      toastMessage("warning", "Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const changeQuestionType = (type: string) => {
    const newField = {
      ...fieldMap[type],
      type,
      id: choice.id,
    };
    props.setInnerFields(index, newField);
  };

  const renderFields = (field: any, idx: any) => {
    switch (field.type) {
      case "input":
        return (
          <Tooltip placement="right" title={choice.type}>
            <div className="d-flex align-items-center mb-3 cp" key={idx}>
              <div
                style={{
                  backgroundColor: `${iconClassNames[choice.type].color}`,
                }}
                className="field-header"
              >
                <span>{index + 1}</span>
                <i className={iconClassNames[choice.type].icon}></i>
              </div>
              <div className="qb-floating-label">
                <Input
                  value={field.value}
                  name="value"
                  onChange={(e) => handleChange(idx, e)}
                  placeholder="Type question here..."
                />
              </div>
            </div>
          </Tooltip>
        );
      case "checkbox":
        return (
          <div className="field-group" key={idx}>
            <div className="checkboxes mt-3">
              <div className="circle"></div>
              <div className="me-3">
                <Input
                  name="label"
                  value={field.label}
                  onChange={(e) => handleChange(idx, e)}
                  placeholder={`${textMap[choice.type].text} ${idx + 1}`}
                />
              </div>
              <div className="add me-3">
                <i
                  className="add-green-icon cp"
                  onClick={() => addField(idx)}
                ></i>
              </div>
              <div className="remove">
                <i
                  className="delete-green-icon cp"
                  onClick={() => removeField(idx)}
                ></i>
              </div>
            </div>
          </div>
        );
      case "dropdown":
        return (
          <div className="field-group" key={idx}>
            <div className="checkboxes mt-3">
              <div className="circle"></div>
              <div className="me-3">
                <Input
                  name="label"
                  value={field.label}
                  onChange={(e) => handleChange(idx, e)}
                  placeholder={`${textMap[choice.type].text} ${idx + 1}`}
                />
              </div>
              <div className="add me-3">
                <i
                  className="add-green-icon cp"
                  onClick={() => addField(idx)}
                ></i>
              </div>
              <div className="remove">
                <i
                  className="delete-green-icon cp"
                  onClick={() => removeField(idx)}
                ></i>
              </div>
            </div>
          </div>
        );
      case "likert_scale":
        return (
          <div className="field-group" key={idx}>
            <div className="checkboxes mt-3">
              <div className="circle"></div>
              <div className="me-3">
                <Input
                  name="label"
                  value={field.label}
                  onChange={(e) => handleChange(idx, e)}
                  placeholder={`${textMap[choice.type].text} ${idx + 1}`}
                />
              </div>
              <div className="add me-3">
                <i
                  className="add-green-icon cp"
                  onClick={() => addField(idx)}
                ></i>
              </div>
              <div className="remove">
                <i
                  className="delete-green-icon cp"
                  onClick={() => removeField(idx)}
                ></i>
              </div>
            </div>
          </div>
        );
      case "picture":
        return (
          <div className="field-group" key={idx}>
            <div className="checkboxes">
              <div className="circle"></div>
              <div className="first-input">
                <Input
                  name="label"
                  value={field.label}
                  onChange={(e) => handleChange(idx, e)}
                  placeholder={`${textMap[choice.type].text} ${idx + 1}`}
                />
              </div>
              <div className="image-label-input">
                <Upload
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={({ file }) =>
                    handlePictureChoice(idx, file as File)
                  }
                  accept=".jpg,.jpeg,.png"
                >
                  <Input
                    style={{ cursor: "pointer" }}
                    name="value"
                    value={field.name}
                    placeholder="Upload image"
                    disabled
                  />
                </Upload>
              </div>
              <div className="add ms-3 me-2">
                <i
                  className="add-green-icon cp"
                  onClick={() => addField(idx)}
                ></i>
              </div>
              <div className="remove">
                <i
                  className="delete-green-icon cp"
                  onClick={() => removeField(idx)}
                ></i>
              </div>
            </div>
          </div>
        );
      case "slider":
        return (
          <div className="field-group" key={idx}>
            <Slider
              defaultValue={[field.minRange, field.maxRange]}
              min={0}
              max={10}
              step={1}
              onChange={(scale) => handleOpinionScale(scale)}
              range
              className="slider-choice"
            />
          </div>
        );
      case "group":
        return (
          <QuestionGroup
            group={choice}
            accepted={props.accepted}
            handleDrop={props.handleDrop}
            index={index}
            setInnerFields={props.setInnerFields}
            questions={props.questions}
            key={idx}
            questionnaireStatus={props.questionnaireStatus}
            duplicationGroupQ={props.duplicationGroupQ}
            moveItem={props.moveItem}
            handleSequenceDrop={props.handleSequenceDrop}
            qid={props.qid}
          />
        );
      default:
        return <></>;
    }
  };

  const iconClassNames: any = {
    multiple_choice: { icon: "multichoice-icon", color: "#47AAB2" },
    dropdown: { icon: "dropdown-icon", color: "#584579" },
    likert_scale: { icon: "opinionscale-icon", color: "#74A4BE" },
    checkbox: { icon: "checkboxes-icon", color: "#3A7684" },
    picture_choice: { icon: "picturechoice-icon", color: "#A086C2" },
    short_text: { icon: "shorttext-icon", color: "#FFBA54" },
    long_text: { icon: "longtext-icon", color: "#E26E5D" },
    yes_no: { icon: "yesno-icon", color: "#BF3A5B" },
    date: { icon: "date-icon", color: "#F09F98" },
    number: { icon: "number-icon", color: "#E05078" },
    opinion_scale: "opinionscale-icon",
    group: { icon: "questiongroup-icon", color: "#D94D4E" },
  };

  return (
    <div
      className={
        props.questionnaireStatus === "published"
          ? "choice-field disabled-div-qb"
          : "choice-field"
      }
      // @ts-ignore
      ref={composeRefs(ref, drop)}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      {props.chooseField && (
        <div className="question-group-choice mb-3">
          <label>
            <b>Question Type</b>
          </label>
          <Select
            onChange={changeQuestionType}
            value={choice.type}
            style={{ width: "200px", marginLeft: "10px" }}
          >
            {groupOptions.map((option: any) => (
              <Select.Option key={option.key} value={option.type}>
                {option.title}
              </Select.Option>
            ))}
          </Select>
        </div>
      )}

      {choice && choice.hasOwnProperty("title") && (
        <Tooltip placement="right" title={choice.type}>
          <div className="d-flex align-items-center mb-3 cp">
            <div
              style={{
                backgroundColor: `${iconClassNames[choice.type].color}`,
              }}
              className="field-header"
            >
              <span>{index + 1}</span>
              <i className={iconClassNames[choice.type].icon}></i>
            </div>

            <div className="qb-floating-label">
              <Input
                value={choice.title}
                name="title"
                className="mb-2"
                onChange={(e) => handleChange(index, e, true)}
                placeholder="Type question here..."
              />
            </div>
          </div>
        </Tooltip>
      )}

      {choice.fields.map((field: any, idx: any) => renderFields(field, idx))}

      {choice.type === "picture_choice" && (
        <div className="mt-3 ms-4">
          Upload an image of max 5 mb, accepted formats are .jpg,.jpeg,.png{" "}
        </div>
      )}

      <div className="options mb-4">
        {choice.options.showMax ? (
          <div className="max">
            <span>Max</span>
            <span className="icons-wrapper">
              <i id="plus" className="arrow-up-icon cp" onClick={handleMax}></i>
              <i
                id="minus"
                className="arrow-down-icon cp"
                onClick={handleMax}
              ></i>
            </span>
            <Input
              name="max"
              type="number"
              value={choice.options.max}
              onChange={handleOptions}
            />
            {choice.type === "number" ? (
              <span className="ms-2">Values</span>
            ) : (
              <span className="ms-2">Characters</span>
            )}
          </div>
        ) : (
          <>
            {choice.options.hasOwnProperty("min") && (
              <>
                <div className="max">
                  <span>Min</span>
                  <span className="icons-wrapper">
                    <i
                      id="plus"
                      className="arrow-up-icon cp"
                      onClick={handleMin}
                    ></i>
                    <i
                      id="minus"
                      className="arrow-down-icon cp"
                      onClick={handleMin}
                    ></i>
                  </span>
                  <Input
                    name="min"
                    type="number"
                    value={choice.options.min}
                    onChange={handleOptions}
                  />
                </div>
                <div className="max ms-2">
                  <span>Max</span>
                  <span className="icons-wrapper">
                    <i
                      id="plus"
                      className="arrow-up-icon cp"
                      onClick={handleMax}
                    ></i>
                    <i
                      id="minus"
                      className="arrow-down-icon cp"
                      onClick={handleMax}
                    ></i>
                  </span>
                  <Input
                    name="max"
                    type="number"
                    value={choice.options.max}
                    onChange={handleOptions}
                  />
                  {choice.type === "number" ? (
                    <span className="ms-2">Values</span>
                  ) : (
                    <span className="ms-2">Characters</span>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>

      {props.qid === "demographic" && choice.type !== "group" ? (
        <div className="d-flex">
          <div className="me-2">Change field in profile</div>
          <div className="w-50">
            <Select
              size="small"
              // defaultValue={null}
              value={choice.questionCode ? choice.questionCode : null}
              onChange={(val) => handleSelectChange(index, val, true)}
            >
              <Select.Option value="">None</Select.Option>
              <Select.Option value="dob">Date Of Birth</Select.Option>
              <Select.Option value="gender">Gender</Select.Option>
              <Select.Option value="ethnicity">Ethnicity</Select.Option>
              <Select.Option value="residentialStatus">
                Residential Status
              </Select.Option>
              <Select.Option value="district">Home Address</Select.Option>
              <Select.Option value="addressDuringSem">
                Semester Address
              </Select.Option>
              {/* <Select.Option value='school' >School</Select.Option> */}
              <Select.Option value="onCampusAddress">
                Campus Residance
              </Select.Option>
              <Select.Option value="offCampusAddress">
                Off Campus Residence
              </Select.Option>
              <Select.Option value="facultyOfStudy">
                Faculty Of Study
              </Select.Option>
              <Select.Option value="course">Course Of Study</Select.Option>
              <Select.Option value="country">Country</Select.Option>
              <Select.Option value="currentLocalAddress">
                Current Local Address
              </Select.Option>
            </Select>
          </div>
        </div>
      ) : null}

      <div className="questionaire-actions">
        <div className="left-actions"></div>
        <div className="right-actions">
          {!["group", "checkbox"].includes(choice.type) && (
            <div className="branch me-3">
              <Tooltip placement="bottom" title="Logic Jump">
                <i
                  className="branch-icon cp"
                  onClick={() => setShowBranching(true)}
                ></i>
              </Tooltip>
            </div>
          )}
          {["number"].includes(choice.type) && (
            <div className="response me-3">
              <Tooltip placement="bottom" title="Data Validation">
                <i
                  className="data-validation-icon cp"
                  onClick={() => setShowDataValidation(true)}
                ></i>
              </Tooltip>
            </div>
          )}
          {!["group"].includes(choice.type) && (
            <div className="response me-3">
              <Tooltip placement="bottom" title="Response Code">
                <i
                  className="response-icon cp"
                  onClick={() => setResponseModal(true)}
                ></i>
              </Tooltip>
            </div>
          )}
          <div className="duplicate me-3">
            <Tooltip placement="bottom" title="Duplicate">
              <i
                className="copy-icon cp"
                onClick={() => {
                  if (props.orginalGroupQs && props.duplicationGroupQ) {
                    props.duplicationGroupQ(props.orginalGroupQs, choice);
                  } else {
                    props.duplicate(index, choice.type);
                  }
                }}
              ></i>
            </Tooltip>
          </div>
          <div className="delete">
            <Tooltip placement="bottom" title="Delete">
              <i
                className="delete-red-icon cp"
                onClick={() => props.delete(index)}
              ></i>
            </Tooltip>
          </div>
        </div>

        <ResponseLogic
          show={showResponseModal}
          hideModal={setResponseModal}
          question={props.choice}
          index={props.index}
          handleResponseCode={handleResponseCode}
          setInnerFields={props.setInnerFields}
        />

        {showBranching && (
          <BranchLogic
            show={showBranching}
            choice={props.choice}
            questions={props.questions}
            index={props.index}
            setInnerFields={props.setInnerFields}
            hideModal={setShowBranching}
          />
        )}

        {showDataValidation && (
          <DataValidation
            show={showDataValidation}
            choice={props.choice}
            questions={props.questions}
            index={props.index}
            hideModal={setShowDataValidation}
          />
        )}
      </div>
    </div>
  );
};

export default connect(null, { uploadAsset })(TextChoiceField);
