import React, { useEffect, useState } from "react";
import { Modal, Tabs } from "antd";
import AvailabilityForm from "./AvailabilityForm";
import { useAppDispatch } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { fetchTherapistTimings } from "../../../redux/actions/adminAction";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import AppointmentCreation from "./AppointmentCreation";

const { TabPane } = Tabs;

const TherapistSlotModal = (props: any) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const closeHandler = () => {
    dispatch(hideModal());
  };

  const { therapistId } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchTherapistTimings(therapistId));
        setLoading(false);
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
        dispatch(hideModal());
      }
    };
    fetchData();
  }, [dispatch, therapistId, setLoading]);

  return (
    <Modal
      visible={props.show}
      onCancel={closeHandler}
      width={"30rem"}
      footer={null}
      className="therapist-slot-wrapper"
    >
      <div className="theropist-modal">
        <Tabs className="therapist-slot-tab singer-tabs-color">
          <TabPane tab="Appointment" key="appointments">
            {loading ? null : <AppointmentCreation therapistId={therapistId} />}
          </TabPane>
          <TabPane tab="Availability" key="availability">
            {loading ? null : <AvailabilityForm />}
          </TabPane>
        </Tabs>
      </div>
    </Modal>
  );
};

export default TherapistSlotModal;
