import React, { useEffect, useState } from "react";
import { Modal, Breadcrumb, Avatar } from "antd";
import { Popover } from "antd";
import { useAppDispatch } from "../../redux/hooks";
import { authLogout } from "../../redux/actions/userAction";
import { useHistory } from "react-router-dom";
import Logo_Black from "../Common/Logo_black";
import { LogoSidebar } from "../../components/AppLayout/SidebarIcons";
import { getImageInitialsFullname } from "../../helpers/user";
const confirm = Modal.confirm;

type Props = {
  // authLogout: (body?: any) => void;
  title?: string;
  logoTopNav?: boolean;
  back?: boolean;
  prev?: string;
  warningMessage?: string;
  textSave?: string;
  confirmMessage?: boolean;
  addCrumb?: boolean;
  subtitle?: string;
  scoreBack?: boolean;
  scorePreview?: boolean;
  qid?: string;
  qname?: string;
};

const Navigation = (props: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [buttonDisable, setButtonDisable] = useState(false);

  const [image, setImage] = useState<string | null>("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const img = localStorage.getItem("profileImage");
      setImage(img);
      const name = localStorage.getItem("fullName") || "";
      setName(name);
    }
  }, [setImage, setName]);

  const showConfirm = () => {
    confirm({
      title: "Logout",
      content: "Are you sure you want to logout ?",
      onOk() {
        dispatch(authLogout());
      },
      onCancel() {
        // setButtonDisable(false);
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const Logout = (
    <ul className="welcone-dropdown">
      <li
        onClick={() => {
          history.push("/profile/edit");
        }}
        role="button"
        onKeyDown={() => console.log("edit profile keyed down")}
        tabIndex={-1}
      >
        Edit Profile
      </li>
      <li
        onClick={() => history.push("/auth/change-password")}
        role="button"
        onKeyDown={() => console.log("password show keyed down")}
        tabIndex={-1}
      >
        Change password
      </li>
      <li
        onClick={showConfirm}
        role="button"
        onKeyDown={() => console.log("confirm show keyed down")}
        tabIndex={-1}
      >
        Logout
      </li>
    </ul>
  );

  function showConfirmMessage() {
    confirm({
      title: "Education",
      content: props.warningMessage,
      onOk() {
        if (props.textSave === "Confirm" && props.prev) {
          history.push(props.prev);
        }
      },
      onCancel() {
        if (props.textSave === "Ok" && props.prev) {
          history.push(props.prev);
        }
      },
      okText: props.textSave,
      cancelText: props.textSave === "Ok" ? "Go anyway" : "Cancel",
    });
  }

  const redirect = () => {
    if (props.back) {
      history.goBack();
    } else if (props.confirmMessage) {
      showConfirmMessage();
    } else if (props.prev) {
      history.push(props.prev);
    }
  };

  const breadCrumbRedirect = () => {
    if (props.scoreBack && props.scorePreview) {
      history.push(
        `/question/preview?id=${props.qid}&name=${props.qname}&view=staticView`
      );
    } else if (props.scoreBack) {
      history.push(
        `/question/builder?id=${props.qid}&name=${props.qname}&view=canEdit`
      );
    } else {
      history.goBack();
    }
  };

  return (
    <div className="navigation">
      <div className="logo-container d-block d-md-none m-3">
        <a href="/">
          <LogoSidebar />
        </a>
      </div>
      {props.addCrumb ? (
        <div>
          <Breadcrumb separator=" > " className="navigation-crumb cp">
            <Breadcrumb.Item onClick={breadCrumbRedirect}>
              {props.title}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{props.subtitle}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      ) : (
        <div className="ps-md-5 ps-2 d-flex align-items-center">
          <h1 className="font-ml fw-bold m-0">
            {(props.prev || props.back) && (
              <span
                onClick={redirect}
                role="button"
                onKeyDown={() => console.log("back keyed down")}
                tabIndex={-1}
              >
                <i className="arrow-left-icon me-3 cp"></i>
              </span>
            )}
            {props.title}
          </h1>
          {props.logoTopNav && (
            <div className="logo-container me-4">
              <Logo_Black width="160px" />
            </div>
          )}
        </div>
      )}
      <div className="actions">
        <div className="action-button d-none d-md-block border-start border-light">
          <i className="notification-icon-ver2 cp icon-sm"></i>
        </div>
        <div className="action-button d-none d-md-block border-start border-light">
          <i className="help-icon cp icon-sm"></i>
        </div>
        <div className="user">
          <Popover placement="bottomRight" content={Logout} trigger={["click"]}>
            <div className="d-flex align-items-center cp">
              <h6 className="d-flex m-0 pe-2">{name}</h6>
              <div className="notification d-none d-md-flex">
                {image !== "" ? (
                  <div className="image-container-nav user-icon-img">
                    <Avatar shape="circle" src={image} />
                  </div>
                ) : (
                  <Avatar shape="circle">
                    {getImageInitialsFullname(name)}
                  </Avatar>
                )}
              </div>
              <div className="settings-circle-icon ms-2"></div>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
