import React, { useEffect, useMemo, useState } from "react";
import { Input } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { debounce } from "lodash";
import { setAdminSearchTerm } from "../../redux/reducers/administratorSlice";
import CustomButton from "../Common/CustomButton";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import roles from "../../constants/roles";

type Props = {
  type: string;
};

const AdministratorHeader: React.FC<Props> = ({ type }) => {
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector((state) => state.administrator.searchTerm);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setSearch(searchTerm);
  }, [searchTerm]);

  const modifySearchTerm = useMemo(() => {
    return debounce((val) => {
      dispatch(setAdminSearchTerm(val));
    }, 500);
  }, [dispatch]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearch(val);
    modifySearchTerm(val);
  };

  const handleButtonClick = () => {
    const updatedType =
      type === roles.Therapist["fe_value"] ? type : roles.Admin["fe_value"];
    dispatch(
      setModalDetails({
        type: `ADD_${updatedType}_MODAL`,
        modalProps: {
          show: true,
          type: updatedType,
          header: type,
        },
      })
    );
  };

  return (
    <div className="d-block d-md-flex justify-content-between align-items-center">
      <Input
        value={search}
        onChange={changeHandler}
        style={{ width: "300px" }}
        placeholder="Search..."
        className="search-input mb-4"
      />
      <CustomButton
        primary={true}
        clickHandler={handleButtonClick}
        buttonText={`Add ${type}`}
        addButton={true}
        buttonType={"button"}
        className="btn-lg mb-4"
      />
    </div>
  );
};

export default AdministratorHeader;
